import styled from 'styled-components';
import { DocRoomCard } from '../doc-room-wrapper/doc-room-card/DocRoomCard';

export const StyledColexoRoom = styled(DocRoomCard)`
    .description {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
        align-self: stretch;
    }
    .clx-raum-logo {
        display: flex;
        align-items: center;
    }
    .clx-raum-logo .icon {
        width: 5.775rem;
        height: 3.88688rem;
    }
    .clx-raum-logo .text {
        margin-left: -1rem;
        width: 5.4375rem;
        height: 2rem;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.1rem */
    }
    .todo-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 2.3125rem;
        align-self: stretch;
    }
`;
