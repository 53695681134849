import React, { ReactNode } from 'react';
import { StyledColexoRoom } from './ColexoRoom.styles';
import { ContentWrapper } from './content-wrapper/ContentWrapper';

interface ColexoRoomProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoRoom: React.FC<ColexoRoomProps> = ({ className, children }) => {
    return (
        <StyledColexoRoom className={className}>
            <ContentWrapper/>
            <div className={'illustration-wrapper'}>
                <img src={'/illustration.png'}/>
            </div>
        </StyledColexoRoom>
    );
};
