import styled from 'styled-components';

export const StyledFormElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    & .label {
        display: flex;
        width: 10.08206rem;
        height: 0.875rem;
        flex-direction: column;
        justify-content: center;
        color: #1A384E;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }

    textarea, input[type='text'] {
        display: flex;
        padding: 1.125rem 0.875rem;
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;
        border-radius: 0.375rem;
        border: 1px solid #8A9AB1;
    }
    textarea:focus, input[type='text']:focus {
        background: #E3F2FF;
    }
`;
