import styled from 'styled-components';
import { AlertCard } from './alert/AlertCard';

export const StyledFileValueErrorAlert = styled(AlertCard)`
    & button {
        display: flex;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-md, 0.5rem);
        align-self: stretch;
        border-radius: 0.3125rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Base-Neutral-600, #1A384E);
        color: var(--Base-White, #FFF);
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 1rem */

        & .caption {
            color: var(--Base-White, #FFF);
            text-align: center;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 1rem */
        }
    }
`;
