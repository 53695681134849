import React, { ReactNode } from 'react';
import { StyledCalculationPage } from './CalculationPage.styles';
import { CenAttributeSection, CenCursorList } from '@baumeister/web-cen-protocol';
import { useCenPageContext } from '@baumeister/web-cen-protocol';
import { Col, Row } from '@baumeister/web-ui-bootstrap';
import { CustomNodeFlow } from './CustomNodeFlow';
import { ReactFlowProvider } from 'reactflow';

interface CalculationPageProps {
    className?: string;
    children?: ReactNode;
}

export const CalculationPage: React.FC<CalculationPageProps> = ({ className, children }) => {
    const { pageData, channel } = useCenPageContext();
    return (
        <StyledCalculationPage className={className}>
            <CenAttributeSection entityData={pageData.data} dictionary={pageData.dictionary} />
            <Row>
                <Col lg={12}>
                    <ReactFlowProvider>
                        <CustomNodeFlow/>
                    </ReactFlowProvider>
                </Col>
            </Row>
            {pageData.children.map((child) => (
                <CenCursorList
                    key={child.listPageData.name}
                    cursor={child}
                />
            ))}
        </StyledCalculationPage>
    );
};
