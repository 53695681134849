import React, { ReactNode } from 'react';
import { StyledPreview } from './Preview.styles';
import { ReportPreviewContent } from './content/ReportPreviewContent';
import { ReportHeader } from './content/header/ReportHeader';
import { selectPageChildCursorData, useCenPageContext } from '@baumeister/web-cen-protocol';
import {
    IFileValue, IReportBaseWidgetValue, IReportChartDataValue,
    IReportDesignBaseWidget,
    IReportDesignPage, IReportDesignSection, IReportFileSourceValue,
    IReportPageValue, IReportTableCellValue, IReportTableHeaderValue,
} from '../../../../../../serverTypes';
import { useAppSelector } from '../../../../../../store';
import { IReportPage } from '../../types';
import { collectReport } from './reportCollector';

interface PreviewProps {
    className?: string;
    onEmptyClick: () => void;
    children?: ReactNode;
}

export const Preview: React.FC<PreviewProps> = ({ className, onEmptyClick, children }) => {
    const { pageData, channel } = useCenPageContext();
    const reportDesignPage: IReportDesignPage[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignPage"));
    const reportDesignSection: IReportDesignSection[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignSection"));
    const reportDesignBaseWidget: IReportDesignBaseWidget[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignBaseWidget"));
    const reportPageValue: IReportPageValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportPageValue"));
    const reportBaseWidgetValue: IReportBaseWidgetValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportBaseWidgetValue"));
    const reportChartDataValue: IReportChartDataValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportChartDataValue"));
    const reportTableHeaderValue: IReportTableHeaderValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableHeaderValue"));
    const reportTableCellValue: IReportTableCellValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableCellValue"));
    const reportFileSourceValue: IReportFileSourceValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportFileSourceValue"));

    const reportPages: IReportPage[] = collectReport(reportDesignPage, reportDesignSection, reportPageValue, reportDesignBaseWidget, reportBaseWidgetValue, reportTableHeaderValue, reportTableCellValue, reportChartDataValue, reportFileSourceValue);
    console.log(reportPages);
    return (
        <StyledPreview className={className}>
            {reportPages.length > 0 && <ReportPreviewContent reportPage={reportPages[0]} fullPage={false} onEmptyClick={() => onEmptyClick && onEmptyClick()}/>}
            {children}
        </StyledPreview>
    );
};
