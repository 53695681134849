import React, { ReactNode } from 'react';
import { StyledDelegateIcon } from './DelegateIcon.styles';

interface DelegateIconProps {
    className?: string;
    children?: ReactNode;
}

export const DelegateIcon: React.FC<DelegateIconProps> = ({ className, children }) => {
    return <StyledDelegateIcon className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<path d="M11.8748 5.87029L7.58904 0.653016C7.52913 0.580008 7.45279 0.530265 7.36966 0.510079C7.28653 0.489894 7.20035 0.500171 7.12201 0.539613C7.04368 0.579054 6.97672 0.645887 6.92959 0.73166C6.88246 0.817433 6.85728 0.918294 6.85724 1.02149V3.65295C5.46757 3.79773 3.93272 4.62597 2.67002 5.92963C1.14963 7.50003 0.203007 9.52368 0.00425323 11.6275C-0.0112786 11.7911 0.015965 11.9565 0.0821068 12.1001C0.148249 12.2437 0.249919 12.3583 0.372648 12.4275C0.495377 12.4967 0.632912 12.517 0.765679 12.4856C0.898447 12.4541 1.01968 12.3725 1.11213 12.2523C1.70143 11.4886 3.79825 9.07369 6.85724 8.86109V11.456C6.85728 11.5592 6.88246 11.6601 6.92959 11.7459C6.97672 11.8316 7.04368 11.8985 7.12201 11.9379C7.20035 11.9773 7.28653 11.9876 7.36966 11.9674C7.45279 11.9472 7.52913 11.8975 7.58904 11.8245L11.8748 6.60723C11.955 6.50942 12 6.37691 12 6.23876C12 6.1006 11.955 5.96809 11.8748 5.87029ZM7.7144 10.1967V8.32566C7.7144 8.18729 7.66925 8.05459 7.58887 7.95675C7.5085 7.8589 7.39949 7.80394 7.28582 7.80394C5.78151 7.80394 4.3163 8.28197 2.93092 9.22564C2.22534 9.70839 1.56793 10.2887 0.971772 10.9552C1.28249 9.40042 2.06572 7.92198 3.22449 6.72527C4.46845 5.44117 5.98669 4.67357 7.28582 4.67357C7.39949 4.67357 7.5085 4.61861 7.58887 4.52076C7.66925 4.42292 7.7144 4.29022 7.7144 4.15185V2.28146L10.9657 6.23876L7.7144 10.1967Z" fill="#FF922C"/>
</StyledDelegateIcon>;
};
