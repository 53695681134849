import React, { forwardRef, ReactNode, useState } from 'react';
import { StyledDateTemplate } from './DateTemplate.styles';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import { IDateValue, IDesignDateQuestion } from '../../../../../../../../serverTypes';
import { IQuestionData } from '../../../../types';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import { Locale } from 'date-fns/locale/types';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

registerLocale('de', de as unknown as Locale);
setDefaultLocale('de');

const ForwardedPatternFormat = forwardRef<HTMLInputElement, StyledDateTemplateProps>((props, ref) => (
    <PatternFormat {...props} getInputRef={ref} />
));

interface NumericTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

const MIN_DATE = new Date(1600, 0, 1);
const MAX_DATE = new Date(2100, 11, 31);

const formatDate = (value: string | undefined): string | undefined => {
    if (!value) {
        return value;
    }
    if (value.length !== 8) return value;
    return `${value.slice(0, 2)}.${value.slice(2, 4)}.${value.slice(4)}`;
};

function formatDateFromDate(date: Date | undefined | null): string | undefined {
    if (!date) {
        return undefined;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

const validateDate = (dateString: string | undefined): boolean => {
    if (!dateString) {
        return false;
    }
    // Check if the date string is complete
    if (dateString.length !== 10) return false;

    const [day, month, year] = dateString.split('.').map(Number);

    // Check year range (adjust as needed)
    if (year < 1900 || year > 2100) return false;

    // Check month range
    if (month < 1 || month > 12) return false;

    // Check day range
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) return false;

    return true;
};
function parseDate(dateString: string | undefined): Date | undefined {
    if (!dateString) {
        return undefined;
    }
    // Check if the string matches the expected format
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const match = dateString.match(regex);

    if (!match) {
        return undefined; // Return null if the format is invalid
    }

    // Extract day, month, and year from the matched groups
    const [, day, month, year] = match.map(Number);

    // Create a new Date object
    // Note: month is 0-indexed in JavaScript Date, so we subtract 1
    const date = new Date(year, month - 1, day);

    // Validate the date
    if (
        date.getFullYear() !== year ||
        date.getMonth() !== month - 1 ||
        date.getDate() !== day
    ) {
        return undefined; // Return null if the date is invalid (e.g., 31.04.2023)
    }

    return date;
}
interface StyledDateTemplateProps extends PatternFormatProps {
    // Add any additional props here
}

export const DateTemplate: React.FC<NumericTemplateProps> = ({ className, questionData, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const dateValue = questionData.values[0].value as IDateValue;
    const designDate = questionData.design as IDesignDateQuestion;

    const initialDate = dateValue.input || '';
    const [value, setValue ] = useState<string|undefined>(initialDate);
    const [isValid, setIsValid] = useState<boolean>(validateDate(initialDate));

    const onAccept = (value: string) => {
        cenSocketProtocol.requestUpdateValue(questionData.values[0].link, "input", value);
    }
    const disabled = questionData.instance.locked;
    const showDatePicker = (questionData.design as IDesignDateQuestion).showDatePicker;
    const handleDateChange = (newDate: Date | null) => {
        const formattedDate = formatDateFromDate(newDate);
        setValue(formattedDate);
        if (newDate) {
            setIsValid(validateDate(formattedDate));
        } else {
            setIsValid(true);
        }
        onAccept(formattedDate || '');
    };

    const inputElement = <ForwardedPatternFormat
        value={value || ''}
        disabled={disabled}
        format="##.##.####"
        mask={['T', 'T', 'M', 'M', 'J', 'J', 'J', 'J']}
        allowEmptyFormatting
        placeholder="TT.MM.JJJJ"
        onValueChange={(values: any) => {
            const formattedDate = formatDate(values.value);
            const isValidDate = validateDate(formattedDate);
            setIsValid(isValidDate);
            if (isValidDate) {
                setValue(formattedDate);
                onAccept(formattedDate || '');
            }
        }}
    />;


    return <StyledDateTemplate>
        {showDatePicker ? (
                <DatePicker
                    selected={parseDate(value)}
                    disabled={questionData.instance.locked}
                    onChange={handleDateChange}
                    dateFormat="dd.MM.yyyy"
                    isClearable
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={500}
                    popperPlacement="bottom-start"
                    locale={'de'}
                    customInput={inputElement}
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                />
            ) :
        inputElement}
        {!isValid && <p style={{ color: 'red' }}>Bitte geben Sie ein gültiges Datum ein</p>}
    </StyledDateTemplate>
};
