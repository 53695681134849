import styled from 'styled-components';

export const StyledReportImageTemplate = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
`;
