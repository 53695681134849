import React from 'react';
import { Handle, Node, NodeProps, Position } from 'reactflow';

type FixedValueNodeProps = {
    value: string;
}

type FixedValueNode = Node<FixedValueNodeProps>;

export const FixedValueNode = ({ data, isConnectable }: NodeProps<FixedValueNodeProps> ) => {
    return <>
        <div><b>{data.value}</b></div>
        <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
    </>;
}
