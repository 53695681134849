import styled from 'styled-components';

export const StyledTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--radius-xl, 0.75rem);
    background: var(--Base-White, #FFF);

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
    .content {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
    }
    .summary {
        display: flex;
        min-width: 18.75rem;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
    }

    .cell-displayFileName {
        display: flex;
        height: 4.5rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
        align-items: center;
        gap: var(--spacing-lg, 0.75rem);
        align-self: stretch;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
        white-space: nowrap;
    }

    .cell-downloads {
        display: flex;
        height: 4.5rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
    }
    .cell-summary {
        display: flex;
        height: 4.5rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-lg, 0.75rem);
        align-self: stretch;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
    }
    .cell-extension {
        display: flex;
        height: 4.5rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
    }
    .cell-uploadTime {
        display: flex;
        height: 4.5rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
    }
`;
