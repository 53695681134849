import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    display: flex;
    height: 6.6875rem;
    padding: 0rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    .title {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.1rem */
    }
    .subtitle {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .description {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
`;
