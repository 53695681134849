import React from 'react';
import { IQuestionData } from '../../../types';
import { TextTemplate } from './text/TextTemplate';
import { SelectTemplate } from './select/SelectTemplate';
import { TableTemplate } from './table/TableTemplate';
import { FileUploadTemplate } from './file/FileUploadTemplate';
import { NumericTemplate } from './numeric/NumericTemplate';
import { DateTemplate } from './date/DateTemplate';
import { PromptTemplate } from './prompt/PromptTemplate';
import { ImageTemplate } from './image/ImageTemplate';

interface TemplateRendererProps {
    className?: string;
    questionData: IQuestionData;
    inTable?: boolean;
}

export const TemplateRenderer: React.FC<TemplateRendererProps> = ({ className, inTable = false, questionData }) => {
    switch (questionData.type) {
        case 'text': return (<TextTemplate className={className} inTable={inTable} questionData={questionData} />);
        case 'select': return(<SelectTemplate className={className} questionData={questionData} />);
        case 'numeric': return(<NumericTemplate className={className} questionData={questionData}/>)
        case 'date': return(<DateTemplate className={className} questionData={questionData}/>)
        case 'file': return(<FileUploadTemplate className={className} questionData={questionData} />);
        case 'table': return(<TableTemplate className={className} questionData={questionData}/>);
        case 'prompt': return(<PromptTemplate className={className} questionData={questionData} inTable={inTable} />);
        case 'image': return (<ImageTemplate className={className} questionData={questionData}/>);
    }
    return undefined;
};
