import styled from 'styled-components';

export const StyledStatusWrapper = styled.div`
    display: flex;
    width: 22.5625rem;
    height: 22.5625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
`;
