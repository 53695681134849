import React, { memo, useEffect, useState } from 'react';
import { Handle, Node, NodeProps, Position } from 'reactflow';
import { IEditableOptionData } from '@baumeister/web-cen-protocol';

type SelectNodeProps = {
    operation: string;
    options: IEditableOptionData[];
}

type SelectNode = Node<SelectNodeProps>;

const printText = (options: IEditableOptionData[], value:string) => {
    for (const option of options) {
        if (option.value === value) {
            return option.label?.toString() || 'unknown';
        }
    }
    return 'Click to edit';
}

const SelectNode = ({ data, isConnectable, selected }: NodeProps<SelectNodeProps> ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>(data.operation);
    useEffect(() => {
        if (selected) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [selected]);
    const handleChange = (evt:React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(evt.target.value);
        data.operation = evt.target.value;
        // Optionally, you can update the node data or perform other actions here
    };

    return <div>
        {isEditing ? (
            <select
                name={'mathNode'}
                onChange={handleChange}
            >{data.options.map((element) => (<option selected={element.value === selectedOption} key={element.value} value={element.value}>{element.label as string}</option>))}
            </select>
        ) : (
            <div onClick={() => setIsEditing(true)}>
                <b>{printText(data.options, selectedOption)}</b>
            </div>
        )}
        <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
        <Handle
            type="target"
            id='a'
            style={{ top: 10, bottom: 'auto', background: '#555' }}
            position={Position.Left}
            isConnectable={isConnectable}
        />
        <Handle
            type="target"
            id='b'
            style={{ bottom: 5, top: 'auto', background: '#555' }}
            position={Position.Left}
            isConnectable={isConnectable}
        />
    </div>;
}

export default memo(SelectNode);
