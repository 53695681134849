import React, { ReactNode, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AdminLayoutWrapper } from './components/layout/AdminLayoutWrapper';
import { CenDefaultPage, useWebSocketContext, WebSocketProvider } from '@baumeister/web-cen-protocol';
import { CalculationPage } from './pages/admin/design/calculation/CalculationPage';
import Settings from './ory/settings';
import { LayoutWrapper } from './components/layout/LayoutWrapper';
import { DashboardPage } from './pages/company/dashboard/DashboardPage';
import { CompanyCreatePage } from './pages/company/create/CompanyCreatePage';
import { BaseDataPage } from './pages/company/basedata/BaseDataPage';
import { SectionTypeInstancePage } from './pages/company/info/sectiontype/SectionTypeInstancePage';
import {
    DocumentSectionTypeInstancePage
} from './pages/company/document/documentSectionType/DocumentSectionTypeInstancePage';
import { ReportPage } from './pages/company/report/ReportPage';
import { Onboarding1Page } from './pages/company/onboarding/Onboarding1Page';
import { Onboarding2Page } from './pages/company/onboarding/Onboarding2Page';
import { Onboarding3Page } from './pages/company/onboarding/Onboarding3Page';

interface AuthorizedRoutesProps {
    websocketUrl: string;
}

export const AuthorizedRoutes: React.FC<AuthorizedRoutesProps> = ({websocketUrl}) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const navigate = useNavigate();
    useEffect(() => {
        cenSocketProtocol.requestPageByPath('user', '/user');
        cenSocketProtocol.requestPageByPath('company', '/company');
        navigate("/");
    }, []);
    return (
        <WebSocketProvider url={websocketUrl}>
            <Routes>
                {/* Admin Routes */}
                <Route
                    path="/admin/infoHub/designSection/17/designTextQuestion/23"
                    element={
                        <AdminLayoutWrapper>
                            <CenDefaultPage channel={'main'}>
                                <CalculationPage />
                            </CenDefaultPage>
                        </AdminLayoutWrapper>
                    }
                />

                <Route
                    path="/admin/*"
                    element={
                        <AdminLayoutWrapper>
                            <CenDefaultPage channel={'main'} />
                        </AdminLayoutWrapper>
                    }
                />

                {/* Company Routes */}
                <Route
                    path="/company"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'}>
                                <DashboardPage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/_create"
                    element={
                        <LayoutWrapper useNavbar={false}>
                            <CenDefaultPage channel={'main'}>
                                <CompanyCreatePage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/basedata"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'}>
                                <BaseDataPage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/sectionTypeInstance/*"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'}>
                                <SectionTypeInstancePage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/documentSectionTypeInstance/*"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'}>
                                <DocumentSectionTypeInstancePage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/report"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'}>
                                <ReportPage />
                            </CenDefaultPage>
                        </LayoutWrapper>
                    }
                />

                {/* Onboarding Routes */}
                <Route
                    path="/company/onboarding/1"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <Onboarding1Page />
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/onboarding/2"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <Onboarding2Page />
                        </LayoutWrapper>
                    }
                />

                <Route
                    path="/company/onboarding/3"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <Onboarding3Page />
                        </LayoutWrapper>
                    }
                />

                {/* Catch-all route for company paths */}
                <Route
                    path="/company/*"
                    element={
                        <LayoutWrapper useNavbar={true}>
                            <CenDefaultPage channel={'main'} />
                        </LayoutWrapper>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <LayoutWrapper useNavbar={true}><CenDefaultPage channel={'main'} /></LayoutWrapper>
                    }
                />
                {/* Default redirect for any unmatched routes */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </WebSocketProvider>
    );
};
