import styled from 'styled-components';

export const StyledReportTextTemplate = styled.div`
    align-self: stretch;
    .subTitle {
        color: var(--Base-Neutral-600, #1A384E);

        /* Heading 6 */
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.75rem */
    }
    .detailWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
        align-self: stretch;
    }
    .inputField {
        display: flex;
        padding: 1.25rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--spacing-3xl, 1.5rem);
        align-self: stretch;
        border-radius: var(--radius-sm, 0.375rem);
        border: 1px solid var(--Athens-Gray-200, #D8DCE5);
        background: var(--Base-White, #FFF);
        align-self: stretch;
    }
    .content {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        align-self: start;
        /* Paragraph M */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
`;
