import React, { ReactNode } from 'react';
import { StyledCommunicationComponent } from './CommunicationComponent.styles';
import { HeadingWrapper } from './heading-wrapper/HeadingWrapper';
import { CommunicationWrapper } from './communication-wrapper/CommunicationWrapper';
import { DocRoomCard } from './communication-wrapper/doc-room-card/DocRoomCard';
import { Communication } from './communication-wrapper/communication/Communication';
import { Tasks } from './communication-wrapper/tasks/Tasks';
import { ColexoRoom } from './communication-wrapper/colexo-room/ColexoRoom';

interface CommunicationComponentProps {
    className?: string;
    children?: ReactNode;
    title: string;
}

export const CommunicationComponent: React.FC<CommunicationComponentProps> = ({ className, title, children }) => {
    return (
        <StyledCommunicationComponent className={className}>
            <HeadingWrapper title={title}/>
            {children}
        </StyledCommunicationComponent>
    );
};
