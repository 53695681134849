import React from 'react';
import { StyledContentRight } from './ContentRight.styles';
import { ContentStep } from './step/ContentStep';

interface ContentRightProps {
    className?: string;
    activeStep: number;
}

export const ContentRight: React.FC<ContentRightProps> = ({ className, activeStep }) => {
    return (
        <StyledContentRight className={className}>
            <ContentStep step={1}
                         title={'Account erstellen'}
                         subtitle={'Registrieren und Profil anlegen'}
                         checked={activeStep > 1}
                         active={activeStep === 1}
            />
            <ContentStep step={2}
                         title={'Unternehmensdaten erfassen'}
                         subtitle={'Basisdaten erfassen & Fact Sheet erstellen'}
                         checked={activeStep > 2}
                         active={activeStep === 2}
            />
            <ContentStep step={3}
                         title={'Interessenten gewinnen'}
                         subtitle={'Verschicken Sie das Fact Sheet'}
                         checked={activeStep > 3}
                         active={activeStep === 3}
            />
            <ContentStep step={4}
                         title={'Verkauf abschließen'}
                         subtitle={'Sie sind jetzt bereit zu verkaufen'}
                         checked={activeStep > 4}
                         active={activeStep === 4}
            />
        </StyledContentRight>
    );
};
