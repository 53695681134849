import styled from 'styled-components';

export const StyledInputContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3.75rem;
    flex: 1 0 0;
`;
