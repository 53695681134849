import React, { ReactNode } from 'react';
import { StyledTeamMemberPhoto } from './TeamMemberPhoto.styles';

interface TeamMemberPhotoProps {
    className?: string;
    children?: ReactNode;
    src: string;
}

export const TeamMemberPhoto: React.FC<TeamMemberPhotoProps> = ({ className, src, children }) => {
    return (
        <StyledTeamMemberPhoto className={className}>
            <img src={src} className={'image'}/>
        </StyledTeamMemberPhoto>
    );
};
