import React, { ReactNode } from 'react';
import { StyledContentTitle } from './ContentTitle.styles';

interface ContentTitleProps {
    className?: string;
    children?: ReactNode;
    title: string;
}

export const ContentTitle: React.FC<ContentTitleProps> = ({ className, title, children }) => {
    return <StyledContentTitle className={className}>{title}{children}</StyledContentTitle>;
};
