import React, { ReactNode } from 'react';
import { StyledProgressCircle } from './ProgressCircle.styles';
import { CircleIcon } from './CircleIcon';
import { ProgressText } from './ProgressText';
import classNames from 'classnames';

interface ProgressCircleProps {
    className?: string;
    children?: ReactNode;
    value: number;
    small: boolean;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({ className, value = 100, small, children }) => {
    return <StyledProgressCircle className={classNames(className, small ? 'small' : '')}>
        <CircleIcon className={'circleIcon'} value={value} scale={100} small={small}/>
        {children}
    </StyledProgressCircle>;
};
