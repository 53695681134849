import React from 'react';
import {StyledDocRoomCard} from './DocRoomCard.styles';
import {DocRoomCardProps} from "./DocRoomCard.types";

export const DocRoomCard: React.FC<DocRoomCardProps> = ({ className, children }) => {
    return (
        <StyledDocRoomCard className={className}>
            {children}
        </StyledDocRoomCard>
    );
};
