import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { StyledFileUploadTemplate } from './FileUploadTemplate.styles';
import { IQuestionData } from '../../../../types';
import { FileUploadIllustration } from './illustration/FileUploadIllustration';
import { FileUploadContent } from './content/FileUploadContent';
import { FileUploadButtonWrap } from './buttonwrap/FileUploadButtonWrap';
import { Dropzone, ExtFile, FileMosaic, FilesUiProvider, IconsSet } from '@files-ui/react';
import { IFileValue } from '../../../../../../../../serverTypes';
import { useCenPageContext, useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ColexoProtocolMessage, InfoDeleteFileValueMessage } from '../../../../../../../../protocol';
import { iconsConfig } from './FileIcons';
import { AlertStateEnum, FileValueAlert } from './FileValueAlert';
import { useSectionInstanceModal } from '../../section/SectionInstanceModalProvider';
import { UploadFileList } from './UploadFileList';
import classNames from 'classnames';

interface FileUploadTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export function extractExtFiles(questionData: IQuestionData, idMap:Map<string|number, string>) {
    return questionData.values.map(value => {
        const fileValue = value.value as IFileValue;
        idMap.set(fileValue.fileId || 0, value.link);
        const extFile:ExtFile = {
            id: fileValue.fileId,
            name: fileValue.originalFileName,
            type: fileValue.mimeType,
            size: fileValue.size,
            valid: true,
            uploadStatus: 'success',
            extraData: {
                valueLink: value.link,
                question: questionData
            },
        };
        (extFile as any).file = {
            extraData: {
                valueLink: value.link,
                question: questionData
            },
        };
        return extFile;
    });
}

export const FileUploadTemplate: React.FC<FileUploadTemplateProps> = ({ className, questionData, children }) => {
    const [idMap, setIdMap] = useState<Map<number|string, string>>(new Map());
    const [files, setFiles] = useState<ExtFile[]>(extractExtFiles(questionData, idMap));
    const [showAlert, setShowAlert] = useState<boolean>(true);
    const [alertState, setAlertState] = useState<AlertStateEnum>(AlertStateEnum.NONE);
    const { cenSocketProtocol } = useWebSocketContext();
    const { channel, pageData } = useCenPageContext();
    const [ errorFileValues, setErrorFileValues ] = useState<IFileValue[]>([]);
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const pageLink = pageData.dataPath;
    const uploadBaseUrl = (questionData.design as any).uploadBaseUrl;
    const downloadBaseUrl = (questionData.design as any).downloadBaseUrl;
    const fileValues = questionData.values.map(element => element.value) as IFileValue[];
    const initiateProcessing = () => {
        setAlertState(AlertStateEnum.PROCESSING);
        setShowAlert(true);
        setErrorFileValues([]);
    };
    useEffect(() => {
        const fileValues = (questionData.values.map(element => element.value) as IFileValue[])
            .filter(element => element.needsLlmProcessing);
        /*
        if (fileValues.length === 0) {
            setAlertState(AlertStateEnum.NONE);
            setErrorFileValues([]);
            return;
        }
        */
        const isProcessing = fileValues.some(file => file.gptNeedsUpdate || file.gptProcessing);
        const hasError = fileValues.some(file => file.gptError);
        // console.log(`isProcessing: ${isProcessing}, hasError: ${hasError}`);
        // we need to distinguish between NONE and DONE as DONE shows the success alert state
        if ((alertState === AlertStateEnum.NONE || alertState === AlertStateEnum.DONE) && isProcessing) {
            initiateProcessing();
        } else if (alertState === AlertStateEnum.PROCESSING && !isProcessing) {
            setAlertState(AlertStateEnum.DONE);
            setErrorFileValues([]);
        }
        if (hasError && (alertState === AlertStateEnum.PROCESSING || (alertState === AlertStateEnum.NONE && isProcessing))) {
            setAlertState(AlertStateEnum.ERROR);
            setErrorFileValues(fileValues.filter(file => file.gptError));
        }
    }, [questionData.values]);

    const handleDelete = (file: ExtFile) => {
        let entityPath:string|undefined = file.extraData?.valueLink as string;
        if (!entityPath) {
            entityPath = idMap.get(file.id || '0');
        }
        if (entityPath) {
            const deleteMessage:InfoDeleteFileValueMessage = {
                type: ColexoProtocolMessage.INFO_DELETE_FILE_VALUE,
                payload: {
                    entityPath,
                    channel,
                    pageLink
                }
            };
            cenSocketProtocol.request(deleteMessage);
        }
    }
    const handleButtonClick = () => {
        dropZoneRef.current?.querySelector('.drop-zone')?.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true
        }));
    }
    const updateFiles = (incomingFiles:ExtFile[]) => {
        setFiles([...files, ...incomingFiles]);
    };
    const removeFile = (id: string|number|undefined) => {
        const deleteFiles = files.filter((x) => x.id === id);
        deleteFiles.forEach((file) => handleDelete(file));
        setFiles(files.filter((x) => x.id !== id));
    };
    const updateAlertState = (state:AlertStateEnum) => {
        setAlertState(state);
    }
    const hideAlert = () => {
        setShowAlert(false);
    }
    const disabled = questionData.instance.locked || false;
    // console.log(`alert state: ${alertState}`);
    // const hasLlmVariables = fileValues.some(element => element.needsLlmProcessing);
    return (
        <FilesUiProvider
            config={{
                icons: iconsConfig as IconsSet
            }}
        >
            <StyledFileUploadTemplate className={classNames(className, disabled && 'disabled')} ref={dropZoneRef}>
                <Dropzone
                    disabled={disabled}
                    className={'drop-zone'}
                    onChange={updateFiles}
                    uploadConfig={{
                        url: uploadBaseUrl + questionData.instanceLink,
                        method: 'POST',
                        autoUpload: true,
                    }}
                    header={false}
                    cleanFiles={false}
                    onUploadStart={(files) => {
                        initiateProcessing();
                    }}
                    onUploadFinish={(files) => {
                        for (const file of files) {
                            idMap.set(file.id || '0', file.serverResponse?.payload?.data as string);
                            if (file.file) {
                                (file.file as any).extraData = {
                                    valueLink: file.serverResponse?.payload?.data as string
                                }
                            }
                        }
                        setIdMap(idMap);
                    }}
                    value={files}>
                    <FileUploadContent questionData={questionData} onButtonPress={() => handleButtonClick()}/>
                </Dropzone>
                {files.length > 0 && <UploadFileList extFiles={files} fileValues={fileValues} onDelete={(id) => removeFile(id)} downloadUrl={downloadBaseUrl} disabled={disabled} />}
                {alertState !== AlertStateEnum.NONE  && showAlert &&
                    <FileValueAlert alertState={alertState}
                                    hideAlert={() => hideAlert()}
                                    errorFileValues={errorFileValues}
                                    updateState={(state) => updateAlertState(state)}
                    />}
            {children}</StyledFileUploadTemplate>

        </FilesUiProvider>
    );
};
