import React, { ReactNode } from 'react';
import { StyledBellRinging } from './BellRinging.styles';

interface BellRingingProps {
    className?: string;
}

export const BellRinging: React.FC<BellRingingProps> = ({ className}) => {
    return (
        <StyledBellRinging xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
            <path d="M23.7278 18.9925C23.034 17.7975 22.0028 14.4163 22.0028 10C22.0028 7.34784 20.9492 4.8043 19.0738 2.92893C17.1985 1.05357 14.6549 0 12.0028 0C9.3506 0 6.80706 1.05357 4.93169 2.92893C3.05633 4.8043 2.00276 7.34784 2.00276 10C2.00276 14.4175 0.970263 17.7975 0.276513 18.9925C0.0993507 19.2963 0.00543054 19.6415 0.00422321 19.9931C0.00301588 20.3448 0.0945643 20.6906 0.269636 20.9956C0.444708 21.3006 0.697112 21.5541 1.00139 21.7304C1.30568 21.9068 1.65108 21.9997 2.00276 22H7.10401C7.33473 23.1289 7.94829 24.1436 8.84093 24.8722C9.73357 25.6009 10.8505 25.9989 12.0028 25.9989C13.155 25.9989 14.272 25.6009 15.1646 24.8722C16.0572 24.1436 16.6708 23.1289 16.9015 22H22.0028C22.3543 21.9995 22.6996 21.9064 23.0037 21.73C23.3078 21.5535 23.56 21.3 23.735 20.9951C23.9099 20.6901 24.0013 20.3444 24.0001 19.9928C23.9988 19.6412 23.9049 19.2962 23.7278 18.9925ZM12.0028 24C11.3825 23.9998 10.7776 23.8074 10.2713 23.4492C9.7649 23.0911 9.382 22.5848 9.17526 22H14.8303C14.6235 22.5848 14.2406 23.0911 13.7343 23.4492C13.2279 23.8074 12.623 23.9998 12.0028 24ZM2.00276 20C2.96526 18.345 4.00276 14.51 4.00276 10C4.00276 7.87827 4.84562 5.84344 6.34591 4.34315C7.8462 2.84285 9.88103 2 12.0028 2C14.1245 2 16.1593 2.84285 17.6596 4.34315C19.1599 5.84344 20.0028 7.87827 20.0028 10C20.0028 14.5063 21.0378 18.3412 22.0028 20H2.00276Z" fill="#1A384E"/>
        </StyledBellRinging>
    );
};
