import React, { ReactNode } from 'react';
import { StyledCompanyInfoComponent } from './CompanyInfoComponent.styles';
import { HeadingWrapper } from './heading-wrapper/HeadingWrapper';
import { DocRoomWrapper } from './doc-room-wrapper/DocRoomWrapper';
import { CompanyData } from './company-data/CompanyData';
import { ColexoRoom } from './colexo-room/ColexoRoom';
import { Analyse } from './analyse/Analyse';

interface CompanyInfoComponentProps {
    className?: string;
    children?: ReactNode;
}

export const CompanyInfoComponent: React.FC<CompanyInfoComponentProps> = ({ className, children }) => {
    return (
        <StyledCompanyInfoComponent className={className}>
            <HeadingWrapper title={'Unternehmensinformationen'}/>
            <DocRoomWrapper>
                <CompanyData/>
                <ColexoRoom/>
                <Analyse/>
            </DocRoomWrapper>
        </StyledCompanyInfoComponent>
    );
};
