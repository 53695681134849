import React, { ReactNode } from 'react';
import { StyledArrowRightIcon } from './ArrowRightIcon.styles';

interface ArrowRightIconProps {
    className?: string;
    children?: ReactNode;
}

export const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ className, children }) => {
    return (
        <StyledArrowRightIcon className={className} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path d="M4.66675 10.4661H16.3334M16.3334 10.4661L10.5001 4.63281M16.3334 10.4661L10.5001 16.2995" stroke="#1A384E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </StyledArrowRightIcon>
    );
};
