import React, { ReactNode } from 'react';
import { StyledContentWrapper } from './ContentWrapper.styles';

interface ContentWrapperProps {
    className?: string;
    children?: ReactNode;
    title: string;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ className, title, children }) => {
    return (
        <StyledContentWrapper className={className}>
            <div className={'title'}>
                {title}
            </div>
        </StyledContentWrapper>
    );
};
