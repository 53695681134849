import React, { ReactNode } from 'react';
import { StyledContentStep } from './ContentStep.styles';
import { CircleWrapper } from './circle-wrapper/CircleWrapper';
import { VectorIcon } from '../../../../../../components/icons/VectorIcon';
import classNames from 'classnames';

interface ContentStepProps {
    className?: string;
    step: number;
    title: string;
    subtitle: string;
    checked: boolean;
    active: boolean;
}

export const ContentStep: React.FC<ContentStepProps> = ({ className, step, title, subtitle, active, checked }) => {
    return <StyledContentStep className={classNames(className, active && 'active')}>
        <CircleWrapper className={'circle-step'}>
            <div className={'text'}>
                {step}
            </div>
        </CircleWrapper>
        <div className={'text-wrapper'}>
            <div className={'title'}>{title}</div>
            <div className={'subtitle'}>{subtitle}</div>
        </div>
        <CircleWrapper className={classNames('circle-result', active && 'active')} iconClassName={classNames('ellipse-icon', checked && 'checked', active && 'active')}>
            <div className={'icon'}>
                <VectorIcon/>
            </div>
        </CircleWrapper>
    </StyledContentStep>;
};
