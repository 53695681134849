import React, { ReactNode } from 'react';
import { StyledReportNavbar } from './ReportNavbar.styles';
import { ColexoReportLogo } from '../../../../components/icons/ColexoReportLogo';
import { IReportPage } from '../../info/sectiontype/types';

interface ReportNavbarProps {
    className?: string;
    children?: ReactNode;
    reportPage: IReportPage;
}

export function formatMillisToDate(milliseconds: number): string {
    const date = new Date(milliseconds);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export const ReportNavbar: React.FC<ReportNavbarProps> = ({ className, reportPage, children }) => {
    return (
        <StyledReportNavbar className={className}>
            <div className={'frame'}>
                <div className={'inner'}>
                    <div className={'clx-raum-logo'}>
                        <ColexoReportLogo className={'logo'}/>
                        <div className={'logoText'}>Report</div>
                    </div>
                </div>
                {children}
            </div>
            <div className={'rightFrame'}>
                <div className={'rightTextFrame'}>
                    {reportPage.value.companyLogoDownloadUrl && <img src={reportPage.value.companyLogoDownloadUrl} className={'rightTextCompanyLogo'}/>}
                    <div className={'rightTextCompanyName'}>
                        {reportPage.value.companyName}
                    </div>
                    <div className={'rightTextReportDate'}>
                        Stand: <span className={'rightTextReportDateValue'}>{formatMillisToDate(reportPage.value.lastUpdateTime || new Date().getTime())}</span>
                    </div>
                </div>
            </div>
        </StyledReportNavbar>
    );
};
