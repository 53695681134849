import styled from 'styled-components';

export const StyledButton = styled.button`
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.3125rem;
    border: 0;

    &.btn-primary {
        background-color: ${props => props.theme.colors.primary};
    }
    &.btn-secondary {
        background-color: transparent;
        border: 1px solid #1A384E;
    }
    &.btn-addrow {
        color: rgba(26, 56, 78, 0.50);
        background: #F4F5F8;
    }
    &.btn-action {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        background: var(--Disabled-BTN-Color, linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(26, 56, 78, 0.50));
    }

    &.btn-disabled {
        opacity: 0.5;
    }

    & .caption {
        color: #1A384E;
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
`;
