import React, { ReactNode } from 'react';
import { StyledColexoReportLogo } from './ColexoReportLogo.styles';

interface ColexoReportLogoProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoReportLogo: React.FC<ColexoReportLogoProps> = ({ className, children }) => {
    return (
        <StyledColexoReportLogo xmlns="http://www.w3.org/2000/svg" width="82" height="56" viewBox="0 0 82 56" fill="none">
            <path d="M0.782814 12.2069C1.27722 11.7512 1.85403 11.5234 2.47204 11.5234H22.2484V16.0796H4.94408V29.7482H22.2484V34.3043H2.47204C1.85403 34.3043 1.27722 34.0765 0.782814 33.6209C0.288405 33.1653 0 32.6337 0 32.0262V13.8015C0 13.194 0.288405 12.6625 0.782814 12.2069Z" fill="white"/>
            <path d="M27.1929 7.00781H32.0958V29.7507H49.4412V34.3069H29.6649C29.0469 34.3069 28.4701 34.0791 27.9757 33.6235C27.4813 33.1679 27.1929 32.6363 27.1929 32.0288V7.00781Z" fill="white"/>
            <path d="M48.3704 25.6582C46.64 20.7983 44.9096 15.9763 43.1379 11.1164C45.5688 8.87628 47.9996 6.63616 50.4305 4.39604C52.2433 8.80035 54.0973 13.1667 55.9102 17.571C61.3898 11.9897 66.8283 6.37038 72.308 0.789062C75.3157 2.00404 78.2821 3.21903 81.2898 4.39604C74.5329 11.6859 67.7347 18.9758 60.9778 26.2657C56.7754 26.0379 52.5729 25.8481 48.3704 25.6582Z" fill="#00FF8C"/>
            <path d="M60.9776 28.3918C64.4385 37.2384 67.8582 46.085 71.319 54.9696C67.5285 55.1594 63.7381 55.3113 59.9476 55.5011L52.2019 35.6817C48.8646 38.4914 45.5686 41.301 42.2313 44.1107C40.9129 42.3641 39.6357 40.6176 38.3173 38.8711C41.6545 34.6566 44.9506 30.4801 48.2878 26.2656C52.5315 26.987 56.734 27.7084 60.9776 28.3918Z" fill="#CBFFE7"/>
        </StyledColexoReportLogo>
    );
};
