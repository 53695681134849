import React, { ReactNode } from 'react';
import { StyledCircleIcon } from './CircleIcon.styles';
import classNames from 'classnames';

interface CircleIconProps {
    className?: string;
    children?: ReactNode;
    value: number;
    scale: number;
    small: boolean;
}

export const CircleIcon: React.FC<CircleIconProps> = ({ className, value, scale, small, children }) => {
    const dashValue = 560;
    const dashOffset = 560 - ((value / scale) * dashValue);
    return <StyledCircleIcon className={classNames(className, small ? 'small' : '')} xmlns="http://www.w3.org/2000/svg" width="262" height="262" viewBox="0 0 262 262" fill="none">
            <circle cx="131" cy="133" r="89" stroke="#f4f5f8" strokeWidth="4" fill="none" />
            <circle
                    cx="131" cy="133" r="89" stroke="#1A384E" strokeWidth="8"
                    fill="none"
                    transform={'rotate(-90) translate(-260 0)'}
                    strokeLinecap="round"
                    strokeDasharray={dashValue}
                    strokeDashoffset={dashOffset}
            />
    </StyledCircleIcon>;
};
/*
            <path className={'overlay'}
                d="M195.454 197.176C159.857 232.772 102.143 232.772 66.5463 197.176C30.9495 161.579 30.9495 103.865 66.5463 68.2682C102.143 32.6714 159.857 32.6714 195.454 68.2682C231.05 103.865 231.05 161.579 195.454 197.176ZM69.1245 70.8463C34.9515 105.019 34.9515 160.424 69.1245 194.597C103.297 228.77 158.703 228.77 192.876 194.597C227.048 160.424 227.048 105.019 192.876 70.8463C158.703 36.6734 103.297 36.6734 69.1245 70.8463Z"
                fill="none" strokeWidth="8" stroke="#1A384E" />

 */
