import styled from 'styled-components';
import { AppContainer } from '@baumeister/web-ui-bootstrap';

export const StyledAppContainer = styled(AppContainer)`
    padding-left: 0;
    padding-right: 0;
    max-width: 1600px;
    &.admin.no-sidebar main {
        width: 99vw;
    }

    .main-wrapper {
        width: 1600px;
        max-width: 1600px;
        @media screen and (max-width: 1599px) {
            width: 1340px;
            max-width: 1340px;
        }
    }


    &:not(.admin) main {
        width: inherit;
        display: flex;
        justify-content: center;
    }
    &:not(.admin) .main-wrapper {
        display: flex;
        flex-direction: column;
    }
`;
