import React, { ReactNode, useMemo } from 'react';
import { StyledDocumentSectionTypeInstancePage } from './DocumentSectionTypeInstancePage.styles';
import { IDesignSectionType, IDocumentSectionTypeInstance, ISectionTypeInstance } from '../../../../serverTypes';
import { useAppSelector } from '../../../../store';
import { selectPageChildCursorData, selectPageData, useCenPageContext } from '@baumeister/web-cen-protocol';
import { IDocumentSectionTypeData, IDocumentSectionTypeInstanceData } from './types';
import { SectionTypeInstanceContent } from '../../info/sectiontype/content/SectionTypeInstanceContent';
import { ProgressCircle } from '../../info/sectiontype/content/left/progresscircle/ProgressCircle';
import { DocumentSectionTypeInstanceDataProvider } from './DocumentSectionTypeInstanceDataProvider';
import { QuestionItemList } from './content/left/questionitemlist/QuestionItemList';
import { BigCircleText } from './content/left/bigcircletext/BigCircleText';
import { InputContent } from './content/right/inputcontent/InputContent';

interface DocumentSectionTypeInstancePageProps {
    className?: string;
    children?: ReactNode;
}

export const DocumentSectionTypeInstancePage: React.FC<DocumentSectionTypeInstancePageProps> = ({
                                                                                                    className,
                                                                                                    children,
                                                                                                }) => {
    const { pageData, channel } = useCenPageContext();
    const documentSectionTypeInstances:IDocumentSectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "documentSectionTypeInstance"));
    const sectionTypeInstances: ISectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "sectionTypeInstance"));
    const designSectionType: IDesignSectionType[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designSectionType"));
    const pageEntityData = useAppSelector((state) =>
        selectPageData(state.entities, pageData.dataPath)?.data || {}
    ) as IDocumentSectionTypeInstance;
    const documentList:IDocumentSectionTypeData[] = [];
    sectionTypeInstances.forEach((value, index) => documentList.push({
        document: undefined,
        design: undefined,
        documentDataLink: undefined,
        instance: value,
        orderNumber: index,
        nextSection: undefined
    }));
    const documentSectionTypeMap:Map<number, IDocumentSectionTypeInstance> = new Map();
    documentSectionTypeInstances.forEach((value) => documentSectionTypeMap.set(value.sectionTypeInstance?.sectionTypeInstanceId || 0, value));
    const designSectionMap:Map<number, IDesignSectionType> = new Map();
    designSectionType.forEach((value) => (designSectionMap.set(value.designSectionTypeId || 0, value)));
    /*
    console.log(documentList);
    console.log('design section map', designSectionMap);
    console.log('document section type map', documentSectionTypeMap);

     */
    let previousElement:IDocumentSectionTypeData | undefined = undefined;
    documentList.forEach((element) => {
        if (previousElement !== undefined) {
            previousElement.nextSection = element;
        }
        element.design = designSectionMap.get(element.instance?.designSectionType?.designSectionTypeId || 0);
        element.document = documentSectionTypeMap.get(element.instance?.sectionTypeInstanceId || 0);
        element.documentDataLink = documentSectionTypeMap.get(element.instance?.sectionTypeInstanceId || 0)?._link;
        previousElement = element;
    });
    const documentSectionTypeInstanceData:IDocumentSectionTypeInstanceData = useMemo(() => {
        return {
            sections: documentList,
            currentType: documentList.filter((element) => element.document?.documentSectionTypeInstanceId === pageEntityData.documentSectionTypeInstanceId)[0],
            totalDocs: documentList.reduce((accumulator, value) => accumulator + (value.document?.documentsUploaded || 0), 0)
        };
    }, [documentSectionTypeInstances, designSectionType, sectionTypeInstances]);
    //console.log(documentSectionTypeInstanceData);
    return (
        <StyledDocumentSectionTypeInstancePage className={className}>
            <DocumentSectionTypeInstanceDataProvider value={documentSectionTypeInstanceData}>
                <SectionTypeInstanceContent
                    leftContent={(collapsed) => (
                        <React.Fragment>
                            <ProgressCircle value={100} small={collapsed}>
                                <BigCircleText count={documentSectionTypeInstanceData.totalDocs || 0} small={collapsed}/>
                            </ProgressCircle>
                            <QuestionItemList collapsed={collapsed}/>
                        </React.Fragment>
                    )}
                    rightContent={(collapsed) => (
                        <InputContent/>
                    )}
                />
            </DocumentSectionTypeInstanceDataProvider>
        </StyledDocumentSectionTypeInstancePage>
    );
};
