import React, { ReactNode } from 'react';
import { StyledTableContents } from './TableContents.styles';

interface TableContentsProps {
    className?: string;
    children?: ReactNode;
}

export const TableContents: React.FC<TableContentsProps> = ({ className, children }) => {
    return <StyledTableContents className={className}>{children}</StyledTableContents>;
};
