import styled from 'styled-components';

export const StyledContentRight = styled.div`
    display: flex;
    min-width: 25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3xl, 1.5rem);
    flex: 1 0 0;
`;
