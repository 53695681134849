import React, { ReactNode } from 'react';
import { StyledAvatar } from './Avatar.styles';
import { AvatarIcon } from '../icons/AvatarIcon';
import { IUser } from '../../serverTypes';
import { useAppSelector } from '../../store';
import { selectPageData } from '@baumeister/web-cen-protocol';

interface AvatarProps {
    className?: string;
    children?: ReactNode;
    onClick?: () => void;
}

const createInitials = (user: IUser) => {
    if (!user) {
        return '';
    }
    let initial = '';
    if (user.firstName && user.firstName.length > 0) {
        initial = user.firstName.substring(0, 1).toUpperCase();
    }
    if (user.lastName && user.lastName.length > 0) {
        initial += user.lastName.substring(0, 1).toUpperCase();
    }
    return initial;
}

export const Avatar: React.FC<AvatarProps> = ({ className, onClick, children }) => {
    const userData = useAppSelector((state) =>
        selectPageData(state.entities, 'user')
    );
    const user = userData?.data as IUser;
    return (
        <StyledAvatar className={className} onClick={() => onClick && onClick()}>
            <AvatarIcon className={'avatarIcon'}/>
            <div className={'avatarText'}>{createInitials(user)}</div>
        </StyledAvatar>
    );
};
