import styled from 'styled-components';

export const StyledTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .company-chip {
        display: flex;
        width: 9.9375rem;
        padding: var(--spacing-xl, 1rem) var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-lg, 0.75rem);
        border-radius: var(--radius-sm, 0.375rem);
        background: var(--Base-White, #FFF);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }
    .company-name {
        flex: 1 0 0;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .topbar-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--spacing-3xl, 1.5rem);
        flex: 1 0 0;
    }
    button {
        display: flex;
        padding: 1rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.375rem;
        background: var(--Base-White, #FFF);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        border: 0;
    }

    button .icon {
        width: 1.25rem;
        height: 1.25rem;
        fill: var(--Base-Neutral-600, #1A384E);
    }
    .download-text {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
