import React, { ReactNode } from 'react';
import { StyledTopBar } from './TopBar.styles';
import { CompanyIcon } from '../../../../../../../../components/icons/CompanyIcon';
import { selectPageData } from '@baumeister/web-cen-protocol';
import { useAppSelector } from '../../../../../../../../store';
import { ICompany } from '../../../../../../../../serverTypes';
import { DownloadIcon } from '../../../../../../../../components/icons/DownloadIcon';
import { useDocumentSectionTypeInstanceData } from '../../../../DocumentSectionTypeInstanceDataProvider';

interface TopBarProps {
    className?: string;
    children?: ReactNode;
    disableDownload?: boolean;
}

export const TopBar: React.FC<TopBarProps> = ({ className, children, disableDownload }) => {
    const companyPageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const company: ICompany | undefined = companyPageData?.data as unknown as ICompany;
    const documentSectionTypeInstanceData = useDocumentSectionTypeInstanceData();
    const allDownloadUrl = documentSectionTypeInstanceData.currentType?.document?.allDocumentsDownloadUrl;
    return (
        <StyledTopBar className={className}>
            <div className={'company-chip'}>
                <CompanyIcon className={'icon'}/>
                <div className={'company-name'}>
                    {company?.name}
                </div>
            </div>
            {!disableDownload &&
            <a className={'topbar-right'} href={allDownloadUrl} target={'blank'}>
                <button><DownloadIcon/><div className={'download-text'}>Alle herunterladen</div></button>
            </a>
            }
            {children}
        </StyledTopBar>
    );
};
