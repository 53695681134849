import React, { ReactNode } from 'react';
import { StyledCompanyData } from './CompanyData.styles';
import { ContentWrapper } from '../doc-room-wrapper/doc-room-card/content-wrapper/ContentWrapper';
import { Button } from '../../../../../components/button/Button';
import { MaskGroup } from './mask-group/MaskGroup';
import { useAppSelector } from '../../../../../store';
import { useNavigate } from 'react-router-dom';
import { IDocumentSectionTypeInstance, ISectionTypeInstance } from '../../../../../serverTypes';
import { selectPageChildCursorData } from '@baumeister/web-cen-protocol';

interface CompanyDataProps {
    className?: string;
    children?: ReactNode;
}

export const CompanyData: React.FC<CompanyDataProps> = ({ className, children }) => {
    const navigate = useNavigate();
    const documentSectionTypeInstance: IDocumentSectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "documentSectionTypeInstance")
    );
    const documentUrl = documentSectionTypeInstance.length > 0 ? documentSectionTypeInstance[0]._link : undefined;


    return (
        <StyledCompanyData className={className}>
            <ContentWrapper className={'content-wrapper'}>
                <div className={'title'}>Unternehmensdaten</div>
                <div className={'description'}>Hier finden Sie eine Übersicht über all Ihre Unternehmensdaten und können diese jederzeit flexibel anpassen.</div>
                <Button caption={'Mein Unternehmen'} variant={'primary'} className={'button'} onClick={() => documentUrl && navigate(documentUrl)}/>
            </ContentWrapper>
            <div className={'illustration-wrapper'}>
                <MaskGroup/>
            </div>
        </StyledCompanyData>
    );
};
