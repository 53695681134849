import React, { ReactNode } from 'react';
import { StyledDocRoomWrapper } from './DocRoomWrapper.styles';

interface DocRoomWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const DocRoomWrapper: React.FC<DocRoomWrapperProps> = ({ className, children }) => {
    return (
        <StyledDocRoomWrapper className={className}>
            {children}
        </StyledDocRoomWrapper>
    );
};
