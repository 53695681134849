import React, { useEffect, useState } from 'react';
import {
    Configuration,
    FrontendApi,
    LoginFlow,
    UiNode,
    UiNodeInputAttributes,
    UiText,
    UpdateLoginFlowBody,
} from '@ory/client';
import { OryErrorMessage, OryLayout } from './OryLayout';
import { AxiosError } from 'axios';
import { filterNodesByGroups } from '@ory/integrations/ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IOryUIOptions, mapUINode } from './utils';
import { CustomLink } from './CustomLink';
import { OryPageEnum } from './types';
import { sdk } from './sdk';
import { useAuth } from './AuthContext';

const LoginPageComponent: React.FC = () => {
    const [flow, setFlow] = useState<LoginFlow>();
    const navigate = useNavigate();
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleReset = async () => {
        try {
            const { data } = await sdk.createBrowserLogoutFlow();
            if (data.logout_url) {
                // Remove the reset parameter
                searchParams.delete('reset');
                setSearchParams(searchParams);

                // Redirect to logout URL, which will then redirect back to login
                window.location.href = data.logout_url;
            }
        } catch (err) {
            console.error('Logout error:', err);
            // If logout fails, still try to create a new login flow
            initializeLoginFlow();
        }
    };

    const initializeLoginFlow = () => {
        return sdk.createBrowserLoginFlow({
            returnTo: '/',
            refresh: true,
        }).then(({ data: flow }) => {
            setFlow(flow);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        const reset = searchParams.get('reset');
        if (reset === 'true') {
            handleReset();
        } else {
            initializeLoginFlow();
        }
    }, []);

    // Rest of your component remains the same...
    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        try {
            const form = event.currentTarget;
            const formData = new FormData(form);
            let body = Object.fromEntries(formData) as unknown as UpdateLoginFlowBody;

            if ('submitter' in event.nativeEvent) {
                const method = (
                    event.nativeEvent as unknown as { submitter: HTMLInputElement }
                ).submitter;
                body = {
                    ...body,
                    ...{ [method.name]: method.value },
                };
            }

            await sdk.updateLoginFlow({
                flow: flow?.id || '',
                updateLoginFlowBody: body,
            });

            const { data: session } = await sdk.toSession();

            console.log(`received a session`);
            login(session.id, {
                id: session.identity.id,
                username: session.identity.traits.email
            });

        } catch (err) {
            if (err instanceof AxiosError && err.response?.status === 400) {
                if (err.response.data) {
                    setFlow(err.response.data as LoginFlow);
                }
            }
            console.error('Login error:', err);
        } finally {
            setLoading(false);
        }
    };

    const oryOptions: IOryUIOptions = {
        submitButtonPostfix: (label) => {
            // Find if there's a hidden identifier field
            const identifierNode = flow?.ui.nodes.find(
                (node: UiNode) => {
                    const attributes = node.attributes as UiNodeInputAttributes;
                    return node.group === 'default' &&
                    attributes.name === 'identifier' &&
                    attributes.type === 'hidden'
                }
            );

            // If we have a hidden identifier, show the "Not me?" message
            if (identifierNode) {
                return (
                    <div className="buttonHelper">
                        Nicht Sie? <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleReset();
                        }}
                        className="text-primary hover:text-primary/80"
                    >
                        Zurück zum Login
                    </a>
                    </div>
                );
            }

            // Default helper text for regular login
            return (
                <div className="buttonHelper">
                    <CustomLink href="/auth/registration">Hier registrieren.</CustomLink>
                </div>
            );
        },
        passwordHelper: (
            <div className="helper">
                <CustomLink href="/auth/recovery" tabIndex={1}>
                    Passwort vergessen?
                </CustomLink>
            </div>
        ),
    };

    const errorMessages: OryErrorMessage[] =
        flow?.ui.messages?.map((element: UiText) => ({
            type: String(element.type),
            message: element.text
        })) || [];

    if (loading) {
        return <div>Logging in...</div>;
    }

    console.log(flow);
    return flow ? (
        <OryLayout
            handleSubmit={(evt) => submit(evt)}
            errorMessages={errorMessages}
            title={"Welcome to ESRS Portal"}
            subtitle={"Access your sustainability reporting dashboard"}
            page={OryPageEnum.Login}
        >
            {filterNodesByGroups({
                nodes: flow.ui.nodes,
                groups: ['default', 'password'],
            }).map((node, key) => mapUINode(OryPageEnum.Login, node, key, oryOptions))}
        </OryLayout>
    ) : (
        <div>Loading...</div>
    );
};

export default LoginPageComponent;
