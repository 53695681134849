import React, { ReactNode } from 'react';
import { StyledColexoLogo } from './ColexoLogo.styles';
import classNames from 'classnames';

interface JoneLogoProps {
    className?: string;
    children?: ReactNode;
    large: boolean;
}

export const ColexoLogo: React.FC<JoneLogoProps> = ({ className, large, children }) => {
    return <StyledColexoLogo className={classNames(className, large ? 'large' : '')}>
        <svg className="logo" viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 32H4.5V28H14.5V32ZM14.5 22H4.5V18H14.5V22ZM14.5 12H4.5V8H14.5V12Z" fill="#1A394F"/>
            <path d="M35 8H25V32H35V8Z" fill="#1A394F"/>
            <path d="M55.5 8H45.5V32H55.5V8Z" fill="#1A394F"/>
            <path d="M75.5 8H65.5V32H75.5V8Z" fill="#1A394F"/>
        </svg>
    </StyledColexoLogo>;
};
