import React, { ReactNode } from 'react';
import { StyledHelpButtonIcon } from './HelpButtonIcon.styles';

interface HelpButtonIconProps {
    className?: string;
    children?: ReactNode;
}

export const HelpButtonIcon: React.FC<HelpButtonIconProps> = ({ className, children }) => {
    return (
        <StyledHelpButtonIcon className={className} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g style={{mixBlendMode: 'darken'}}>
                <rect x="0.396973" y="0.335938" width="20" height="20" rx="10" fill="#F5F6F9"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.8162 14.0656C14.0403 13.8371 14.0403 13.4636 13.8206 13.2438L11.2234 10.6467L13.8206 8.04511C14.0403 7.82538 14.0447 7.44745 13.8162 7.22333C13.592 6.99921 13.2141 6.99921 12.9944 7.21894L10.3972 9.81611L7.79565 7.21894C7.58472 7.00361 7.198 6.99482 6.97388 7.22333C6.74976 7.44745 6.75415 7.83417 6.96948 8.04511L9.56665 10.6467L6.96948 13.2438C6.75415 13.4592 6.74976 13.8415 6.97388 14.0656C7.20239 14.2897 7.58472 14.2853 7.79565 14.0744L10.3972 11.4728L12.9944 14.07C13.2141 14.2897 13.592 14.2897 13.8162 14.0656Z" fill="#1A384E" fillOpacity="0.5"/>
            </g>
        </StyledHelpButtonIcon>
    );
};
