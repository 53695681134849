import styled from 'styled-components';

export const StyledSwitch = styled.div`
    display: flex;
    width: 5.5rem;
    height: 2.75rem;
    border-radius: 6.25rem;
    background: var(--Athens-Gray-50, #F5F6F9);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.02) inset, 0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.12) inset;
    cursor: pointer;
    &.onSwitch {
        justify-content: flex-end;
        align-items: center;
        gap: 1.0645rem;
        padding: 0.125rem 0rem 0.125rem 0.63119rem;
        .knobIcon {
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            fill: var(--Base-Neutral-600, #1A384E);
            stroke-width: 0.5px;
            stroke: rgba(0, 0, 0, 0.02);
            filter: drop-shadow(0px 0.25px 0.5px rgba(0, 0, 0, 0.12));
        }
        .backIcon {
            width: 1.30431rem;
            height: 1.30425rem;
            flex-shrink: 0;
            fill: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        }
    }
    &.offSwitch {
        align-items: center;
        gap: 1.28875rem;
        padding: 0.125rem 0.8425rem 0.125rem 0.05219rem;
        .knobIcon {
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            fill: var(--Base-Neutral-600, #1A384E);
            stroke-width: 0.5px;
            stroke: rgba(0, 0, 0, 0.02);
            filter: drop-shadow(0px 0.25px 0.5px rgba(0, 0, 0, 0.12));
        }
        .backIcon {
            width: 0.81656rem;
            height: 1.27038rem;
            flex-shrink: 0;
            fill: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        }
    }
`;
