import styled from 'styled-components';

export const StyledEmptyReportComponent = styled.div`
    display: flex;
    min-height: 28.125rem;
    padding: 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    align-self: stretch;
    border-radius: var(--radius-xl, 0.75rem);
    background: var(--grey-gradient, linear-gradient(96deg, #FFF 0%, #F4F5F8 65.5%));

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);

    .title {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Heading 4 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 2.6rem */
    }
    .detail-wrapper {
        display: flex;
        max-width: 37.5rem;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
    }
    .notice {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;

        /* Paragraph L */
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.875rem */
    }

    button {
        align-self: initial;
    }
`;
