import { RegistrationFlow, UiNode, UpdateRegistrationFlowBody, UpdateSettingsFlowBody } from '@ory/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sdk, sdkError } from './sdk';
import { OryLayout } from './OryLayout';
import { filterNodesByGroups } from '@ory/integrations/ui';
import { IOryUIOptions, mapUINode } from './utils';
import { UiNodeInputAttributes, UpdateRegistrationFlowWithPasswordMethod } from '@ory/client/api';
import { CustomLink } from './CustomLink';
import { OryPageEnum } from './types';
import { AxiosError } from 'axios';

/** Registration is a React component that renders the Registration form using Ory Elements.
 * It is used to handle the registration flow for a variety of authentication methods.
 *
 * The Registration component also handles the OAuth2 registration flow (as an OAuth2 provider)
 * For more information regarding OAuth2 registration, please see the following documentation:
 * https://www.ory.sh/docs/oauth2-oidc/custom-login-consent/flow
 *
 */
export interface RegistrationPageProps {

}
export const Registration: React.FC<RegistrationPageProps> =  () => {
    const [flow, setFlow] = useState<RegistrationFlow | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false);

    // The return_to is a query parameter is set by you when you would like to redirect
    // the user back to a specific URL after registration is successful
    // In most cases it is not necessary to set a return_to if the UI business logic is
    // handled by the SPA.
    // In OAuth flows this value might be ignored in favor of keeping the OAuth flow
    // intact between multiple flows (e.g. Login -> Recovery -> Settings -> OAuth2 Consent)
    // https://www.ory.sh/docs/oauth2-oidc/identity-provider-integration-settings
    const returnTo = searchParams.get("return_to")

    // The login_challenge is a query parameter set by the Ory OAuth2 registration flow
    // Switching between pages should keep the login_challenge in the URL so that the
    // OAuth flow can be completed upon completion of another flow (e.g. Login).
    const loginChallenge = searchParams.get("login_challenge")

    const navigate = useNavigate()

    // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
    const getFlow = useCallback(
        (flowId: string) =>
            sdk
                // the flow data contains the form fields, error messages and csrf token
                .getRegistrationFlow({ id: flowId })
                .then(({ data: flow }) => setFlow(flow))
                .catch(sdkErrorHandler),
        [],
    )

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = sdkError(getFlow, setFlow, "/registration", true)

    // create a new registration flow
    const createFlow = () => {
        sdk
            // we don't need to specify the return_to here since we are building an SPA. In server-side browser flows we would need to specify the return_to
            .createBrowserRegistrationFlow({
                ...(returnTo && { returnTo: returnTo }),
                ...(loginChallenge && { loginChallenge: loginChallenge }),
            })
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                setSearchParams({ ["flow"]: flow.id })
                // Set the flow data
                setFlow(flow)
            })
            .catch(sdkErrorHandler)
    }

    // submit the registration form data to Ory
    const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setLoading(true);

        const formData = new FormData(evt.currentTarget);
        const submitter = (evt.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;

        // Create the registration body with all necessary fields
        const body: UpdateRegistrationFlowWithPasswordMethod = {
            method: 'password',
            csrf_token: formData.get("csrf_token")?.toString() || "",
            password: formData.get("password")?.toString() || "",
            traits: {
                // Add all user traits
                email: formData.get("traits.email")?.toString() || "",
                name: {
                    first: formData.get("traits.name.first")?.toString() || "",
                    last: formData.get("traits.name.last")?.toString() || "",
                },
                // Optional website field if it exists in the form
                ...(formData.get("traits.website") && {
                    website: formData.get("traits.website")?.toString()
                })
            }
        };

        console.log('Submitting registration with data:', {
            ...body,
            password: '[REDACTED]' // Don't log the actual password
        });

        submitFlow(body);
    };
    const submitFlow = async (body: UpdateRegistrationFlowWithPasswordMethod) => {
        if (!flow) return navigate("/registration", { replace: true });

        try {
            const { data } = await sdk
                .updateRegistrationFlow({
                    flow: flow.id,
                    updateRegistrationFlowBody: body,
                });

            console.log(`data returned: ${JSON.stringify(data)}`);
            // Check if we need to verify the email
            if ("continue_with" in data) {
                for (const cw of (data as any).continue_with ?? []) {
                    if (cw.action === "show_verification_ui") {
                        const search = new URLSearchParams();
                        search.set("flow", cw.flow.id);
                        navigate(
                            {
                                pathname: "/auth/verification",
                                search: search.toString(),
                            },
                            { replace: true },
                        );
                        return;
                    }
                }
            }

            // If no verification needed, redirect to login
            navigate("/auth/login", { replace: true });
        } catch (error) {
            console.log(`error received`);
            console.log(error);
            await sdkErrorHandler(error as AxiosError<any, unknown>);
        }
    };

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = searchParams.get("flow")
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one
            return
        }
        // we assume there was no flow, so we create a new one
        createFlow()
    }, [navigate])

    const nodes:UiNode[] = (flow?.ui.nodes ?? []).sort((a: UiNode, b: UiNode) => {
        // Password field should be last
        if ((a.attributes as UiNodeInputAttributes).type === 'submit') return 1;
        if ((b.attributes as UiNodeInputAttributes).type === 'submit') return -1;
        // Password field should be last (but before submit)
        if ((a.attributes as UiNodeInputAttributes).name === 'password') return 1;
        if ((b.attributes as UiNodeInputAttributes).name === 'password') return -1;

        // Keep csrf_token first
        if ((a.attributes as UiNodeInputAttributes).name === 'csrf_token') return -1;
        if ((b.attributes as UiNodeInputAttributes).name === 'csrf_token') return 1;

        // Keep original order for other fields
        return 0;
    });
    console.log(flow);
    const oryOptions: IOryUIOptions = {
        submitButtonPostfix: (label) => (<div className="buttonHelper">Sie haben bereits ein Konto? <CustomLink
            href="/auth/login">Hier einloggen</CustomLink></div>),
    };

    // the flow is not set yet, so we show a loading indicator
    return flow ? (
        <OryLayout
            errorMessages={flow.ui.messages?.map(message => ({
                type: String(message.type),
                message: message.text
            })) || []}
            title={"Start Your ESRS Journey"}
            subtitle={"Register to manage your corporate sustainability reporting requirements"}
            handleSubmit={handleFormSubmit}
            page={OryPageEnum.Registration}
        >
            {filterNodesByGroups({
                nodes,
                groups: ['default', 'password'],
            }).map((node, key) => mapUINode(OryPageEnum.Registration, node, key, oryOptions))}
        </OryLayout>
    ) : (
        <div>Loading...</div>
    );
}
