import React, { ReactNode } from 'react';
import { StyledHeadingComponent } from './HeadingComponent.styles';
import { TopBar } from './topbar/TopBar';
import { SearchBar } from './searchbar/SearchBar';

interface HeadingComponentProps {
    className?: string;
    children?: ReactNode;
}

export const HeadingComponent: React.FC<HeadingComponentProps> = ({ className, children }) => {
    return (
        <StyledHeadingComponent className={className}>
            <TopBar/>
            <SearchBar/>
        </StyledHeadingComponent>
    );
};
