import React, { memo, useEffect, useState } from 'react';
import { Handle, Node, NodeProps, NodeToolbar, Position } from 'reactflow';
import { Button, Modal, ModalBody, ModalHeader, ReactSelect } from '@baumeister/web-ui-bootstrap';
import { IEditableOptionData } from '@baumeister/web-cen-protocol';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

type DocumentVariableNodeProps = {
    prefix?: string;
    value: string;
    options: IEditableOptionData[];
}

type DocumentVariableNode = Node<DocumentVariableNodeProps>;

const DocumentVariableNode = ({ data, isConnectable, selected }: NodeProps<DocumentVariableNodeProps> ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>(data.value);
    const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);
    const value = data.value;
    const prefix = data.prefix;
    const foundOptions = data.options.filter(element => element.value === value);
    const foundOption = foundOptions.length > 0 ? foundOptions[0] : undefined;
    return <div>
        {prefix && (<div>{prefix}<br /></div>)}
        <NodeToolbar isVisible={selected}>
            <Button onClick={() => setPromptDialogOpen(true)}>Bearbeiten</Button>
        </NodeToolbar>
        <div>
            <b>{value}</b>
        </div>
        <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
        {promptDialogOpen && (
            <Modal>
                <ModalHeader toggle={() => setPromptDialogOpen(false)}>
                    Bitte wählen Sie den Wert aus
                </ModalHeader>
                <ModalBody>
                    <ReactSelect
                        name={'default'}
                        options={data.options}
                        defaultValue={foundOption}
                        onChange={(newValue: unknown) => {
                            const value = (newValue as IEditableOptionData).value;
                            data.value = value;
                            setIsEditing(false);
                            setSelectedValue(value);
                            setPromptDialogOpen(false);
                        }}
                        isSearchable={true}

                        />
                </ModalBody>
            </Modal>
        )}
    </div>;
}

export default memo(DocumentVariableNode);
