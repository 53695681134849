import styled from 'styled-components';

export const StyledReportPage = styled.div`
    width: 100%;
    .subWrapper {
        display: flex;
        padding: 2.5rem 3.75rem 7.5rem 3.75rem;
        flex-direction: column;
        align-items: center;
        gap: 5rem;
        align-self: stretch;
    }
`;
