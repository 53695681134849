import React, { ReactNode } from 'react';
import { StyledLeftContent } from './LeftContent.styles';
import classNames from 'classnames';

interface LeftContentProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
    setCollapsed: (flag:boolean) => void;
}

export const LeftContent: React.FC<LeftContentProps> = ({ className, collapsed, setCollapsed, children }) => {
    return <StyledLeftContent className={classNames(className, collapsed ? 'collapsed' : '')}>
        {children}
    </StyledLeftContent>;
};
