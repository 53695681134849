import React from 'react';
import styled from 'styled-components';

interface AmountProps {
    value: number;
    className?: string;
}

const AmountUS: React.FC<AmountProps> = ({ value, className }) => {
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
    });
    const displayString = formatter.format(value);
    return <span className={`${className} ${value > 0 ? 'pos' : 'neg'}`}>{displayString}</span>;
};

export const Amount = styled(AmountUS)`
`;
