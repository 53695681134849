import styled from 'styled-components';

export const StyledSectionSuccess = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // padding: 19.5rem 0rem 89.5rem 0rem;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: rgba(238, 255, 241, 0.85);
    backdrop-filter: blur(2px);
    z-index: 30;
    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: calc(-9.625rem / 2);
        margin-top: calc(-9.625rem / 2);
        width: 9.625rem;
        height: 9.625rem;
        flex-shrink: 0;
    }
`;
