import React, { ReactNode } from 'react';
import { StyledCommunication } from './Communication.styles';
import { ContentWrapper } from './content-wrapper/ContentWrapper';
import { TeamWrapper } from './team-wrapper/TeamWrapper';

interface CommunicationProps {
    className?: string;
    children?: ReactNode;
}

export const Communication: React.FC<CommunicationProps> = ({ className, children }) => {
    return (
        <StyledCommunication className={className}>
            <ContentWrapper>
            </ContentWrapper>
            <TeamWrapper>

            </TeamWrapper>
        </StyledCommunication>
    );
};
