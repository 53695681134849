import styled from 'styled-components';

export const StyledContentFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    & .notice {
        display: flex;
        width: 4.9375rem;
        height: 1rem;
        flex-direction: column;
        justify-content: center;
        color: #22384C;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
`;
