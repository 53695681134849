import styled from 'styled-components';

export const StyledSectionInstanceHeader = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
`;

export const SectionInstanceDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    flex: 1 0 0;

    .headline {
        width: 25.96063rem;
        color: #1A384E;

        /* Heading 4 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 2.6rem */
    }

    .description {
        width: 31.0625rem;
        color: #1A384E;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
`;
