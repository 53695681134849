import React, { ReactNode } from 'react';
import { StyledButtonWrap } from './ButtonWrap.styles';
import { DelegateIcon } from '../../../../../../../components/icons/DelegateIcon';
import { CancelIcon } from '../../../../../../../components/icons/CancelIcon';
import { AcceptIcon } from '../../../../../../../components/icons/AcceptIcon';
import classNames from 'classnames';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import {
    ColexoProtocolMessage,
    InfoDeclineQuestionRequestMessage,
    InfoLockQuestionRequestMessage,
} from '../../../../../../../protocol';
import { IQuestionData } from '../../../types';
import { Tooltip } from '@baumeister/web-ui-bootstrap';

interface ButtonWrapProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const ButtonWrap: React.FC<ButtonWrapProps> = ({ className, questionData, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const onDecline = () => {
        const declineMessage:InfoDeclineQuestionRequestMessage = {
            type: ColexoProtocolMessage.INFO_DECLINE_QUESTION,
            payload: {
                entityPath: questionData.instanceLink
            }
        };
        cenSocketProtocol.request(declineMessage);
    }
    const onLock = () => {
        const lockMessage:InfoLockQuestionRequestMessage = {
            type: ColexoProtocolMessage.INFO_LOCK_QUESTION,
            payload: {
                entityPath: questionData.instanceLink,
                lockState: !questionData.instance.locked
            }
        };
        cenSocketProtocol.request(lockMessage);
    }
    const disabled = questionData.instance.locked;
    const ignored = questionData.instance.state === 'IGNORE';
    const done = questionData.instance.state === 'DONE';
    const questionInstanceId = questionData.instance.questionInstanceId;
    const ignoreTooltipId = `ignore-${questionInstanceId}`;
    const doneTooltipId = `done-${questionInstanceId}`;
    return <StyledButtonWrap className={className}>
        <button data-tooltip-id={ignoreTooltipId} className={classNames('button', 'decline-button', disabled && 'locked', ignored && 'ignore')} onClick={() => onDecline()} disabled={disabled}><CancelIcon/></button>
        {ignored && <Tooltip className={'ignored-tooltip'} target={ignoreTooltipId} placement={'top'}>Frage nicht beantwortet</Tooltip> }
        <button className={classNames('button', 'delegate-button', disabled && 'locked')} disabled={disabled}><DelegateIcon/></button>
        <button data-tooltip-id={doneTooltipId} className={classNames('button', 'accept-button', disabled && 'locked', done && 'done')} onClick={() => onLock()} disabled={disabled}><AcceptIcon/></button>
        {done && <Tooltip className={'done-tooltip'} target={doneTooltipId} placement={'top'}>Frage beantwortet</Tooltip> }
    </StyledButtonWrap>;
};
