import styled from 'styled-components';

export const StyledTaskWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-xl, 1rem);
    align-self: stretch;

    .task {
        flex: 1 0 0;
    }
    .status-btn {
        display: flex;
        padding: 0.5rem 0.75rem 0.5rem 0.875rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.375rem;
    }

    .count {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

        /* Paragraph XS - Bold */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.125rem */
    }
    .text {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;
