import styled from 'styled-components';

export const StyledReportNavbar = styled.div`
    display: flex;
    padding: 1.625rem 3.75rem;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-3xl, 1.5rem);
    align-self: stretch;
    border-bottom: 1px solid rgba(166, 166, 166, 0.20);
    background: var(--Base-Neutral-600, #1A384E);
    .frame {
        display: flex;
        max-width: 100rem;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
    }
    .inner {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 6.25rem;
        align-self: stretch;
    }
    .inner .clx-raum-logo {
        display: flex;
        align-items: center;
        gap: -0.375rem;
    }
    .logoFrame {
        display: flex;
        align-items: center;
        gap: -0.375rem;
    }
    .logo {
        width: 11.05944rem;
        height: 7.44388rem;
    }
    .logoText {
        color: var(--Base-White, #FFF);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 2.625rem */
    }
    .rightFrame {
        display: flex;
        width: 11.5625rem;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-md, 0.5rem);
    }
    .rightTextFrame {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xs, 0.25rem);
        align-self: stretch;
    }
    .rightTextCompanyLogo {
        width: 4.194rem;
        height: 4.2305rem;
    }
    .rightTextCompanyName {
        align-self: stretch;
        color: var(--Base-White, #FFF);
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 180%; /* 1.8rem */
    }
    .rightTextReportDate {
        align-self: stretch;
        color: var(--Base-White, #FFF);
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
        color: var(--Base-White, #FFF);
    }
    .rightTextReportDateValue {
        /* Paragraph M */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;
