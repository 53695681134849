import React, { ReactNode } from 'react';
import { StyledRightContent } from './RightContent.styles';
import { InputContent } from './inputcontent/InputContent';
import { useSectionTypeInstanceData } from '../../SectionTypeInstanceDataProvider';
import classNames from 'classnames';

interface RightContentProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
}

export const RightContent: React.FC<RightContentProps> = ({ className, collapsed, children }) => {
    return <StyledRightContent className={classNames(className, collapsed ? 'collapsed' : '')}>
        {children}
    </StyledRightContent>;
};
