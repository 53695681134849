import styled from 'styled-components';

export const StyledCircleIcon = styled.svg`
//    width: 11.5625rem;
//    height: 11.5625rem;
//    transform: rotate(45deg);
    flex-shrink: 0;
    // transition: width 0.8s ease, height 0.8s ease;
    &.small {
        width: 7rem;
        height: 7rem;
//        width: 5.08231rem;
//        height: 5.08231rem;
    }
`;
