import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { StyledSectionInstance } from './SectionInstance.styles';
import { DetailWrapper } from '../detailwrapper/DetailWrapper';
import classNames from 'classnames';
import { ISectionData, ISectionInstanceModal } from '../../../types';
import { SectionInstanceHeader } from './header/SectionInstanceHeader';
import { SectionSuccess } from './success/SectionSuccess';
import { SectionInstanceModalProvider } from './SectionInstanceModalProvider';

interface SectionInstanceProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
    sectionData: ISectionData;
    modal: boolean;
}

export const SectionInstance: React.FC<SectionInstanceProps> = ({ className, collapsed, sectionData, modal, children }) => {
    const allDone = sectionData.questions.filter(element => !element.instance.locked && !element.instance.disabled).length == 0;
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [firstRender, setFirstRender] = useState(true);
    const [fade, setFade] = useState(false);

    // console.log('showSuccess', showSuccess, 'fade', fade, 'firstRender', firstRender, 'allDone', allDone);
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }
        if (allDone) {
            setShowSuccess(true);
            setTimeout(() => setFade(true), 600);
        }
    }, [allDone]);
    return <StyledSectionInstance className={classNames(className, collapsed ? 'collapsed' : '')}>
                    <SectionInstanceHeader headline={sectionData.design?.title} description={sectionData.design?.description}/>
                    {sectionData.questions.map((element, index) => <DetailWrapper key={element.instance.questionInstanceId || (index + 1000)} questionData={element}/>)}
                    {showSuccess && <SectionSuccess className={classNames('success', fade && 'fade')} onTransitionEnd={() => {
                        setShowSuccess(false);
                        setFade(false);
                    }}/>}
                {modal && <div className={'modal-element'} />}
            </StyledSectionInstance>;
};
