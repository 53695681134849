import styled from 'styled-components';

export const StyledOnboardingFrame = styled.div`
    display: flex;
    padding: 6.25rem 3.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
`;
