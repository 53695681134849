import styled from 'styled-components';

export const StyledWorkflowSection = styled.div`
    position: relative;
    display: flex;
    height: 53.125rem;
    padding: 6.25rem 3.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: #FFF;
`;
