import React, { ReactNode } from 'react';
import { StyledTableHeaderCell } from './TableHeaderCell.styles';
import { ITableColumnModel } from '@baumeister/web-cen-protocol';
import { ArrowDown } from '../../../../../../../../../components/icons/ArrowDown';

interface TableHeaderCellProps {
    className?: string;
    children?: ReactNode;
    column: ITableColumnModel;
    sortHandler: () => void;
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({ className, column, sortHandler, children }) => {
    return (
        <StyledTableHeaderCell className={className}>
            <div className={'table-header'} onClick={() => sortHandler()}>
                {column.title}
                {column.sorting && (column.ascending ? <ArrowDown/> : <ArrowDown className={'rotate-90'}/>)}
            </div>
        </StyledTableHeaderCell>
    );
};
