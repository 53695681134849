import React, { ReactNode } from 'react';
import { StyledStatusWrapper } from './StatusWrapper.styles';
import { TaskWrapper } from './task-wrapper/TaskWrapper';

interface StatusWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const StatusWrapper: React.FC<StatusWrapperProps> = ({ className, children }) => {
    return (
        <StyledStatusWrapper className={className}>
            <TaskWrapper done={true} title={'Wirtschaftsdaten einfügen'} subtitle={'23.05.23 | - 8 Tage'}/>
            <TaskWrapper done={false} title={'Aktuelle BWA`s bei Dr. Loch anfragen'} subtitle={'05.07.23 | + 10 Tage'}/>
            <TaskWrapper done={false} title={'NDA freigebem'} subtitle={'05.07.23 | + 10 Tage'}/>
            <TaskWrapper done={false} title={'Kaufvertragsanlagen erstellen'} subtitle={'08.07.23 | +13 Tage'}/>
        </StyledStatusWrapper>
    );
};
