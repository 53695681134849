import styled from 'styled-components';

export const StyledContentStep = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 2.625rem;
    align-self: stretch;
    position: relative;

    &:not(.active) {
        opacity: 0.5;
    }
    .circle-step .text {
        display: flex;
        width: 1.72788rem;
        height: 3.02531rem;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        left: 1.1205rem;
        top: 0.478rem;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.875rem */
    }

    .text-wrapper {
        flex: 1 0 0;
    }
    .text-wrapper .title {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.875rem */
    }

    .text-wrapper .subtitle {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .circle-result {
        position: absolute;
        right: -0.03813rem;
        top: -0.00244rem;
        width: 3.98656rem;
        height: 3.98656rem;
    }
    .circle-result .icon {
        position: absolute;
        width: 1.56256rem;
        height: 1.12506rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 50%;
        left: 50%;
        margin-left: calc(-1.56256rem / 2);
        margin-top: calc(-1.2506rem / 2);
    }
    .circle-result .ellipse-icon {
        fill: var(--Brand-Accent-601, #DCFED6);
    }
    .ellipse-icon circle {
        fill: var(--Base-Neutral-200, #DFE3EA);
    }
    .ellipse-icon.checked circle {
        fill: var(--Brand-Accent-601, #DCFED6);
    }
    .ellipse-icon.active circle {
        fill: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }
    .circle-result.active .icon svg path {
        fill: var(--Base-White, #FFF);
    }

`;
