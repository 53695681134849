import styled from 'styled-components';

export const StyledTableColumn = styled.div`
    color: rgba(26, 56, 78, 0.50);
    /* Paragraph M */
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
    input {
        width: 100%;
        border-radius: 0;
    }
    input.numeric {
        text-align: right;
    }
`;
