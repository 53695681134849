import styled from 'styled-components';

export const StyledAlertCard = styled.div`
    position: absolute;
    left: 50%;
    margin-left: calc(-21.875rem / 2);
    display: flex;
    width: 21.875rem;
    height: 24rem;
    padding: 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: var(--Athens-Gray-50, #F5F6F9);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.60), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 38px 90px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    text-decoration: none;

    z-index: 800;
    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        align-self: stretch;
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacing-3xl, 1.5rem);
        align-self: stretch;
    }
    .title-text {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Paragraph L - Bold */
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.875rem */
    }
    .notice-text {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;

        /* Paragraph M */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
    .help-button {
        display: flex;
        width: 1.25rem;
        height: 1.25rem;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0.375rem;
        top: 0.65625rem;
    }
    .help-button-icon {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        fill: var(--Athens-Gray-50, #F5F6F9);
        mix-blend-mode: darken;
        cursor: pointer;
    }
    .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-md, 0.5rem);
        align-self: stretch;
    }
`;
