import { createGlobalStyle } from 'styled-components';

export const ColexoGlobalStyles = createGlobalStyle`
    @media screen and (max-width: 1599px) {
        html {
            font-size: 14px;
        }
    }

    body {
        background: var(--Base-Neutral-50, #F6F9FF);
    }
    .tooltip {
        padding: 8px;
    }
    .inner-tooltip {
        display: inline-flex;
        height: 1.6875rem;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0.1875rem;
        background: var(--Spring-Green-50, #EDFFF6);

        /* global-drop */
        box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
        color: var(--Spring-Green-700, #009652);

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .tooltip > svg {
        display: none;
    }

    .inner-tooltip.ignored-tooltip {
        display: inline-flex;
        height: 1.5rem;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0.1875rem;
        background: var(--Brand-Accent-801, #FFEFF2);

        /* global-drop */
        box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
        color: var(--Brand-Accent-800, #FF045F);

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }

    .inner-tooltip.edit-tooltip {
        display: flex;
        height: 1.5rem;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        border-radius: 0.1875rem;
        background: var(--Athens-Gray-50, #F5F6F9);

        /* global-drop */
        box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
        color: var(--Base-Neutral-600, #1A384E);

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .inner-tooltip.notice-tooltip {
        display: flex;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        border-radius: 0.1875rem;
        background: var(--Athens-Gray-50, #F5F6F9);
        max-width: 300px;
        height: inherit;
        /* global-drop */
        box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
        color: var(--Base-Neutral-600, #1A384E);

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
`;
