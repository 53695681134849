import React, { ReactNode } from 'react';
import { StyledFileValueErrorAlert } from './FileValueErrorAlert.styles';
import { ArrowClockwiseIcon } from '../../../../../../../../components/icons/ArrowClockwiseIcon';
import { Button } from '../../../../../../../../components/button/Button';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import { IFileValue } from '../../../../../../../../serverTypes';
import { ColexoProtocolMessage, InfoLlmFileValueMessage } from '../../../../../../../../protocol';
import { AlertStateEnum } from './FileValueAlert';

interface FileValueErrorAlertProps {
    className?: string;
    children?: ReactNode;
    hideAlert: () => void;
    errorFileValues: IFileValue[];
    updateState: (alertState: AlertStateEnum) => void;
}

export const FileValueErrorAlert: React.FC<FileValueErrorAlertProps> = ({ className, hideAlert, updateState, errorFileValues, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const retryFileValues = () => {
        errorFileValues.forEach((element) => {
            const triggerLlmMessage: InfoLlmFileValueMessage = {
                type: ColexoProtocolMessage.INFO_LLM_FILE_VALUE,
                payload: {
                    entityPath: element._link
                }
            };
            cenSocketProtocol.request(triggerLlmMessage);
            console.log(`sending ${JSON.stringify(triggerLlmMessage)}`);
            updateState(AlertStateEnum.PROCESSING);
        });
    }
    return (
        <StyledFileValueErrorAlert
            className={className}
            hideAlert={hideAlert}
            title={'Bei der Auswertung ist ein Fehler aufgetreten.'}
            notice={'Unsere KI hat Probleme die Daten auszulesen. Bitte versuchen Sie es erneut. Alternativ können Sie auch manuell fortfahren.'}
        >
            <Button caption={'Erneut versuchen'} onClick={() => retryFileValues()}>
                <ArrowClockwiseIcon/>
            </Button>

        </StyledFileValueErrorAlert>
    );
};
