import React, { ReactNode } from 'react';
import { SectionInstanceDetailWrapper, StyledSectionInstanceHeader } from './SectionInstanceHeader.styles';
import { ArrowLeftIcon } from '../../../../../../../../components/icons/ArrowLeftIcon';

interface SectionInstanceHeaderProps {
    className?: string;
    children?: ReactNode;
    headline: string | undefined;
    description: string | undefined;
}

export const SectionInstanceHeader: React.FC<SectionInstanceHeaderProps> = ({ className, headline, description, children }) => {
    return <StyledSectionInstanceHeader className={className}>
        <SectionInstanceDetailWrapper>
            <div className={'headline'}>
                {headline}
            </div>
            {description && <div className={'description'}>
                {description}
            </div>}
        </SectionInstanceDetailWrapper>
    </StyledSectionInstanceHeader>;
};
