import React, { ReactNode } from 'react';
import { StyledSignOutIcon } from './SignOutIcon.styles';

interface SignOutIconProps {
    className?: string;
}

export const SignOutIcon: React.FC<SignOutIconProps> = ({ className}) => {
    return (
        <StyledSignOutIcon className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.0034 23C10.0034 23.2652 9.89806 23.5196 9.71052 23.7071C9.52299 23.8946 9.26863 24 9.00342 24H1.00342C0.738201 24 0.483848 23.8946 0.296311 23.7071C0.108775 23.5196 0.00341797 23.2652 0.00341797 23V1C0.00341797 0.734784 0.108775 0.48043 0.296311 0.292893C0.483848 0.105357 0.738201 0 1.00342 0H9.00342C9.26863 0 9.52299 0.105357 9.71052 0.292893C9.89806 0.48043 10.0034 0.734784 10.0034 1C10.0034 1.26522 9.89806 1.51957 9.71052 1.70711C9.52299 1.89464 9.26863 2 9.00342 2H2.00342V22H9.00342C9.26863 22 9.52299 22.1054 9.71052 22.2929C9.89806 22.4804 10.0034 22.7348 10.0034 23ZM23.7109 11.2925L18.7109 6.2925C18.5233 6.10486 18.2688 5.99944 18.0034 5.99944C17.7381 5.99944 17.4836 6.10486 17.2959 6.2925C17.1083 6.48014 17.0029 6.73464 17.0029 7C17.0029 7.26536 17.1083 7.51986 17.2959 7.7075L20.5897 11H9.00342C8.7382 11 8.48385 11.1054 8.29631 11.2929C8.10878 11.4804 8.00342 11.7348 8.00342 12C8.00342 12.2652 8.10878 12.5196 8.29631 12.7071C8.48385 12.8946 8.7382 13 9.00342 13H20.5897L17.2959 16.2925C17.1083 16.4801 17.0029 16.7346 17.0029 17C17.0029 17.2654 17.1083 17.5199 17.2959 17.7075C17.4836 17.8951 17.7381 18.0006 18.0034 18.0006C18.2688 18.0006 18.5233 17.8951 18.7109 17.7075L23.7109 12.7075C23.8039 12.6146 23.8777 12.5043 23.928 12.3829C23.9783 12.2615 24.0042 12.1314 24.0042 12C24.0042 11.8686 23.9783 11.7385 23.928 11.6171C23.8777 11.4957 23.8039 11.3854 23.7109 11.2925Z" fill="#1A384E"/>
        </StyledSignOutIcon>
    );
};
