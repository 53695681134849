import React, { ReactNode } from 'react';
import { StyledItemProcessText } from './ItemProcessText.styles';

interface ItemProcessTextProps {
    className?: string;
    children?: ReactNode;
    value: number;
    scale: number;
}

export const ItemProcessText: React.FC<ItemProcessTextProps> = ({ className, value, scale, children }) => {
    return <StyledItemProcessText className={className}>{value}/{scale}</StyledItemProcessText>;
};
