import React, { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import { StyledTextWrapper } from './TextWrapper.styles';
import classNames from 'classnames';

interface TextWrapperProps extends HTMLAttributes<HTMLDivElement>{
    className?: string;
    children?: ReactNode;
    title: string;
    subtitle: string;
    secondSubtitle?: string | undefined;
    collapsed: boolean;
}

export const TextWrapper: React.FC<TextWrapperProps> = ({ className, title, subtitle, secondSubtitle, collapsed, children, ...props }) => {
    const isFirstRender = useRef(true);
    const [transitioning, setTransitioning] = useState<boolean>(false);
    useEffect(() => {
        setTransitioning(true);
    }, [collapsed]);
    return <StyledTextWrapper className={classNames(className, collapsed ? 'collapsed' : '', transitioning ? 'transitioning' : '')} onTransitionEnd={() => setTransitioning(false)} {...props}>
        {(!collapsed || transitioning) && <><div className={'title'}>
            {title}
        </div>
        <div className={'subtitle'}>
            {subtitle}
        </div>
        {secondSubtitle && <div className={'secondSubtitle'}>
            {secondSubtitle}
        </div>}
        </>}
    </StyledTextWrapper>;
};
