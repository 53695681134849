import styled from 'styled-components';

export const StyledTab = styled.div`
    display: flex;
    width: 13.0625rem;
    padding: 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    &.inactive {
        cursor: pointer;
    }

    & .text {
        color: #1A384E;
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        width: 13rem;
    }

    &.inactive .text {
        color: #DFE3EA;
    }

    & .line {
        width: 13.01413rem;
        height: 0.1875rem;
        background: #1A384E;
    }

    &.inactive .line {
        background: #DFE3EA;
    }
`;
