import styled from 'styled-components';

export const StyledDocRoomWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-3xl, 1.5rem);
    flex: 1 0 0;
    align-self: stretch;
`;
