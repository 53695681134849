import React, { ReactNode } from 'react';
import { StyledInprogressItemWrapper } from './InprogressItemWrapper.styles';
import { FileIcon } from '../../../../../../components/icons/FileIcon';
import { Button } from '../../../../../../components/button/Button';
import classNames from 'classnames';

interface InprogressItemWrapperProps {
    className?: string;
    children?: ReactNode;
    title: string;
    subtitle: string;
    buttonText: string;
    disabled?: boolean;
    button?: boolean;
}

export const InprogressItemWrapper: React.FC<InprogressItemWrapperProps> = ({ className, title, subtitle, buttonText, disabled, button = true, children }) => {
    return (
        <StyledInprogressItemWrapper className={classNames(className, disabled && 'disabled')}>
            <div className={'left-col-wrapper'}>
                <FileIcon className={'icon'}/>
                <div className={classNames('text-wrapper', !button && 'no-button')}>
                    <div className={'title'}>
                        {title}
                    </div>
                    <div className={'subtitle'}>
                        {subtitle}
                    </div>
                </div>
            </div>
            {button && <Button caption={buttonText} variant={'secondary'} className={'button'}/>}
        </StyledInprogressItemWrapper>
    );
};
