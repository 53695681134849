import styled from 'styled-components';

export const StyledAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    position: relative;
    cursor: pointer;
    .avatarIcon {
        width: 2rem;
        height: 2rem;
        fill: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }
    .avatarText {
        position: absolute;
        width: 2rem;
        left: 0;
        top: 0.625rem;
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 0.75rem */
    }
`;
