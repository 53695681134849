import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { IDocumentSectionTypeInstanceData } from './types';

export const DocumentSectionTypeInstanceDataContext = createContext<IDocumentSectionTypeInstanceData>({

    currentType: {
        document: undefined,
        design: undefined,
        instance: undefined,
        documentDataLink: undefined,
        orderNumber: 0,
        nextSection: undefined
    },
    sections: [],
    totalDocs: 0
});

export interface DocumentSectionTypeInstanceDataProviderProps {
    value: IDocumentSectionTypeInstanceData;
    children: ReactNode;
}
export const DocumentSectionTypeInstanceDataProvider: React.FC<DocumentSectionTypeInstanceDataProviderProps> = ({ children, value }) =>{
    return <DocumentSectionTypeInstanceDataContext.Provider value={value}>{children}</DocumentSectionTypeInstanceDataContext.Provider>;
};

export const useDocumentSectionTypeInstanceData = () => useContext(DocumentSectionTypeInstanceDataContext);
