import React, { ReactNode } from 'react';
import { StyledInputContent } from '../../../../../info/sectiontype/content/right/inputcontent/InputContent.styles';
import { useDocumentSectionTypeInstanceData } from '../../../DocumentSectionTypeInstanceDataProvider';
import { TextWrapper } from '../../../../../info/sectiontype/content/right/textwrapper/TextWrapper';
import { HeadingComponent } from '../headingcomponent/HeadingComponent';
import { Table } from '../table/Table';
import { InputFormWrapper } from './InputContent.styles';
import { CenTableColumn, selectPageData } from '@baumeister/web-cen-protocol';
import { IFileValue } from '../../../../../../../serverTypes';
import classNames from 'classnames';
import { iconsConfig } from '../../../../../info/sectiontype/content/right/templates/file/FileIcons';
import { Button } from '../../../../../../../components/button/Button';
import { ButtonGroup } from './buttongroup/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../store';

interface InputContentProps {
    className?: string;
    children?: ReactNode;
}

const renderDownloads = (row:IFileValue) => {
    let className = 'default';
    let text = '';
    const downloads = row?.downloads || 0;
    if (downloads < 5) {
        className = 'less_five';
        text = '< 5';
    } else if (downloads < 10) {
        className = 'less_twentyfive';
        text = '< 25';
    } else {
        className = 'more_twentyfive';
        text = '> 25';
    }
    return <div className={classNames('badge', className)}>{text}</div>;
}

const getFileName = (row:IFileValue) => {
    if (!row) {
        return '';
    }
    const fileName = row.displayFileName || '';
    if (fileName.endsWith('.' + row.extension)) {
        return fileName.substring(0, fileName.length - (1 + (row.extension?.length || 0)));
    }
    return fileName || '';
}

const extensionToImage = (row:IFileValue) => {
    if (!row) {
        return '';
    }
    const extension = row.extension || '';
    const image = (iconsConfig as any)[extension];
    if (image) {
        return (<img className={'extension'} src={image}/>);
    }
    return undefined;
}

const extractUploadTime = (row: IFileValue) => {
    if (!row?.uploadTime) {
        return '';
    }
    const format:Intl.DateTimeFormat = new Intl.DateTimeFormat('de', { day: '2-digit', month: 'short', year: 'numeric'});
    return format.format(new Date(row.uploadTime));
}
export const InputContent: React.FC<InputContentProps> = ({ className, children }) => {
    const documentSectionTypeInstanceData = useDocumentSectionTypeInstanceData();
    const navigate = useNavigate();
    const pageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const dashboardUrl = pageData?.dataPath;
    if (!pageData) {
        return undefined;
    }
    return (
        <StyledInputContent className={className}>
            <TextWrapper title={documentSectionTypeInstanceData.currentType?.design?.title || ''} subtitle={documentSectionTypeInstanceData.currentType?.design?.subTitle ||''}/>
            <HeadingComponent/>
            <InputFormWrapper>
                <Table name={'fileValue'}>
                    <CenTableColumn name={'downloads'}
                                    render={(row) => renderDownloads(row as IFileValue)}
                    />
                    <CenTableColumn name={'displayFileName'}
                                    render={(row) => <React.Fragment><a href={(row as IFileValue)?.downloadLink} target={'blank'}>{getFileName(row as IFileValue)}</a></React.Fragment>}
                    />
                    <CenTableColumn name={'extension'}
                                    render={(row) => <React.Fragment>{extensionToImage(row as IFileValue)}</React.Fragment>}
                    />
                    <CenTableColumn name={'summary'}
                                    render={(row) => <React.Fragment></React.Fragment>}
                                    />
                    <CenTableColumn name={'uploadTime'}
                                    render={(row) => <React.Fragment>{extractUploadTime(row as IFileValue)}</React.Fragment>}
                                    />
                </Table>
            </InputFormWrapper>
            <ButtonGroup>
                {dashboardUrl && <Button className={'button'} caption={'Zurück zum Dashboard'} variant={'secondary'} onClick={() => navigate(dashboardUrl)}/>}
                {documentSectionTypeInstanceData.currentType?.nextSection !== undefined &&
                    <Button className={'button'} caption={'Weiter zum nächsten Abschnitt'} variant={'secondary'} onClick={() => navigate(documentSectionTypeInstanceData.currentType?.nextSection?.documentDataLink || '')}/>
                }
            </ButtonGroup>
            {children}
        </StyledInputContent>
    );
};
