import styled from 'styled-components';

export const StyledContentHeader = styled.div`
    display: flex;
    width: 35rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    & .content-top-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2.75rem;
    }

    & .index {
        color: rgba(26, 56, 78, 0.50);
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.875rem */
    }

    & .heading-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
    }

    & .heading-wrap .content-title {
        width: 33.11844rem;
        height: 2.9375rem;
    }

    & .text-wrapper {
        display: flex;
        width: 35.6875rem;
        max-width: 38.75rem;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    & .sub-title {
        align-self: stretch;
    }

    .arrow-left {
        width: 2.75rem;
        height: 2.75rem;
        text-align: center;
        margin: 0;
    }

    & .tab-menu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
    }
`;
