import React, { ReactNode } from 'react';
import { StyledSmallEllipseIcon } from './SmallEllipseIcon.styles';

interface SmallEllipseIconProps {
    className?: string;
    children?: ReactNode;
}

export const SmallEllipseIcon: React.FC<SmallEllipseIconProps> = ({ className, children }) => {
    return (
        <StyledSmallEllipseIcon className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M13.0838 2.16508C13.2105 1.98412 13.4606 1.93936 13.635 2.07495C15.1801 3.27613 16.2353 5.00591 16.5919 6.9396C16.9728 9.00473 16.5264 11.1368 15.3491 12.8757C14.1717 14.6146 12.3579 15.8209 10.299 16.2341C8.24011 16.6474 6.10128 16.2346 4.34411 15.0847C2.58694 13.9349 1.35235 12.1402 0.906786 10.088C0.461224 8.0359 0.840422 5.89085 1.96254 4.11583C3.08466 2.34081 4.85972 1.07818 6.90461 0.60043C8.81935 0.153092 10.8269 0.427495 12.5462 1.36243C12.7403 1.46797 12.7994 1.71509 12.6843 1.90365C12.5692 2.09221 12.3235 2.15068 12.1288 2.04623C10.5893 1.2201 8.79682 0.979895 7.08661 1.37945C5.24621 1.80942 3.64866 2.94579 2.63875 4.54331C1.62884 6.14082 1.28756 8.07138 1.68857 9.91831C2.08957 11.7652 3.20071 13.3804 4.78216 14.4153C6.36361 15.4502 8.28856 15.8217 10.1416 15.4498C11.9946 15.0778 13.627 13.9922 14.6866 12.4272C15.7462 10.8622 16.148 8.94332 15.8052 7.0847C15.4866 5.35757 14.5489 3.81119 13.1755 2.73117C13.0019 2.59462 12.9571 2.34604 13.0838 2.16508Z" fill="#1A384E"/>
        </StyledSmallEllipseIcon>
    );
};
