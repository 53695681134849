import styled from 'styled-components';

export const StyledColexoLogo = styled.div`
    width: 7.20906rem;
    height: 2.814rem;

    &.large {
        width: 8.51569rem;
        height: 3.47156rem;
    }

`;
