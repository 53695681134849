import styled from 'styled-components';

export const StyledPaginationNumberBase = styled.div`
    display: flex;
    width: 2.5rem;
    padding: var(--spacing-md, 0.5rem);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    button.content.active {
        display: flex;
        width: 2.5rem;
        padding: var(--spacing-md, 0.5rem);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: var(--spacing-md, 0.5rem);
        border: 0px;
        box-shadow: none;
    }
    button.content:not(.active) {
        display: flex;
        width: 2.5rem;
        padding: var(--spacing-md, 0.5rem);
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: var(--spacing-md, 0.5rem);
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Paragraph XS - Bold */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.125rem */
        border: 0px;
        box-shadow: none;
    }
    .active {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-md, 0.5rem);
        background: var(--Athens-Gray-50, #F5F6F9);
    }
`;
