import React, { ReactNode } from 'react';
import { StyledKnobOnIcon } from './KnobOnIcon.styles';

interface KnobOnIconProps {
    className?: string;
    children?: ReactNode;
}

export const KnobOnIcon: React.FC<KnobOnIconProps> = ({ className, children }) => {
    return (
        <StyledKnobOnIcon className={className} xmlns="http://www.w3.org/2000/svg" width="43" height="44" viewBox="0 0 43 44" fill="none">
            <g filter="url(#filter0_d_3666_1483)">
                <rect x="1.5" y="1.80469" width="40" height="40" rx="20" fill="#1A384E"/>
                <rect x="1.25" y="1.55469" width="40.5" height="40.5" rx="20.25" stroke="black" strokeOpacity="0.02" strokeWidth="0.5"/>
                <path d="M18.0254 20.8817C17.4995 19.0759 16.9736 17.2841 16.4351 15.4782C17.1739 14.6458 17.9127 13.8133 18.6514 12.9809C19.2024 14.6175 19.7658 16.24 20.3168 17.8766C21.9822 15.8027 23.635 13.7146 25.3003 11.6406C26.2144 12.0921 27.1159 12.5436 28.03 12.9809C25.9765 15.6898 23.9105 18.3987 21.8569 21.1075C20.5797 21.0229 19.3026 20.9523 18.0254 20.8817Z" fill="#00FF8C"/>
                <path d="M21.8568 21.8971C22.9087 25.1844 23.9479 28.4717 24.9997 31.7731C23.8478 31.8437 22.6958 31.9001 21.5438 31.9706L19.1898 24.6059C18.1755 25.65 17.1738 26.694 16.1596 27.7381C15.7589 27.0891 15.3707 26.4401 14.97 25.7911C15.9843 24.225 16.986 22.6731 18.0002 21.107C19.2899 21.3751 20.5671 21.6431 21.8568 21.8971Z" fill="#CBFFE7"/>
            </g>
            <defs>
                <filter id="filter0_d_3666_1483" x="0.4" y="0.954687" width="42.2" height="42.2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="0.1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3666_1483"/>
                    <feOffset dy="0.25"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3666_1483"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3666_1483" result="shape"/>
                </filter>
            </defs>
        </StyledKnobOnIcon>
    );
};
