import styled from 'styled-components';
import { Dropzone } from '@files-ui/react';

export const StyledFileUploadTemplate = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0 ;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    border-radius: var(--radius-sm, 0.375rem);
    border: 1px solid var(--Athens-Gray-100, #E9EBF0);
    &:disabled {
        opacity: 0.5;
    }
    & .filesui-disabled-root {
        background: white;
        opacity: 0.5;
    }
    & .fui-dropzone-root {
        width: inherit;
        min-width: inherit;
        min-height: 217px;
        position: inherit;
        display: inherit;
        text-rendering: inherit;
        font-size: inherit;
        font-family: inherit;
        text-align: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        box-sizing: inherit;
        word-break: inherit;
    }
    & .fui-dropzone-root.files-ui-dropzone-extra-default {
        color: inherit;
        border: none;
        border-radius: inherit;
        flex: 1 0 0;
        align-self: stretch;
        border-bottom: 1px solid var(--Athens-Gray-50, #F5F6F9);

    }
    & .fui-dropzone-root .files-ui-header {
        width: inherit;
    }
    & .fui-dropzone-root .files-ui-footer {
        display: none;
    }

    & .fui-dropzone-root .files-ui-file-mosaic-main-container {
        z-index: 10;
    }
`;
