import React, { ReactNode } from 'react';
import { StyledReportPage } from './ReportPage.styles';
import { ReportNavbar } from './navbar/ReportNavbar';
import { selectPageChildCursorData, useCenPageContext } from '@baumeister/web-cen-protocol';
import {
    IReportBaseWidgetValue, IReportChartDataValue,
    IReportDesignBaseWidget,
    IReportDesignPage,
    IReportDesignSection, IReportFileSourceValue,
    IReportPageValue, IReportTableCellValue, IReportTableHeaderValue, ISectionTypeInstance,
} from '../../../serverTypes';
import { useAppSelector } from '../../../store';
import { IReportPage } from '../info/sectiontype/types';
import { collectReport } from '../info/sectiontype/content/preview/reportCollector';
import { StyledPreview } from '../info/sectiontype/content/preview/Preview.styles';
import { ReportPreviewContent } from '../info/sectiontype/content/preview/content/ReportPreviewContent';
import { useNavigate } from 'react-router-dom';

interface ReportPageProps {
    className?: string;
    children?: ReactNode;
}

export const ReportPage: React.FC<ReportPageProps> = ({ className, children }) => {
    const { pageData, channel } = useCenPageContext();
    const navigate = useNavigate();
    const reportDesignPage: IReportDesignPage[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignPage"));
    const reportDesignSection: IReportDesignSection[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignSection"));
    const reportDesignBaseWidget: IReportDesignBaseWidget[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignBaseWidget"));
    const reportPageValue: IReportPageValue[] = [pageData.data as IReportPageValue];
    const reportBaseWidgetValue: IReportBaseWidgetValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportBaseWidgetValue"));
    const reportChartDataValue: IReportChartDataValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportChartDataValue"));
    const reportTableHeaderValue: IReportTableHeaderValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableHeaderValue"));
    const reportTableCellValue: IReportTableCellValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableCellValue"));
    const sectionTypeInstance: ISectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "sectionTypeInstance"));
    const reportFileSourceValue: IReportFileSourceValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportFileSourceValue"));
    const companyUrl = sectionTypeInstance?.length > 0 ? sectionTypeInstance[0]._link : '';

    const reportPages: IReportPage[] = collectReport(reportDesignPage, reportDesignSection, reportPageValue, reportDesignBaseWidget, reportBaseWidgetValue, reportTableHeaderValue, reportTableCellValue, reportChartDataValue, reportFileSourceValue);
    console.log(reportPages);
    return (
        <StyledReportPage className={className}>
                {reportPages.length > 0 && <ReportPreviewContent reportPage={reportPages[0]} fullPage={true} onEmptyClick={() => navigate(companyUrl)}/>}
        </StyledReportPage>
    );
};
