import styled from 'styled-components';

export const StyledImageTemplate = styled.div`
    display: flex;
    flex: 1 1 auto;
    & .files-ui-dropzone-children-container {
        width: 100%;
    }
    & .files-ui-file-mosaic-main-container {
        width: 100%;
    }
    & .files-ui-file-mosaic-image-layer.blur {
        display: none;
    }
    & .files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container .files-ui-file-mosaic-image-layer {
        position: relative;
    }

    & .files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container {
        width: inherit;
        height: inherit;
    }

    & .files-ui-header span {
        display: none;
    }

    & .files-ui-header span:first-child {
        display: flex;
    }

    & .files-ui-file-mosaic-image-layer.files-ui-layer img {
        max-width: 700px;
        max-height: 100%;
        object-fit: contain;
    }
`;
