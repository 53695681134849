import styled from 'styled-components';

export const StyledSmallCircleText = styled.div`
    position: absolute;
    left: 0.5625rem;
    top: 0.625rem;
    color: var(--Base-Neutral-600, #1A384E);
    text-align: center;
    font-family: Lato;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 0.75rem */
    .docs {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
`;
