import React, { ReactNode } from 'react';
import { StyledRechtlicheBasisdatenIcon } from './RechtlicheBasisdatenIcon.styles';

interface RechtlicheBasisdatenIconProps {
    className?: string;
}

export const RechtlicheBasisdatenIcon: React.FC<RechtlicheBasisdatenIconProps> = ({ className }) => {
    return (
        <StyledRechtlicheBasisdatenIcon className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_3409_23044)">
                <path d="M17.9166 20.5026H13.2499V7.83594C13.2499 7.28394 12.8019 6.83594 12.2499 6.83594C11.6979 6.83594 11.2499 7.28394 11.2499 7.83594V20.5026H6.58325C6.03125 20.5026 5.58325 20.9506 5.58325 21.5026C5.58325 22.0546 6.03125 22.5026 6.58325 22.5026H17.9166C18.4686 22.5026 18.9166 22.0546 18.9166 21.5026C18.9166 20.9506 18.4686 20.5026 17.9166 20.5026Z" fill="white"/>
                <path d="M9.9166 4.50133H4.94994C4.57127 4.50133 4.22461 4.716 4.05527 5.05467L0.388605 12.388C0.141938 12.8827 0.341938 13.4827 0.835272 13.7293C1.32994 13.9773 1.93127 13.776 2.17661 13.2827L4.94194 7.75333L7.65127 13.2747C7.82461 13.6293 8.1806 13.8333 8.54994 13.8333C8.69794 13.8333 8.84861 13.8013 8.98994 13.7307C9.48594 13.4867 9.68994 12.888 9.44727 12.392L6.55527 6.5H9.9166C10.4686 6.5 10.9166 6.052 10.9166 5.5C10.9166 4.948 10.4686 4.50133 9.9166 4.50133Z" fill="white"/>
                <path d="M24.1113 12.3867L20.4446 5.05333C20.3019 4.76933 20.0326 4.58133 19.7273 4.52533C19.6819 4.51067 19.6246 4.5 19.5499 4.5H14.5833C14.0313 4.5 13.5833 4.948 13.5833 5.5C13.5833 6.052 14.0313 6.5 14.5833 6.5H17.9446L15.0526 12.392C14.8086 12.888 15.0139 13.4867 15.5099 13.7307C15.6513 13.8 15.8019 13.8333 15.9499 13.8333C16.3193 13.8333 16.6739 13.628 16.8486 13.2747L19.5579 7.75333L22.3219 13.2813C22.5699 13.776 23.1699 13.976 23.6633 13.728C24.1579 13.48 24.3593 12.88 24.1113 12.3867Z" fill="white"/>
                <path d="M9.47522 12.5H0.379221C0.332554 12.6027 0.293887 12.708 0.279221 12.816C0.263221 12.9307 0.251221 13.0467 0.251221 13.1667C0.251221 15.74 2.34455 17.8333 4.91789 17.8333C7.49122 17.8333 9.58455 15.74 9.58455 13.1667C9.58455 13.048 9.57122 12.932 9.54589 12.74C9.53789 12.6547 9.50322 12.5773 9.47522 12.5Z" fill="white"/>
                <path d="M24.142 12.5H15.046C14.9993 12.6027 14.9606 12.708 14.946 12.816C14.93 12.9307 14.918 13.0467 14.918 13.1667C14.918 15.74 17.0113 17.8333 19.5846 17.8333C22.158 17.8333 24.2513 15.74 24.2513 13.1667C24.2513 13.048 24.238 12.932 24.2126 12.74C24.2046 12.6547 24.17 12.5773 24.142 12.5Z" fill="white"/>
                <path d="M12.25 8.5C13.9069 8.5 15.25 7.15685 15.25 5.5C15.25 3.84315 13.9069 2.5 12.25 2.5C10.5931 2.5 9.25 3.84315 9.25 5.5C9.25 7.15685 10.5931 8.5 12.25 8.5Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3409_23044">
                    <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"/>
                </clipPath>
            </defs>
        </StyledRechtlicheBasisdatenIcon>
    );
};
