import React, { ReactNode } from 'react';
import {
    StyledAddRowsButton,
    StyledDeleteButton,
    StyledListColumn,
    StyledListGroup,
    StyledListRenderer,
    StyledListRow,
    StyledTableTemplate,
} from './TableTemplate.styles';
import { IQuestionData } from '../../../../types';
import { TableHeader } from './header/TableHeader';
import { TableRow } from './row/TableRow';
import { TableColumn } from './column/TableColumn';
import { TableContents } from './contents/TableContents';
import { TableHeadColumn } from './headcolumn/TableHeadColumn';
import { IDesignTableQuestion, ITableValue } from '../../../../../../../../serverTypes';
import { TemplateRenderer } from '../TemplateRenderer';
import classNames from 'classnames';
import { useCenPageContext, useWebSocketContext } from '@baumeister/web-cen-protocol';
import {
    ColexoProtocolMessage,
    InfoAddRowRequestMessage,
    InfoDeleteTableRowMessage,
} from '../../../../../../../../protocol';
import { DeleteIcon } from '../../../../../../../../components/icons/DeleteIcon';
import { Tooltip } from '@baumeister/web-ui-bootstrap';

interface TableTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

const renderRow = (columns:IQuestionData[], tableValue:ITableValue) => {
    const rowNumber = tableValue.valueInstance;
    return <TableRow className={'row'}>
        {
            columns.map((column, index) => {
                const columnQuestionData = {
                    ...column,
                    values: column.values.filter(element => element.value.valueInstance === rowNumber)
                };
                return <TableColumn key={index} className={'column'}><TemplateRenderer questionData={columnQuestionData} inTable={true}/></TableColumn>
            })
        }
    </TableRow>
}

const renderListRow = (columns:IQuestionData[], tableValue:ITableValue) => {
    const rowNumber = tableValue.valueInstance;
    columns.sort((a, b) => (a.design.orderNumber || 0) - (b.design.orderNumber || 0));
    const groups: IQuestionData[][] = [];
    for (let i = 0; i < columns.length; i += 2) {
        const chunk = columns.slice(i, i + 2);
        groups.push(chunk);
    }
    // console.log('Having chunks', groups);
    // console.log('and row', tableValue);
    return <React.Fragment>
        {groups.map((group, groupIndex) =>
            <StyledListGroup key={groupIndex}>
                {group.map((column, columnIndex) => {
                    const columnQuestionData = {
                        ...column,
                        values: column.values.filter(element => (element.value?.valueContainer as any)?.baseValueId === tableValue.baseValueId)
                    };
                    // console.log('question data for ', column.design.title, columnQuestionData);
                    if (columnQuestionData.values.length == 0) {
                        return <>(not available)</>;
                    }
                    return <StyledListColumn key={columnIndex} className={classNames(group.length === 1 ? 'large' : '')}>
                                <div className={'label'}>
                                    {column.design.title}
                                </div>
                                <TemplateRenderer className={'field'} questionData={columnQuestionData} inTable={true}/>
                            </StyledListColumn>;
                })}
            </StyledListGroup>
            )}
    </React.Fragment>;
}


interface TableRendererProps {
    className?: string;
    tableValues: ITableValue[];
    questionData:IQuestionData;
    children: ReactNode;
}

const TableRenderer: React.FC<TableRendererProps> = ({className, questionData, tableValues, children}) => {
    return (
        <React.Fragment>
            <TableHeader>
                {questionData.columns.map((element, index) => <TableHeadColumn key={index}>{element.design.title}</TableHeadColumn>)}
            </TableHeader>
            <TableContents>
                {tableValues.map((element, index) => <React.Fragment key={index}>{renderRow(questionData.columns, element)}</React.Fragment>)}
            </TableContents>
            {children}
        </React.Fragment>
    );
}

interface DeleteButtonProps {
    questionData: IQuestionData;
    row: ITableValue;
    className?: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({questionData, row, className}) => {
    const tooltipId = `row_${questionData.instance.questionInstanceId}_col${row.valueInstance}`;
    const { cenSocketProtocol} = useWebSocketContext();
    const deleteRow = () => {
        const message: InfoDeleteTableRowMessage = {
            type: ColexoProtocolMessage.INFO_DELETE_TABLE_ROW,
            payload: {
                tableValueEntityPath: row._link
            }
        }
        cenSocketProtocol.request(message);
    }
    return <StyledDeleteButton onClick={() => deleteRow()}>
        <DeleteIcon className={'deleteIcon'} data-tooltip-id={tooltipId} />
        <Tooltip className={'edit-tooltip'} placement={'top'} target={tooltipId}>
            Dieses Feld löschen?
        </Tooltip>
    </StyledDeleteButton>
}

const ListRenderer: React.FC<TableRendererProps> = ({className, questionData, tableValues, children }) => {
    const designTableQuestion = questionData.design as IDesignTableQuestion;
    const allowDelete = designTableQuestion.addRows && (designTableQuestion.initialRows || 0) < tableValues.length;
    return (
        <StyledListRenderer className={className}>
            {tableValues.map((row, rowIndex) =>
                <StyledListRow key={rowIndex}>
                    {allowDelete && <DeleteButton questionData={questionData} row={row}/>}
                    {renderListRow(questionData.columns, row)}
                </StyledListRow>
            )}
        </StyledListRenderer>
    )
}

interface AddRowsProps {
    questionData: IQuestionData;
}

const AddRows: React.FC<AddRowsProps> = ({questionData}) => {
    const { cenSocketProtocol} = useWebSocketContext();
    const { pageData, channel } = useCenPageContext();
    const onClick = () => {
        const addRowMessage:InfoAddRowRequestMessage = {
            type: ColexoProtocolMessage.INFO_ADD_ROW,
            payload: {
                entityPath: questionData.instanceLink,
                pageLink: pageData.dataPath,
                channel
            }
        }
        cenSocketProtocol.request(addRowMessage);
    }
    return <StyledAddRowsButton caption={'+hinzufügen'} variant={'addrow'} onClick={onClick}></StyledAddRowsButton>
};

export const TableTemplate: React.FC<TableTemplateProps> = ({ className, questionData, children }) => {
    const tableValues:ITableValue[] = questionData.values.filter((element) => (element.value as any).tableValueId).map(element => element.value as ITableValue);
    tableValues.sort((a, b) => (a.valueInstance || 0) - (b.valueInstance || 0));
    const addRows = (questionData.design as IDesignTableQuestion).addRows;
    const listMode = (questionData.design as IDesignTableQuestion).showAsList;
    const rendered = listMode
        ? <ListRenderer className={className} tableValues={tableValues} questionData={questionData}>{children}</ListRenderer>
        : <TableRenderer className={className} tableValues={tableValues} questionData={questionData}>{children}</TableRenderer>;
    return <StyledTableTemplate>
        {rendered}
        {addRows && <AddRows questionData={questionData} />}
    </StyledTableTemplate>
};
