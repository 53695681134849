import styled from 'styled-components';

export const StyledPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.75rem;
    align-self: stretch;
    background: var(--Base-White, #FFF);
`;
