import React, { ReactNode } from 'react';
import { StyledCollapseIcon } from './CollapseIcon.styles';

interface CollapseIconProps {
    className?: string;
    children?: ReactNode;
}

export const CollapseIcon: React.FC<CollapseIconProps> = ({ className, children }) => {
    return (
        <StyledCollapseIcon className={className}xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none">
            <path d="M8.085 14.8512C8.14336 14.9163 8.18965 14.9937 8.22124 15.0788C8.25282 15.1639 8.26907 15.2551 8.26907 15.3472C8.26907 15.4393 8.25282 15.5305 8.22124 15.6156C8.18965 15.7007 8.14336 15.7781 8.085 15.8432C8.02665 15.9083 7.95737 15.96 7.88112 15.9952C7.80487 16.0305 7.72315 16.0486 7.64062 16.0486C7.55809 16.0486 7.47637 16.0305 7.40012 15.9952C7.32387 15.96 7.25459 15.9083 7.19623 15.8432L0.915172 8.8328C0.856773 8.76769 0.810445 8.69038 0.778836 8.60527C0.747227 8.52017 0.730957 8.42894 0.730957 8.33681C0.730957 8.24469 0.747227 8.15346 0.778836 8.06836C0.810445 7.98325 0.856773 7.90594 0.915172 7.84083L7.19623 0.830444C7.31409 0.6989 7.47394 0.625 7.64062 0.625C7.8073 0.625 7.96715 0.6989 8.085 0.830444C8.20286 0.961987 8.26907 1.1404 8.26907 1.32643C8.26907 1.51246 8.20286 1.69087 8.085 1.82241L2.24754 8.33681L8.085 14.8512Z" fill="white"/>
        </StyledCollapseIcon>
    );
};
