import React, { ReactNode } from 'react';
import { StyledOnboarding2Page } from './Onboarding2Page.styles';
import { OnboardingFrame } from './frame/OnboardingFrame';
import { ContentFrame } from './content-frame/ContentFrame';
import { ContentLeft } from './content-frame/left/ContentLeft';
import { ContentRight } from './content-frame/right/ContentRight';
import { useNavigate, useResolvedPath } from 'react-router-dom';

interface Onboarding2PageProps {
    className?: string;
}

export const Onboarding2Page: React.FC<Onboarding2PageProps> = ({ className}) => {
    const navigate = useNavigate();
    const forwardPath = useResolvedPath('../3', {relative: 'path'});
    const backwardPath = useResolvedPath('../1', {relative: 'path'});
    return (
        <OnboardingFrame className={className}>
            <ContentFrame>
                <ContentLeft
                    step={3}
                    title={`Gewinnen Sie Interessenten.`}
                    onButtonClick={() => navigate(forwardPath)}
                    onArrowClick={() => navigate(backwardPath)}
                >
                    <p>Verschicken Sie Ihr Fact Sheet an Interessenten und bekommen interessante Verkaufsangebote und Anfragen.</p>
                </ContentLeft>
                <ContentRight activeStep={3}/>
            </ContentFrame>
        </OnboardingFrame>
    );
};
