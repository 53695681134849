import React, { ReactNode } from 'react';
import { StyledVectorIcon } from './VectorIcon.styles';

interface VectorIconProps {
    className?: string;
}

export const VectorIcon: React.FC<VectorIconProps> = ({ className }) => {
    return (
        <StyledVectorIcon className={className} xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
            <path
                d="M25.2071 1.90337L9.20708 17.9034C9.11421 17.9963 9.00392 18.0701 8.88252 18.1204C8.76112 18.1708 8.63099 18.1967 8.49958 18.1967C8.36816 18.1967 8.23804 18.1708 8.11664 18.1204C7.99524 18.0701 7.88495 17.9963 7.79208 17.9034L0.792079 10.9034C0.604439 10.7157 0.499023 10.4612 0.499023 10.1959C0.499023 9.9305 0.604439 9.67601 0.792079 9.48837C0.97972 9.30073 1.23422 9.19531 1.49958 9.19531C1.76494 9.19531 2.01944 9.30073 2.20708 9.48837L8.49958 15.7821L23.7921 0.488368C23.9797 0.300727 24.2342 0.195312 24.4996 0.195312C24.7649 0.195313 25.0194 0.300727 25.2071 0.488368C25.3947 0.676009 25.5001 0.930505 25.5001 1.19587C25.5001 1.46123 25.3947 1.71573 25.2071 1.90337Z"
                fill="#8A9AB1" />
        </StyledVectorIcon>
    );
};
