import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    display: flex;
    width: 22.5625rem;
    height: 4.1875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    .title {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.1rem */
    }
`;
