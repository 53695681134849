import React, { ReactNode } from 'react';
import { StyledMaskGroup } from './MaskGroup.styles';

interface MaskGroupProps {
    className?: string;
    children?: ReactNode;
}

export const MaskGroup: React.FC<MaskGroupProps> = ({ className, children }) => {
    return (
        <StyledMaskGroup className={className} xmlns="http://www.w3.org/2000/svg" width="189" height="127" viewBox="0 0 189 127" fill="none">
                <path
                    d="M93.5017 126.52C145.139 126.52 187 125.448 187 124.127C187 122.806 145.139 121.734 93.5017 121.734C41.864 121.734 0.00341797 122.806 0.00341797 124.127C0.00341797 125.448 41.864 126.52 93.5017 126.52Z"
                    fill="#CEDFEA" />
                <g filter="url(#filter0_f_2918_15511)">
                    <path
                        d="M175.974 62.8787L171.518 86.3098L168.614 101.571C168.524 102.042 168.333 102.485 168.058 102.888C167.166 104.205 165.392 105.088 163.416 105.088H94.4447C92.4673 105.088 90.6035 104.205 89.5737 102.888C89.2587 102.485 89.0196 102.042 88.8815 101.571L84.7343 87.452L83.7713 84.1796L77.5131 62.8796C77.4906 62.8066 77.4725 62.7326 77.4581 62.6596H77.4337L76.1413 58.1198C75.2694 55.053 77.2848 52.3359 80.4753 52.3359H118.049L123.358 59.2737H172.23C174.602 59.2737 176.344 60.9576 175.974 62.8805V62.8787Z"
                        fill="#0D2B5C" />
                    <path
                        d="M169.77 31.6094H138.109C137.227 31.6094 136.408 32.0664 135.947 32.8165L132.602 38.2523H91.6586C89.8165 38.2523 88.3237 39.7434 88.3237 41.5833V99.9989C89.2091 101.813 91.0711 103.038 93.1983 103.038H167.432C169.56 103.038 171.421 101.814 172.307 99.9989V34.1434C172.307 32.7434 171.17 31.6094 169.769 31.6094H169.77Z"
                        fill="#253F8C" />
                    <path
                        d="M133.176 12.118L141.544 56.2912L104.98 63.201L95.1956 11.5501L124.273 6.05469L125.69 13.5324L133.176 12.118Z"
                        fill="#F8F8FF" />
                    <path d="M133.176 12.118L125.689 13.5324L124.272 6.05469L133.176 12.118Z" fill="#2A4492" />
                    <path d="M129.128 18.8336L113.979 21.6953L114.209 22.9124L129.358 20.0506L129.128 18.8336Z"
                          fill="#C8C8E8" />
                    <path d="M129.72 21.9664L114.572 24.8281L114.802 26.0452L129.951 23.1835L129.72 21.9664Z"
                          fill="#C8C8E8" />
                    <path d="M130.313 25.0992L115.165 27.9609L115.395 29.178L130.544 26.3163L130.313 25.0992Z"
                          fill="#C8C8E8" />
                    <path d="M130.905 28.2286L104.614 33.1953L104.844 34.4124L131.136 29.4456L130.905 28.2286Z"
                          fill="#C8C8E8" />
                    <path d="M131.498 31.3614L105.207 36.3281L105.437 37.5452L131.729 32.5785L131.498 31.3614Z"
                          fill="#C8C8E8" />
                    <path d="M132.091 34.4864L105.8 39.4531L106.03 40.6702L132.322 35.7035L132.091 34.4864Z"
                          fill="#C8C8E8" />
                    <path d="M132.684 37.6192L106.393 42.5859L106.623 43.803L132.914 38.8363L132.684 37.6192Z"
                          fill="#C8C8E8" />
                    <path d="M133.277 40.752L106.985 45.7188L107.216 46.9358L133.507 41.9691L133.277 40.752Z"
                          fill="#C8C8E8" />
                    <path d="M133.87 43.877L107.578 48.8438L107.809 50.0608L134.1 45.0941L133.87 43.877Z"
                          fill="#C8C8E8" />
                    <path d="M128.541 48.1293L114.09 50.8594L114.32 52.0764L128.772 49.3464L128.541 48.1293Z"
                          fill="#C8C8E8" />
                    <path d="M111.715 22.1272L102.836 23.8047L104.252 31.2833L113.131 29.6058L111.715 22.1272Z"
                          fill="#C8C8E8" />
                    <path
                        d="M109.421 32.2147L122.626 75.1934L87.0503 86.0977L71.6106 35.8432L99.9015 27.1719L102.137 34.4477L109.421 32.2147Z"
                        fill="#F8F8FF" />
                    <path d="M109.421 32.2147L102.137 34.4477L99.9014 27.1719L109.421 32.2147Z" fill="#2A4492" />
                    <path d="M106.14 39.3261L91.4011 43.8438L91.7649 45.0279L106.504 40.5102L106.14 39.3261Z"
                          fill="#C8C8E8" />
                    <path d="M107.075 42.373L92.3364 46.8906L92.7002 48.0748L107.439 43.5571L107.075 42.373Z"
                          fill="#C8C8E8" />
                    <path d="M108.012 45.4198L93.2729 49.9375L93.6368 51.1216L108.376 46.604L108.012 45.4198Z"
                          fill="#C8C8E8" />
                    <path d="M108.946 48.464L83.366 56.3047L83.7298 57.4888L109.31 49.6481L108.946 48.464Z"
                          fill="#C8C8E8" />
                    <path d="M109.882 51.5109L84.3022 59.3516L84.6661 60.5357L110.246 52.695L109.882 51.5109Z"
                          fill="#C8C8E8" />
                    <path d="M110.818 54.5577L85.238 62.3984L85.6018 63.5826L111.182 55.7419L110.818 54.5577Z"
                          fill="#C8C8E8" />
                    <path d="M111.755 57.6046L86.1746 65.4453L86.5384 66.6294L112.118 58.7887L111.755 57.6046Z"
                          fill="#C8C8E8" />
                    <path d="M112.689 60.6515L87.1089 68.4922L87.4727 69.6763L113.053 61.8356L112.689 60.6515Z"
                          fill="#C8C8E8" />
                    <path d="M113.626 63.6906L88.0457 71.5312L88.4095 72.7154L113.99 64.8747L113.626 63.6906Z"
                          fill="#C8C8E8" />
                    <path d="M108.801 68.5027L94.7405 72.8125L95.1043 73.9966L109.165 69.6868L108.801 68.5027Z"
                          fill="#C8C8E8" />
                    <path d="M89.1993 44.5238L80.5598 47.1719L82.7954 54.4481L91.4348 51.8L89.1993 44.5238Z"
                          fill="#C8C8E8" />
                    <path
                        d="M168.286 19.2571L159.452 63.3393L122.964 56.0454L133.292 4.5L162.309 10.3001L160.814 17.7634L168.286 19.2571Z"
                        fill="#F8F8FF" />
                    <path d="M168.286 19.2539L160.813 17.7602L162.309 10.2969L168.286 19.2539Z" fill="#2A4492" />
                    <path d="M162.009 23.9758L146.892 20.9531L146.648 22.1677L161.765 25.1903L162.009 23.9758Z"
                          fill="#C8C8E8" />
                    <path d="M161.383 27.093L146.266 24.0703L146.022 25.2848L161.139 28.3075L161.383 27.093Z"
                          fill="#C8C8E8" />
                    <path d="M160.757 30.218L145.639 27.1953L145.396 28.4098L160.513 31.4325L160.757 30.218Z"
                          fill="#C8C8E8" />
                    <path d="M160.13 33.3398L133.893 28.0938L133.65 29.3083L159.887 34.5543L160.13 33.3398Z"
                          fill="#C8C8E8" />
                    <path d="M159.504 36.4648L133.267 31.2188L133.024 32.4333L159.261 37.6793L159.504 36.4648Z"
                          fill="#C8C8E8" />
                    <path d="M158.878 39.5898L132.641 34.3438L132.398 35.5583L158.635 40.8043L158.878 39.5898Z"
                          fill="#C8C8E8" />
                    <path d="M158.252 42.7148L132.015 37.4688L131.772 38.6833L158.009 43.9293L158.252 42.7148Z"
                          fill="#C8C8E8" />
                    <path d="M157.626 45.8398L131.389 40.5938L131.146 41.8083L157.382 47.0543L157.626 45.8398Z"
                          fill="#C8C8E8" />
                    <path d="M157.001 48.9648L130.764 43.7188L130.52 44.9333L156.757 50.1793L157.001 48.9648Z"
                          fill="#C8C8E8" />
                    <path d="M150.466 50.907L136.045 48.0234L135.801 49.238L150.223 52.1215L150.466 50.907Z"
                          fill="#C8C8E8" />
                    <path d="M144.633 20.4984L135.772 18.7266L134.276 26.1897L143.138 27.9614L144.633 20.4984Z"
                          fill="#C8C8E8" />
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M130.873 14.6799L122.946 54.2344L123.681 54.3814L131.609 14.827L130.873 14.6799Z"
                              fill="#9E9E9E" />
                    </g>
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M111.105 36.4209L110.388 36.6406L116.188 55.5185L116.905 55.2988L111.105 36.4209Z"
                              fill="#9E9E9E" />
                    </g>
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M142.752 54.2763L142.028 54.0816L150.032 24.5547L150.756 24.7494L142.752 54.2763Z"
                              fill="#9E9E9E" />
                    </g>
                    <path
                        d="M184.697 41.6893L173.008 85.1044L137.072 75.4523L150.739 24.6875L179.317 32.3636L177.338 39.7133L184.697 41.6893Z"
                        fill="#F8F8FF" />
                    <path d="M184.697 41.6929L177.338 39.7169L179.316 32.3672L184.697 41.6929Z" fill="#2A4492" />
                    <path d="M178.131 45.9751L163.243 41.9766L162.921 43.1727L177.809 47.1712L178.131 45.9751Z"
                          fill="#C8C8E8" />
                    <path d="M177.303 49.0454L162.415 45.0469L162.093 46.243L176.981 50.2415L177.303 49.0454Z"
                          fill="#C8C8E8" />
                    <path d="M176.475 52.1235L161.587 48.125L161.265 49.3211L176.153 53.3197L176.475 52.1235Z"
                          fill="#C8C8E8" />
                    <path d="M175.646 55.1975L149.806 48.2578L149.484 49.454L175.324 56.3937L175.646 55.1975Z"
                          fill="#C8C8E8" />
                    <path d="M174.819 58.2757L148.979 51.3359L148.657 52.5321L174.497 59.4718L174.819 58.2757Z"
                          fill="#C8C8E8" />
                    <path d="M173.991 61.3538L148.151 54.4141L147.829 55.6102L173.669 62.5499L173.991 61.3538Z"
                          fill="#C8C8E8" />
                    <path d="M173.161 64.4319L147.322 57.4922L147 58.6883L172.839 65.6281L173.161 64.4319Z"
                          fill="#C8C8E8" />
                    <path d="M172.333 67.51L146.493 60.5703L146.171 61.7665L172.011 68.7062L172.333 67.51Z"
                          fill="#C8C8E8" />
                    <path d="M171.506 70.5804L145.666 63.6406L145.344 64.8368L171.184 71.7765L171.506 70.5804Z"
                          fill="#C8C8E8" />
                    <path d="M164.859 72.0958L150.656 68.2812L150.334 69.4774L164.537 73.2919L164.859 72.0958Z"
                          fill="#C8C8E8" />
                    <path d="M161.019 41.3751L152.292 39.0312L150.313 46.3813L159.04 48.7252L161.019 41.3751Z"
                          fill="#C8C8E8" />
                    <path
                        d="M178.83 57.9292L175.834 82.8667L173.685 100.758C173.615 101.333 173.436 101.876 173.164 102.37C172.278 103.993 170.417 105.087 168.289 105.087H94.0557C91.9275 105.087 90.0664 103.992 89.1802 102.37C88.9103 101.876 88.728 101.334 88.6594 100.758L86.6657 84.1576L86.2225 80.4778L83.514 57.9292C83.5041 57.8552 83.4987 57.7813 83.4969 57.7074H83.4734L82.9716 53.1991C82.6395 50.208 84.9825 47.5938 87.9951 47.5938H123.477L128.103 54.3359H175.01C177.286 54.3359 179.065 56.0063 178.831 57.9292H178.83Z"
                        fill="#1D4887" />
                    <g style={{mixBlendMode:'multiply'}}>
                        <path
                            d="M175.835 82.8616L173.685 100.753C173.615 101.328 173.436 101.871 173.164 102.365C172.279 103.988 170.417 105.082 168.289 105.082H155.532C153.698 102.552 152.616 99.4415 152.616 96.079C152.616 87.5952 159.501 80.7188 167.994 80.7188C170.857 80.7188 173.539 81.5003 175.835 82.8616Z"
                            fill="#A7A9AC" />
                    </g>
                    <path
                        d="M177.863 107.417C182.807 102.479 182.807 94.4737 177.863 89.5358C172.92 84.598 164.904 84.598 159.961 89.5358C155.017 94.4737 155.017 102.479 159.961 107.417C164.904 112.355 172.92 112.355 177.863 107.417Z"
                        fill="#17a2b8" />
                    <path
                        d="M175.543 97.3809H170.008V91.8522C170.008 91.2473 169.517 90.7578 168.912 90.7578C168.307 90.7578 167.816 91.2482 167.816 91.8522V97.3809H162.281C161.676 97.3809 161.186 97.8713 161.186 98.4753C161.186 99.0793 161.677 99.5697 162.281 99.5697H167.816V105.098C167.816 105.703 168.307 106.193 168.912 106.193C169.517 106.193 170.008 105.702 170.008 105.098V99.5697H175.543C176.149 99.5697 176.639 99.0793 176.639 98.4753C176.639 97.8713 176.148 97.3809 175.543 97.3809Z"
                        fill="#EFEBDF" />
                </g>
                <g filter="url(#filter1_b_2918_15511)">
                    <path
                        d="M175.69 63.4803L171.233 86.9114L168.33 102.172C168.239 102.644 168.048 103.087 167.774 103.49C166.881 104.807 165.108 105.69 163.131 105.69H94.16C92.1826 105.69 90.3188 104.807 89.2891 103.49C88.9741 103.087 88.7349 102.644 88.5968 102.172L84.4497 88.0536L83.4867 84.7812L77.2285 63.4811C77.2059 63.4081 77.1879 63.3342 77.1734 63.2612H77.149L75.8566 58.7214C74.9848 55.6545 77.0001 52.9375 80.1906 52.9375H117.764L123.074 59.8752H171.945C174.317 59.8752 176.059 61.5592 175.69 63.482V63.4803Z"
                        fill="#0D2B5C" />
                    <path
                        d="M169.486 32.2109H137.824C136.942 32.2109 136.123 32.668 135.662 33.418L132.317 38.8539H91.3739C89.5319 38.8539 88.0391 40.3449 88.0391 42.1849V100.6C88.9245 102.414 90.7864 103.639 92.9137 103.639H167.147C169.275 103.639 171.136 102.415 172.022 100.6V34.745C172.022 33.345 170.886 32.2109 169.485 32.2109H169.486Z"
                        fill="#253F8C" />
                    <path
                        d="M132.892 12.7196L141.26 56.8927L104.696 63.8026L94.9111 12.1516L123.988 6.65625L125.405 14.134L132.892 12.7196Z"
                        fill="#F8F8FF" />
                    <path d="M132.892 12.7196L125.405 14.134L123.988 6.65625L132.892 12.7196Z" fill="#2A4492" />
                    <path d="M128.843 19.4352L113.695 22.2969L113.925 23.5139L129.074 20.6522L128.843 19.4352Z"
                          fill="#C8C8E8" />
                    <path d="M129.436 22.568L114.287 25.4297L114.518 26.6467L129.666 23.785L129.436 22.568Z"
                          fill="#C8C8E8" />
                    <path d="M130.029 25.7008L114.88 28.5625L115.111 29.7795L130.259 26.9178L130.029 25.7008Z"
                          fill="#C8C8E8" />
                    <path d="M130.621 28.8302L104.33 33.7969L104.56 35.0139L130.851 30.0472L130.621 28.8302Z"
                          fill="#C8C8E8" />
                    <path d="M131.214 31.963L104.923 36.9297L105.153 38.1467L131.444 33.18L131.214 31.963Z"
                          fill="#C8C8E8" />
                    <path d="M131.807 35.088L105.515 40.0547L105.746 41.2717L132.037 36.305L131.807 35.088Z"
                          fill="#C8C8E8" />
                    <path d="M132.4 38.2208L106.108 43.1875L106.339 44.4046L132.63 39.4378L132.4 38.2208Z"
                          fill="#C8C8E8" />
                    <path d="M132.992 41.3536L106.701 46.3203L106.931 47.5374L133.223 42.5707L132.992 41.3536Z"
                          fill="#C8C8E8" />
                    <path d="M133.585 44.4786L107.294 49.4453L107.524 50.6624L133.816 45.6956L133.585 44.4786Z"
                          fill="#C8C8E8" />
                    <path d="M128.257 48.7309L113.805 51.4609L114.036 52.678L128.487 49.9479L128.257 48.7309Z"
                          fill="#C8C8E8" />
                    <path d="M111.431 22.7288L102.551 24.4062L103.967 31.8848L112.847 30.2074L111.431 22.7288Z"
                          fill="#C8C8E8" />
                    <path
                        d="M109.137 32.8163L122.341 75.795L86.7656 86.6993L71.3259 36.4447L99.6168 27.7734L101.852 35.0492L109.137 32.8163Z"
                        fill="#F8F8FF" />
                    <path d="M109.137 32.8163L101.853 35.0492L99.6169 27.7734L109.137 32.8163Z" fill="#2A4492" />
                    <path d="M105.855 39.9277L91.1165 44.4453L91.4803 45.6294L106.219 41.1118L105.855 39.9277Z"
                          fill="#C8C8E8" />
                    <path d="M106.791 42.9745L92.0518 47.4922L92.4156 48.6763L107.154 44.1587L106.791 42.9745Z"
                          fill="#C8C8E8" />
                    <path d="M107.727 46.0214L92.9883 50.5391L93.3521 51.7232L108.091 47.2055L107.727 46.0214Z"
                          fill="#C8C8E8" />
                    <path d="M108.661 49.0656L83.0813 56.9062L83.4451 58.0904L109.025 50.2497L108.661 49.0656Z"
                          fill="#C8C8E8" />
                    <path d="M109.598 52.1124L84.0176 59.9531L84.3814 61.1372L109.961 53.2966L109.598 52.1124Z"
                          fill="#C8C8E8" />
                    <path d="M110.533 55.1593L84.9534 63L85.3172 64.1841L110.897 56.3434L110.533 55.1593Z"
                          fill="#C8C8E8" />
                    <path d="M111.47 58.2062L85.8899 66.0469L86.2537 67.231L111.834 59.3903L111.47 58.2062Z"
                          fill="#C8C8E8" />
                    <path d="M112.404 61.2531L86.8242 69.0938L87.188 70.2779L112.768 62.4372L112.404 61.2531Z"
                          fill="#C8C8E8" />
                    <path d="M113.341 64.2921L87.761 72.1328L88.1248 73.3169L113.705 65.4762L113.341 64.2921Z"
                          fill="#C8C8E8" />
                    <path d="M108.516 69.1043L94.4558 73.4141L94.8196 74.5982L108.88 70.2884L108.516 69.1043Z"
                          fill="#C8C8E8" />
                    <path d="M88.9146 45.1253L80.2751 47.7734L82.5107 55.0497L91.1501 52.4016L88.9146 45.1253Z"
                          fill="#C8C8E8" />
                    <path
                        d="M168.001 19.8587L159.168 63.9408L122.679 56.647L133.007 5.10156L162.024 10.9017L160.529 18.365L168.001 19.8587Z"
                        fill="#F8F8FF" />
                    <path d="M168.001 19.8555L160.529 18.3617L162.024 10.8984L168.001 19.8555Z" fill="#2A4492" />
                    <path d="M161.724 24.5773L146.607 21.5547L146.364 22.7692L161.481 25.7919L161.724 24.5773Z"
                          fill="#C8C8E8" />
                    <path d="M161.098 27.6945L145.981 24.6719L145.738 25.8864L160.855 28.909L161.098 27.6945Z"
                          fill="#C8C8E8" />
                    <path d="M160.472 30.8195L145.355 27.7969L145.111 29.0114L160.229 32.0341L160.472 30.8195Z"
                          fill="#C8C8E8" />
                    <path d="M159.846 33.9491L133.609 28.7031L133.365 29.9177L159.602 35.1637L159.846 33.9491Z"
                          fill="#C8C8E8" />
                    <path d="M159.219 37.0741L132.982 31.8281L132.739 33.0427L158.976 38.2887L159.219 37.0741Z"
                          fill="#C8C8E8" />
                    <path d="M158.593 40.1913L132.356 34.9453L132.113 36.1598L158.35 41.4059L158.593 40.1913Z"
                          fill="#C8C8E8" />
                    <path d="M157.967 43.3163L131.73 38.0703L131.487 39.2848L157.724 44.5309L157.967 43.3163Z"
                          fill="#C8C8E8" />
                    <path d="M157.341 46.4413L131.104 41.1953L130.861 42.4098L157.098 47.6559L157.341 46.4413Z"
                          fill="#C8C8E8" />
                    <path d="M156.716 49.5663L130.479 44.3203L130.236 45.5348L156.473 50.7809L156.716 49.5663Z"
                          fill="#C8C8E8" />
                    <path d="M150.182 51.5086L135.76 48.625L135.517 49.8395L149.938 52.7231L150.182 51.5086Z"
                          fill="#C8C8E8" />
                    <path d="M144.349 21.0999L135.487 19.3281L133.992 26.7912L142.853 28.563L144.349 21.0999Z"
                          fill="#C8C8E8" />
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M130.589 15.2815L122.661 54.8359L123.397 54.983L131.324 15.4285L130.589 15.2815Z"
                              fill="#9E9E9E" />
                    </g>
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M110.821 37.0224L110.104 37.2422L115.904 56.1201L116.621 55.9003L110.821 37.0224Z"
                              fill="#9E9E9E" />
                    </g>
                    <g style={{mixBlendMode:'multiply'}} opacity="0.6">
                        <path d="M142.467 54.8779L141.744 54.6832L149.747 25.1562L150.471 25.351L142.467 54.8779Z"
                              fill="#9E9E9E" />
                    </g>
                    <path
                        d="M184.412 42.2909L172.723 85.7059L136.787 76.0538L150.454 25.2891L179.032 32.9651L177.054 40.3148L184.412 42.2909Z"
                        fill="#F8F8FF" />
                    <path d="M184.412 42.2945L177.053 40.3185L179.032 32.9688L184.412 42.2945Z" fill="#2A4492" />
                    <path d="M177.847 46.5767L162.958 42.5781L162.636 43.7743L177.525 47.7728L177.847 46.5767Z"
                          fill="#C8C8E8" />
                    <path d="M177.018 49.647L162.13 45.6484L161.808 46.8446L176.696 50.8431L177.018 49.647Z"
                          fill="#C8C8E8" />
                    <path d="M176.191 52.7251L161.302 48.7266L160.98 49.9227L175.869 53.9212L176.191 52.7251Z"
                          fill="#C8C8E8" />
                    <path d="M175.361 55.7991L149.522 48.8594L149.2 50.0555L175.039 56.9952L175.361 55.7991Z"
                          fill="#C8C8E8" />
                    <path d="M174.534 58.8772L148.694 51.9375L148.372 53.1336L174.212 60.0734L174.534 58.8772Z"
                          fill="#C8C8E8" />
                    <path d="M173.706 61.9554L147.866 55.0156L147.544 56.2118L173.384 63.1515L173.706 61.9554Z"
                          fill="#C8C8E8" />
                    <path d="M172.877 65.0335L147.037 58.0938L146.715 59.2899L172.555 66.2296L172.877 65.0335Z"
                          fill="#C8C8E8" />
                    <path d="M172.048 68.1116L146.209 61.1719L145.887 62.368L171.726 69.3077L172.048 68.1116Z"
                          fill="#C8C8E8" />
                    <path d="M171.221 71.1819L145.381 64.2422L145.059 65.4383L170.899 72.3781L171.221 71.1819Z"
                          fill="#C8C8E8" />
                    <path d="M164.574 72.6974L150.371 68.8828L150.049 70.079L164.252 73.8935L164.574 72.6974Z"
                          fill="#C8C8E8" />
                    <path d="M160.734 41.9766L152.007 39.6328L150.028 46.9829L158.755 49.3267L160.734 41.9766Z"
                          fill="#C8C8E8" />
                    <path
                        d="M178.545 58.5307L175.55 83.4682L173.4 101.36C173.33 101.935 173.151 102.478 172.879 102.972C171.994 104.594 170.132 105.689 168.005 105.689H93.771C91.6428 105.689 89.7818 104.593 88.8955 102.972C88.6256 102.478 88.4433 101.936 88.3747 101.36L86.381 84.7591L85.9379 81.0793L83.2294 58.5307C83.2194 58.4568 83.214 58.3829 83.2122 58.309H83.1888L82.6869 53.8007C82.3548 50.8096 84.6978 48.1953 87.7105 48.1953H123.193L127.818 54.9374H174.725C177.001 54.9374 178.78 56.6079 178.546 58.5307H178.545Z"
                        fill="#1D4887" />
                    <g style={{mixBlendMode:'multiply'}}>
                        <path
                            d="M175.55 84.6662L173.4 102.558C173.331 103.133 173.151 103.676 172.879 104.17C171.994 105.792 170.132 106.887 168.005 106.887H155.247C153.413 104.356 152.331 101.246 152.331 97.8837C152.331 89.3999 159.217 82.5234 167.71 82.5234C170.572 82.5234 173.255 83.305 175.55 84.6662Z"
                            fill="#A7A9AC" />
                    </g>
                    <path
                        d="M177.579 109.214C182.522 104.276 182.522 96.2705 177.579 91.3327C172.635 86.3949 164.62 86.3949 159.676 91.3327C154.732 96.2705 154.732 104.276 159.676 109.214C164.62 114.152 172.635 114.152 177.579 109.214Z"
                        fill="#17a2b8" />
                    <path
                        d="M175.258 99.1778H169.723V93.6491C169.723 93.0442 169.232 92.5547 168.627 92.5547C168.023 92.5547 167.532 93.0451 167.532 93.6491V99.1778H161.997C161.391 99.1778 160.901 99.6682 160.901 100.272C160.901 100.876 161.392 101.367 161.997 101.367H167.532V106.895C167.532 107.5 168.023 107.99 168.627 107.99C169.232 107.99 169.723 107.499 169.723 106.895V101.367H175.258C175.864 101.367 176.354 100.876 176.354 100.272C176.354 99.6682 175.863 99.1778 175.258 99.1778Z"
                        fill="#EFEBDF" />
                </g>
                <path d="M57.6284 44.1953L60.8333 50.8329L57.6726 50.9636L57.6284 44.1953Z" fill="#0D2B5C" />
                <path
                    d="M57.5175 72.7344L63.7631 120.293H59.5509L49.7295 83.1527C49.6573 82.8579 49.2376 82.857 49.1645 83.1527L39.2231 120.294H35.1328L41.0327 72.7353H57.5175V72.7344Z"
                    fill="#0D2B5C" />
                <path d="M60.1926 120.297L60.6737 122.464L62.4246 122.8L63.5122 122.396L63.18 120.297H60.1926Z"
                      fill="#ECBCC3" />
                <path
                    d="M63.4346 121.656C63.4346 121.656 67.0573 122.912 66.968 123.506C66.8904 124.024 61.9571 123.951 60.6963 123.924C60.5203 123.921 60.3668 123.804 60.3118 123.632C60.1656 123.174 60.375 122.061 60.4553 121.811L61.2676 121.974C62.0049 122.122 62.7712 122.01 63.4346 121.656Z"
                    fill="#0D2B5C" />
                <path d="M38.5219 120.297L38.04 122.464L36.2899 122.8L35.2024 122.396L35.5345 120.297H38.5219Z"
                      fill="#ECBCC3" />
                <path
                    d="M35.2799 121.656C35.2799 121.656 31.6571 122.912 31.7465 123.506C31.8241 124.024 36.7574 123.951 38.0182 123.924C38.1942 123.921 38.3476 123.804 38.4027 123.632C38.5489 123.174 38.3395 122.061 38.2592 121.811L37.4469 121.974C36.7095 122.122 35.9433 122.01 35.2799 121.656Z"
                    fill="#0D2B5C" />
                <path
                    d="M57.6285 44.2011C57.5825 54.7997 59.2558 66.6054 58.0636 72.0052C58.0437 72.5263 58.0401 72.7372 57.5175 72.7372C53.55 73.8226 47.577 73.4828 41.0327 72.7372C40.5273 72.7372 40.1067 72.3505 40.0643 71.8466C42.197 68.2804 39.4497 55.1414 37.7583 44.202L44.317 43.3438C44.317 43.3438 49.8649 61.8709 49.8911 61.815C49.9227 61.8691 52.1628 43.3663 52.1628 43.3663L54.3966 43.5511L57.6285 44.2011Z"
                    fill="#1D4887" />
                <path
                    d="M52.5373 43.3438C52.5373 43.3438 49.8929 61.8168 49.8911 61.815C47.9155 59.0348 43.8396 43.4213 43.8396 43.4087L52.5364 43.3438H52.5373Z"
                    fill="#F1F2F2" />
                <path
                    d="M51.0806 58.2847L50.5959 60.6493C50.1916 61.3425 49.9208 61.7635 49.891 61.8131C49.873 61.7833 49.7141 61.6256 49.4524 61.3597L49.4506 61.3578L47.9226 58.6047L48.2177 54.5887L48.8748 45.6172H49.6853L50.8378 56.0689L51.0815 58.2856L51.0806 58.2847Z"
                    fill="#0D2E5E" />
                <path
                    d="M53.4462 48.8126L55.1556 48.9893C54.5392 53.4877 51.8848 58.4476 50.596 60.6517C50.1917 61.3449 49.9209 61.7659 49.8911 61.8155C50.2169 60.8726 50.5337 58.654 50.837 56.0704C51.485 50.5173 52.0689 43.2731 52.5364 43.3443L54.3966 43.5516L55.1556 47.5253L53.4462 48.8126Z"
                    fill="#0D2B5C" />
                <path
                    d="M49.8912 61.815C49.8732 61.7852 49.7143 61.6275 49.4526 61.3615L49.4508 61.3597C47.8488 59.7326 42.3948 54.0668 41.698 48.9888L43.4074 48.8121L41.698 47.5248L42.457 43.5511L44.4679 43.3438C45.4011 43.8161 46.986 49.5711 48.217 54.5906C49.0221 57.8782 49.6746 60.8495 49.8903 61.815H49.8912Z"
                    fill="#0D2B5C" />
                <path
                    d="M52.9364 38.2031C52.9364 38.2031 51.5176 41.9875 52.4219 43.3316C50.0528 43.9978 47.7089 44.0131 45.3921 43.3316L46.5167 38.2419L52.9364 38.2031Z"
                    fill="#D8ABB4" />
                <path
                    d="M53.8108 33.6829C53.8108 33.6829 54.3667 41.6123 50.5562 41.6916C46.7457 41.771 45.317 36.2999 45.3964 34.4762C45.4759 32.6525 48.4136 25.7536 53.8117 33.6829H53.8108Z"
                    fill="#ECBCC3" />
                <path
                    d="M49.3126 33.6463C49.3126 33.6463 52.7233 34.6505 54.1367 33.6463C54.4778 33.4038 54.828 33.0143 55.161 32.5627C56.1674 31.2015 55.2621 29.2804 53.5717 29.175C52.8623 29.1308 52.1764 29.0406 51.6592 28.8739C49.9065 28.3086 44.8297 29.6347 45.0111 31.4836C45.0111 31.4836 43.2584 31.5747 43.7422 32.9557C44.2259 34.3368 45.2232 38.196 46.4922 38.7324C46.4922 38.7324 45.4795 35.6241 45.7521 35.2788C46.0238 34.9336 46.2963 35.4041 46.4317 36.1425C46.5671 36.8808 46.9904 37.4135 46.9904 37.4135C46.9904 37.4135 47.3532 36.6707 47.1564 35.9513C46.9597 35.232 47.1411 34.8714 47.1411 34.8714C47.1411 34.8714 48.5075 35.2013 49.3126 33.6472V33.6463Z"
                    fill="#0D2B5C" />
                <path
                    d="M49.1122 43.9255L50.8523 45.8475C51.6763 44.8874 51.9029 44.3474 52.6547 43.3432L52.3 43.1016C52.3081 43.2548 48.2151 44.2167 45.4326 43.1466L44.4678 43.3432C45.4642 44.415 45.6997 44.6016 47.4407 45.8475L49.1122 43.9255Z"
                    fill="#DDDDDD" />
                <path d="M49.1122 43.9219L48.3018 44.817L48.874 45.6148H49.6844L49.9236 44.817L49.1122 43.9219Z"
                      fill="#0D2E5E" />
                <path d="M67.3397 51.3782V75.3934H47.4604V47.3125H63.2693V51.3782H67.3397Z" fill="#F8F8FF" />
                <path d="M67.3397 51.3782H63.2693V47.3125L67.3397 51.3782Z" fill="#2A4492" />
                <path d="M64.5472 54.5H56.3115V55.1617H64.5472V54.5Z" fill="#C8C8E8" />
                <path d="M64.5472 56.2031H56.3115V56.8648H64.5472V56.2031Z" fill="#C8C8E8" />
                <path d="M64.5472 57.9062H56.3115V58.5679H64.5472V57.9062Z" fill="#C8C8E8" />
                <path d="M64.5464 59.6016H50.2529V60.2632H64.5464V59.6016Z" fill="#C8C8E8" />
                <path d="M64.5464 61.3047H50.2529V61.9664H64.5464V61.3047Z" fill="#C8C8E8" />
                <path d="M64.5464 63.0078H50.2529V63.6695H64.5464V63.0078Z" fill="#C8C8E8" />
                <path d="M64.5464 64.7109H50.2529V65.3726H64.5464V64.7109Z" fill="#C8C8E8" />
                <path d="M64.5464 66.4141H50.2529V67.0757H64.5464V66.4141Z" fill="#C8C8E8" />
                <path d="M64.5464 68.1172H50.2529V68.7789H64.5464V68.1172Z" fill="#C8C8E8" />
                <path d="M61.328 69.8203H53.4714V70.482H61.328V69.8203Z" fill="#C8C8E8" />
                <path d="M55.0806 54.5H50.2529V58.5656H55.0806V54.5Z" fill="#C8C8E8" />
                <path
                    d="M46.4289 66.07L47.5408 66.4703C47.8603 66.5974 48.3892 66.8624 48.8224 67.3997C49.2647 67.9478 49.7457 68.9511 49.3721 69.3965C49.1717 69.6353 48.7872 69.639 48.6239 69.6408C48.0463 69.6462 47.4605 69.2748 47.4605 69.2748C47.4199 69.3721 47.3161 69.5885 47.0823 69.7625C47.0282 69.803 46.7565 69.9977 46.4289 69.9914C46.0498 69.9833 45.7836 69.6967 45.2664 69.1224C45.2664 69.1224 45.0182 68.8466 44.4578 68.1101L46.0146 65.8906L46.4289 66.0691V66.07Z"
                    fill="#ECBCC3" />
                <path
                    d="M37.1409 44.4811C36.4505 44.7993 35.8927 45.3474 35.5624 46.0307C34.2285 48.7901 30.4829 56.3688 31.631 61.39C32.6914 66.0245 44.6058 68.9751 44.6058 68.9751L46.493 65.9632L38.2456 59.6691L40.169 55.1527C40.3702 51.9183 39.3738 48.1861 37.7574 44.1953L37.14 44.4793L37.1409 44.4811Z"
                    fill="#0D2B5C" />
                <path
                    d="M26.7048 110.211V122.536C26.7048 123.317 26.0712 123.949 25.2896 123.949H6.31288C5.53129 123.949 4.89771 123.317 4.89771 122.536V110.211H26.7048Z"
                    fill="#0D2B5C" />
                <g style={{mixBlendMode:'multiply'}} opacity="0.4">
                    <path
                        d="M26.7048 110.211V117.774C26.3221 117.974 25.8862 118.086 25.425 118.086H6.1775C5.71631 118.086 5.28038 117.974 4.89771 117.774V110.211H26.7048Z"
                        fill="#19274C" />
                </g>
                <path
                    d="M5.1812 108.367H26.4215C26.9486 108.367 27.3764 108.794 27.3764 109.321V115.3C27.3764 116.375 26.5027 117.248 25.426 117.248H6.1767C5.09997 117.248 4.22632 116.375 4.22632 115.3V109.321C4.22632 108.794 4.65412 108.367 5.1812 108.367Z"
                    fill="#0D2B5C" />
                <path
                    d="M26.7048 110.211V116.769C26.3627 117.069 25.9151 117.249 25.425 117.249H6.1775C5.68742 117.249 5.23977 117.069 4.89771 116.769V110.211H26.7048Z"
                    fill="#0D2B5C" />
                <path
                    d="M20.8339 109.203H10.7688V105.917C10.7688 105.05 11.4755 104.344 12.3437 104.344H19.259C20.1272 104.344 20.8339 105.05 20.8339 105.917V109.203ZM11.776 108.198H19.8276V105.917C19.8276 105.604 19.573 105.349 19.259 105.349H12.3437C12.0305 105.349 11.7751 105.603 11.7751 105.917V108.198H11.776Z"
                    fill="#0D2B5C" />
                <path
                    d="M17.8582 116.242H13.7444C13.5834 116.242 13.4529 116.373 13.4529 116.533V118.129C13.4529 118.29 13.5834 118.42 13.7444 118.42H17.8582C18.0192 118.42 18.1497 118.29 18.1497 118.129V116.533C18.1497 116.373 18.0192 116.242 17.8582 116.242Z"
                    fill="#F2F2F2" />
                <path d="M39.8513 31.097L33.6663 29.3247L37.3955 25.1562L39.8513 31.097Z" fill="#1D4887" />
                <path
                    d="M36.4587 28.1418C39.9541 24.6505 39.9541 18.9901 36.4587 15.4988C32.9633 12.0076 27.2962 12.0076 23.8008 15.4988C20.3055 18.9901 20.3055 24.6505 23.8008 28.1418C27.2962 31.6331 32.9633 31.6331 36.4587 28.1418Z"
                    fill="#1D4887" />
                <path
                    d="M27.8287 25.909C27.8097 25.909 27.7899 25.9081 27.7709 25.9054C27.6148 25.8873 27.4749 25.799 27.3928 25.6647L24.9631 21.7054C24.8151 21.4647 24.8909 21.1501 25.1319 21.0031C25.372 20.8562 25.6879 20.931 25.835 21.1717L27.9226 24.573L34.4966 17.8876C34.6943 17.6857 35.0183 17.683 35.2196 17.8813C35.4208 18.0788 35.4235 18.4024 35.2259 18.6034L28.1933 25.7557C28.0967 25.854 27.965 25.909 27.8287 25.909Z"
                    fill="#DFE3EA" />
                <defs>
                    <filter id="filter0_f_2918_15511" x="67.6106" y="0.5" width="121.086" height="114.617"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2918_15511" />
                    </filter>
                    <filter id="filter1_b_2918_15511" x="67.3259" y="1.10156" width="121.086" height="115.812"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2918_15511" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2918_15511"
                                 result="shape" />
                    </filter>
                </defs>
        </StyledMaskGroup>
    );
};
