import styled from 'styled-components';

export const StyledOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 94.69063rem;
    height: 53.42088rem;
    flex-shrink: 0;
    svg {
        fill: rgba(0, 0, 0, 0.43);
        backdrop-filter: blur(10px);
    }
    opacity: 0;
    &.hover {
        opacity: 1;
    }
    .success-card {
        position: absolute;
        top: 21.1rem;
        left: 33.2rem;
        display: flex;
        width: 28.25rem;
        height: 11.25rem;
        padding: 0.625rem 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
        flex-shrink: 0;
        border-radius: var(--radius-sm, 0.375rem);
        background: #FFF;

        /* Drop */
        box-shadow: 0px 4px 23px 17px rgba(129, 129, 129, 0.10);
    }
    .success-card-content-wrapper {
        display: flex;
        width: 21.5625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
    .button {
        align-self: unset;
    }
`;
