import styled from 'styled-components';
import { Button } from '../../../../../../../../components/button/Button';

export const StyledTableTemplate = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    .row {
        padding: 0;
    }
    .column {
        flex: 1;
    }

    .row > .column:first-child textarea {
        text-align: left;
    }
    .row > .column:last-child textarea {
        text-align: right;
    }
    .row > .column:not(:first-child):not(:last-child) textarea {
        text-align: center;
    }

    .column textarea {
        width: 100%;
        border-radius: 0;
        color: rgba(26, 56, 78, 0.50);
    }
    /* Add right-radius to the first cell of the last row */
    .row:last-child .column:first-child input {
        border-bottom-left-radius: 0.375rem;
    }

    /* Add left-radius to the last cell of the last row */
    .row:last-child .column:last-child input {
        border-bottom-right-radius: 0.375rem;
    }
`;

export const StyledTableRenderer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

`;

export const StyledListRenderer = styled.div`
    display: flex;
    //padding: 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`;

export const StyledListRow = styled.div`
    display: flex;
    position: relative;
    padding: 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid #D8DCE5;
`;

export const StyledListGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
`;

export const StyledListColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;

    .label {
        width: 10.4375rem;
        color: rgba(26, 56, 78, 0.50);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 1.125rem */
    }

    .field {
        display: flex;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: flex-start;
        gap: 0.625rem;
        border-radius: 0.375rem;
        border: 1px solid #D8DCE5;
        background: #FFF;
    }

    &.large .field {
        width: 24.625rem;
    }
`;

export const StyledAddRowsButton = styled(Button)`
    color: rgba(26, 56, 78, 0.50);
    background: #F4F5F8;
    text-align: center;
    border-radius: 0.375rem;
    border: 1px dashed #8A9AB1;

    /* Paragraph XS */
    font-family: Lato;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.125rem */
`;

export const StyledDeleteButton = styled.div`
    position: absolute;
    right: 8px;
    top: 0;
    .deleteIcon {
        width: 0.75rem;
        height: 0.75rem;
        flex-shrink: 0;
        cursor: pointer;
    }

`;
