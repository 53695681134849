import React, { ReactNode } from 'react';
import { StyledTableRow } from './TableRow.styles';

interface TableRowProps {
    className?: string;
    children?: ReactNode;
}

export const TableRow: React.FC<TableRowProps> = ({ className, children }) => {
    return <StyledTableRow className={className}>{children}</StyledTableRow>;
};
