import styled from 'styled-components';

export const StyledCommunicationComponent = styled.div`
    display: flex;
    min-width: 75rem;
    max-width: 100rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.6875rem;
    flex: 1 0 0;
    align-self: stretch;
`;
