import React, { ReactNode } from 'react';
import { StyledEllipse25Icon } from './Ellipse25Icon.styles';

interface Ellipse25IconProps {
    className?: string;
    children?: ReactNode;
}

export const Ellipse25Icon: React.FC<Ellipse25IconProps> = ({ className, children }) => {
    return (
        <StyledEllipse25Icon className={className} xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
            <circle cx="32.3927" cy="32.088" r="31.8927" />
        </StyledEllipse25Icon>
    );
};
