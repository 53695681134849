import React, { ReactNode, useEffect } from 'react';
import { StyledAlertCard } from './AlertCard.styles';
import { HelpButtonIcon } from '../../../../../../../../../components/icons/HelpButtonIcon';
import { useSectionInstanceModal } from '../../../section/SectionInstanceModalProvider';
import {AlertCardProps} from "./AlertCard.types";



let instanceCounter = 0;

export const AlertCard: React.FC<AlertCardProps> = ({ className, title, notice, hideAlert, children }) => {
    const sectionInstanceModal = useSectionInstanceModal();

    useEffect(() => {
        const instanceId = `alertState-${instanceCounter++}`;
        console.log(`updating modal state to true`);
        sectionInstanceModal.setModal(instanceId, true);
        return () => {
            console.log(`removing modal state again`);
            sectionInstanceModal.setModal(instanceId, false);
        }
    }, []);
    return (
        <StyledAlertCard className={className}>
            <div className={'content-wrapper'}>
                <div className={'text-wrapper'}>
                    <div className={'title-text'}>{title}</div>
                    <div className={'notice-text'}>{notice}</div>
                </div>
                <div className={'button-group'}>
                    {children}
                </div>
            </div>
            <div className={'help-button'} onClick={() => hideAlert()}>
                <HelpButtonIcon className={'help-button-icon'}/>
            </div>
        </StyledAlertCard>
    );
};
