import React from 'react';
import { StyledSwitch } from './Switch.styles';
import { KnobOffIcon } from '../../../../../../../components/icons/KnobOffIcon';
import classNames from 'classnames';
import { ColexoSwitchOffIcon } from '../../../../../../../components/icons/ColexoSwitchOffIcon';
import { ColexoSwitchIcon } from '../../../../../../../components/icons/ColexoSwitchIcon';
import { KnobOnIcon } from '../../../../../../../components/icons/KnobOnIcon';

interface SwitchProps {
    className?: string;
    onChange: (value: boolean) => void;
    switchState: boolean;
    setSwitchState: (value: boolean) => void;
}

export const Switch: React.FC<SwitchProps> = ({ className, switchState, setSwitchState, onChange }) => {
    const updateState = () => {
        setSwitchState(!switchState);
        onChange && onChange(!switchState);
    }
    return (
        <StyledSwitch className={classNames(className, switchState ? 'onSwitch' : 'offSwitch')} onClick={() => updateState()}>
            {!switchState && <KnobOffIcon className={'knobIcon'}/>}
            {switchState && <ColexoSwitchOffIcon className={'backIcon'}/>}
            {switchState && <KnobOnIcon className={'knobIcon'}/>}
            {!switchState && <ColexoSwitchIcon className={'backIcon'} />}
        </StyledSwitch>
    );
};
