import React, { ReactNode, useState } from 'react';
import { StyledAccountUserWrapper } from './AccountUserWrapper.styles';
import { SignOutIcon } from '../icons/SignOutIcon';
import { BellRinging } from '../icons/BellRinging';
import { UserCircle } from '../icons/UserCircle';
import { AvatarIcon } from '../icons/AvatarIcon';
import { useAppSelector } from '../../store';
import { selectPageData } from '@baumeister/web-cen-protocol';
import { IUser } from '../../serverTypes';
import { Avatar } from './Avatar';
import { TopMenu } from './TopMenu';

interface AccountUserWrapperProps {
    className?: string;
    logoutUrl: string | undefined;
}

export const AccountUserWrapper: React.FC<AccountUserWrapperProps> = ({ className, logoutUrl }) => {

    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    return (
        <StyledAccountUserWrapper className={className}>
            <div className={'bellRinging'}>
                <BellRinging className={'bellRingingIcon'} />
            </div>
            <div className={'line'} />
            <div className={'signOut'}>
                <Avatar className={'avatar'} onClick={() => setMenuVisible(!menuVisible)}/>
                {menuVisible && <TopMenu logOutUrl={logoutUrl}/>}
            </div>
        </StyledAccountUserWrapper>
    );
};
