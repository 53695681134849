import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { StyledReportChartTemplate } from './ReportChartTemplate.styles';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IReportChartWidget } from '../../../../../types';
import { Bullet, Color, Legend, Line, Root, Scrollbar, Sprite } from '@amcharts/amcharts5';
import { AxisRendererY, DateAxis, LineSeries, ValueAxis, XYChart } from '@amcharts/amcharts5/xy';
import { IXYAxis } from '@amcharts/amcharts5/.internal/charts/xy/series/XYSeries';
import classNames from 'classnames';

interface ReportChartTemplateProps {
    className?: string;
    widget: IReportChartWidget
    fullPage: boolean;
}

let counter = 0 ;

interface IDataElement {
    date: number,
    series0: number,
    series1: number,
    series2: number
}

const textColor = 0xe9ebed; // undefined; // 0xffffff;

function createData(widget:IReportChartWidget): IDataElement[] {
    const map:Map<number, IDataElement> = new Map();
    let seriesIndex = 0;
    for (const series of widget.series) {
        if (series) {
            for (const element of series.values) {
                if (element) {
                    const year = element.year || 2024;
                    let mapData = map.get(year);
                    if (!mapData) {
                        mapData = {
                            date: new Date(year, 0, 1).getTime(),
                            series0: 0,
                            series1: 0,
                            series2: 0
                        }
                        map.set(year, mapData);
                    }
                    if (seriesIndex === 0) {
                        mapData.series0 = element.value || 0;
                    } else if (seriesIndex === 1) {
                        mapData.series1 = element.value || 0;
                    } else if (seriesIndex === 2) {
                        mapData.series2 = element.value || 0;
                    }
                }
            }
        }
        ++seriesIndex;
    }
    return [...map.values()].sort((a, b) => a.date - b.date);
}

function createSeries(root: Root, chart: XYChart, xAxis: IXYAxis | undefined, yAxis: IXYAxis | undefined, name: string, field: string, color1: number, color2: number, data: IDataElement[]) {
    if (!xAxis || !yAxis) {
        return;
    }
    const series: LineSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                getFillFromSprite: false,
                autoTextColor: false,
                background: am5.PointedRectangle.new(root, {
                    fill: am5.color(0xF5F6F9), // Set the background color
                    fillOpacity: 1, // Set the background opacity
                    cornerRadius: 10, // Set the corner radius
                    shadowColor: am5.color(0xbbbdbf),
                    shadowBlur: 40,
                    shadowOffsetX: 4,
                    shadowOffsetY: 4

                })
            }),
            stacked: true,
            connect: false,
            stroke: am5.color(0xD5FFED)
        })
    );
    series.fills.template.set('fillGradient', am5.LinearGradient.new(root, {
        stops: [{
            color: am5.color(color1)
        }, {
            color: am5.color(color2)
        }],
        rotation: 90
    }));
    series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
    });
    const sprite = am5.Circle.new(root, {
        radius: 5,
        fill: am5.color(0x00FF8C) as Color
    }) as Sprite;
    series.bullets.push(function() {
        return am5.Bullet.new(root, {
            sprite
        }) as Bullet;
    });
    if (series) {
        series.strokes.template.set("strokeWidth", 4);
        const tooltip = series.get("tooltip");
        if (tooltip) {
            tooltip.label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}");
            tooltip.label.setAll({
                fill: am5.color(0x1A384E)
            })
        }
        series.data.setAll(data);
    }
}


export const ReportChartTemplate: React.FC<ReportChartTemplateProps> = ({ className, fullPage, widget }) => {
    const [id, setId] = useState<string>(() => `chartdiv${counter++}`);
    const chartRef = useRef<XYChart>();
    const xAxisRef = useRef<IXYAxis>();
    const yAxisRef = useRef<IXYAxis>();
    const rootRef = useRef<Root>();
    const legendRef = useRef<Legend>();
    useLayoutEffect(() => {
        let rootValue = rootRef.current;
        am5.array.each(am5.registry.rootElements, function(root) {
            if (root) {
                if (root.dom.id == id) {
                    root.dispose();
                }
            }
        });
        rootValue = am5.Root.new(id);
        rootRef.current = rootValue;

        const root = (rootValue || rootRef.current) as Root;
        root.setThemes([
            am5themes_Animated.new(root),
            // am5themes_Dark.new(root)
        ]);

        let chart: XYChart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                wheelY: "zoomX",
                layout: root.verticalLayout,
                maxTooltipDistance: 0
            })
        );
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraTooltipPrecision: 1,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );
        const renderer = yAxis.get('renderer');
        renderer.labels.template.setAll({
            fill: am5.color(0xffffff)
        });
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "year", count: 1 },
                gridIntervals: [
                    { timeUnit: "year", count: 0.5 }
                ],
                renderer: am5xy.AxisRendererX.new(root, {})
            })
        );

        if (xAxis) {
            const dateFormat = xAxis.get("dateFormats");
            if (dateFormat) {
                dateFormat["day"] = "MM/dd";
            }
            const periodChangeDateFormat = xAxis.get("periodChangeDateFormats");
            if (periodChangeDateFormat) {
                periodChangeDateFormat["day"] = "MMM";
            }
            const renderer = xAxis.get('renderer');
            if (textColor) {
                renderer.labels.template.setAll({
                    fill: am5.color(textColor)
                });
            }
        }

        const scrollbarX: Scrollbar = am5.Scrollbar.new(root, {
            orientation: "horizontal",
            height: 3,
            minHeight: 3
        });
        chart.set("scrollbarX", scrollbarX);
        scrollbarX.set("marginBottom", 20); // Adjust the value as needed

        // Customize scrollbar grips
        scrollbarX.startGrip.setAll({
            scale: 0.5 // Adjust the scale to change the size
        });

        scrollbarX.endGrip.setAll({
            scale: 0.5 // Adjust the scale to change the size
        });
        let legend: Legend = chart.children.push(am5.Legend.new(root, {}));
        legendRef.current = legend;
        // Customize legend marker to display a line instead of a box
        legend.markers.template.setAll({
            width: 10,
            height: 2
        });
        chart.set("cursor", am5xy.XYCursor.new(root, {}));
        xAxis.set("tooltip", am5.Tooltip.new(root, {
            themeTags: ["axis"]
        }));

        yAxis.set("tooltip", am5.Tooltip.new(root, {
            themeTags: ["axis"]
        }));

        chartRef.current = chart;
        xAxisRef.current = xAxis;
        yAxisRef.current = yAxis;
        return () => {
            root.dispose();
            rootRef.current = undefined;
            legend.dispose();
            //gradient.dispose();
            xAxis.dispose();
            xAxisRef.current = undefined;
            yAxis.dispose();
            yAxisRef.current = undefined;
        };
    }, []);

    useEffect(() => {
        const chart = chartRef.current;
        const root = rootRef.current;
        const legend = legendRef.current;
        if (chart && root && legend) {
            const xAxis = xAxisRef.current;
            const yAxis = yAxisRef.current;

            chart.series.clear();
            const data = createData(widget);
            console.log(`data for chart widget ${widget.value.reportBaseWidgetValueId}`, data);
            if (widget.series[0]) {
                createSeries(root, chart, xAxis, yAxis, widget.design?.titleOne || 'Serie 1', "series0", 0xbcffdb, 0x00FF8C, data);
            }
            if (widget.series[1]) {
                createSeries(root, chart, xAxis, yAxis, widget.design?.titleTwo || 'Serie 2', "series1", 0xbcffdb, 0x1a384e, data);
            }
            if (widget.series[2]) {
                createSeries(root, chart, xAxis, yAxis, widget.design?.titleThree || 'Serie 3', "series2", 0xbcffdb, 0x1a384e, data);
            }
            legend.data.setAll(chart.series.values);
            if (textColor) {
                legend.labels.template.setAll({
                    fill: am5.color(textColor)
                })
            }
        }
    }, [chartRef, xAxisRef, yAxisRef, widget, rootRef, legendRef])
    return (
        <StyledReportChartTemplate className={classNames(className, `chart-widget-${widget.value.reportBaseWidgetValueId}`)}>
            <div id={id} style={{ width: fullPage ? "1300px" : "316px", height: "500px" }}></div>
        </StyledReportChartTemplate>
    );
};
