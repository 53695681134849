import React, { HTMLAttributes, ReactNode } from 'react';
import { StyledEditIcon } from './EditIcon.styles';

interface EditIconProps extends HTMLAttributes<SVGElement>{
    className?: string;
    children?: ReactNode;
}

export const EditIcon: React.FC<EditIconProps> = ({ className, children }) => {
    return (
        <StyledEditIcon className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M14.2075 4.60891L11.4144 1.81641C11.3215 1.72353 11.2113 1.64985 11.0899 1.59958C10.9686 1.54931 10.8385 1.52344 10.7072 1.52344C10.5759 1.52344 10.4458 1.54931 10.3245 1.59958C10.2031 1.64985 10.0929 1.72353 10 1.81641L2.29313 9.52329C2.19987 9.61581 2.12593 9.72595 2.0756 9.8473C2.02528 9.96865 1.99959 10.0988 2.00001 10.2302V13.0233C2.00001 13.2885 2.10536 13.5429 2.2929 13.7304C2.48043 13.9179 2.73479 14.0233 3.00001 14.0233H5.79313C5.9245 14.0237 6.05464 13.998 6.17599 13.9477C6.29735 13.8974 6.40748 13.8234 6.50001 13.7302L11.7294 8.50141L11.9469 9.37016L9.64688 11.6695C9.55306 11.7633 9.50032 11.8904 9.50026 12.0231C9.5002 12.1557 9.55283 12.2829 9.64657 12.3767C9.74031 12.4705 9.86747 12.5233 10.0001 12.5233C10.1327 12.5234 10.2599 12.4708 10.3538 12.377L12.8538 9.87704C12.9149 9.816 12.9592 9.74016 12.9823 9.65692C13.0054 9.57367 13.0066 9.48586 12.9856 9.40204L12.5544 7.67641L14.2075 6.02329C14.3004 5.93042 14.3741 5.82018 14.4243 5.69883C14.4746 5.57749 14.5005 5.44744 14.5005 5.3161C14.5005 5.18476 14.4746 5.0547 14.4243 4.93336C14.3741 4.81202 14.3004 4.70177 14.2075 4.60891ZM3.00001 11.2302L4.79313 13.0233H3.00001V11.2302ZM6.00001 12.8164L3.20688 10.0233L8.50001 4.73016L11.2931 7.52329L6.00001 12.8164ZM12 6.81641L9.20751 4.02329L10.7075 2.52329L13.5 5.31641L12 6.81641Z" fill="#1A384E"/>
        </StyledEditIcon>
    );
};
