import styled from 'styled-components';

export const StyledQuestionItemList = styled.div`
    display: flex;
    height: 25.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3xl, 1.5rem);
    align-self: stretch;
`;
