import React, { ReactNode } from 'react';
import { StyledVector2 } from './Vector2.styles';

interface Vector2Props {
    className?: string;
    children?: ReactNode;
}

export const Vector2: React.FC<Vector2Props> = ({ className, children }) => {
    return (
        <StyledVector2 className={className} xmlns="http://www.w3.org/2000/svg" width="4" height="9" viewBox="0 0 4 9" fill="none">
            <path d="M0.143555 0.492188H0.982098V7.32726H3.9487V8.69655H0.566349C0.460651 8.69655 0.361999 8.62809 0.27744 8.49116C0.192881 8.35423 0.143555 8.19448 0.143555 8.01191V0.492188Z" fill="#112145"/>
        </StyledVector2>
    );
};
