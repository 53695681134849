import styled from 'styled-components';

export const StyledReportFileSources = styled.div`
    align-self: stretch;
    color: var(--Base-Neutral-600, #1A384E);
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.5rem */
    .value {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

        /* Paragraph M */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;
