import React, { ReactNode } from 'react';
import { StyledReportIconWrapper } from './ReportIconWrapper.styles';
import { ClxReportIcon } from '../../../../../../../components/icons/ClxReportIcon';
import { Vector1 } from './Vector1';
import { Vector2 } from './Vector2';
import { Vector3 } from './Vector3';
import { Vector4 } from './Vector4';

interface ReportIconWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const ReportIconWrapper: React.FC<ReportIconWrapperProps> = ({ className, children }) => {
    return (
        <StyledReportIconWrapper className={className}>
            <ClxReportIcon className={'clx-report-icon'}/>
            <div className={'overlay'}>
                <div className={'rectangle'} />
                <Vector1 />
                <Vector2 />
                <Vector3 />
                <Vector4 />
            </div>
        </StyledReportIconWrapper>
    );
};
