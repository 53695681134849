import React, { ReactNode } from 'react';
import { StyledOryButton } from './OryButton.styles';
import { UiNode, UiNodeInputAttributes } from '@ory/client';
import { IOryUIOptions, translateOryLabel } from '../utils';
import classNames from 'classnames';
import { OryPageEnum } from '../types';

interface OryButtonProps {
    className?: string;
    children?: ReactNode;
    attrs: UiNodeInputAttributes;
    options: IOryUIOptions;
    node: UiNode;
    page: OryPageEnum;
}

export const OryButton: React.FC<OryButtonProps> = ({ className, children, attrs, options, node }) => {
    const oryLabel = node.meta.label;
    const label = translateOryLabel(oryLabel);
    return (
        <>
        <StyledOryButton className={classNames(className, "submitButton", `id-${oryLabel?.id}`, `type-${oryLabel?.type}`)}
            type={attrs.type as 'submit' | 'reset' | 'button' | undefined}
            name={attrs.name}
            value={attrs.value}
            disabled={attrs.disabled}
        >{label}
        </StyledOryButton>
            {oryLabel && options.submitButtonPostfix(oryLabel)}
        </>
    );
};
