import React, { memo } from 'react';
import { Handle, Node, NodeProps, Position } from 'reactflow';

type ConditionalNodeProps = {
}

type ConditionalNode = Node<ConditionalNodeProps>;


const ConditionalNode = ({ data, isConnectable, selected }: NodeProps<ConditionalNode> ) => {

    return <div>
        <div><b>WENN ... DANN</b></div>
        <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
        />
        <Handle
            type="source"
            id='true'
            style={{ top: 10, bottom: 'auto', background: '#555' }}
            position={Position.Right}
            isConnectable={isConnectable}
        />
        <Handle
            type="source"
            id='false'
            style={{ bottom: 5, top: 'auto', background: '#555' }}
            position={Position.Right}
            isConnectable={isConnectable}
        />
    </div>;
}

export default memo(ConditionalNode);
