import React, { ReactNode } from 'react';
import { StyledReportPreviewContent } from './ReportPreviewContent.styles';
import { IReportPage } from '../../../types';
import { isReportSectionAvailable, ReportSection } from './section/ReportSection';
import { ReportNavbar } from '../../../../../report/navbar/ReportNavbar';
import { EmptyReportComponent } from './empty/EmptyReportComponent';

interface ReportPreviewContentProps {
    className?: string;
    children?: ReactNode;
    reportPage: IReportPage;
    fullPage: boolean;
    onEmptyClick: () => void;
}


export const ReportPreviewContent: React.FC<ReportPreviewContentProps> = ({ className, reportPage, fullPage, onEmptyClick, children }) => {
    const anyDataAvailable = reportPage.sections.map(element => isReportSectionAvailable(element)).some(value => value);
    return (
        <StyledReportPreviewContent className={className}>
            <ReportNavbar reportPage={reportPage}/>
            {anyDataAvailable && reportPage.sections.map((element, index) => <ReportSection key={element.design?.reportDesignSectionId} section={element} fullPage={fullPage}/>)}
            {!anyDataAvailable && <div className={'top-content'}><EmptyReportComponent onClick={() => onEmptyClick && onEmptyClick()}/></div>}
        </StyledReportPreviewContent>
    );
};
