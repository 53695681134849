import React, { ReactNode } from 'react';
import { StyledUploadIcon } from './UploadIcon.styles';

interface UploadIconProps {
    className?: string;
    children?: ReactNode;
}

export const UploadIcon: React.FC<UploadIconProps> = ({ className, children }) => {
    return (
        <StyledUploadIcon className={className} xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
            <path d="M32.6153 17.304C31.184 13.4883 27.4808 10.8828 23.3611 10.8828C20.0617 10.8828 16.9988 12.5302 15.1703 15.2357C14.7307 15.1572 14.2847 15.118 13.8368 15.118C9.6351 15.1178 6.21753 18.5356 6.21753 22.7373C6.21753 26.9382 9.6351 30.3552 13.8368 30.3552H20.8309V34.8828H23.1688L23.1691 30.3552H31.1776C34.8192 30.3552 37.7824 27.3926 37.7824 23.7513C37.7824 20.6108 35.6103 17.963 32.6166 17.3037L32.6153 17.304ZM31.1766 28.0175H23.168V22.317L25.5395 24.9067L27.264 23.3278L22.0685 17.6551L16.7443 23.3162L18.4477 24.9178L20.83 22.3847V28.0176H13.8359C10.9236 28.0176 8.5549 25.6488 8.5549 22.7374C8.5549 19.8252 10.9237 17.4559 13.8356 17.4559C14.3591 17.4559 14.8787 17.534 15.3802 17.6873L16.2881 17.965L16.746 17.1334C18.0768 14.7203 20.6113 13.2211 23.3605 13.2211C26.6983 13.2211 29.6776 15.4615 30.6066 18.6696L30.8262 19.4282L31.6118 19.5075C33.7961 19.7287 35.443 21.5531 35.443 23.7521C35.443 26.104 33.5292 28.0175 31.1766 28.0175Z" fill="#1A384E" fillOpacity="0.5"/>
        </StyledUploadIcon>
    );
};
