import React, { ReactNode } from 'react';
import { StyledClxReportWrapper } from './ClxReportWrapper.styles';
import { ColexoIcon } from '../../../../../../components/icons/ColexoIcon';
import { ReportIconWrapper } from './report-icon-wrapper/ReportIconWrapper';
import classNames from 'classnames';
import { Button } from '../../../../../../components/button/Button';
import { DashEditIcon } from '../../../../../../components/icons/DashEditIcon';
import { DownloadIcon } from '../../../../../../components/icons/DownloadIcon';
import { useNavigate } from 'react-router-dom';
import {
    IReportDesignBaseWidget,
    IReportDesignPage,
    IReportDesignSection,
    IReportPageValue,
} from '../../../../../../serverTypes';
import { useAppSelector } from '../../../../../../store';
import { selectPageChildCursorData, useCenPageContext } from '@baumeister/web-cen-protocol';
import { formatMillisToDate } from '../../../../report/navbar/ReportNavbar';

interface ContentFrameProps {
    className?: string;
    children?: ReactNode;
}

export const ClxReportWrapper: React.FC<ContentFrameProps> = ({ className, children }) => {
    const { channel } = useCenPageContext();
    const reportPageValue: IReportPageValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportPageValue"));
    const navigate = useNavigate();
    const goToReport = () => {
        navigate('/company/report');
    }
    const date = reportPageValue && reportPageValue[0] ? formatMillisToDate(reportPageValue[0]?.lastUpdateTime || new Date().getTime()) : new Date().getTime();
    return (
        <StyledClxReportWrapper className={className}>
            <div onClick={() => goToReport()}>
                <ReportIconWrapper className={'report-link'} />
            </div>
            <div className={'clx-report-content-wrap report-link'} onClick={() => goToReport()}>
                <div className={'clx-raum-logo'}>
                    <ColexoIcon className={'icon'} />
                    <div className={'text'}>-Report</div>
                </div>
                <div className={'created-text'}>Erstellt am {date}</div>
            </div>
            <button className={classNames('edit', 'button')}>
                <DashEditIcon />
            </button>
            <button className={classNames('download', 'button')}>
                <DownloadIcon />
            </button>
            <Button caption={'Teilen'} variant={'secondary'} className={'share'} />
        </StyledClxReportWrapper>
    );
};
