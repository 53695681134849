import React, { ReactNode } from 'react';
import { StyledEditButton } from './EditButton.styles';
import { EditIcon } from '../../../../../../../components/icons/EditIcon';
import { IQuestionData } from '../../../types';
import classNames from 'classnames';
import { Tooltip } from '@baumeister/web-ui-bootstrap';
import { ColexoProtocolMessage, InfoLockQuestionRequestMessage } from '../../../../../../../protocol';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';

interface EditButtonProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const EditButton: React.FC<EditButtonProps> = ({ className, questionData, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const onUnlock = () => {
        const lockMessage:InfoLockQuestionRequestMessage = {
            type: ColexoProtocolMessage.INFO_LOCK_QUESTION,
            payload: {
                entityPath: questionData.instanceLink,
                lockState: false
            }
        };
        cenSocketProtocol.request(lockMessage);
    }
    const locked = questionData.instance.locked;
    if (!locked) {
        return undefined;
    }
    const tooltipId = `edit-${questionData.instance.infoTagId}`;
    return (
        <StyledEditButton className={className}>
            <button className={'hover'} data-tooltip-id={tooltipId} onClick={() => onUnlock()}>
                <EditIcon className={'icon'}/>
            </button>
            <Tooltip className={'edit-tooltip'} placement={'top'} target={tooltipId}>
                Frage bearbeiten?
            </Tooltip>
        </StyledEditButton>
    );
};
