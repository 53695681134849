import React, { useState } from 'react';
import { StyledContentHeader } from './ContentHeader.styles';
import { ArrowLeftIcon } from '../../../../components/icons/ArrowLeftIcon';
import { ContentTitle } from '../../../../components/content/title/ContentTitle';
import { ContentSubTitle } from '../../../../components/content/subtitle/ContentSubTitle';
import { Tab } from '../../../../components/tab/Tab';

interface ContentHeaderProps {
    className?: string;
}

enum CreateMode {
    AUTOMATIC, MANUAL
}
export const ContentHeader: React.FC<ContentHeaderProps> = ({ className }) => {
    const [ createMode, setCreateMode ] = useState(CreateMode.AUTOMATIC);
    const trigger = (newMode:CreateMode) => {
        setCreateMode(newMode);
    }
    return <StyledContentHeader className={className}>
        <div className={'index'}>01 von 04</div>
        <div className={'content-top-wrapper'}>
            <div className={'text-wrapper'}>
                <div className={'heading-wrap'}>
                    <div className={'arrow-left'}>
                        <ArrowLeftIcon/>
                    </div>
                    <ContentTitle className={'content-title'} title={'Automatische Datenabfrage'}/>
                </div>
                <div className={'sub-title'}>
                    <ContentSubTitle text={'Sie bzw. Ihr Unternehmen ist im Handelsregister eingetragen? Dann können Sie bequem unsere Schnittstelle nutzen, um Ihr Unternehmen automatisch anzulegen.'}/>
                </div>
            </div>
            <div className={'tab-menu'}>
                <Tab text={'Daten automatisch erfassen'} active={createMode === CreateMode.AUTOMATIC} onClick={() => trigger(CreateMode.AUTOMATIC)}/>
                <Tab text={'Daten manuell erfassen'} active={createMode === CreateMode.MANUAL} onClick={() => trigger(CreateMode.MANUAL)}/>
            </div>
        </div>
    </StyledContentHeader>;
};
