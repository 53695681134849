import React from 'react';
import ReactDOM from 'react-dom/client';

import { registerCursorRenderer, WebSocketProvider } from '@baumeister/web-cen-protocol';
import { CalculationCursorView } from './pages/admin/design/calculation/CalculationCursorView';
import { registerAmountRenderer } from './renderer/currency/AmountRenderer';
import { installAmountEditor } from './renderer/currency/AmountEditor';
import { H } from 'highlight.run';
import LoginPageComponent from './ory/login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Registration } from './ory/registration';
import { Recovery } from './ory/recovery';
import { ThemeProvider } from 'styled-components';
import { colexoTheme } from './theme/colexo-theme';
import { WebBaseBootstrapGlobalStyles } from '@baumeister/web-ui-bootstrap';
import { ColexoGlobalStyles } from './theme/colexoGlobalStyles';
import { Provider } from 'react-redux';
import appStore from './store';
import { AuthProvider } from './ory/AuthContext';
import { AuthorizedRoutes } from './AuthorizedRoutes';
import Settings from './ory/settings';
import { Verification } from './ory/verification';
import { getInitialSession } from './ory/sdk';

const element = document.getElementById('root');
if (!element) {
    throw new Error('no root element found.');
}
const root = ReactDOM.createRoot(element);
registerAmountRenderer();
installAmountEditor();

export const development = process.env.NODE_ENV === 'development';
// registerValueRenderers();
registerCursorRenderer('calcNodes', CalculationCursorView);
const websocketUrl = development ? (import.meta as any).env.VITE_WEBSOCKET_URL : `wss://${window.location.hostname}/server/websocket`;
const projectId = development ? (import.meta as any).env.VITE_HIGLIGHT_PROJECT_ID : '<<HIGHLIGHT_PROJECT_ID>>';
const backendUrl = development ? (import.meta as any).env.VITE_HIGHLIGHT_BACKEND_URL : "<<HIGHLIGHT_BACKEND_URL>>";
const otlpEndpoint = development ? (import.meta as any).env.VITE_HIGHLIGHT_OTLP_ENDPOINT : "<<HIGHLIGHT_OTLP_ENDPOINT>>";
const environment = development ? "development" : "production";
export const orySdkUrl = development ? (import.meta as any).env.VITE_ORY_SDK_URL : "<<ORY_SDK_URL>>";
export const ignoreLogin = development ? (import.meta as any).env.VITE_ORY_IGNORE_LOGIN === "true": false;

// Unauthorized Routes Component
const UnauthorizedRoutes = () => (
    <Routes>
        <Route path="/auth/login" element={<LoginPageComponent />} />
        <Route path="/auth/registration" element={<Registration />} />
        <Route path="/auth/recovery" element={<Recovery />} />
        <Route path="/auth/settings" element={<Settings />} />
        <Route path="/auth/verification" element={<Verification />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
    </Routes>
);

console.log(`having websocket url ${websocketUrl}`);
// Root App Component with Auth-Aware Routing
interface RootAppProps {
    authenticated: boolean;
}
const RootApp: React.FC<RootAppProps> = ({authenticated}) => {
    console.log(`having authenticated ${authenticated}`);
    return (
            <ThemeProvider theme={colexoTheme}>
                <WebBaseBootstrapGlobalStyles />
                <ColexoGlobalStyles />
                <Provider store={appStore}>
                        {authenticated ?
                            <WebSocketProvider url={websocketUrl}>
                                <AuthorizedRoutes websocketUrl={websocketUrl} />
                            </WebSocketProvider>
                        : <UnauthorizedRoutes/>
                        }
                </Provider>
            </ThemeProvider>
    );
};

let highlightInitialized = false;

export const isHighlightInitialized = () => {
    return highlightInitialized;
}

const initializeApp = async () => {
    let initialAuthState;
    try {
        initialAuthState = await getInitialSession();
        console.log(`having initial auth state `, initialAuthState);
    } catch (error) {
        console.error('Failed to get initial session:', error);
        initialAuthState = {
            isAuthenticated: false,
            token: null,
            user: null
        };
    }

    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <AuthProvider initialState={initialAuthState}>
                    {({ isAuthenticated }) => <RootApp authenticated={isAuthenticated}/>}
                </AuthProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
};

if (projectId) {
    console.log('using highlight project id ' + projectId);
    try {
        H.init(projectId, {
            serviceName: "colexo-web-app",
            tracingOrigins: true,
            backendUrl: backendUrl,
            otlpEndpoint: otlpEndpoint,
            enableCanvasRecording: true,
            environment: environment,
            networkRecording: {
                enabled: true,
                recordHeadersAndBody: false,
                urlBlocklist: [
                    // insert full or partial urls that you don't want to record here
                    // Out of the box, Highlight will not record these URLs (they can be safely removed):
                    "https://www.googleapis.com/identitytoolkit",
                    "https://securetoken.googleapis.com",
                    backendUrl
                ],
            },
        });
        highlightInitialized = true;
    } catch (error) {
        console.error(`couldn't start highlight recording.`);
        console.error(error);
    }
}


// Initialize the app
initializeApp().then(() => {});
