import React, { ReactNode } from 'react';
import { StyledFormElement } from './FormElement.styles';
import { IDataProperty } from '@baumeister/web-cen-protocol';
import { Label } from '@baumeister/web-ui-bootstrap';

interface FormElementProps {
    className?: string;
    children?: ReactNode;
    dataProperty: IDataProperty;
}

export const FormElement: React.FC<FormElementProps> = ({ className, dataProperty, children }) => {
    return <StyledFormElement className={className}>
        <Label for={dataProperty.property} className={'label'}>{dataProperty.title}{dataProperty.required ? '*' : ''}</Label>
        {children}
    </StyledFormElement>;
};
