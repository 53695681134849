import styled from 'styled-components';

export const StyledTextWrapper = styled.div`
    display: flex;
    width: 80%;
    max-width: 80%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md, 0.5rem);
    .title {
        color: #1A384E;

        /* Heading 2 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.4rem */
    }

    .subtitle {
        color: rgba(26, 56, 78, 0.50);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
`;
