import styled from 'styled-components';

export const StyledClxReportWrapper = styled.div`
    display: flex;
    width: 26.0625rem;
    padding: var(--spacing-lg, 0.75rem);
    align-items: center;
    gap: var(--spacing-md, 0.5rem);
    background: var(--Base-Neutral-601, #F4F5F8);
    .clx-raum-logo {
        display: flex;
        align-items: center;
        gap: -0.375rem;
    }
    .clx-raum-logo .icon {
        width: 2.8445rem;
        height: 1.9145rem;
    }
    .clx-raum-logo .text {
        width: 3.58488rem;
        height: 1.21588rem;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
    .clx-report-content-wrap .created-text {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    & .report-link {
        cursor: pointer;
    }
    .button.edit {
        display: flex;
        width: 2.75rem;
        height: 2.75rem;
        padding: 0.6rem 0.6rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: none;
        background: transparent;
    }
    .button.download {
        display: flex;
        width: 2.75rem;
        height: 2.75rem;
        padding: 0.6rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: none;
        background: transparent;
    }
    & .share {
        display: flex;
        padding: 0.75rem 1.4rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.3125rem;
        border: 1px solid var(--Base-Neutral-600, #1A384E);
        align-self: unset;
    }
`;
