import { CenCursorListProps } from '@baumeister/web-cen-protocol';
import React from 'react';
import { CustomNodeFlow } from './CustomNodeFlow';
import { ReactFlowProvider } from 'reactflow';

export const CalculationCursorView: React.FC<CenCursorListProps> = ({cursor}) => {
    return <ReactFlowProvider>
        <CustomNodeFlow/>
    </ReactFlowProvider>;
}
