import React, { ReactNode } from 'react';
import { StyledUploadFileList } from './UploadFileList.styles';
import { ExtFile } from '@files-ui/react';
import { IFileValue } from '../../../../../../../../serverTypes';
import { UploadFileWrapper } from './UploadFileWrapper';

interface UploadFileListProps {
    className?: string;
    children?: ReactNode;
    extFiles: ExtFile[];
    fileValues: IFileValue[];
    onDelete: (id: string|number|undefined) => void;
    downloadUrl: string;
    disabled: boolean;
}

const findFileValue = (fileValues: IFileValue[], extFile: ExtFile) => {
    return fileValues.find(element => element._link === ((extFile.file as any)?.extraData as any)?.valueLink);
}

// Frame 4586
export const UploadFileList: React.FC<UploadFileListProps> = ({ className, extFiles, fileValues, onDelete, downloadUrl, disabled, children }) => {
    return (
        <StyledUploadFileList className={className}>
            <div className={'upload-illustration-comp'}>
                <div className={'content'}>
                    <div className={'heading-text'}>
                        <div className={'heading'}>
                            Ihre hochgeladenen Dokumente:
                        </div>
                    </div>
                </div>
            </div>
            <div className={'uploaded-files-wrapper'}>
                {extFiles.map((element, index) => {
                    const fileValue = findFileValue(fileValues, element);
                    // console.log(index, JSON.stringify(element), fileValue, fileValues);
                    return (<UploadFileWrapper key={index} onDelete={onDelete} extFile={element}
                                               fileValue={fileValue} downloadUrl={downloadUrl} disabled={disabled}/>
                    );
                }
                )}
            </div>
            {children}
        </StyledUploadFileList>
    );
};
