import React, { HTMLAttributes, ReactNode } from 'react';
import { StyledTab } from './Tab.styles';
import classNames from 'classnames';

interface TabProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: ReactNode;
    text: string;
    active: boolean;
}

export const Tab: React.FC<TabProps> = ({ className, text, active, children, ...props }) => {
    return <StyledTab className={classNames(className, active ? 'active' : 'inactive')} {...props}>
        <div className={'text'}>{text}</div>
        <div className={'line'}/>
    </StyledTab>;
};
