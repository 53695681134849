import React, { memo, useState } from 'react';
import { Handle, Node, NodeProps, NodeToolbar, Position } from 'reactflow';
import { Button } from '@baumeister/web-ui-bootstrap';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

type StaticNodeProps = {
    value: string;
    set?: boolean;
}

type StaticNode = Node<StaticNodeProps>;

const StaticNode = ({ data, isConnectable, selected }: NodeProps<StaticNodeProps> ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>(data.value);

    return <div>
        <NodeToolbar isVisible={selected}>
            <Button onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'Schliessen' : 'Bearbeiten'}</Button>
        </NodeToolbar>
        {isEditing ? (
                <TextareaAutosize
            name={'default'}
            value={selectedValue}
            onChange={(evt) => {
                const value = (evt.target as HTMLTextAreaElement).value;
                setSelectedValue(value);
                data.value = value;
            }}
            style={{width: '100%'}}
            className={classNames('autogrow', 'transition-height')}

            />
        ) : (
            <div onClick={() => setIsEditing(true)}>
                <b>{selectedValue ? <pre>{selectedValue}</pre> : <i>(leer)</i>}</b>
            </div>
        )}
        <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
        {data.set && <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
        />}
    </div>;
}

export default memo(StaticNode);
