import styled from 'styled-components';

export const StyledSectionTypeInstanceContent = styled.div`
    display: flex;
    flex-direction: row;
    background: #F6F9FF;
    .side-nav {
        position: fixed;
        z-index: 999;
        display: flex;
        min-width: 29.21875rem;
        max-width: 32.5rem;
        min-height: 180.875rem;
        padding: 2.5rem 1.25rem 0rem 1.25rem;
        @media screen and (max-width: 1599px) {
            padding-top: 1rem;
        }
        align-items: flex-start;
        gap: 0.625rem;
        flex: 1 0 0;
        align-self: stretch;
    }
    .side-nav.collapsed {
        min-width: 7.4375rem;
        max-width: 10rem;
    }
`;
