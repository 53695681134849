import styled from 'styled-components';

export const StyledContentLeft = styled.div`
    display: flex;
    min-width: 25rem;
    align-items: flex-start;
    flex: 1 0 0;
    .content-col-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 1.5rem);
        flex: 1 0 0;
    }
    .step {
        width: 6.29256rem;
        height: 1.91156rem;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.875rem */
    }

    .title {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 3.6rem */
    }

    p {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.875rem */
    }

    p b {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.875rem */
    }

    .button-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    .button-group .arrow-left {
        width: 2.75rem;
        height: 2.75rem;
    }

    .button-group .button {
        display: flex;
        padding: 0.75rem 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
    }

    .arrow-left {
        cursor: pointer;
    }
`;
