import React, { ReactNode } from 'react';
import { StyledFileIcon } from './FileIcon.styles';

interface FileIconProps {
    className?: string;
}

export const FileIcon: React.FC<FileIconProps> = ({ className }) => {
    return (
        <StyledFileIcon className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="41" viewBox="0 0 32 41" fill="none">
            <g clipPath="url(#clip0_2918_15796)">
                <path d="M12.2012 9.02424C12.0595 9.02424 11.9258 8.93785 11.8739 8.79849L9.22434 1.68511C9.08394 1.30767 8.80316 1.0071 8.43517 0.838943C8.06719 0.671046 7.65483 0.654591 7.27309 0.793948C7.09377 0.858998 6.89136 0.767979 6.82544 0.589284C6.75875 0.410074 6.85061 0.211066 7.03201 0.144987C7.58684 -0.058135 8.18916 -0.0352516 8.72686 0.210809C9.26456 0.456354 9.67407 0.894737 9.87856 1.44548L12.5281 8.5586C12.5948 8.73781 12.503 8.93682 12.3216 9.0029C12.2824 9.01755 12.2414 9.02424 12.2012 9.02424Z" fill="#75FBC3"/>
                <path d="M1.93726 31.7942V2.81204C1.93726 2.10909 2.51233 1.53906 3.22208 1.53906H30.0729C31.1372 1.53906 32 2.39397 32 3.44841V37.1797C32 38.2298 31.1327 39.0891 30.0729 39.0891H9.28602C8.85575 39.0891 8.44443 38.9173 8.13614 38.618L2.41916 32.9473C2.11061 32.6414 1.93726 32.2266 1.93726 31.7942Z" fill="#E1D9E1"/>
                <path d="M30.0628 10.7558V39.738C30.0628 40.4409 29.4877 41.011 28.7779 41.011H1.92711C0.862866 41.011 0 40.156 0 39.1016V5.37028C0 4.32022 0.867278 3.46094 1.92711 3.46094H22.714C23.1443 3.46094 23.5556 3.63269 23.8639 3.93197L29.5809 9.60267C29.8894 9.90864 30.0628 10.3234 30.0628 10.7558Z" fill="#F7F9FF"/>
                <path d="M25.5367 37.1643H25.4685C25.1111 37.1643 24.8218 36.8773 24.8218 36.5235C24.8218 36.1698 25.1109 35.8828 25.4685 35.8828H25.5367C25.8941 35.8828 26.1834 36.1698 26.1834 36.5235C26.1832 36.8776 25.8941 37.1643 25.5367 37.1643Z" fill="#E1D9E1"/>
                <path d="M22.2586 37.1643H12.7468C12.3895 37.1643 12.1001 36.8773 12.1001 36.5235C12.1001 36.1698 12.3892 35.8828 12.7468 35.8828H22.2586C22.6159 35.8828 22.9053 36.1698 22.9053 36.5235C22.905 36.8776 22.6159 37.1643 22.2586 37.1643Z" fill="#E1D9E1"/>
                <path d="M9.75052 37.1643H4.52609C4.16875 37.1643 3.87939 36.8773 3.87939 36.5235C3.87939 36.1698 4.16849 35.8828 4.52609 35.8828H9.75052C10.1079 35.8828 10.3972 36.1698 10.3972 36.5235C10.3972 36.8773 10.1079 37.1643 9.75052 37.1643Z" fill="#E1D9E1"/>
                <path d="M25.537 32.3987H20.3073C19.95 32.3987 19.6606 32.1117 19.6606 31.7579C19.6606 31.4041 19.9497 31.1172 20.3073 31.1172H25.537C25.8943 31.1172 26.1837 31.4041 26.1837 31.7579C26.1834 32.1117 25.8943 32.3987 25.537 32.3987Z" fill="#E1D9E1"/>
                <path d="M16.9685 32.3987H4.52609C4.16875 32.3987 3.87939 32.1117 3.87939 31.7579C3.87939 31.4041 4.16849 31.1172 4.52609 31.1172H16.9685C17.3258 31.1172 17.6152 31.4041 17.6152 31.7579C17.6149 32.1117 17.3258 32.3987 16.9685 32.3987Z" fill="#E1D9E1"/>
                <path d="M25.5371 27.6408H14.9045C14.5472 27.6408 14.2578 27.3539 14.2578 27.0001C14.2578 26.6463 14.5469 26.3594 14.9045 26.3594H25.5371C25.8944 26.3594 26.1838 26.6463 26.1838 27.0001C26.1835 27.3539 25.8944 27.6408 25.5371 27.6408Z" fill="#E1D9E1"/>
                <path d="M11.9922 27.6408H4.52609C4.16875 27.6408 3.87939 27.3539 3.87939 27.0001C3.87939 26.6463 4.16849 26.3594 4.52609 26.3594H11.9922C12.3495 26.3594 12.6388 26.6463 12.6388 27.0001C12.6386 27.3539 12.3495 27.6408 11.9922 27.6408Z" fill="#E1D9E1"/>
                <path d="M25.537 22.8752H21.7912C21.4339 22.8752 21.1445 22.5883 21.1445 22.2345C21.1445 21.8807 21.4336 21.5938 21.7912 21.5938H25.537C25.8943 21.5938 26.1837 21.8807 26.1837 22.2345C26.1834 22.5885 25.8943 22.8752 25.537 22.8752Z" fill="#E1D9E1"/>
                <path d="M18.3903 22.8752H4.52609C4.16875 22.8752 3.87939 22.5883 3.87939 22.2345C3.87939 21.8807 4.16849 21.5938 4.52609 21.5938H18.3903C18.7477 21.5938 19.037 21.8807 19.037 22.2345C19.037 22.5883 18.7477 22.8752 18.3903 22.8752Z" fill="#E1D9E1"/>
                <path d="M25.5371 18.1096H13.4485C13.0911 18.1096 12.8018 17.8226 12.8018 17.4689C12.8018 17.1151 13.0908 16.8281 13.4485 16.8281H25.5371C25.8945 16.8281 26.1838 17.1151 26.1838 17.4689C26.1836 17.8226 25.8945 18.1096 25.5371 18.1096Z" fill="#E1D9E1"/>
                <path d="M10.132 18.1096H4.52609C4.16875 18.1096 3.87939 17.8226 3.87939 17.4689C3.87939 17.1151 4.16849 16.8281 4.52609 16.8281H10.132C10.4893 16.8281 10.7787 17.1151 10.7787 17.4689C10.7784 17.8226 10.4893 18.1096 10.132 18.1096Z" fill="#E1D9E1"/>
                <path d="M18.8115 13.344H4.52609C4.16875 13.344 3.87939 13.057 3.87939 12.7032C3.87939 12.3494 4.16849 12.0625 4.52609 12.0625H18.8115C19.1689 12.0625 19.4582 12.3494 19.4582 12.7032C19.4582 13.057 19.1689 13.344 18.8115 13.344Z" fill="#E1D9E1"/>
                <path d="M18.8115 8.58615H4.52609C4.16875 8.58615 3.87939 8.29921 3.87939 7.94542C3.87939 7.59163 4.16849 7.30469 4.52609 7.30469H18.8115C19.1689 7.30469 19.4582 7.59163 19.4582 7.94542C19.4582 8.29921 19.1689 8.58615 18.8115 8.58615Z" fill="#E1D9E1"/>
                <path d="M30.0605 10.7409V10.8132H23.4592C23.005 10.8132 22.6482 10.4596 22.6482 10.0097V3.46094H22.7131C23.1428 3.46094 23.5565 3.62961 23.8648 3.93506L29.5823 9.59984C29.8903 9.9053 30.0605 10.3151 30.0605 10.7409Z" fill="#C3B8C3"/>
                <path d="M8.84945 8.96825C8.32732 8.96825 7.83607 8.64994 7.64533 8.13751L5.71926 2.96716C5.296 1.83071 5.88483 0.565441 7.03185 0.146085C7.21118 0.0810345 7.41281 0.171282 7.47951 0.351006C7.5462 0.529959 7.45433 0.72871 7.27294 0.795046C6.48715 1.08224 6.08361 1.94924 6.37348 2.72779L8.29929 7.89762C8.41114 8.19768 8.74902 8.3504 9.05109 8.24062C9.35368 8.13031 9.50912 7.79632 9.39728 7.49626L8.13996 4.11982C8.07326 3.94086 8.16513 3.74211 8.34653 3.67578C8.52507 3.61021 8.72826 3.70149 8.79418 3.8807L10.0515 7.25715C10.1701 7.57546 10.1563 7.92051 10.0133 8.22904C9.87036 8.53733 9.61397 8.77182 9.29243 8.88932C9.14659 8.9428 8.99659 8.96825 8.84945 8.96825Z" fill="#75FBC3"/>
            </g>
            <defs>
                <clipPath id="clip0_2918_15796">
                    <rect width="32" height="41" fill="white" transform="translate(0 0.0078125)"/>
                </clipPath>
            </defs>
        </StyledFileIcon>
    );
};
