import styled from 'styled-components';

export const StyledProgressText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: calc(11.5625rem / -2);
    margin-top: calc(11.5625rem / -2);
    display: flex;
    width: 11.5625rem;
    height: 11.5625rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #1A384E;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato, sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // transition: width 0.8s ease, height 0.8s ease, top 0.8s ease, left 0.8s ease, font-size 0.8s ease;
    &.small {
        width: 7.1875rem;
        height: 7.1875rem;
        margin-left: calc(7.1875rem / -2);
        margin-top: calc(7.1875rem / -2);
        font-size: 1.25rem;
    }
`;
