import React, { useCallback, useEffect, useState } from 'react';
import { UpdateVerificationFlowWithCodeMethodBody, VerificationFlow } from '@ory/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sdk, sdkError } from './sdk';
import { IOryUIOptions, mapUINode } from './utils';
import { filterNodesByGroups } from '@ory/integrations/ui';
import { OryLayout } from './OryLayout';
import { OryPageEnum } from './types';
import { CustomLink } from './CustomLink';
import { useAuth } from './AuthContext';

export const Verification = () => {
    const [flow, setFlow] = useState<VerificationFlow | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    // Get the verification flow based on the flow ID
    const getFlow = useCallback(
        (flowId: string) =>
            sdk
                .getVerificationFlow({ id: flowId })
                .then(({ data: flow }) => setFlow(flow))
                .catch(sdkErrorHandler),
        [],
    );

    // Handle SDK errors
    const sdkErrorHandler = sdkError(getFlow, setFlow, "/verification");

    // Create a new verification flow
    const createFlow = () => {
        sdk
            .createBrowserVerificationFlow()
            .then(({ data: flow }) => {
                setSearchParams({ ["flow"]: flow.id });
                setFlow(flow);
            })
            .catch(sdkErrorHandler);
    };

    // Handle form submission
    const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData(evt.currentTarget);
            const submitter = (evt.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;

            // Create the request body based on the form data
            const body: UpdateVerificationFlowWithCodeMethodBody = {
                method: 'code',
                csrf_token: formData.get("csrf_token")?.toString() || "",
                code: formData.get("code")?.toString() || "",
            };

            // Modify body based on submitter if needed
            if (submitter.name === 'email') {
                body.email = submitter.value;
            }

            if (!flow) {
                navigate("/auth/login", { replace: true });
                return;
            }

            // Update verification flow
            const { data: updatedFlow } = await sdk.updateVerificationFlow({
                flow: flow.id,
                updateVerificationFlowBody: body as any,
            });

            if (updatedFlow.state === 'passed_challenge') {
                // Get session after successful verification
                const { data: session } = await sdk.toSession();

                // Login the user
                login(session.id, {
                    id: session.identity.id,
                    username: session.identity.traits.email
                });

                // Redirect to dashboard or home
                navigate("/", { replace: true });
            } else {
                setFlow(updatedFlow);
            }

        } catch (error: any) {
            if (error.response?.status === 400) {
                setFlow(error.response.data);
            } else if (error.response?.status === 410) {
                createFlow();
            } else {
                console.error('Verification error:', error);
                sdkErrorHandler(error);
            }
        } finally {
            setLoading(false);
        }
    };

    // Initialize flow when component mounts
    useEffect(() => {
        const flowId = searchParams.get("flow");
        if (flowId) {
            getFlow(flowId).catch(createFlow);
            return;
        }
        createFlow();
    }, []);

    const oryOptions: IOryUIOptions = {
        submitButtonPostfix: () => (
            <div className="buttonHelper">
                Bereits verifiziert? <CustomLink href="/auth/login">Hier einloggen</CustomLink>
            </div>
        ),
    };

    if (loading) {
        return <div>Verifizierung läuft...</div>;
    }

    console.log(flow);
    return flow ? (
        <OryLayout
            errorMessages={flow.ui.messages?.map(message => ({
                type: String(message.type),
                message: message.text
            })) || []}
            title={"E-Mail-Adresse bestätigen"}
            subtitle={"Bitte geben Sie den Code ein, den wir Ihnen per E-Mail geschickt haben."}
            handleSubmit={(evt) => handleFormSubmit(evt)}
            page={OryPageEnum.Verification}
        >
            {filterNodesByGroups({
                nodes: flow.ui.nodes,
                groups: ['code'],
            }).map((node, key) => mapUINode(OryPageEnum.Verification, node, key, oryOptions))}
        </OryLayout>
    ) : (
        <div>Loading...</div>
    );
};
