import styled from 'styled-components';

export const StyledContentLeft = styled.div`
    display: flex;
    height: 13.5625rem;
    padding-right: 2.4375rem;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xl, 1rem);
    border-right: 1px solid #D9D9D9;
    .progress-circle-wrapper {
        width: 7.375rem;
        height: 7.375rem;
        flex-shrink: 0;
    }
    .progress-circle-wrapper .text-wrapper {
        display: flex;
        width: 5.96794rem;
        flex-direction: column;
        padding: 2.06rem 0.7rem;
    }
    .text-wrapper .percentage {
        display: flex;
        justify-content: center;
        width: 5.96794rem;
        height: 1.8125rem;
        text-align: center;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .text-wrapper .description {
        display: flex;
        justify-content: center;
        width: 5.96794rem;
        height: 1.4375rem;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
    .circle {
        position: absolute;
        margin-left: -1.6rem;
        margin-top: -1.6rem;
        width: 10.5rem;
        height: 10.5rem;
        flex-shrink: 0;
    }
    .process-circle-wrapper {
        display: flex;
        width: 7.375rem;
        height: 7.375rem;
        flex-shrink: 0;
    }
    .show-files {
        display: flex;
        width: 8.5rem;
        height: 1.125rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
`;
