import React, { ReactNode } from 'react';
import { StyledQuestionItemList } from './QuestionItemList.styles';
import { QuestionItemWrapper } from '../questionitemwrapper/QuestionItemWrapper';
import { useSectionTypeInstanceData } from '../../../SectionTypeInstanceDataProvider';
import { ItemProcessText } from '../questionitemwrapper/ItemProcessText';

interface QuestionItemListProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
}

export const QuestionItemList: React.FC<QuestionItemListProps> = ({ className, collapsed, children }) => {
    const sectionTypeInstanceData = useSectionTypeInstanceData();
    return <StyledQuestionItemList className={className}>
        {sectionTypeInstanceData.instances.map((element) =>
            <QuestionItemWrapper key={element.instance.sectionTypeInstanceId}
                                 value={element.instance.answeredQuestions || 0}
                                 scale={element.instance.totalQuestions || 0}
                                 title={element.design?.title || ''}
                                 subtitle={element.design?.subTitle || ''}
                                 secondSubtitle={`${element.instance.answeredQuestions} / ${element.instance.totalQuestions} Fragen beantwortet`}
                                 active={element.instance.sectionTypeInstanceId === sectionTypeInstanceData.currentType?.sectionTypeInstanceId}
                                 link={element.instance.thisLink || undefined}
                                 collapsed={collapsed}
                                 smallCircleText={(
                                    <ItemProcessText value={element.instance.answeredQuestions || 0} scale={element.instance.totalQuestions || 0}/>
                                 )}/>
        )}
    </StyledQuestionItemList>;
};
