import React, { ReactNode, useState } from 'react';
import { StyledSearchBar } from './SearchBar.styles';
import { useDocumentSectionTypeInstanceData } from '../../../../DocumentSectionTypeInstanceDataProvider';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ColexoProtocolMessage, DocumentSearchFiles } from '../../../../../../../../protocol';

interface SearchBarProps {
    className?: string;
    children?: ReactNode;
}

export const SearchBar: React.FC<SearchBarProps> = ({ className, children }) => {
    const documentSectionTypeInstanceData = useDocumentSectionTypeInstanceData();
    const [searchFieldValue, setSearchFieldValue] = useState<string>(documentSectionTypeInstanceData.currentType?.document?.searchDocumentText || '');
    const { cenSocketProtocol } = useWebSocketContext();
    const updateSearchData = (searchText: string) => {
        setSearchFieldValue(searchText);
        const searchMessage:DocumentSearchFiles = {
            type: ColexoProtocolMessage.DOCUMENT_SEARCH_FILES,
            payload: {
                entityPath: documentSectionTypeInstanceData.currentType?.documentDataLink || '',
                channel: 'main',
                search: searchText
            }
        };
        cenSocketProtocol.request(searchMessage);
    }
    return (
        <StyledSearchBar className={className}>
            <input className={'search-field'} name={'search'} type={'text'} placeholder={'Suchen Sie nach einem Dokument ...'} value={searchFieldValue} onChange={(evt) => updateSearchData(evt.target.value)}/>
        </StyledSearchBar>
    );
};
