import React, { ReactNode } from 'react';
import { StyledTasks } from './Tasks.styles';
import { StatusWrapper } from './status-wrapper/StatusWrapper';
import { ContentWrapper } from './content-wrapper/ContentWrapper';
import { Button } from '../../../../../../../components/button/Button';

interface TasksProps {
    className?: string;
    children?: ReactNode;
}

export const Tasks: React.FC<TasksProps> = ({ className, children }) => {
    return (
        <StyledTasks className={className}>
            <ContentWrapper/>
            <StatusWrapper/>
            <Button caption={'Alle Aufgaben'} variant={'primary'} className={'button'}/>
        </StyledTasks>
    );
};
