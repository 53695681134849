import React, { FormEvent, ReactNode } from 'react';
import { StyledOryLayout } from './OryLayout.styles';
import classNames from 'classnames';
import { Form } from '@baumeister/web-ui-bootstrap';
import { Link } from 'react-router-dom';
import { OryPageEnum } from './types';

export interface OryErrorMessage {
    type: string;
    message: string;
}

export interface OryLayoutProps {
    className?: string;
    children?: ReactNode;
    handleSubmit?: (evt: React.FormEvent<HTMLFormElement>) => void;
    errorMessages: OryErrorMessage[];
    title?: string;
    subtitle?: string;
    page: OryPageEnum;
}

export const OryLayout: React.FC<OryLayoutProps> = ({ className, handleSubmit, errorMessages, title, subtitle, children, page }) => {
    const doSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (handleSubmit) {
            handleSubmit(evt);
        }
    }

    // Helper function to determine if a link should be external

    return (
        <>
        <StyledOryLayout className={classNames(className, page, "form-page")}>
            <div className="login-box form-box">
                <svg className="logo" viewBox="0 0 200 200" width="200" height="200">
                    <circle cx="100" cy="100" r="95" fill="#007BFF" stroke="#F2F2F2" stroke-width="5"/>
                    <circle cx="100" cy="100" r="70" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-dasharray="4 8"/>
                    <line x1="60" y1="60" x2="140" y2="140" stroke="#FFFFFF" stroke-width="2" />
                    <line x1="140" y1="60" x2="60" y2="140" stroke="#FFFFFF" stroke-width="2" />
                    <circle cx="100" cy="60" r="6" fill="#FFFFFF"/>
                    <circle cx="140" cy="100" r="6" fill="#FFFFFF"/>
                    <circle cx="100" cy="140" r="6" fill="#FFFFFF"/>
                    <circle cx="60" cy="100" r="6" fill="#FFFFFF"/>
                    <text x="50" y="110" fill="#FFFFFF" font-family="Arial, sans-serif" font-size="30" font-weight="bold">ESRS</text>
                    <text x="70" y="135" fill="#F2F2F2" font-family="Arial, sans-serif" font-size="18">Portals</text>
                </svg>
                <div className="welcome">
                    <h5>{title}</h5>
                    <div className="subteaser">{subtitle}</div>
                </div>
                <Form className="form"
                      onSubmit={(evt) => doSubmit(evt)}>
                    {children}
                </Form>
                {errorMessages.map((element: OryErrorMessage, index: number) =>
                    <div key={index} className={classNames("errorMessage", "form")}>
                        {element.message}
                    </div>
                )}
            </div>

        </StyledOryLayout>
        </>
    );
};
