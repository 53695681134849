import React, { ReactNode } from 'react';
import { StyledContentForm } from './ContentForm.styles';
import { ContentFooter } from '../footer/ContentFooter';
import { CenCreateFormFooter, useCenPageContext } from '@baumeister/web-cen-protocol';
import { CenCreateForm } from '@baumeister/web-cen-protocol';
import { FormElement } from '../../../../components/form/element/FormElement';

interface ContentFormProps {
    className?: string;
    children?: ReactNode;
}

export const ContentForm: React.FC<ContentFormProps> = ({ className, children }) => {
    const { pageData, channel } = useCenPageContext();
    return <StyledContentForm className={className}>{children}
        <CenCreateForm dictionary={pageData.dictionary} dataPath={pageData.dataPath} channel={channel}
                       renderAttribute={(dataProperty, inputElement) => <FormElement dataProperty={dataProperty}>{inputElement}</FormElement>}
                       showFormActionButtons={false}
                       entity={pageData.data}
        >
            <CenCreateFormFooter><ContentFooter/></CenCreateFormFooter>
        </CenCreateForm>
    </StyledContentForm>;
};
