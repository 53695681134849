import styled from 'styled-components';

export const StyledTableContents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0 0 0.375rem 0.375rem;
    border: 1px solid #D8DCE5;
`;
