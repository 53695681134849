import React, { ReactNode } from 'react';
import { StyledCompanyInfoSection } from './CompanyInfoSection.styles';

interface CompanyInfoSectionProps {
    className?: string;
    children?: ReactNode;
}

export const CompanyInfoSection: React.FC<CompanyInfoSectionProps> = ({ className, children }) => {
    return (
        <StyledCompanyInfoSection className={className}>
            {children}
        </StyledCompanyInfoSection>
    );
};
