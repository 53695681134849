import styled from 'styled-components';

export const StyledInprogressItemWrapper = styled.div`
    display: flex;
    width: 22.5625rem;
    justify-content: space-between;
    align-items: flex-start;
    &.disabled {
        opacity: 0.65;
    }
    .left-col-wrapper {
        display: flex;
        width: 10.1875rem;
        align-items: center;
        gap: var(--spacing-3xl, 1.5rem);
        flex-shrink: 0;
    }
    .left-col-wrapper .icon {
        width: 2rem;
        height: 2.5625rem;
        flex-shrink: 0;
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xxs, 0.125rem);
    }
    .text-wrapper .title {
        width: 8.31419rem;
        height: 1.0625rem;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .text-wrapper.no-button .title {
        width: 19.0625rem;
    }
    .text-wrapper .subtitle {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    & .button {
        display: flex;
        padding: 0.375rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: unset;
    }
`;
