import styled from 'styled-components';
import { PatternFormat } from 'react-number-format';

export const StyledDateTemplate = styled.div`
    align-self: stretch;
    display: flex;
    flex: 1 0 0;
    gap: 0.625rem;
    align-items: flex-start;
    input {
        width: 100%;
        padding: 0.75rem;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 0.375rem;
        border: 1px solid #D8DCE5;
        background: #FFF;
        color: #1A384E;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
    input:disabled {
        opacity: 0.5;
    }
    .react-datepicker-wrapper {
        display: flex;
        flex: 1 0 0;
    }
`;
