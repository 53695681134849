import React, { ReactNode } from 'react';
import { StyledWirtschaftlicheBasisdatenIcon } from './WirtschaftlicheBasisdatenIcon.styles';

interface WirtschaftlicheBasisdatenIconProps {
    className?: string;
    children?: ReactNode;
}

export const WirtschaftlicheBasisdatenIcon: React.FC<WirtschaftlicheBasisdatenIconProps> = ({
                                                                                                className,
                                                                                                children,
                                                                                            }) => {
    return (
        <StyledWirtschaftlicheBasisdatenIcon className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_3409_23059)">
                <path d="M11.5 15V16.125H7V15H0.8125V17.8125C0.8125 18.123 1.0645 18.375 1.375 18.375H17.125C17.4355 18.375 17.6875 18.123 17.6875 17.8125V15H11.5Z" fill="white"/>
                <path d="M17.6875 4.3125H12.625V2.0625C12.625 1.752 12.373 1.5 12.0625 1.5H6.4375C6.127 1.5 5.875 1.752 5.875 2.0625V4.3125H0.8125C0.502 4.3125 0.25 4.5645 0.25 4.875V13.3125C0.25 13.623 0.502 13.875 0.8125 13.875H7V12.75H11.5V13.875H17.6875C17.998 13.875 18.25 13.623 18.25 13.3125V4.875C18.25 4.5645 17.998 4.3125 17.6875 4.3125ZM11.5 4.3125H7V2.625H11.5V4.3125Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3409_23059">
                    <rect width="18" height="18" fill="white" transform="translate(0.25 0.5)"/>
                </clipPath>
            </defs>
        </StyledWirtschaftlicheBasisdatenIcon>
    );
};
