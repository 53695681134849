import {
    IReportBaseWidgetValue,
    IReportChartDataValue, IReportChartWidgetValue,
    IReportDesignBaseWidget, IReportDesignChartWidget, IReportDesignImageWidget,
    IReportDesignPage, IReportDesignSection, IReportDesignTableWidget,
    IReportDesignTextWidget, IReportFileSourceValue, IReportImageWidgetValue,
    IReportPageValue, IReportTableCellValue, IReportTableHeaderValue, IReportTableWidgetValue,
    IReportTextWidgetValue,
} from '../../../../../../serverTypes';
import {
    IReportChartWidget, IReportImageWidget,
    IReportPage, IReportSection,
    IReportTableWidget,
    IReportTextWidget,
    IReportWidget,
    ReportWidgetType,
} from '../../types';

function extractWidget(widgetValue: IReportBaseWidgetValue, designWidgetMap: Map<number, IReportDesignBaseWidget>, reportTableHeaderValues: IReportTableHeaderValue[], reportTableCellValues: IReportTableCellValue[], reportChartDataValues: IReportChartDataValue[]) {
    if (widgetValue._dictionary.type === 'ReportValueReportTextWidgetValue') {
        return {
            design: designWidgetMap.get(widgetValue.designWidget?.reportDesignBaseWidgetId || 0) as IReportDesignTextWidget,
            value: widgetValue as IReportTextWidgetValue,
            type: ReportWidgetType.TEXT,
            fileSources: []
        } as IReportTextWidget;
    } else if (widgetValue._dictionary.type === 'ReportValueReportImageWidgetValue') {
        return {
            design: designWidgetMap.get(widgetValue.designWidget?.reportDesignBaseWidgetId || 0) as IReportDesignImageWidget,
            value: widgetValue as IReportImageWidgetValue,
            type: ReportWidgetType.IMAGE,
            fileSources: []
        } as IReportImageWidget;
    } else if (widgetValue._dictionary.type === 'ReportValueReportTableWidgetValue') {
        const tableWidget: IReportTableWidget = {
            design: designWidgetMap.get(widgetValue.designWidget?.reportDesignBaseWidgetId || 0) as IReportDesignTableWidget,
            value: widgetValue as IReportTableWidgetValue,
            type: ReportWidgetType.TABLE,
            rows: [],
            headers: [],
            columnCount: 0,
            fileSources: []
        };
        for (const tableCell of reportTableCellValues) {
            if (tableCell.tableWidget?.reportBaseWidgetValueId === widgetValue.reportBaseWidgetValueId) {
                const rowIndex = tableCell.row || 0;
                const columnIndex = tableCell.column || 0;
                let row = tableWidget.rows[rowIndex];
                if (!row) {
                    row = {
                        columns: []
                    };
                    tableWidget.rows[rowIndex] = row;
                }
                row.columns[columnIndex] = tableCell;
                tableWidget.columnCount = Math.max(tableWidget.columnCount, columnIndex);
            }
        }
        for (const tableHeader of reportTableHeaderValues) {
            if (tableHeader.tableWidget?.reportBaseWidgetValueId === widgetValue.reportBaseWidgetValueId) {
                tableWidget.headers[tableHeader.column || 0] = tableHeader;
            }
        }
        tableWidget.columnCount++;
        return tableWidget;
    } else if (widgetValue._dictionary.type === 'ReportValueReportChartWidgetValue') {
        const chartWidget: IReportChartWidget = {
            design: designWidgetMap.get(widgetValue.designWidget?.reportDesignBaseWidgetId || 0) as IReportDesignChartWidget,
            value: widgetValue as IReportChartWidgetValue,
            type: ReportWidgetType.CHART,
            series: [],
            fileSources: []
        };
        for (const chartValue of reportChartDataValues) {
            if (chartValue.chartWidgetValue?.reportBaseWidgetValueId === widgetValue.reportBaseWidgetValueId) {
                const seriesValue = chartValue.series || 0;
                let series = chartWidget.series[seriesValue];
                if (!series) {
                    series = {
                        values: []
                    };
                    chartWidget.series[seriesValue] = series;
                }
                series.values.push(chartValue);
            }
        }
        return chartWidget;
    } else {
        console.log(`unknown widget type ${widgetValue._dictionary.type} found`);
        return undefined;
    }
}

export function collectReport(reportDesignPages: IReportDesignPage[], reportDesignSection: IReportDesignSection[], reportPageValues: IReportPageValue[], reportDesignBaseWidgets: IReportDesignBaseWidget[], reportBaseWidgetValues: IReportBaseWidgetValue[], reportTableHeaderValues: IReportTableHeaderValue[], reportTableCellValues: IReportTableCellValue[], reportChartDataValues: IReportChartDataValue[], reportFileSourceValues: IReportFileSourceValue[]) {
    const reportDesignMap:Map<number, IReportDesignPage> = new Map();
    reportDesignPages.forEach(element => reportDesignMap.set(element.reportDesignPageId || 0, element));
    const designWidgetMap:Map<number, IReportDesignBaseWidget> = new Map();
    reportDesignBaseWidgets.forEach(element => designWidgetMap.set(element.reportDesignBaseWidgetId || 0, element));
    const result:IReportPage[] = [];
    for (const pageValue of reportPageValues) {
        const page:IReportPage = {
            design: reportDesignMap.get(pageValue.designPage?.reportDesignPageId || 0),
            value: pageValue,
            sections: []
        };
        for (const designSection of reportDesignSection) {
            if (designSection.page?.reportDesignPageId === page.design?.reportDesignPageId) {
                const section: IReportSection = {
                    design: designSection,
                    widgets: []
                };
                for (const widgetValue of reportBaseWidgetValues) {
                    const designWidget = designWidgetMap.get(widgetValue.designWidget?.reportDesignBaseWidgetId || 0);
                    if (designWidget?.section?.reportDesignSectionId === designSection.reportDesignSectionId) {
                        const widget:IReportWidget<IReportDesignBaseWidget, IReportBaseWidgetValue> | undefined = extractWidget(widgetValue, designWidgetMap, reportTableHeaderValues, reportTableCellValues, reportChartDataValues);
                        if (widget) {
                            section.widgets.push(widget);
                            for (const fileSourceValue of reportFileSourceValues) {
                                if (fileSourceValue.widget?.reportBaseWidgetValueId === widgetValue.reportBaseWidgetValueId) {
                                    widget.fileSources.push(fileSourceValue);
                                }
                            }
                        }
                    }
                }
                section.widgets.sort((a, b) => (a.design?.orderNumber || 0) - (b.design?.orderNumber || 0));
                page.sections.push(section);
            }
        }
        page.sections.sort((a, b) => (a.design?.orderNumber || 0) - (b.design?.orderNumber || 0));
        result.push(page);
    }
    return result;
}

