import React from 'react';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { EuroIcon } from 'react-line-awesome';
import {
    acceptNumber,
    CenBaseRendererProperties,
    CenEditorRendererProperties,
    registerCenEditorRenderer,
} from '@baumeister/web-cen-protocol';
import { Input, InputGroup, InputGroupText, InputProps } from '@baumeister/web-ui-bootstrap';
import classNames from 'classnames';

const InputAmountUS: React.FC<InputProps> = (properties) => {
    return <Input {...properties} onFocus={(evt) => evt.target.select()} />;
};

const InputAmount = styled(InputAmountUS)`
    text-align: right;
    background-color: #fff;
`;

const AmountEditorUS: React.FC<CenEditorRendererProperties & CenBaseRendererProperties> = (properties) => {
    return (
        <InputGroup className={classNames(properties.className, 'amount')}>
            <NumericFormat
                defaultValue={properties.value}
                decimalScale={4}
                fixedDecimalScale={true}
                customInput={InputAmount}
                decimalSeparator={','}
                onValueChange={(values, sourceInfo) => properties.onAccept(values.floatValue)}
            />
            <InputGroupText>
                €
            </InputGroupText>
        </InputGroup>
    );
};

export const AmountEditor = styled(AmountEditorUS)`
input.form-control {
    background-color: #fff;
    color: black;
}
input.form-control:focus-visible {
    background-color: #fff;
    outline: -webkit-focus-ring-color auto 1px;
    color: black;
}
`;

export const installAmountEditor = () => {
    registerCenEditorRenderer('float', 'currency', { acceptNumber }, (properties) => <AmountEditor {...properties} />);
};
