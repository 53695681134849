import React, { ReactNode } from 'react';
import { StyledUploadPlusIcon } from './UploadPlusIcon.styles';

interface UploadPlusIconProps {
    className?: string;
    children?: ReactNode;
}

export const UploadPlusIcon: React.FC<UploadPlusIconProps> = ({ className, children }) => {
    return (
        <StyledUploadPlusIcon className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M18.22 0V12.3H29.89V17.79H18.22V30.15H12.22V17.79H0.609985V12.3H12.22V0H18.22Z" fill="#1A384E" fillOpacity="0.5"/>
        </StyledUploadPlusIcon>
    );
};
