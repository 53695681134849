import styled from 'styled-components';

export const StyledFileUploadContent = styled.div`
    .heading-text {
        display: flex;
        padding: 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        align-self: stretch;

        .subtitle {
            width: 33.25rem;
            color: #1A384E;
            text-align: center;

            /* Paragraph XS */
            font-family: Lato;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.125rem */
        }
    }
    .upload-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
        align-self: stretch;
        .heading-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--spacing-md, 0.5rem);
            align-self: stretch;
        }
        .title {
            color: var(--Base-Neutral-600, #1A384E);
            text-align: center;

            /* Paragraph M - Bold */
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 1.5rem */
            a {
                color: var(--Brand-Accent-600, #00FF8C);

                /* Paragraph M - Bold */
                font-family: Lato;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                text-decoration: none;
            }
        }

        .subtitle {
            align-self: stretch;
            color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
            text-align: center;

            /* Paragraph XS */
            font-family: Lato;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.125rem */
        }

    }

`;
