import React, { ReactNode } from 'react';
import { StyledQuestionInput } from './QuestionInput.styles';
import { TextTemplate } from '../templates/text/TextTemplate';
import { ButtonWrap } from '../buttonwrap/ButtonWrap';
import { IQuestionData } from '../../../types';
import { SelectTemplate } from '../templates/select/SelectTemplate';
import { TableTemplate } from '../templates/table/TableTemplate';
import classNames from 'classnames';
import { TemplateRenderer } from '../templates/TemplateRenderer';

interface QuestionInputProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const QuestionInput: React.FC<QuestionInputProps> = ({ className, questionData, children }) => {
    const renderTemplate = () => {

    }
    return <StyledQuestionInput className={classNames(className, `type-${questionData.type}`)}>
        <TemplateRenderer questionData={questionData}/>
        <ButtonWrap questionData={questionData} className={'buttonwrap'}/>
    </StyledQuestionInput>;
};
