import styled from 'styled-components';

export const StyledTableHeadColumn = styled.div`
    color: #1A384E;

    /* Paragraph M - Bold */
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.5rem */
`;
