import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    display: flex;
    height: 14.1875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    padding: 2.5rem 1.25rem;
    .clx-raum-logo {
        display: flex;
        align-items: center;
    }
    .clx-raum-logo .icon {
        width: 5.775rem;
        height: 3.88688rem;
    }
    .clx-raum-logo .text {
        margin-left: -1rem;
        width: 5.4375rem;
        height: 2rem;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.1rem */
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-md, 0.5rem);
    }
    .text-wrapper .line {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
`;
