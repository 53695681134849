import styled from 'styled-components';

export const StyledDocRoomCard = styled.div`
    display: flex;
    padding-top: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    flex: 1 0 0;
    border-radius: var(--radius-sm, 0.375rem);
    opacity: 0.5;
    background: var(--Base-White, #FFF);

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
`;
