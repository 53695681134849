import React, { ReactNode } from 'react';
import { StyledBaseDataPage } from './BaseDataPage.styles';
import { BaseDataContent } from './content/BaseDataContent';

interface BaseDataPageProps {
    className?: string;
    children?: ReactNode;
}

export const BaseDataPage: React.FC<BaseDataPageProps> = ({ className, children }) => {
    return (
        <StyledBaseDataPage className={className}>
            <div className={'section'}>
                <BaseDataContent/>
            </div>
            <div className={'avatar-upload_wrapper'}>
                <div className={'avatar-wrapper'}>
                    <div className={'placeholder'}></div>
                </div>
            </div>
        </StyledBaseDataPage>
    );
};
