import styled from 'styled-components';
import { DocRoomCard } from '../doc-room-wrapper/doc-room-card/DocRoomCard';

export const StyledCompanyData = styled(DocRoomCard)`
    .title {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 2.1rem */
        align-self: stretch;
    }
    .description {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }

    .content-wrapper .button {
        align-self: unset;
    }

    .illustration-wrapper {
        display: flex;
        width: 23.5625rem;
        height: 6.9375rem;
        padding-right: 1.25rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
`;
