import styled from 'styled-components';

export const StyledBaseDataContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3.75rem;
    flex: 1 0 0;
    .text-wrapper {
        display: flex;
        width: 32.9375rem;
        max-width: 32.9375rem;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-md, 0.5rem);

        .title {
            align-self: stretch;
            color: var(--Base-Neutral-600, #1A384E);

            /* Heading 2 */
            font-family: Lato;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 2.4rem */
        }
        .subtitle {
            align-self: stretch;
            color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.5rem */
        }
    }
`;
