import React from 'react';
import { OnboardingFrame } from './frame/OnboardingFrame';
import { ContentFrame } from './content-frame/ContentFrame';
import { ContentLeft } from './content-frame/left/ContentLeft';
import { ContentRight } from './content-frame/right/ContentRight';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { selectPageData } from '@baumeister/web-cen-protocol';
import { useAppSelector } from '../../../store';

interface Onboarding3PageProps {
    className?: string;
}

export const Onboarding3Page: React.FC<Onboarding3PageProps> = ({ className }) => {
    const navigate = useNavigate();
    const backwardPath = useResolvedPath('../2', {relative: 'path'});
    const pageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const dashboardUrl = pageData?.dataPath;
    console.log('having dashboard url ', dashboardUrl);
    return (
        <OnboardingFrame className={className}>
            <ContentFrame>
                <ContentLeft
                    step={4}
                    title={'Geschafft. Sie sind bereit zu verkaufen!'}
                    onButtonClick={() => dashboardUrl && navigate(dashboardUrl)}
                    onArrowClick={() => navigate(backwardPath)}
                >
                    <p>Herzlichen Glückwunsch, Sie sind jetzt bereit, erfolgreich zu verkaufen! Erkunden Sie jetzt Ihr persönlcihes Dashboard und bearbeiten Sie Ihre Unternehmensinformationen für ein aussagekräftiges FactSheet.</p>
                </ContentLeft>
                <ContentRight activeStep={4}/>
            </ContentFrame>
        </OnboardingFrame>
    );
};
