import React, { ReactNode, useState } from 'react';
import { StyledSectionTypeInstancePage } from './SectionTypeInstancePage.styles';
import { SectionTypeInstanceContent } from './content/SectionTypeInstanceContent';
import { IEditableOptionData, selectPageChildCursorData, useCenPageContext } from '@baumeister/web-cen-protocol';
import { useAppSelector } from '../../../../store';
import {
    IBaseValue,
    IDateValue,
    IDesignBaseQuestion,
    IDesignConstraint,
    IDesignDateQuestion,
    IDesignFileQuestion,
    IDesignImageQuestion,
    IDesignNumericQuestion,
    IDesignPromptQuestion,
    IDesignSection,
    IDesignSectionType,
    IDesignSelectOption,
    IDesignSelectQuestion,
    IDesignTableQuestion,
    IDesignTextQuestion,
    IFileValue,
    IImageValue,
    INumericValue,
    IPromptValue,
    IQuestionInstance,
    IReportBaseWidgetValue,
    IReportChartDataValue,
    IReportDesignBaseWidget,
    IReportDesignPage,
    IReportDesignSection,
    IReportFileSourceValue,
    IReportPageValue,
    IReportTableCellValue,
    IReportTableHeaderValue,
    ISectionInstance,
    ISectionTypeInstance,
    ISelectValue,
    ITableValue,
    ITextValue,
} from '../../../../serverTypes';
import {
    ConstraintOperationType,
    IQuestionData,
    IReportPage,
    ISectionData,
    ISectionTypeData,
    ISectionTypeInstanceData,
    ISectionTypeInstanceExt,
    IValueData,
    QuestionDataType,
} from './types';
import { SectionTypeInstanceDataProvider } from './SectionTypeInstanceDataProvider';
import { ProgressCircle } from './content/left/progresscircle/ProgressCircle';
import { QuestionItemList } from './content/left/questionitemlist/QuestionItemList';
import { ProgressText } from './content/left/progresscircle/ProgressText';
import { InputContent } from './content/right/inputcontent/InputContent';
import { ReportPreviewNotifier } from './content/left/reportpreview/ReportPreviewNotifier';
import { Switch } from './content/left/switch/Switch';
import { collectReport } from './content/preview/reportCollector';

interface SectionTypeInstanceProps {
    className?: string;
    children?: ReactNode;
}

interface IEditableOptionDataExt extends IEditableOptionData {
    orderNumber: number;
}

class ValueCollector {
    private readonly designQuestionMap: Map<number, IDesignBaseQuestion> = new Map();

    private readonly questionInstanceMap: Map<number, IQuestionInstance> = new Map();

    private readonly sectionMap:Map<number, IDesignBaseQuestion[]> = new Map();

    private readonly valueMap:Map<number, IValueData> = new Map();

    private readonly valueQuestionInstanceMap:Map<number, IValueData[]> = new Map();

    private readonly valueDesignQuestionMap:Map<number, IValueData[]> = new Map();

    private readonly selectOptionMap:Map<number, IEditableOptionDataExt[]> = new Map();

    private readonly designConstraintMap: Map<number, IDesignConstraint[]> = new Map();

    private readonly designQuestionDataMap: Map<number, IQuestionData> = new Map();

    constructor(textValue: ITextValue[], numericValue: INumericValue[], dateValue: IDateValue[], imageValue: IImageValue[], promptValue: IPromptValue[], selectValue: ISelectValue[], tableValue: ITableValue[], fileValue: IFileValue[], designTextQuestions: IDesignTextQuestion[], designNumericQuestions: IDesignNumericQuestion[], designDateQuestions: IDesignDateQuestion[], designImageQuestions: IDesignImageQuestion[], designPromptQuestions: IDesignPromptQuestion[], designSelectQuestions: IDesignSelectQuestion[], designSelectOptions: IDesignSelectOption[], designTableQuestions: IDesignTableQuestion[], designFileQuestions: IDesignFileQuestion[], questionInstances:IQuestionInstance[], designConstraints: IDesignConstraint[]) {
        designTextQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designTableQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designFileQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designSelectQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designNumericQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designDateQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designImageQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designPromptQuestions.forEach(element => this.designQuestionMap.set(element.infoTagId || 0, element));
        designConstraints.forEach(element => {
            const key = element.parent?.designBaseQuestionId as unknown as number|| 0;
            let array:IDesignConstraint[] | undefined = this.designConstraintMap.get(key);
            if (!array) {
                array = [];
                this.designConstraintMap.set(key, array);
            }
            array.push(element);
        });
        this.designQuestionMap.forEach(element => {
            let array:IDesignBaseQuestion[]|undefined = this.sectionMap.get(element.section?.infoTagId || 0);
            if (!array) {
                array = [];
                this.sectionMap.set(element.section?.infoTagId || 0, array);
            }
            array.push(element);
        });
        this.sectionMap.forEach((element, key) => {
            element.sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0));
            this.sectionMap.set(key, element);
        });
        textValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        tableValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        fileValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        selectValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        numericValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        dateValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        imageValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        promptValue.forEach(element => this.valueMap.set(element.infoTagId || 0, { link: element._link, value: element as IBaseValue}));
        this.valueMap.forEach((element, key) => {
            let instanceArray:IValueData[]|undefined = this.valueQuestionInstanceMap.get((element.value.question?.questionInstanceId as unknown as number) || 0);
            if (!instanceArray) {
                instanceArray = [];
                this.valueQuestionInstanceMap.set((element.value.question?.questionInstanceId as unknown as number)|| 0, instanceArray);
            }
            instanceArray.push(element);
            let questionArray:IValueData[]|undefined = this.valueDesignQuestionMap.get((element.value.designQuestion?.designBaseQuestionId as unknown as number) || 0);
            if (!questionArray) {
                questionArray = [];
                this.valueDesignQuestionMap.set((element.value.designQuestion?.designBaseQuestionId as unknown as number) || 0, questionArray);
            }
            questionArray.push(element);
        });
        questionInstances.forEach(element => this.questionInstanceMap.set(element.infoTagId || 0, element));
        designSelectOptions.forEach(element => {
            let optionArray:IEditableOptionDataExt[]|undefined = this.selectOptionMap.get((element.selectQuestion as any)?.designSelectQuestionId || 0);
            if (!optionArray) {
                optionArray = [];
                this.selectOptionMap.set((element.selectQuestion as any)?.designSelectQuestionId || 0, optionArray);
            }
            optionArray.push({
                label: element.text,
                value: element.value || '',
                orderNumber: element.orderNumber || 0
            });
        });
        this.selectOptionMap.forEach((value, key) => {
            this.selectOptionMap.set(key, value.sort((a, b) => a.orderNumber - b.orderNumber));
        })
    }

    public getQuestionsForSectionInstance(sectionInstanceId:number):IQuestionData[] {
        return Array.from(this.questionInstanceMap.values())
            .filter(element => (element.sectionInstance?.sectionInstanceId || 0) === sectionInstanceId)
            .map(element => this.mapQuestionInstanceToData(element))
            .filter(element => element.design.container?.designQuestionContainerId === element.design.section?.designQuestionContainerId);
    }

    private mapQuestionInstanceToData(questionInstance: IQuestionInstance){
        const { type, questionId } = this.determineType(questionInstance);
        const designQuestion = this.designQuestionMap.get(questionId || 0);
        if (!designQuestion) {
            throw new Error('no design question found for ' + questionId);
        }
        const designConstraints: IDesignConstraint[] = this.designConstraintMap.get(designQuestion?.designBaseQuestionId || 0) || [];
        const questionData:IQuestionData = {
            type: type ? type as QuestionDataType : 'table',
            design: designQuestion,
            instance: questionInstance,
            instanceLink: questionInstance._link,
            columns: type === 'table' ? this.findQuestionsForDesignQuestionContainer((designQuestion?.designBaseQuestionId) || 0) : [],
            values: this.getValuesByDesignQuestion((designQuestion?.designBaseQuestionId as unknown as number) || 0),
            selectOptions: type === 'select' ? this.selectOptionMap.get(questionId) || [] : []
        };
        this.designQuestionDataMap.set(designQuestion?.designBaseQuestionId || 0, questionData);
        return questionData;
    }

    private findQuestionsForDesignQuestionContainer(designContainerId: number): IQuestionData[] {
        return Array.from(this.designQuestionMap.values())
            .filter(designQuestion => (designQuestion.container as any).designTableQuestionId === designContainerId)
            .map(element => this.mapDesignQuestionToInstance(element))
            .filter(element => element !== undefined)
            .map(element => element as IQuestionData);
    }

    private mapDesignQuestionToInstance(designQuestion: IDesignBaseQuestion) {
        const element:IQuestionInstance[] = Array.from(this.questionInstanceMap.values())
            .filter(element => element.question?.designBaseQuestionId === designQuestion.designBaseQuestionId);
        if (element.length > 1) {
            throw new Error('more than one question instance found for ' + JSON.stringify(designQuestion));
        }
        return element.length > 0 ? this.mapQuestionInstanceToData(element[0]) : undefined;
    }

    private determineType(element:IQuestionInstance) {
        if (!element.question) {
            return {
                type: undefined,
                questionId: undefined
            };
        }
        if ((element.question as any).designTextQuestionId) {
            return {
                type: 'text',
                questionId: (element.question as any).designTextQuestionId
            };
        } else if ((element.question as any).designNumericQuestionId) {
            return {
                type: 'numeric',
                questionId: (element.question as any).designNumericQuestionId
            };
        } else if ((element.question as any).designDateQuestionId) {
            return {
                type: 'date',
                questionId: (element.question as any).designDateQuestionId
            };
        } else if ((element.question as any).designTableQuestionId) {
            return {
                type: 'table',
                questionId: (element.question as any).designTableQuestionId
            };
        } else if ((element.question as any).designFileQuestionId) {
            return {
                type: 'file',
                questionId: (element.question as any).designFileQuestionId
            };
        } else if ((element.question as any).designSelectQuestionId) {
            return {
                type: 'select',
                questionId: (element.question as any).designSelectQuestionId
            };
        } else if ((element.question as any).designImageQuestionId) {
            return {
                type: 'image',
                questionId: (element.question as any).designImageQuestionId
            };
        } else if ((element.question as any).designPromptQuestionId) {
            return {
                type: 'prompt',
                questionId: (element.question as any).designPromptQuestionId
            };
        }
        return {
            type: undefined,
            questionId: undefined
        };
    }

    public getValuesByQuestionInstance(questionInstance:number):IValueData[] {
        return this.valueQuestionInstanceMap.get(questionInstance) || [];
    }

    public getValuesByDesignQuestion(designBaseQuestionId:number):IValueData[] {
        return this.valueDesignQuestionMap.get(designBaseQuestionId) || [];
    }
}

interface ISectionInstanceData {
    design:IDesignSection;
    sectionType: ISectionTypeInstance;
    instance: ISectionInstance;
}
class SectionCollector {
    private designSectionMap:Map<number, IDesignSection> = new Map();
    private sectionTypeInstanceMap:Map<number, ISectionTypeInstance> = new Map();
    private instances:ISectionInstanceData[];
    constructor(private readonly sectionInstances: ISectionInstance[], private readonly designSections: IDesignSection[], private readonly sectionTypeInstances: ISectionTypeInstanceExt[], private readonly valueCollector:ValueCollector) {
        designSections.forEach(element => this.designSectionMap.set(element.infoTagId || 0, element));
        sectionTypeInstances.forEach(element => this.sectionTypeInstanceMap.set(element.sectionTypeInstanceId || 0, element));
        this.instances = sectionInstances.map(element => {
            return {
                design: this.designSectionMap.get((element.designSection as any)?.designSectionId || 0),
                sectionType: this.sectionTypeInstanceMap.get(element.sectionTypeInstance?.sectionTypeInstanceId || 0),
                instance: element
            } as ISectionInstanceData;
        }).filter(element => element.design).sort((a, b) => (a?.design.orderNumber || 0) - (b?.design.orderNumber || 0));
    }


    public getSectionsByTypeInstance(sectionTypeInstanceId: number): ISectionData[] {
        return this.instances.filter(element => element.sectionType.sectionTypeInstanceId === sectionTypeInstanceId)
            .map(element => {
                return {
                    design: element.design,
                    instance: element.instance,
                    questions: this.valueCollector.getQuestionsForSectionInstance(element.instance.sectionInstanceId || 0)
                } as ISectionData;
            })
    }
}

export const SectionTypeInstancePage: React.FC<SectionTypeInstanceProps> = ({ className, children }) => {
    const { pageData, channel } = useCenPageContext();

    const reportDesignPage: IReportDesignPage[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignPage"));
    const reportDesignSection: IReportDesignSection[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignSection"));
    const reportDesignBaseWidget: IReportDesignBaseWidget[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportDesignBaseWidget"));
    const reportPageValue: IReportPageValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportPageValue"));
    const reportBaseWidgetValue: IReportBaseWidgetValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportBaseWidgetValue"));
    const reportChartDataValue: IReportChartDataValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportChartDataValue"));
    const reportTableHeaderValue: IReportTableHeaderValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableHeaderValue"));
    const reportTableCellValue: IReportTableCellValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportTableCellValue"));
    const reportFileSourceValue: IReportFileSourceValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "reportFileSourceValue"));
    const reportPages: IReportPage[] = collectReport(reportDesignPage, reportDesignSection, reportPageValue, reportDesignBaseWidget, reportBaseWidgetValue, reportTableHeaderValue, reportTableCellValue, reportChartDataValue, reportFileSourceValue);

    const designSectionTypes: IDesignSectionType[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, 'designSectionType'));
    const sectionTypeInstances: ISectionTypeInstanceExt[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, 'sectionTypeInstance'));
    const designSection: IDesignSection[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designSection"));
    const designSectionMap = new Map<number,IDesignSection>();
    designSection.forEach(element => designSectionMap.set(element.infoTagId || 0, element));

    const sectionInstances: ISectionInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, 'sectionInstance'));

    const designTextQuestions: IDesignTextQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designTextQuestion"));
    const designNumericQuestions: IDesignNumericQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designNumericQuestion"));
    const designDateQuestions: IDesignDateQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designDateQuestion"));
    const designImageQuestions: IDesignImageQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designImageQuestion"));
    const designPromptQuestions: IDesignPromptQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designPromptQuestion"));
    const designSelectQuestions: IDesignSelectQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designSelectQuestion"));
    const designSelectOptions: IDesignSelectOption[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designSelectOption"));
    const designTableQuestions: IDesignTableQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designTableQuestion"));
    const designFileQuestions: IDesignFileQuestion[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designFileQuestion"));
    const designConstraints: IDesignConstraint[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "designConstraint"));
    const textValue: ITextValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "textValue"));
    const numericValue: INumericValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "numericValue"));
    const dateValue: IDateValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "dateValue"));
    const imageValue: IImageValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "imageValue"));
    const promptValue: IPromptValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "promptValue"));
    const selectValue: ISelectValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "selectValue"));
    const tableValue: ITableValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "tableValue"));
    const fileValue: IFileValue[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "fileValue"));
    const questionInstance: IQuestionInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, channel, "questionInstance"));
    const [previewVisible, setPreviewVisible] = useState<boolean>(true);
    const [reportVisible, setReportVisible] = useState<boolean>(false);
    const [switchState, setSwitchState] = useState<boolean>(false);
    const valueCollector = new ValueCollector(textValue, numericValue, dateValue, imageValue, promptValue, selectValue, tableValue, fileValue, designTextQuestions, designNumericQuestions, designDateQuestions, designImageQuestions, designPromptQuestions, designSelectQuestions, designSelectOptions, designTableQuestions, designFileQuestions, questionInstance, designConstraints);
    const sectionCollector = new SectionCollector(sectionInstances, designSection, sectionTypeInstances, valueCollector);

    const currentType = pageData.data as unknown as ISectionTypeInstanceExt;
    // console.log(currentType);
    const map:Map<number, ISectionTypeInstance> = new Map();
    sectionTypeInstances.forEach(element => map.set(element.sectionTypeInstanceId || 0, element));
    const designMap:Map<number, IDesignSectionType> = new Map();
    designSectionTypes.forEach(element => designMap.set(element.designSectionTypeId || 0, element));
    const onSwitchChange = (value: boolean) => {
        setReportVisible(value);
        window.scrollTo(0, 0);
    };
    const typeInstanceData:ISectionTypeInstanceData = {
        currentType,
        currentDesign: designMap.get(currentType.designSectionType?.designSectionTypeId || 0),
        instances: sectionTypeInstances.map(element => {
            return {
                instance: element,
                design: designMap.get(element.designSectionType?.designSectionTypeId || 0)
            } as ISectionTypeData;
        }),
        sections: sectionCollector.getSectionsByTypeInstance(currentType.sectionTypeInstanceId || 0)
    };
    const onDisableReport = () => {
        setReportVisible(false);
        setSwitchState(false);
    }
    const onEnableReport = () => {
        setReportVisible(true);
        setSwitchState(true);
    }
    let currentReportSections = 0;
    let totalReportSections = 0;
    for (const reportPage of reportPages) {
        for (const section of reportPage.sections) {
            totalReportSections += section.widgets?.length || 0;
            currentReportSections += section.widgets?.filter(element => element.value.dataAvailable).length || 0;
        }
    }
    // console.log(`report sections - current ${currentReportSections}, total: ${totalReportSections}`);
    return <StyledSectionTypeInstancePage className={className}>
        <SectionTypeInstanceDataProvider value={typeInstanceData}>
            <SectionTypeInstanceContent
                leftContent={(collapsed) => (
                    <React.Fragment>
                        <Switch switchState={switchState} setSwitchState={(value) => setSwitchState(value)} onChange={(value) => onSwitchChange(value)}/>
                        <ProgressCircle value={typeInstanceData.currentType?.completedPercentage || 0} small={collapsed}>
                            <ProgressText value={typeInstanceData.currentType?.completedPercentage || 0} small={collapsed}/>
                        </ProgressCircle>
                        <QuestionItemList collapsed={collapsed}/>
                    </React.Fragment>
                )}
                rightContent={(collapsed) => (
                    <InputContent collapsed={collapsed} reportVisible={reportVisible} onDisableReport={() => onDisableReport()}/>
                )}
            >
            </SectionTypeInstanceContent>
        </SectionTypeInstanceDataProvider>
    </StyledSectionTypeInstancePage>;
};
