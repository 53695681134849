import React, { HTMLAttributes, ReactNode } from 'react';
import { StyledSectionSuccess } from './SectionSuccess.styles';
import { CheckCircleIcon } from '../../../../../../../../components/icons/CheckCircleIcon';

interface SectionSuccessProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: ReactNode;
}

export const SectionSuccess: React.FC<SectionSuccessProps> = ({ className, ...props }) => {
    return <StyledSectionSuccess className={className} {...props}>
        <CheckCircleIcon className={'icon'}/>
    </StyledSectionSuccess>;
};
