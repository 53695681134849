import React, { ReactNode } from 'react';
import { StyledArrowClockwiseIcon } from './ArrowClockwiseIcon.styles';

interface ArrowClockwiseIconProps {
    className?: string;
    children?: ReactNode;
}

export const ArrowClockwiseIcon: React.FC<ArrowClockwiseIconProps> = ({ className, children }) => {
    return (
        <StyledArrowClockwiseIcon className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M15.2393 3.89006V6.89006C15.2393 7.02267 15.1866 7.14985 15.0928 7.24362C14.9991 7.33738 14.8719 7.39006 14.7393 7.39006H11.7393C11.6067 7.39006 11.4795 7.33738 11.3857 7.24362C11.292 7.14985 11.2393 7.02267 11.2393 6.89006C11.2393 6.75745 11.292 6.63028 11.3857 6.53651C11.4795 6.44274 11.6067 6.39006 11.7393 6.39006H13.4518L11.7899 4.86756L11.7743 4.85256C11.0793 4.15787 10.1951 3.68335 9.232 3.48828C8.26893 3.29321 7.26973 3.38624 6.35922 3.75575C5.44871 4.12526 4.66726 4.75486 4.11249 5.56591C3.55773 6.37696 3.25424 7.3335 3.23995 8.31603C3.22565 9.29855 3.50118 10.2635 4.03212 11.0904C4.56305 11.9172 5.32585 12.5693 6.22522 12.9651C7.1246 13.361 8.12067 13.483 9.08902 13.3161C10.0574 13.1491 10.955 12.7005 11.6699 12.0263C11.7663 11.9351 11.895 11.886 12.0276 11.8897C12.1602 11.8934 12.2859 11.9496 12.3771 12.046C12.4683 12.1424 12.5174 12.271 12.5137 12.4037C12.51 12.5363 12.4538 12.662 12.3574 12.7532C11.2458 13.8074 9.77126 14.3935 8.23928 14.3901H8.15678C7.17412 14.3766 6.20981 14.122 5.3486 13.6486C4.48738 13.1751 3.75565 12.4974 3.21769 11.675C2.67974 10.8525 2.35205 9.91054 2.26341 8.93179C2.17477 7.95303 2.3279 6.96749 2.70935 6.06179C3.0908 5.15608 3.68888 4.35793 4.45103 3.7375C5.21318 3.11706 6.11606 2.69334 7.08032 2.50356C8.04457 2.31378 9.04069 2.36377 9.9811 2.64912C10.9215 2.93447 11.7774 3.44645 12.4737 4.14006L14.2393 5.75256V3.89006C14.2393 3.75745 14.292 3.63028 14.3857 3.53651C14.4795 3.44274 14.6067 3.39006 14.7393 3.39006C14.8719 3.39006 14.9991 3.44274 15.0928 3.53651C15.1866 3.63028 15.2393 3.75745 15.2393 3.89006Z" fill="white"/>
        </StyledArrowClockwiseIcon>
    );
};
