import styled from 'styled-components';

export const StyledButtonWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .button {
        display: flex;
        padding: 0.5rem 0.75rem 0.5rem 0.875rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.375rem;
        border-width: 0;
    }
    .button.locked {
        opacity: 0.2;
    }
    .button.locked.done, .button.locked.ignore {
        opacity: 1;
    }
    .button.locked {
        pointer: cursor;
    }
    .delegate-button {
        background: #FFEED4;
    }

    .decline-button {
        background: #E9EBF0;
    }

    .accept-button {
        background: #D8FFE2;
    }


`;
