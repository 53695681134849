import React, { ReactNode } from 'react';
import { StyledNavContainer } from './NavContainer.styles';
import { NavLinkElement } from './element/NavLinkElement';
import { selectPageChildCursorData, selectPageData } from '@baumeister/web-cen-protocol';
import { useAppSelector } from '../../../store';
import { IDocumentSectionTypeInstance, ISectionTypeInstance } from '../../../serverTypes';

interface ColexoTopBarProps {
    className?: string;
    children?: ReactNode;
}

export const NavContainer: React.FC<ColexoTopBarProps> = ({ className, children }) => {

    const pageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const documentSectionTypeInstance: IDocumentSectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "documentSectionTypeInstance"));
    const documentUrl = documentSectionTypeInstance.length > 0 ? documentSectionTypeInstance[0]._link : undefined;
    const reportUrl = '/company/report';
    const sectionTypeInstance: ISectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "sectionTypeInstance"));
    const companyUrl = sectionTypeInstance?.length > 0 ? sectionTypeInstance[0]._link : undefined;

    const dashboardUrl = pageData?.dataPath;
    let url = window.location.href || '';
    if (url.endsWith('#')) {
        url = url.substring(0, url.length - 2);
    }
    return <StyledNavContainer className={className}>
        <NavLinkElement title={'Dashboard'} link={dashboardUrl} active={url.endsWith('/company')}></NavLinkElement>
        <NavLinkElement title={'Inforaum'} link={companyUrl} active={url.indexOf('/sectionTypeInstance') !== -1}></NavLinkElement>
        <NavLinkElement title={'Dokumentenraum'} link={documentUrl} active={url.indexOf('/documentSectionTypeInstance') !== -1}></NavLinkElement>
        <NavLinkElement title={'Report'} link={reportUrl} active={url.indexOf(reportUrl) !== -1}></NavLinkElement>
        <NavLinkElement title={'Marktplatz'}></NavLinkElement>
    </StyledNavContainer>;
};
