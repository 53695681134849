import React, { ReactNode, useState } from 'react';
import { StyledBaseDataField } from './BaseDataField.styles';
import { ITextValue } from '../../../../../serverTypes';
import { ErrorMessage } from '@baumeister/web-cen-protocol';

interface BaseDataFieldProps {
    className?: string;
    title: string;
    name: string;
    value: string | undefined;
    onValueChange: (value: string) => void;
    errorMap: Map<string, string>;
}

export const BaseDataField: React.FC<BaseDataFieldProps> = ({ className, title, name, value, onValueChange, errorMap }) => {
    const [ textValue, setTextValue ] = useState<string>(value || '');
    const errorMessage = errorMap.get(name);
    return (
        <StyledBaseDataField className={className}>
            <div className={'title'}>{title}</div>
            <input
                name={name}
                value={textValue}
                onChange={(evt) => {
                    const value = (evt.target as HTMLInputElement).value;
                    setTextValue(value);
                    onValueChange(value);
                }}
            />
            {errorMessage &&(
                <div className={'formErrorContainer'}>
                    <ErrorMessage errorMessage={errorMessage} />
                </div>
            )}
        </StyledBaseDataField>
    );
};
