import React, { ReactNode, useState } from 'react';
import { StyledBaseDataForm } from './BaseDataForm.styles';
import { useAppSelector } from '../../../../../store';
import { selectPageData, useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ICompany } from '../../../../../serverTypes';
import { BaseDataField } from './BaseDataField';
import { Button } from '../../../../../components/button/Button';
import { BaseDataSave, ColexoProtocolMessage } from '../../../../../protocol';
import { CenSocketProtocolType, IFormValidation } from '@baumeister/web-cen-protocol';
import { LoadingOverlay } from '@baumeister/web-cen-protocol';

interface BaseDataFormProps {
    className?: string;
    children?: ReactNode;
}

export const BaseDataForm: React.FC<BaseDataFormProps> = ({ className, children }) => {
    const { cenSocketProtocol} = useWebSocketContext();
    const [formErrorList, setFormErrorList] = useState<string[]>([]);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [errorMap, setErrorMap] = useState<Map<string, string>>(new Map());

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const pageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const companyData:ICompany | undefined = pageData?.data as ICompany;

    const baseDataSave: BaseDataSave = {
        type: ColexoProtocolMessage.BASE_DATA_SAVE,
        payload: {
            entityPath: pageData?.dataPath,
            name: companyData?.name,
            street: companyData?.street,
            houseNumber: companyData?.houseNumber,
            postalCode: companyData?.postalCode,
            city: companyData?.city,
            tradeRegisterNumber: companyData?.tradeRegisterNumber
        }
    };
    const saveData = () => {
        setShowSpinner(true);
        setSaveSuccess(false);
        setErrorMap(new Map());
        cenSocketProtocol.request(baseDataSave, (message) => {
            if (message.payload.type === CenSocketProtocolType.VALIDATION_ERRORS_CREATE) {
                const formValidation: IFormValidation = message.payload.payload as IFormValidation;
                const map = new Map<string, string>();
                formValidation.fieldValidations?.map((constraint) =>
                    map.set(constraint.property, constraint.errorMessage)
                );
                setErrorMap(map);
                setFormErrorList(formValidation.formValidations);
                setShowSpinner(false);
            } else if (message.payload.type === CenSocketProtocolType.RESPONSE_VALUE_UPDATED) {
                setSaveSuccess(true);
                setTimeout(() => setSaveSuccess(false), 1500);
                setShowSpinner(false);
            }
        });
    }

    return (
        <StyledBaseDataForm className={className}>
            <div className={'formTitle'}>
                Unternehmensdaten
            </div>
            <div className={'content'}>
                <BaseDataField title={'Unternehmensname'} name={'name'} value={baseDataSave.payload.name} onValueChange={(value) => baseDataSave.payload.name = value} errorMap={errorMap}/>
                <BaseDataField title={'Straße'} name={'street'} value={baseDataSave.payload.street} onValueChange={(value) => baseDataSave.payload.street = value} errorMap={errorMap}/>
                <BaseDataField title={'Hausnummer'} name={'streetNumber'} value={baseDataSave.payload.houseNumber} onValueChange={(value) => baseDataSave.payload.houseNumber = value} errorMap={errorMap}/>
                <BaseDataField title={'Postleitzahl'} name={'postalCode'} value={baseDataSave.payload.postalCode} onValueChange={(value) => baseDataSave.payload.postalCode = value} errorMap={errorMap}/>
                <BaseDataField title={'Stadt'} name={'city'} value={baseDataSave.payload.city} onValueChange={(value) => baseDataSave.payload.city = value} errorMap={errorMap}/>
                <BaseDataField title={'Registernummer'} name={'tradeRegisterNumber'} value={baseDataSave.payload.tradeRegisterNumber} onValueChange={(value) => baseDataSave.payload.tradeRegisterNumber = value} errorMap={errorMap}/>
            </div>
            <Button caption={'Änderungen speichern'} variant={'primary'} className={'saveButton'} onClick={() => saveData()}/>
            {children}
            {showSpinner && <LoadingOverlay loading={true}/>}
            {saveSuccess && <div className={'success'}>Ihre Änderungen wurden gespeichert.</div>}
        </StyledBaseDataForm>
    );
};
