import styled from 'styled-components';
import { ColexoLogo } from '../ColexoLogo';

export const StyledColexoNavbar = styled.div`
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    padding: 1.625rem 3.75rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    background: var(--Base-Neutral-50, #F6F9FF);

    &.no-navbar {
        display: flex;
        width: 94.5rem;
        padding: 1.25rem 3.75rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
    }

    .navbarFrame {
        display: flex;
        max-width: 100rem;
        justify-content: center;
        align-items: center;
        gap: 6.25rem;
        flex: 1 0 0;
    }

    .inner {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 6.25rem;
        flex: 1 0 0;
        align-self: stretch;
    }
`;
