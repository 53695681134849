import React, { HTMLAttributes, ReactNode } from 'react';
import { StyledDeleteIcon } from './DeleteIcon.styles';

interface DeleteIconProps extends HTMLAttributes<SVGElement> {
    className?: string;
}

export const DeleteIcon: React.FC<DeleteIconProps> = ({ className, ...props }) => {
    return (
        <StyledDeleteIcon className={className}xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
            <path d="M10.625 2.54688H8.75V2.17188C8.75 1.87351 8.63147 1.58736 8.4205 1.37638C8.20952 1.1654 7.92337 1.04688 7.625 1.04688H5.375C5.07663 1.04688 4.79048 1.1654 4.5795 1.37638C4.36853 1.58736 4.25 1.87351 4.25 2.17188V2.54688H2.375C2.27554 2.54688 2.18016 2.58638 2.10984 2.65671C2.03951 2.72704 2 2.82242 2 2.92188C2 3.02133 2.03951 3.11671 2.10984 3.18704C2.18016 3.25737 2.27554 3.29688 2.375 3.29688H2.75V10.0469C2.75 10.2458 2.82902 10.4366 2.96967 10.5772C3.11032 10.7179 3.30109 10.7969 3.5 10.7969H9.5C9.69891 10.7969 9.88968 10.7179 10.0303 10.5772C10.171 10.4366 10.25 10.2458 10.25 10.0469V3.29688H10.625C10.7245 3.29688 10.8198 3.25737 10.8902 3.18704C10.9605 3.11671 11 3.02133 11 2.92188C11 2.82242 10.9605 2.72704 10.8902 2.65671C10.8198 2.58638 10.7245 2.54688 10.625 2.54688ZM5 2.17188C5 2.07242 5.03951 1.97704 5.10984 1.90671C5.18016 1.83638 5.27554 1.79688 5.375 1.79688H7.625C7.72446 1.79688 7.81984 1.83638 7.89017 1.90671C7.96049 1.97704 8 2.07242 8 2.17188V2.54688H5V2.17188ZM9.5 10.0469H3.5V3.29688H9.5V10.0469ZM5.75 5.17188V8.17188C5.75 8.27133 5.71049 8.36671 5.64016 8.43704C5.56984 8.50737 5.47446 8.54688 5.375 8.54688C5.27554 8.54688 5.18016 8.50737 5.10984 8.43704C5.03951 8.36671 5 8.27133 5 8.17188V5.17188C5 5.07242 5.03951 4.97704 5.10984 4.90671C5.18016 4.83638 5.27554 4.79688 5.375 4.79688C5.47446 4.79688 5.56984 4.83638 5.64016 4.90671C5.71049 4.97704 5.75 5.07242 5.75 5.17188ZM8 5.17188V8.17188C8 8.27133 7.96049 8.36671 7.89017 8.43704C7.81984 8.50737 7.72446 8.54688 7.625 8.54688C7.52554 8.54688 7.43016 8.50737 7.35983 8.43704C7.28951 8.36671 7.25 8.27133 7.25 8.17188V5.17188C7.25 5.07242 7.28951 4.97704 7.35983 4.90671C7.43016 4.83638 7.52554 4.79688 7.625 4.79688C7.72446 4.79688 7.81984 4.83638 7.89017 4.90671C7.96049 4.97704 8 5.07242 8 5.17188Z" fill="#FF045F"/>
        </StyledDeleteIcon>
    );
};
