import React, { ReactNode } from 'react';
import { StyledFileUploadContent } from './FileUploadContent.styles';
import { IQuestionData } from '../../../../../types';
import { UploadPlusIcon } from '../../../../../../../../../components/icons/UploadPlusIcon';

interface FileUploadContentProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
    onButtonPress: () => void;
}

// upload-illustration-comp
export const FileUploadContent: React.FC<FileUploadContentProps> = ({ className, onButtonPress, children }) => {
    return <StyledFileUploadContent className={className}>
        <div className={'upload-content'}>
            <UploadPlusIcon className={'upload-plus-icon'}/>
            <div className={'heading-text'}>
                <div className={'title'}>
                    Dokumente hier reinziehen oder <a href={'#'} onClick={() => onButtonPress()}>Ordner durchsuchen</a>
                </div>
                <div className={'subtitle'}>
                    Unbegrenzte Anzahl an Dokumenten, max. 20 MB
                </div>
            </div>
        </div>
    </StyledFileUploadContent>;
};
