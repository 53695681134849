import React, { ReactNode, useState } from 'react';
import { StyledInputContent } from './InputContent.styles';
import { TextWrapper } from '../textwrapper/TextWrapper';
import { useSectionTypeInstanceData } from '../../../SectionTypeInstanceDataProvider';
import { SectionInstance } from '../section/SectionInstance';
import { Preview } from '../../preview/Preview';
import { ISectionInstanceModal } from '../../../types';
import { SectionInstanceModalProvider } from '../section/SectionInstanceModalProvider';

interface InputContentProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
    reportVisible: boolean;
    onDisableReport: () => void;
}

export const InputContent: React.FC<InputContentProps> = ({ className, collapsed, reportVisible, onDisableReport }) => {
    const [modalRequests, setModalRequests] = useState<Set<string>>(new Set());
    const modal = modalRequests.size > 0;
    const modalHandler: ISectionInstanceModal = {
        modal,
        setModal: (token, flag) => {
            setModalRequests(prevRequests => {
                const newRequests = new Set(prevRequests);
                if (flag) {
                    newRequests.add(token);
                } else {
                    newRequests.delete(token);
                }
                return newRequests;
            });
        }
    };
    // console.log(`having section instance modal ${modal}`);
    const sectionTypeInstanceData = useSectionTypeInstanceData();
    return <StyledInputContent className={className}>
        {reportVisible &&
            <React.Fragment>
                <TextWrapper title={'Vorschau Ihres CLX-Reports'} subtitle={'Hier sehen Sie die aktuelle Vorschau Ihres Reports. Bitte prüfen Sie vor dem Versenden alle Angaben auf Richtigkeit. Zum Bearbeitungsmodus gelangen Sie über den Schalter in der linken Seitenleiste.'}/>
                <Preview onEmptyClick={() => onDisableReport()}/>
            </React.Fragment>
        }
        {!reportVisible &&
            <React.Fragment>
                <TextWrapper title={sectionTypeInstanceData.currentDesign?.title || ''} subtitle={sectionTypeInstanceData.currentDesign?.description || ''}/>
                <SectionInstanceModalProvider value={modalHandler}>
                    {sectionTypeInstanceData.sections.map((element, index) => <SectionInstance key={element.instance.sectionInstanceId || (index + 1000)} collapsed={collapsed} sectionData={element} modal={modal}/>)}
                </SectionInstanceModalProvider>
            </React.Fragment>
        }
    </StyledInputContent>;
};

