import styled from 'styled-components';

export const StyledTableHeaderCell = styled.div`
    display: flex;
    height: 2.75rem;
    padding: var(--spacing-lg, 0.75rem) var(--spacing-3xl, 1.5rem);
    align-items: center;
    gap: var(--spacing-lg, 0.75rem);
    align-self: stretch;
    border-bottom: 1px solid var(--Athens-Gray-200, #D8DCE5);
    background: var(--Athens-Gray-100, #E9EBF0);
    cursor: pointer;
    .table-header {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs, 0.25rem);
        white-space: nowrap;
    }
    .rotate-90 {
        transform: rotate(180deg);
    }
`;
