import React, { ReactNode } from 'react';
import { StyledCircleWrapper } from './CircleWrapper.styles';
import { Ellipse25Icon } from '../../../../../../../components/icons/Ellipse25Icon';

interface CircleWrapperProps {
    className?: string;
    iconClassName?: string;
    children?: ReactNode;
}

export const CircleWrapper: React.FC<CircleWrapperProps> = ({ className, iconClassName, children }) => {
    return (
        <StyledCircleWrapper className={className}>
            <Ellipse25Icon className={iconClassName}/>
            {children}
        </StyledCircleWrapper>
    );
};
