import React, { ReactNode } from 'react';
import { FileValueProcessingAlert } from './FileValueProcessingAlert';
import { FileValueDoneAlert } from './FileValueDoneAlert';
import { FileValueErrorAlert } from './FileValueErrorAlert';
import { IFileValue } from '../../../../../../../../serverTypes';

interface FileValueAlertProps {
    className?: string;
    children?: ReactNode;
    hideAlert: () => void;
    alertState: AlertStateEnum;
    errorFileValues: IFileValue[];
    updateState: (alertState: AlertStateEnum) => void;
}

export enum AlertStateEnum {
    NONE, PROCESSING, ERROR, DONE
}

export const FileValueAlert: React.FC<FileValueAlertProps> = ({ className, alertState, errorFileValues, updateState, hideAlert, children }) => {
    if (alertState === AlertStateEnum.PROCESSING) {
        return <FileValueProcessingAlert className={className} onChange={() => hideAlert()} hideAlert={() => hideAlert()} />
    } else if (alertState === AlertStateEnum.DONE) {
        return <FileValueDoneAlert className={className} hideAlert={() => hideAlert()} />
    } else if (alertState === AlertStateEnum.ERROR) {
        return <FileValueErrorAlert className={className} hideAlert={() => hideAlert()} errorFileValues={errorFileValues} updateState={updateState} />
    }
    return undefined;
};
