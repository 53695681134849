import React, { ReactNode } from 'react';
import {
    StyledReportTableColumnTemplate, StyledReportTableHeadTemplate,
    StyledReportTableRowTemplate,
    StyledReportTableTemplate, StyledTableHead,
} from './ReportTableTemplate.styles';
import { IReportTableRow, IReportTableWidget } from '../../../../../types';
import { IReportTableCellValue, IReportTableHeaderValue } from '../../../../../../../../../serverTypes';
import classNames from 'classnames';

interface ReportTableTemplateProps {
    className?: string;
    widget: IReportTableWidget;
    fullPage: boolean;
}

interface ReportTableHeadProps {
    header: IReportTableHeaderValue;
    className?: string;
}

const ReportTableHead: React.FC<ReportTableHeadProps> = ({header, className}) => {
    return <StyledReportTableHeadTemplate className={className}>{header?.headerTitle}</StyledReportTableHeadTemplate>
}

interface ReportTableColumnProps {
    column: IReportTableCellValue;
    className?: string;
}

const ReportTableColumn: React.FC<ReportTableColumnProps> = ({column, className}) => {
    return <StyledReportTableColumnTemplate className={className}>{column.content}</StyledReportTableColumnTemplate>
}

interface ReportTableRowProps {
    row: IReportTableRow;
    fullPage: boolean;
}
const ReportTableRow: React.FC<ReportTableRowProps> = ({row, fullPage}) => {
    return <StyledReportTableRowTemplate>
        {row.columns.map((element, index) => element ? <ReportTableColumn key={index} className={classNames(fullPage && 'fullPage')} column={element}/> : <StyledReportTableColumnTemplate></StyledReportTableColumnTemplate>)}
    </StyledReportTableRowTemplate>;
}

export const ReportTableTemplate: React.FC<ReportTableTemplateProps> = ({ className, widget, fullPage }) => {
    return (
        <StyledReportTableTemplate className={className}>
            <table className={'contents'}>
                <StyledTableHead>
                    <tr>
                        {widget.headers.map((element, index) => element ? <ReportTableHead key={index} className={classNames(fullPage && 'fullPage')} header={element}/> : <StyledReportTableHeadTemplate></StyledReportTableHeadTemplate>)}
                    </tr>
                </StyledTableHead>
                <tbody>
                {widget.rows.map((element, index) => element ? <ReportTableRow key={index} row={element} fullPage={fullPage}/> : undefined )}
                </tbody>
            </table>
        </StyledReportTableTemplate>
    );
};
