import styled from 'styled-components';
import { ReactSelect } from '@baumeister/web-ui-bootstrap';
import { Props } from 'react-select';

export const StyledSelectTemplate = styled(ReactSelect)<Props>`
    flex: 1 0 0;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        pointer: cursor;
    }
`;
