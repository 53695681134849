import React, { ReactNode } from 'react';
import { StyledVector4 } from './Vector4.styles';

interface Vector4Props {
    className?: string;
    children?: ReactNode;
}

export const Vector4: React.FC<Vector4Props> = ({ className, children }) => {
    return (
        <StyledVector4 className={className} xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M3.92151 0.920255C4.51343 3.57897 5.09829 6.23769 5.69021 8.90782C5.04192 8.96487 4.39364 9.01051 3.74535 9.06757L2.4206 3.11113C1.84982 3.95553 1.2861 4.79993 0.715323 5.64433C0.489833 5.11943 0.271389 4.59453 0.0458984 4.06964C0.616671 2.80304 1.1804 1.54785 1.75117 0.28125C2.47697 0.498055 3.19572 0.71486 3.92151 0.920255Z" fill="#CBFFE7"/>
        </StyledVector4>
    );
};
