import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { ISectionTypeInstanceData } from './types';
import { SectionTypeInstanceContent } from './content/SectionTypeInstanceContent';

export const SectionTypeInstanceDataContext = createContext<ISectionTypeInstanceData>({
    currentType: undefined,
    currentDesign: undefined,
    instances: [],
    sections: []
});

export interface SectionTypeInstanceDataProviderProps {
    value: ISectionTypeInstanceData;
    children: ReactNode;
}
export const SectionTypeInstanceDataProvider: React.FC<SectionTypeInstanceDataProviderProps> = ({ children, value }) =>{
    return <SectionTypeInstanceDataContext.Provider value={value}>{children}</SectionTypeInstanceDataContext.Provider>;
};

export const useSectionTypeInstanceData = () => useContext(SectionTypeInstanceDataContext);
