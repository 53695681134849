import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
      font-weight: 400;
      line-height: 1.15;
  }

  body {
    margin: auto;
    min-height: 100vh;
    min-width: 100%;
    background: var(--ory-theme-background-canvas);
    display: flex;
    flex-direction: column;
      line-height: initial !important;
  }

  ul, ol, p, h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  main {
    flex: 1;
  }


  a {
      text-decoration: underline;
  }

  .inputField .field {
      margin-top: 0.75rem;
      border-radius: 0.375rem;
      border: 1px solid #8A9AB1;
      background: inherit;
      width: 27.8rem;
      font-size: 1rem;
      padding-left: 0.70rem;
      padding-top: 0.75rem;
      padding-bottom: 0.4rem;
      font-family: Lato;
  }

  button, .submitButton {
      padding: 0.9rem 1.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.3125rem;
      background: #17a2b8;
      border: 1px white;
      font-family: Lato;
      font-weight: 400;
      cursor: pointer;
  }

  *, ::after, ::before {
      padding: initial;
      box-sizing: initial;
  }

  input {
      display: inline-block;
      padding-right: 2px;
  }
`;

export const StyledOryLayout = styled.div`
    body {
        background: #eee;
        /* This is just a helper in case the element has a transparent background or white colors. */
    }

    & {
        font-size: 16px;
        line-height: 1.15;
    }

    a {
        background-color: transparent;
        color: inherit;
    }

    input {
        font-family: inherit;
        margin: 0;
    }

    .welcome h5, button {
        margin-left: 0;
        margin-right: 0;
    }

    button, input {
        font-size: 100%;
        line-height: 1.15;
        overflow: visible;
    }

    button {
        margin-bottom: 0;
        text-transform: none;
    }

    [type=submit], button {
        appearance: button;
    }

    &.form-page {
        background-color: #F5F9F6;  /* Light sage/mint color that represents sustainability */
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    .form-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        width: 28rem;
    }

    .logo {
        height: 2.5rem;
        width: 6rem;
        margin-bottom: 2rem;
    }

    .form, .welcome {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .welcome {
        text-align: center;
        margin-bottom: 2rem;
    }

    .welcome h5 {
        color: #1a384e;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.4rem;
        margin-bottom: 1rem;
        margin-top: 0;
        padding: 0;
    }

    .inputField .label, .welcome .subteaser, .welcome h5 {
        font-family: Lato;
        overflow-wrap: break-word;
    }

    .welcome .subteaser {
        color: rgba(26, 56, 78, .8);
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: .9rem;
    }

    .form {
        margin-top: .15rem;
    }

    .form, .inputField {
        position: relative;
    }

    .inputField .label {
        color: #1a384e;
        display: block;
        font-size: 1rem;
        font-weight: 700;
        height: .875rem;
        left: .5px;
        line-height: 1.5rem;
    }

    .inputField .field {
        background-attachment: inherit;
        background-clip: inherit;
        background-color: inherit;
        background-image: inherit;
        background-origin: inherit;
        background-position: inherit;
        background-repeat: inherit;
        background-size: inherit;
        border: 1px solid #8a9ab1;
        border-radius: .375rem;
        font-family: Lato;
        font-size: 1rem;
        line-height: 1.88rem;
        margin-top: .75rem;
        padding-bottom: .4rem;
        padding-left: .7rem;
        padding-top: .75rem;
        width: 27.8rem;
    }

    .inputField .field {
        margin-top: 0.75rem;
        border-radius: 0.375rem;
        border: 1px solid #8A9AB1;
        background: inherit;
        width: 27.8rem;
        line-height: 1.88rem;
        font-size: 1rem;
        padding-left: 0.70rem;
        padding-top: 0.75rem;
        padding-bottom: 0.4rem;
        font-family: Lato;
    }

    .inputField .field:focus {
        background-color: #e3f2ff;
    }

    .inputField .helper {
        color: rgba(26, 56, 78, .8);
        font-family: Lato;
        font-size: .75rem;
        position: absolute;
        right: .2rem;
        top: 0;
    }

    .email {
        margin-top: 3.75rem;
    }

    .password {
        margin-top: 1.3rem;
    }

    .submitButton {
        margin-top: 1.5rem;
    }

    .submitButton, button {
        align-items: center;
        background-color: #75fb97;
        border: 1px initial;
        border-color: #fff;
        border-radius: .3125rem;
        column-gap: .625rem;
        cursor: pointer;
        font-family: Lato;
        font-weight: 400;
        justify-content: center;
        padding: .9rem 1.5rem;
        row-gap: .625rem;
    }

    button:focus {
        border: 1px solid rgba(26, 56, 78, .8);
    }

    .buttonHelper {
        bottom: .1rem;
        color: rgba(26, 56, 78, .8);
        font-family: Lato, serif;
        font-size: .75rem;
        font-weight: 400;
        margin-left: auto;
        position: absolute;
        right: -1.1rem;
        text-align: right;
    }

    .buttonHelper a {
        text-decoration: underline;
    }


    html {
        font-size: 16px;
        /* This is IMPORTANT since some copied values use "REM" units */
    }

    .errorMessage.form {
        margin-top: 1rem;
        text-align: center;
    }

    .errorMessage {
        width: 27.8rem;
        font-family: Lato, serif;
        font-weight: 400;
        color: red;
    }

    .oryAuthCard > div > :first-child, .oryAuthCard hr {
        display: none;
    }

    &.registration-page .welcome .subteaser, &.recovery-page .welcome .subteaser  {
        width: 28rem;
        line-height: 1.8rem;
    }

    &.registration-page .welcome h5 {
        margin: 0 0 1rem;
    }

    &.registration-page .form, &.recovery-page .form {
        margin-top: 3.5rem;
    }


    &.registration-page .inputField .field {
        margin-bottom: 1.2rem;
    }
    &.registration-page .email {
        margin-top: 0;
    }
    &.registration-page .submitButton {
        margin-top: 0.4rem;
    }

    button, .submitButton {
        padding: 0.9rem 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.3125rem;
        background: #17a2b8;
        border: 1px white;
        font-family: Lato;
        font-weight: 400;
        cursor: pointer;
    }

    a.submitButton {
        display: block;
    }

    &.registration-page button {
        padding: 0.9rem 1rem 0.9rem 1rem;
    }

    button:focus {
        border: 1px solid rgba(26, 56, 78, 0.80);
    }

    .buttonHelper {
        position: absolute;
        font-family: Lato, serif;
        font-weight: 400;
        font-size: 0.75rem;
        color: rgba(26, 56, 78, 0.80);
        text-align: right;
        margin-left: auto;
        right: -1.1rem;
        bottom: 0.1rem;
    }

    &.registration-page .buttonHelper {
        right: -3.9rem;
        bottom: 2.2rem;
    }

    &.registration-page .password {
        margin-top: 0;
    }
    .submitNotice {
        margin-top: 0.8rem;
        font-family: Lato, serif;
        font-weight: 400;
        color: rgba(26, 56, 78, 0.80);
    }

    a {
        color: inherit;
    }

    .errorMessage {
        width: 27.8rem;
        font-family: Lato, serif;
        font-weight: 400;
        color: red;
    }

    .errorMessage.form {
        margin-top: 1rem;
        text-align: center;
    }

    .errorMessage.form-field {
        margin-top: -1rem;
        margin-bottom: 1rem;
        margin-left: 0.4rem;
    }

    &.recovery-page .id-1070005 {
        margin-right: 4rem;
    }
`;
