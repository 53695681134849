import React, { ReactNode } from 'react';
import { StyledTaskWrapper } from './TaskWrapper.styles';

interface TaskWrapperProps {
    className?: string;
    children?: ReactNode;
    count: number;
    text: string;
}

export const TaskWrapper: React.FC<TaskWrapperProps> = ({ className, count, text, children }) => {
    return (
        <StyledTaskWrapper className={className}>
            <div className={'status-btn'}>
                {children}
            </div>
            <div className={'task'}>
                <span className={'count'}>{count}</span>
                &nbsp;
                <span className={'text'}>{text}</span>
            </div>
        </StyledTaskWrapper>
    );
};
