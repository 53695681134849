import React, { ReactNode } from 'react';
import { StyledTeamWrapper } from './TeamWrapper.styles';
import { Button } from '../../../../../../../../components/button/Button';
import { TeamMemberPhoto } from './team-member-photo/TeamMemberPhoto';

interface TeamWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const TeamWrapper: React.FC<TeamWrapperProps> = ({ className, children }) => {
    return (
        <StyledTeamWrapper className={className}>
            <div className={'title'}>
                Mein Team
            </div>
            <div className={'team-member-list-wrapper'}>
                <TeamMemberPhoto src={'/head1.png'}/>
                <TeamMemberPhoto src={'/head2.png'}/>
                <TeamMemberPhoto src={'/head3.png'}/>
                <TeamMemberPhoto src={'/head4.png'}/>
            </div>
            <Button caption={'Mein Team anzeigen'} variant={'primary'} className={'button'}/>
        </StyledTeamWrapper>
    );
};
