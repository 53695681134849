import React, { ReactNode } from 'react';
import { StyledTransactionSection } from './TransactionSection.styles';
import { CommunicationComponent } from '../workflow-section/communication-component/CommunicationComponent';
import { DocRoomCard } from './doc-room-card/DocRoomCard';
import { ContentWrapper } from './doc-room-card/content-wrapper/ContentWrapper';
import { StatusWrapper } from './doc-room-card/status-wrapper/StatusWrapper';
import { InprogressItemWrapper } from './doc-room-card/inprogress-item-wrapper/InprogressItemWrapper';
import { Overlay } from '../overlay/Overlay';

interface TransactionSectionProps {
    className?: string;
    children?: ReactNode;
}

export const TransactionSection: React.FC<TransactionSectionProps> = ({ className, children }) => {
    return (
        <StyledTransactionSection className={className}>
            <CommunicationComponent title={'Transaktionsraum'}>
                <div className={'communication-wrapper'}>
                    <DocRoomCard>
                        <ContentWrapper title={'In Bearbeitung'}/>
                        <StatusWrapper>
                            <InprogressItemWrapper title={'Anlage 1'} subtitle={'Noch zu erstellen'} buttonText={'Erstellen'}/>
                            <InprogressItemWrapper title={'NDA'} subtitle={'Noch zu erstellen'} buttonText={'Erstellen'}/>
                            <InprogressItemWrapper title={'NDA'} subtitle={'RS hat um Kommentar gebeten'} buttonText={'Kommentar'}/>
                            <InprogressItemWrapper title={'NDA'} subtitle={'Freigabe wird benötigt'} buttonText={'Freigabe'}/>
                            <InprogressItemWrapper title={'Anlage 2'} subtitle={'Freigabe erteilt'} buttonText={'Freigabe'} disabled={true}/>
                            <InprogressItemWrapper title={'Anlage 5'} subtitle={'Von Gegenseite zur Überprüfung ermittelt'} buttonText={'Freigabe'} disabled={true}/>
                        </StatusWrapper>
                    </DocRoomCard>
                    <DocRoomCard>
                        <ContentWrapper title={'In Verhandlung'}/>
                        <StatusWrapper>
                            <InprogressItemWrapper title={'Anlage 1'} subtitle={'An Gegenseite zur Überprüfung übermittelt'} buttonText={'Erstellen'} button={false}/>
                            <InprogressItemWrapper title={'Anlage 5'} subtitle={'An Gegenseite zur Überprüfung übermittelt'} buttonText={'Erstellen'} button={false}/>
                        </StatusWrapper>
                    </DocRoomCard>
                    <DocRoomCard>
                        <ContentWrapper title={'In Verhandlung'}/>
                        <StatusWrapper>
                            <InprogressItemWrapper title={'NDA'} subtitle={'Bitte um Unterzeichnung'} buttonText={'Erstellen'} button={false}/>
                            <InprogressItemWrapper title={'Anlage 4'} subtitle={'Abgestimmt'} buttonText={'Erstellen'} button={false}/>
                            <InprogressItemWrapper title={'NDA'} subtitle={'Unterzeichnet'} buttonText={'Erstellen'} button={false}/>
                        </StatusWrapper>
                    </DocRoomCard>
                </div>
            </CommunicationComponent>
            <Overlay/>
        </StyledTransactionSection>
    );
};
