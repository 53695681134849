import { UiNode, UiNodeInputAttributes, UiText } from '@ory/client';
import { isUiNodeInputAttributes } from '@ory/integrations/ui';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { OryButton } from './components/OryButton';
import { OryPageEnum } from './types';
import { OryField } from './components/OryField';
import { sdk } from './sdk';

export interface IOryUIOptions {
    submitButtonPostfix: (label: UiText) => ReactNode;
    passwordHelper?: ReactNode;
}
export const mapUINode = (page: OryPageEnum, node: UiNode, key: number, options: IOryUIOptions) => {
    // other node types are also supported
    // if (isUiNodeTextAttributes(node.attributes)) {
    // if (isUiNodeImageAttributes(node.attributes)) {
    // if (isUiNodeAnchorAttributes(node.attributes)) {
    if (isUiNodeInputAttributes(node.attributes)) {
        const attrs = node.attributes as UiNodeInputAttributes;
        const nodeType = attrs.type;

        switch (nodeType) {
            case 'button':
            case 'submit':
                return (<OryButton page={page} node={node} attrs={attrs} key={key} options={options} />);
        }
        if(attrs.type === 'hidden') {
            return (<input
                name={attrs.name}
                type={attrs.type}
                value={attrs.value}
                required={attrs.required}
                disabled={attrs.disabled}
                key={key}
            />);
        }
        return (<OryField key={key} attrs={attrs} options={options} messages={node.messages}/>);
    }
};

export const translateOryLabel = (label: UiText | undefined) => {
    if (!label) {
        return '';
    }
    switch (label.id) {
        case 1070005:
            return 'Confirm Code';
        case 1070008:
            return 'Resend Email';
        case 1010001:
            return 'Sign In';
        case 1010022:
            return 'Sign In';
        case 1070003:
            return "Reset Password";
        case 1040001:
            return "Register";
        case 1070009:
            return "Confirm Code";
    }
    return `Blubb ${label.text} ${label.id}`;
}

export const mapIdentifierToLabel = (name: string) => {
    switch (name) {
        case 'identifier':
            return 'Email Adresse';
        case 'password':
            return 'Passwort';
        case 'traits.name.first':
            return "Vorname";
        case 'traits.name.last':
            return "Nachname";
        case 'traits.email':
            return "Email Adresse";
        case 'traits.website':
            return "Website";
        case 'email':
            return "Email Adresse";
        case 'code':
            return "Code";

        default:
            return `unbekannt ${name}`;
    }
};
export const mapIdentifierToClass = (name: string) => {
    switch (name) {
        case 'identifier':
            return 'email';
        default:
            return name;
    }
};

export const getSelfServiceLogoutUrl = async() => {
    const logoutData = await sdk.createBrowserLogoutFlow();
    if (logoutData.status === 200) {
        return logoutData.data.logout_url;
    } else {
        console.error(`couldn't fetch logout url`, logoutData);
        return '/';
    }
}
