import styled from 'styled-components';

export const StyledTablePagination = styled.div`
    display: flex;
    padding: var(--spacing-lg, 0.75rem) var(--spacing-3xl, 1.5rem) var(--spacing-xl, 1rem) var(--spacing-3xl, 1.5rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-lg, 0.75rem);
    align-self: stretch;
    .previous-button-wrap {
        display: flex;
        height: 2.25rem;
        align-items: center;
        flex: 1 0 0;
    }
    .pagination-numbers {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xxs, 0.125rem);
    }
    .next-button-wrap {
        display: flex;
        height: 2.25rem;
        justify-content: flex-end;
        align-items: center;
        flex: 1 0 0;
    }

    button {
        display: flex;
        padding: var(--spacing-md, 0.5rem) var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xs, 0.25rem);
        border-radius: var(--radius-sm, 0.375rem);
        border: 1px solid var(--Base-Neutral-600, #1A384E);

        /* Shadows/shadow-xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        background-color: transparent;
    }
    button svg {
        width: 1.25rem;
        height: 1.25rem;
    }
    button .text {
        display: flex;
        padding: 0rem var(--spacing-xxs, 0.125rem);
        justify-content: center;
        align-items: center;
        color: var(--Base-Neutral-600, #1A384E);

        /* Paragraph XS - Bold */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.125rem */
    }
`;
