import styled from 'styled-components';

export const StyledAccountUserWrapper = styled.div`
    display: flex;
    padding: var(--spacing-sm, 0.375rem) var(--spacing-lg, 0.75rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 0.5rem);
    border-radius: var(--radius-sm, 0.375rem);

    .signOut {
        display: flex;
        padding: var(--spacing-xs, 0.25rem) var(--spacing-sm, 0.375rem);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-right: 1px solid var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }
    .signOutIcon {
        width: 1.50006rem;
        height: 1.5rem;
        fill: var(--Base-Neutral-600, #1A384E);
    }
    .bellRinging {
        display: flex;
        padding: var(--spacing-xs, 0.25rem) var(--spacing-sm, 0.375rem);
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
    }
    .bellRingingIcon {
        width: 1.49975rem;
        height: 1.62494rem;
        fill: var(--Base-Neutral-600, #1A384E);
    }
    .userCircle {
        display: flex;
        padding: var(--spacing-xs, 0.25rem) var(--spacing-sm, 0.375rem);
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.1875rem;
    }
    .userCircleIcon {
        width: 1.625rem;
        height: 1.625rem;
        fill: var(--Base-Neutral-600, #1A384E);
    }
    .line {
        width: 0.0625rem;
        height: 1.72088rem;
        background: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }
    .signOut {
        display: flex;
        position: relative;
        padding: 0.25rem 0.375rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        border-right: none;
    }
    .avatar {
        border-right: none;
    }
`;
