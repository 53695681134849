import {
    IBaseValue,
    IDesignBaseQuestion,
    IDesignConstraint,
    IDesignSection,
    IDesignSectionType,
    IQuestionInstance,
    IReportBaseWidgetValue,
    IReportChartDataValue,
    IReportChartWidgetValue,
    IReportDesignBaseWidget,
    IReportDesignChartWidget,
    IReportDesignImageWidget,
    IReportDesignPage,
    IReportDesignSection,
    IReportDesignTableWidget,
    IReportDesignTextWidget,
    IReportFileSourceValue,
    IReportImageWidgetValue,
    IReportPageValue,
    IReportTableCellValue,
    IReportTableHeaderValue,
    IReportTableWidgetValue,
    IReportTextWidgetValue,
    ISectionInstance,
    ISectionTypeInstance,
} from '../../../../serverTypes';
import { IEditableOptionData } from '@baumeister/web-cen-protocol';

export interface ISectionTypeInstanceExt extends ISectionTypeInstance {
    nextLink: string | null | undefined;
    thisLink: string;
    prevLink: string | null | undefined;
}

export interface ISectionTypeData {
    link: string;
    instance: ISectionTypeInstanceExt;
    design: IDesignSectionType | undefined;
}

export interface IValueData {
    link: string;
    value: IBaseValue;
}

export type QuestionDataType = 'file' | 'select' | 'text' | 'numeric' | 'date' | 'image' | 'prompt' | 'table';

export type ConstraintOperationType = 'EQ' | 'LT' | 'LE' | 'GT' | 'GE' | 'NE';

export interface IQuestionData {
    type: QuestionDataType;
    design: IDesignBaseQuestion;
    instance: IQuestionInstance;
    instanceLink: string;
    values: IValueData[];
    columns: IQuestionData[];
    selectOptions: IEditableOptionData[];
}

export interface ISectionData {
    instance: ISectionInstance;
    design: IDesignSection | undefined;
    questions: IQuestionData[];
}
export interface ISectionTypeInstanceData {
    currentType: ISectionTypeInstanceExt | undefined;
    currentDesign: IDesignSectionType | undefined;
    instances: ISectionTypeData[];
    sections: ISectionData[];
}

export interface ISectionInstanceModal {
    modal: boolean;
    setModal: (token: string, flag: boolean) => void;
}

export interface IReportPage {
    design: IReportDesignPage | undefined;
    value: IReportPageValue;
    sections: IReportSection[];
}

export interface IReportSection {
    design: IReportDesignSection | undefined;
    widgets: IReportWidget<IReportDesignBaseWidget, IReportBaseWidgetValue>[];
}

export enum ReportWidgetType {
    TEXT, TABLE, CHART, IMAGE
}
export interface IReportWidget<TDesign extends IReportDesignBaseWidget, TValue extends IReportBaseWidgetValue> {
    design: TDesign | undefined;
    value: TValue;
    type: ReportWidgetType;
    fileSources: IReportFileSourceValue[];
}

export interface IReportTextWidget extends IReportWidget<IReportDesignTextWidget, IReportTextWidgetValue> {
}

export interface IReportImageWidget extends IReportWidget<IReportDesignImageWidget, IReportImageWidgetValue> {

}

export interface IReportTableRow {
    columns: (IReportTableCellValue | undefined)[];
}
export interface IReportTableWidget extends IReportWidget<IReportDesignTableWidget, IReportTableWidgetValue> {
    rows: (IReportTableRow | undefined)[];
    columnCount: number;
    headers: (IReportTableHeaderValue | undefined)[];
}

export interface IReportChartSeries {
    values: IReportChartDataValue[];
}
export interface IReportChartWidget extends IReportWidget<IReportDesignChartWidget, IReportChartWidgetValue> {
    series: (IReportChartSeries | undefined)[];
}
