import React, { ReactNode } from 'react';
import { StyledCheckCircleIcon } from './CheckCircleIcon.styles';

interface CheckCircleIconProps {
    className?: string;
    children?: ReactNode;
}

export const CheckCircleIcon: React.FC<CheckCircleIconProps> = ({ className, children }) => {
    return <StyledCheckCircleIcon className={className} xmlns="http://www.w3.org/2000/svg" width="154" height="154" viewBox="0 0 154 154" fill="none">
<path d="M104.467 59.1577C104.915 59.6046 105.27 60.1354 105.512 60.7196C105.754 61.3038 105.879 61.9301 105.879 62.5625C105.879 63.1949 105.754 63.8212 105.512 64.4054C105.27 64.9896 104.915 65.5204 104.467 65.9674L70.7799 99.6549C70.3329 100.102 69.8022 100.457 69.2179 100.699C68.6337 100.942 68.0075 101.066 67.375 101.066C66.7426 101.066 66.1164 100.942 65.5321 100.699C64.9479 100.457 64.4171 100.102 63.9702 99.6549L49.5327 85.2174C48.6297 84.3143 48.1224 83.0896 48.1224 81.8125C48.1224 80.5354 48.6297 79.3107 49.5327 78.4077C50.4357 77.5046 51.6605 76.9973 52.9375 76.9973C54.2146 76.9973 55.4394 77.5046 56.3424 78.4077L67.375 89.4463L97.6577 59.1577C98.1046 58.7102 98.6354 58.3552 99.2196 58.1131C99.8039 57.8709 100.43 57.7462 101.063 57.7462C101.695 57.7462 102.321 57.8709 102.905 58.1131C103.49 58.3552 104.02 58.7102 104.467 59.1577ZM139.563 77C139.563 89.3737 135.893 101.47 129.019 111.758C122.144 122.046 112.373 130.065 100.942 134.8C89.5099 139.535 76.9306 140.774 64.7947 138.36C52.6588 135.946 41.5112 129.988 32.7617 121.238C24.0121 112.489 18.0536 101.341 15.6396 89.2053C13.2257 77.0694 14.4646 64.4902 19.1998 53.0584C23.935 41.6266 31.9538 31.8556 42.2422 24.9812C52.5305 18.1067 64.6263 14.4375 77 14.4375C93.5873 14.455 109.49 21.052 121.219 32.781C132.948 44.5099 139.545 60.4128 139.563 77ZM129.938 77C129.938 66.53 126.833 56.295 121.016 47.5895C115.199 38.884 106.931 32.0988 97.2583 28.0921C87.5853 24.0854 76.9413 23.0371 66.6724 25.0797C56.4036 27.1223 46.971 32.1641 39.5676 39.5675C32.1641 46.971 27.1223 56.4035 25.0797 66.6724C23.0371 76.9413 24.0854 87.5853 28.0922 97.2583C32.0989 106.931 38.884 115.199 47.5895 121.016C56.2951 126.833 66.53 129.938 77 129.938C91.035 129.922 104.491 124.339 114.415 114.415C124.339 104.491 129.922 91.035 129.938 77Z" fill="#0FD843"/>
    </StyledCheckCircleIcon>;
};
