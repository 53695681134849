import React, { ReactNode, useEffect, useState } from 'react';
import { StyledImageTemplate } from './ImageTemplate.styles';
import { IQuestionData } from '../../../../types';
import { Dropzone, DropzoneEnglish, ExtFile, FileItemEnglish, FileMosaic } from '@files-ui/react';
import { useCenPageContext, useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ColexoProtocolMessage, InfoDeleteFileValueMessage } from '../../../../../../../../protocol';
import { extractExtFiles } from '../file/FileUploadTemplate';

interface ImageTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

DropzoneEnglish.defaultLabel = "Ziehen Sie Ihre Dateien hierher";
DropzoneEnglish.uploadingMessage = function (amountOfFiles) { return "".concat(`${amountOfFiles}`, " Dateien werden hochgeladen"); };
DropzoneEnglish.uploadFinished = function (uploaded, rejected) { return "Hochgeladene Dateien: ".concat(`${uploaded}`, ", Abgelehnte Dateien: ").concat(`${rejected}`); };
DropzoneEnglish.noFilesMessage = "Es gibt keine gültige Datei zum Hochladen";
DropzoneEnglish.footer = {
    acceptAll: "Alle Dateitypen werden akzeptiert",
    acceptCustom: function (accept) { return "Erlaubte Typen: ".concat(`${accept}`); }
};
DropzoneEnglish.header = {
    uploadFilesMessage: "Dateien hochladen",
    maxSizeMessage: function (maxFileSize) { return "Maximale Dateigröße: ".concat(`${maxFileSize}`); },
    validFilesMessage: function (numberOfValidFiles, maxFiles) { return "Dateien ".concat(`${numberOfValidFiles}`, "/").concat(`${maxFiles}`); }
};
DropzoneEnglish.fakeuploadsuccess = "Datei wurde erfolgreich hochgeladen";
DropzoneEnglish.fakeUploadError = "Fehler beim Hochladen. Bitte versuchen Sie es später erneut.";

FileItemEnglish.fullInfoLayer = {
    name: "Name: ",
    size: "Größe: ",
    type: "Typ: "
};
FileItemEnglish.status = {
    preparing: "Vorbereitung",
    uploading: "Wird hochgeladen",
    success: "Erfolgreich",
    valid: "Gültig",
    denied: "Ungültig",
    error: "Fehler",
    aborted: "Abgebrochen"
};

export const ImageTemplate: React.FC<ImageTemplateProps> = ({ className, questionData, children }) => {
    const [idMap, setIdMap] = useState<Map<number|string, string>>(new Map());
    const [files, setFiles] = useState<ExtFile[]>(extractExtFiles(questionData, idMap));
    const { cenSocketProtocol } = useWebSocketContext();
    const { channel, pageData } = useCenPageContext();

    const uploadBaseUrl = (questionData.design as any).uploadBaseUrl;
    const downloadBaseUrl = (questionData.design as any).downloadBaseUrl;
    const pageLink = pageData.dataPath;

    useEffect(() => {
        if (files.length > 1) {
            const filesToRemove = files.slice(1);
            filesToRemove.forEach((file) => handleDelete(file));
            setFiles([files[0]]);
        }
    }, [files]);

    const handleDelete = (file: ExtFile) => {
        let entityPath:string|undefined = file.extraData?.valueLink as string;
        if (!entityPath) {
            entityPath = idMap.get(file.id || '0');
        }
        if (entityPath) {
            const deleteMessage:InfoDeleteFileValueMessage = {
                type: ColexoProtocolMessage.INFO_DELETE_FILE_VALUE,
                payload: {
                    entityPath,
                    channel,
                    pageLink
                }
            };
            cenSocketProtocol.request(deleteMessage);
        }
    }
    const updateFiles = (incomingFiles: ExtFile[]) => {
        setFiles(incomingFiles);
    };
    const removeFile = (id: string|number|undefined) => {
        const deleteFiles = files.filter((x) => x.id === id);
        deleteFiles.forEach((file) => handleDelete(file));
        setFiles(files.filter((x) => x.id !== id));
    };
    return (<StyledImageTemplate className={className}>
            <Dropzone
                onChange={updateFiles}
                disabled={questionData.instance.locked}
                value={files}
                maxFiles={1}
                accept="image/*"
                uploadConfig={{
                    url: uploadBaseUrl + questionData.instanceLink,
                    method: 'POST',
                    autoUpload: true,
                }}
                headerConfig={{
                    validFilesCount: false,
                    cleanFiles: false,
                    deleteFiles: false
                }}
                onUploadFinish={(files) => {
                    for (const file of files) {
                        idMap.set(file.id || '0', file.serverResponse?.payload?.data as string);
                        file.extraData = {
                            valueLink: file.serverResponse?.payload?.data as string
                        }
                    }
                    setIdMap(idMap);
                }}
                maxFileSize={5 * 1024 * 1024} // 5MB
                width="300px"
                height="300px"
            >
                {files.map((file) => (
                    <FileMosaic
                        key={file.id}
                        imageUrl={`${downloadBaseUrl}${idMap.get(file.id || '0')}`}
                        {...file}
                        onDelete={removeFile}
                        downloadUrl={`${downloadBaseUrl}${idMap.get(file.id || '0')}`}/>
                ))}
            </Dropzone>
        </StyledImageTemplate>
    );
}

