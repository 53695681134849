import React, { ReactNode, useMemo } from 'react';
import { StyledTable } from './Table.styles';
import {
    createTableModel,
    IEntityData,
    ITableColumnModel,
    useCenPageContext,
    useEntitySelector,
    useWebSocketContext,
} from '@baumeister/web-cen-protocol';
import { TableHeaderCell } from './row/head/TableHeaderCell';
import { TableColumn } from './row/column/TableColumn';
import { TableCell } from './row/cell/TableCell';
import { TablePagination } from './pagination/TablePagination';
import { selectPageChildCursor } from '@baumeister/web-cen-protocol';
import { selectPageChildCursorData } from '@baumeister/web-cen-protocol';

interface TableProps {
    className?: string;
    children?: ReactNode;
    name: string;
}

export const Table: React.FC<TableProps> = ({ className, name, children }) => {
    const { pageData, channel } = useCenPageContext();
    const { cenSocketProtocol } = useWebSocketContext();
    const cursor = useEntitySelector((state) => selectPageChildCursor(state, channel, name));
    const tableModel = useMemo(() => cursor ? createTableModel(cursor.listPageData.dictionary, cursor.cursor, children) : undefined, [cursor?.listPageData.dictionary, cursor, children]);
    if (cursor === undefined || tableModel === undefined) {
        return undefined;
    }
    const cursorState = cursor.cursor;
    const dictionary = cursor.listPageData.dictionary;
    const data = useEntitySelector((state) =>
        selectPageChildCursorData(state, channel, name) as IEntityData[]
    );
    const sortColumn = (attribute: string) => {
        const ascending = cursorState.sorting && cursorState.sorting.attribute === attribute ? !cursorState.sorting.ascending : true;
        cenSocketProtocol.requestCursorSorting(cursorState.channel, cursorState.cursorId, attribute, ascending);
    };
    return (
        <StyledTable className={className}>
            <div className={'content'}>
                {tableModel.columns.map((column: ITableColumnModel) => (
                    <TableColumn key={column.name} className={column.name}>
                        <TableHeaderCell key={column.name}
                                         column={column}
                                         sortHandler={() => sortColumn(column.name)}
                         />
                        {data.map((childRow: unknown, index: number) => (
                            <TableCell key={index} className={`cell-${column.name}`}>
                                {column.render(childRow as IEntityData)}
                            </TableCell>
                        ))}
                    </TableColumn>
                ))}
            </div>
            <TablePagination cursor={cursor.cursor}/>
        </StyledTable>
    );
};
