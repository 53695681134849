import React, { ReactNode } from 'react';
import { StyledBaseDataContent } from './BaseDataContent.styles';
import { TopBar } from '../../document/documentSectionType/content/right/headingcomponent/topbar/TopBar';
import { BaseDataForm } from './form/BaseDataForm';

interface BaseDataContentProps {
    className?: string;
    children?: ReactNode;
}

export const BaseDataContent: React.FC<BaseDataContentProps> = ({ className, children }) => {
    return (
        <StyledBaseDataContent className={className}>
            <div className={'text-wrapper'}>
                <div className={'title'}>Ihre Stammdaten</div>
                <div className={'subtitle'}>Hier finden Sie eine Übersicht über die Stammdaten Ihres Unternehmens.</div>
            </div>
            <TopBar disableDownload={true}/>
            <BaseDataForm/>
            {children}
        </StyledBaseDataContent>
    );
};
