import React, { ReactNode } from 'react';
import { StyledAnalyse } from './Analyse.styles';
import { ContentWrapper } from '../doc-room-wrapper/doc-room-card/content-wrapper/ContentWrapper';
import { ClxReportWrapper } from './content-frame/ClxReportWrapper';
import { UploadWrap } from './upload-wrap/UploadWrap';

interface AnalyseProps {
    className?: string;
    children?: ReactNode;
}

export const Analyse: React.FC<AnalyseProps> = ({ className, children }) => {
    return (
        <StyledAnalyse className={className}>
            <ContentWrapper>
                <div className={'title'}>
                    Analyse
                </div>
                <div className={'description'}>
                    Hier können Sie Ihren CLX-Report herunterladen oder mit Interessierten teilen.
                </div>
            </ContentWrapper>
            <div className={'frame4384'}>
                <ClxReportWrapper/>
                <UploadWrap/>
            </div>
        </StyledAnalyse>
    );
};
