import React, { ReactNode } from 'react';
import { StyledTextWrapper } from './TextWrapper.styles';

interface TextWrapperProps {
    className?: string;
    children?: ReactNode;
    title: string;
    subtitle: string;
}

export const TextWrapper: React.FC<TextWrapperProps> = ({ className, title, subtitle, children }) => {
    return <StyledTextWrapper className={className}>
        <div className={'title'}>
            {title}
        </div>
        <div className={'subtitle'}>
            {subtitle}
        </div>
    </StyledTextWrapper>;
};
