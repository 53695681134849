import styled from 'styled-components';

export const StyledRightContent = styled.div`
    display: flex;
    margin-left: 32.5rem;
    min-width: 35.875rem;
    padding: 2.5rem 3.75rem;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    &.collapsed {
        margin-left: 10rem;
    }
`;
