import React, { ReactNode } from 'react';
import { StyledSmallCircleText } from './SmallCircleText.styles';

interface SmallCircleTextProps {
    className?: string;
    count: number;
}

export const SmallCircleText: React.FC<SmallCircleTextProps> = ({ className, count }) => {
    return (
        <StyledSmallCircleText className={className}>
            {count} <div className={'docs'}>Docs</div>
        </StyledSmallCircleText>
    );
};
