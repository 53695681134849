import React, { ReactNode } from 'react';
import { StyledContentLeft } from './ContentLeft.styles';
import { Button } from '../../../../../../components/button/Button';
import { CircleIcon } from '../../../../info/sectiontype/content/left/progresscircle/CircleIcon';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store';
import { IDocumentSectionTypeInstance, ISectionTypeInstance } from '../../../../../../serverTypes';
import { selectPageChildCursorData } from '@baumeister/web-cen-protocol';

interface ContentLeftProps {
    className?: string;
    children?: ReactNode;
    percentage: number;
}

export const ContentLeft: React.FC<ContentLeftProps> = ({ className, percentage, children }) => {
    const navigate = useNavigate();
    const documentSectionTypeInstance: IDocumentSectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "documentSectionTypeInstance")
    );
    let files = 0;
    documentSectionTypeInstance.forEach((instance) => files += instance?.documentsUploaded || 0);
    // const documentUrl = documentSectionTypeInstance.length > 0 ? documentSectionTypeInstance[0]._link : undefined;
    const sectionTypeInstance: ISectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "sectionTypeInstance")
    );
    const companyUrl = sectionTypeInstance?.length > 0 ? sectionTypeInstance[0]._link : undefined;
    const show = false;
    return (
        <StyledContentLeft className={className}>
            <div className={'progress-circle-wrapper'}>
                <CircleIcon value={percentage} scale={100} small={false} className={'circle'}/>
                <div className={'text-wrapper'}>
                    <div className={'percentage'}>{percentage}%</div>
                    <div className={'description'}>befüllt</div>
                </div>
            </div>
            <div className={'show-files'}>
                {show && files > 0 && <React.Fragment>Alle {files} Dateien anzeigen</React.Fragment>}
            </div>
            <Button caption={'Zum CLX-Raum'} variant={'primary'} onClick={() => companyUrl && navigate(companyUrl)}/>
            {children}
        </StyledContentLeft>
    );
};
