import React from 'react';
import { StyledReportSection } from './ReportSection.styles';
import {
    IReportChartWidget, IReportImageWidget,
    IReportSection,
    IReportTableWidget,
    IReportTextWidget,
    IReportWidget,
    ReportWidgetType,
} from '../../../../types';
import { IReportBaseWidgetValue, IReportDesignBaseWidget } from '../../../../../../../../serverTypes';
import { ReportChartTemplate } from '../template/chart/ReportChartTemplate';
import { ReportTableTemplate } from '../template/table/ReportTableTemplate';
import { ReportTextTemplate } from '../template/text/ReportTextTemplate';
import { ReportImageTemplate } from '../template/image/ReportImageTemplate';

interface ReportSectionProps {
    className?: string;
    section: IReportSection;
    fullPage: boolean;
}

interface ReportWidgetProps {
    widget: IReportWidget<IReportDesignBaseWidget, IReportBaseWidgetValue>;
    fullPage: boolean;
}

const ReportWidget: React.FC<ReportWidgetProps> = ({widget, fullPage}) => {
    if (widget.type === ReportWidgetType.CHART) {
        return <ReportChartTemplate widget={widget as IReportChartWidget} fullPage={fullPage} />
    } else if (widget.type === ReportWidgetType.TABLE) {
        return <ReportTableTemplate widget={widget as IReportTableWidget} fullPage={fullPage} />
    } else if (widget.type === ReportWidgetType.TEXT) {
        return <ReportTextTemplate widget={widget as IReportTextWidget} fullPage={fullPage} />
    } else if (widget.type === ReportWidgetType.IMAGE) {
        return <ReportImageTemplate widget={widget as IReportImageWidget} fullPage={fullPage} />
    } else {
        return undefined;
    }
}
export const isReportSectionAvailable = (section: IReportSection | undefined) => {
    return section?.widgets.map(element => element.value.dataAvailable).some(value=> value);
}
export const ReportSection: React.FC<ReportSectionProps> = ({ className, fullPage, section }) => {
    const title = section.design?.title;
    if (!isReportSectionAvailable(section)) {
        return undefined;
    }
    const widgets = section.widgets.filter(element => element.value.dataAvailable);
    return (
        <StyledReportSection className={className}>
            {title && <div className={'mainTitle'}>{title}</div>}
            {widgets.map((element, index) => <ReportWidget key={element.value.reportBaseWidgetValueId} widget={element} fullPage={fullPage}/>)}
        </StyledReportSection>
    );
};
