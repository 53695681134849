import React, { ReactNode } from 'react';
import { StyledDocRoomCard } from './DocRoomCard.styles';

interface DocRoomCardProps {
    className?: string;
    children?: ReactNode;
}

export const DocRoomCard: React.FC<DocRoomCardProps> = ({ className, children }) => {
    return (
        <StyledDocRoomCard className={className}>
            {children}
        </StyledDocRoomCard>
    );
};
