import React, { createContext, ReactNode, useContext } from 'react';
import { ISectionInstanceModal } from '../../../types';

export const SectionInstanceModalContext = createContext<ISectionInstanceModal>({
    modal: false,
    setModal: (flag) => {}
});

export interface SectionInstanceModalProviderProps {
    value: ISectionInstanceModal;
    children: ReactNode;
}
export const SectionInstanceModalProvider: React.FC<SectionInstanceModalProviderProps> = ({ children, value }) =>{
    return <SectionInstanceModalContext.Provider value={value}>{children}</SectionInstanceModalContext.Provider>;
};

export const useSectionInstanceModal = () => useContext(SectionInstanceModalContext);
