import React, { MouseEvent, ReactNode } from 'react';
import { StyledUploadFileWrapper } from './UploadFileWrapper.styles';
import { ExtFile } from '@files-ui/react';
import { IFileValue } from '../../../../../../../../serverTypes';
import { DotIcon } from '../../../../../../../../components/icons/DotIcon';
import classNames from 'classnames';
import { TrashIcon } from 'react-line-awesome';
import { TrashBinIcon } from '../../../../../../../../components/icons/TrashBinIcon';

interface UploadFileWrapperProps {
    className?: string;
    children?: ReactNode;
    extFile: ExtFile;
    fileValue: IFileValue | undefined;
    downloadUrl: string;
    onDelete: (id: string|number|undefined) => void;
    disabled: boolean;
}

function formatFileSize(bytes: number): string {
    if (bytes === 0) return "0 KB";

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const base = 1024;
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(base));
    let size = bytes / Math.pow(base, unitIndex);

    // For MB and above, use one decimal place
    if (unitIndex >= 2) {
        size = Number(size.toFixed(1));
        // Use comma as decimal separator for MB and above
        return size.toString().replace('.', ',') + ' ' + units[unitIndex];
    } else {
        // For KB and B, round to nearest integer
        size = Math.round(size);
        return size + ' ' + units[unitIndex];
    }
}

// upload-wrapper
export const UploadFileWrapper: React.FC<UploadFileWrapperProps> = ({ className, extFile, fileValue, downloadUrl, onDelete, disabled, children }) => {
    const extension = fileValue?.extension || 'unbekannt';
    const handleLinkClick = (evt: MouseEvent<HTMLDivElement>) => {
        evt.preventDefault();
        if (!disabled) {
            onDelete && onDelete(extFile.id);
        }
        return false;
    }
    return (
        <StyledUploadFileWrapper className={className}>
            <div className={'frame4571'}>
                <div className={'left-col'}>
                    <div className={'placeholder'}>
                        .{extension}
                    </div>
                    <div className={'text-wrapper'}>
                        <div className={'display-name'}><a href={fileValue?.downloadLink} target={'blank'}>{fileValue?.displayFileName}</a></div>
                        <div className={'file-size'}>{formatFileSize(fileValue?.size || 0)}</div>
                    </div>
                </div>
                <div className={'deleteButton'} onClick={(evt) => handleLinkClick(evt)}>
                    <TrashBinIcon className={classNames(disabled && 'disabled')}></TrashBinIcon>
                </div>
            </div>
            {children}
        </StyledUploadFileWrapper>
    );
};
