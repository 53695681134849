import styled from 'styled-components';

export const StyledVector4 = styled.svg`
    width: 0.35275rem;
    height: 0.54913rem;
    position: absolute;
    left: 1.62788rem;
    top: 2.02488rem;
    fill: #CBFFE7;
`;
