import React, { ReactNode } from 'react';
import { StyledImage, StyledReportImageTemplate } from './ReportImageTemplate.styles';
import { IReportImageWidget, IReportTextWidget } from '../../../../../types';

interface ReportImageTemplateProps {
    className?: string;
    widget: IReportImageWidget;
    fullPage: boolean;
}

export const ReportImageTemplate: React.FC<ReportImageTemplateProps> = ({ className, widget }) => {
    return (
        <StyledReportImageTemplate className={className}>
            <StyledImage src={widget.value.downloadUrl} />
        </StyledReportImageTemplate>
    );
};
