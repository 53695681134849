import React, { ReactNode, useState } from 'react';
import { StyledOverlay } from './Overlay.styles';
import { Button } from '../../../../components/button/Button';
import classNames from 'classnames';

interface OverlayProps {
    className?: string;
    children?: ReactNode;
}

export const Overlay: React.FC<OverlayProps> = ({ className, children }) => {
    const [hover, setHover] = useState<boolean>(false);
    return (
        <StyledOverlay className={classNames(className, hover && 'hover')} onClick={(evt) => {
            setHover(!hover);
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1516" height="856" viewBox="0 0 1516 856" fill="none">
                <g filter="url(#filter0_b_2926_20307)">
                    <path d="M0.950195 0.578125H1516V855.312H0.950195V0.578125Z" fill="black" fillOpacity="0.43" />
                </g>
                <defs>
                    <filter id="filter0_b_2926_20307" x="-19.0498" y="-19.4219" width="1555.05" height="894.734"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2926_20307" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2926_20307"
                                 result="shape" />
                    </filter>
                </defs>
            </svg>
            <div className={'success-card'}>
                <div className={'success-card-content-wrapper'}>
                    An diesem Feature arbeiten wir gerade. Melden Sie sich gerne für die Warteliste an, um informiert zu werden.
                </div>
                <Button caption={'Für Warteliste anmelden'} variant={'primary'} className={'button'} onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    window.location.href = 'mailto:info@colexo.de';
                }}/>
            </div>
        </StyledOverlay>
    );
};
