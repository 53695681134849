import React, { ReactNode } from 'react';
import { StyledBigCircleText } from './BigCircleText.styles';
import classNames from 'classnames';

interface BigCircleTextProps {
    className?: string;
    count: number;
    small: boolean;
}

export const BigCircleText: React.FC<BigCircleTextProps> = ({ className, count, small }) => {
    return (
        <StyledBigCircleText className={classNames(className, small && 'small')}>
            {count}<div className={'docs'}>Docs</div>
        </StyledBigCircleText>
    );
};
