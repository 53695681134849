import React, { ReactNode, useEffect, useState } from 'react';
import { StyledColexoNavbar } from './ColexoNavbar.styles';
import { ColexoLogo } from '../ColexoLogo';
import { NavContainer } from '../topbar/NavContainer';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { StyledColexoLogo } from '../ColexoLogo.styles';
import { AccountUserWrapper } from '../AccountUserWrapper';
import { useAppSelector } from '../../../store';
import { selectPageData } from '@baumeister/web-cen-protocol';
import { IUser } from '../../../serverTypes';
import { Metadata } from 'highlight.run/dist/client/src';
import { H } from 'highlight.run';
import { getSelfServiceLogoutUrl } from '../../../ory/utils';

interface ColexoNavbarProps {
    className?: string;
    children?: ReactNode;
    useNavbar: boolean;
}

export const ColexoNavbar: React.FC<ColexoNavbarProps> = ({ className, useNavbar, children }) => {
    const [logoutUrl, setLogoutUrl] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    useEffect(() => {
        getSelfServiceLogoutUrl().then((url) => {
            console.log('setting logout url to ', url);
            setLogoutUrl(url);
            (window as any).logoutUrl = url;
        }).catch((error) => {
            console.log('error received', error);
        });
    }, [setLogoutUrl]);
    const pageData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const userPage = useAppSelector((state) => selectPageData(state.entities, 'user'));

    const user = userPage?.data as unknown as IUser | undefined;
    if (userName && userName.length === 0 && user && user.email) {
        setUserName(user.email);
            H.identify(user.email, user as unknown as Metadata);
    }

    return <StyledColexoNavbar className={classNames(className, !useNavbar ? 'no-navbar' : 'use-navbar')}>
        <div className={'navbarFrame'}>
            <a href={'/company'}><ColexoLogo large={!useNavbar} className={'logo'}/></a>
            {useNavbar &&
                <div className={'inner'}>
                    <NavContainer/>
                    <AccountUserWrapper logoutUrl={logoutUrl}/>
                </div>}
        </div>
    </StyledColexoNavbar>;
};
