import React, { ReactNode } from 'react';
import { StyledColexoSwitchIcon } from './ColexoSwitchIcon.styles';

interface ColexoSwitchIconProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoSwitchIcon: React.FC<ColexoSwitchIconProps> = ({ className, children }) => {
    return (
        <StyledColexoSwitchIcon className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
            <path d="M3.5112 9.87976C2.9851 8.07427 2.459 6.28287 1.92037 4.47734C2.65942 3.64511 3.39846 2.81288 4.13751 1.98065C4.68866 3.6169 5.25232 5.23905 5.80348 6.87529C7.46947 4.80176 9.12291 2.71414 10.7889 0.640625C11.7033 1.092 12.6052 1.54338 13.5196 1.98065C11.4653 4.68892 9.39851 7.3972 7.34419 10.1055C6.06653 10.0208 4.78887 9.95027 3.5112 9.87976Z" fill="#1A384E" fillOpacity="0.5"/>
            <path d="M7.3441 10.8949C8.39632 14.1815 9.43598 17.4681 10.4882 20.7688C9.33576 20.8393 8.18338 20.8957 7.03094 20.9662L4.67603 13.6031C3.66141 14.647 2.65932 15.6908 1.6447 16.7346C1.24386 16.0857 0.85555 15.4369 0.454712 14.788C1.46933 13.2223 2.47143 11.6707 3.48605 10.105C4.77623 10.373 6.05389 10.641 7.3441 10.8949Z" fill="#1A384E" fillOpacity="0.5"/>
        </StyledColexoSwitchIcon>
    );
};
