import styled from 'styled-components';

export const StyledTeamWrapper = styled.div`
    display: flex;
    width: 26.0625rem;
    height: 13.5625rem;
    padding: var(--spacing-lg, 0.75rem) 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    background: var(--Base-Neutral-601, #F4F5F8);
    .title {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .button {
        align-self: unset;
    }
    .team-member-list-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.625rem;
    }
`;
