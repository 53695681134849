import styled from 'styled-components';

export const StyledLeftContent = styled.div`
    display: flex;
    padding: 0;
    @media screen and (max-width: 1599px) {
        padding: 1rem 0;
    }
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 1599px) {
        gap: 1rem;
    }
    flex: 1 0 0;


`;
