import React, { ReactNode } from 'react';
import { StyledColexoRoom } from './ColexoRoom.styles';
import { ContentWrapper } from '../doc-room-wrapper/doc-room-card/content-wrapper/ContentWrapper';
import { ColexoIcon } from '../../../../../components/icons/ColexoIcon';
import { ContentLeft } from './content-left/ContentLeft';
import { ContentRight } from './content-right/ContentRight';
import { useAppSelector } from '../../../../../store';
import { ISectionTypeInstance } from '../../../../../serverTypes';
import { selectPageChildCursorData } from '@baumeister/web-cen-protocol';

interface ColexoRoomProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoRoom: React.FC<ColexoRoomProps> = ({ className, children }) => {
    const sectionTypeInstance: ISectionTypeInstance[] = useAppSelector((state) =>
        selectPageChildCursorData(state.entities, 'company', "sectionTypeInstance")
    );
    let total = 0;
    let done = 0;
    sectionTypeInstance.forEach((instance) => {
        total += instance?.totalQuestions || 0;
        done += instance?.answeredQuestions || 0;
    });

    let percentage = done > 0 ? Math.round((done / total) * 100) : 0;
    return (
        <StyledColexoRoom className={className}>
            <ContentWrapper>
                <div className={'clx-raum-logo'}>
                    <ColexoIcon className={'icon'} />
                    <div className={'text'}>-Raum</div>
                </div>
                <div className={'description'}>
                    Der gesamte Arbeitsstand auf einen Blick.
                </div>
            </ContentWrapper>
            <div className={'todo-wrapper'}>
                <ContentLeft percentage={percentage}/>
                <ContentRight total={total} done={done} />
            </div>
        </StyledColexoRoom>
    );
};
