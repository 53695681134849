import React, { ReactNode } from 'react';
import { StyledUploadWrap } from './UploadWrap.styles';
import classNames from 'classnames';
import { UploadIcon } from '../../../../../../components/icons/UploadIcon';

interface UploadWrapProps {
    className?: string;
    children?: ReactNode;
}

export const UploadWrap: React.FC<UploadWrapProps> = ({ className, children }) => {
    return (
        <StyledUploadWrap className={className}>
            <img src={'/doc-folder-icon.png'} className={'doc-folder-icon'}/>
            <div className={'ctx-report-content-wrap'}>
                <div className={'ctx-raum-logo'}>
                    Reports hochladen
                </div>
                <span className={'vendor-reports'}>
                    Vendor Reports
                </span>
            </div>
            <button className={classNames('upload', 'button')}>
                <UploadIcon/>
            </button>
            {children}
        </StyledUploadWrap>
    );
};
