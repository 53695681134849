import React, { ReactNode } from 'react';
import { StyledStatusWrapper } from './StatusWrapper.styles';

interface StatusWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const StatusWrapper: React.FC<StatusWrapperProps> = ({ className, children }) => {
    return (
        <StyledStatusWrapper className={className}>
            {children}
        </StyledStatusWrapper>
    );
};
