import styled from 'styled-components';

export const StyledNavLinkElement = styled.a`
    display: flex;
    padding: var(--spacing-lg, 0.75rem) var(--spacing-3xl, 1.5rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 0.375rem);
    align-self: stretch;
    border-radius: 1.5rem;
    background: var(--Base-White, #FFF);

    &.active {
        background: var(--Base-Neutral-600, #1A384E);
    }

    & .title {
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Paragraph M - Bold */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
    &.active .title {
        color: var(--Base-White, #FFF);
    }
`;
