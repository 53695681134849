export enum OryPageEnum { Recovery = 'recovery-page', Login = 'login', Registration = 'registration-page', Settings = 'settings-page', Verification = "verification-page"};

export interface IAuthState {
    isAuthenticated: boolean;
    user: IUser | null;
    token: string | null;
}

export interface IUser {
    id: string;
    username: string;
    // Add other user properties as needed
}

export interface IAuthContextType extends IAuthState {
    login: (token: string, user: IUser) => void;
    logout: () => void;
    updateToken: (newToken: string) => void;
}
