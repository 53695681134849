import React, { ReactNode } from 'react';
import { StyledContentLeft } from './ContentLeft.styles';
import { ArrowLeftIcon } from '../../../../../components/icons/ArrowLeftIcon';
import { Button } from '../../../../../components/button/Button';

interface ContentLeftProps {
    className?: string;
    children?: ReactNode;
    step: number;
    title: string;
    onButtonClick: () => void;
    onArrowClick: () => void;
    hideArrow?: boolean;
}

export const ContentLeft: React.FC<ContentLeftProps> = ({ className, title, step, onButtonClick, onArrowClick, hideArrow, children }) => {
    return (
        <StyledContentLeft className={className}>
            <div className={'content-col-left'}>

                <div className={'step'}>0{step} von 04</div>
                <div className={'title'}>{title}</div>
                {children}
                <div className={'button-group'}>
                    <div className={'arrow-left'} onClick={onArrowClick}>
                        {!hideArrow && <ArrowLeftIcon className={'icon'}/>}
                    </div>
                    <Button variant={'primary'} caption={'Weiter'} className={'button'} onClick={onButtonClick}/>
                </div>
            </div>
        </StyledContentLeft>
    );
};
