import styled from 'styled-components';

export const StyledContentRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.625rem;
    flex: 1 0 0;
    .title {
        display: flex;
        height: 1.1875rem;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.875rem */
    }

    .answered .status-btn {
        background: var(--Spring-Green-100, #D5FFED);
    }
    .delegated .status-btn {
        background: var(--Brand-Accent-100, #FFEED4);
    }
    .not-answered .status-btn {
        background: var(--Athens-Gray-100, #E9EBF0);
    }
    .status-wrapper {
        display: flex;
        height: 9.4375rem;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
        align-self: stretch;
    }
`;
