import React, { ReactNode } from 'react';
import { StyledEmptyReportComponent } from './EmptyReportComponent.styles';
import { Button } from '../../../../../../../../components/button/Button';

interface EmptyReportComponentProps {
    className?: string;
    onClick?: () => void;
}

export const EmptyReportComponent: React.FC<EmptyReportComponentProps> = ({ className , onClick }) => {
    return (
        <StyledEmptyReportComponent className={className}>
            <div className={'title'}>Ihr CLX-Report ist noch leer.</div>
            <div className={'detail-wrapper'}>
                <div className={'notice'}>Für die Erstellung Ihres CLX-Reports benötigen wir noch weitere Informationen von Ihnen. Beantworten Sie dafür bitte weitere Fragen. Sobald ausreichend Informationen vorliegen, wird der Report automatisch generiert.</div>
            </div>
            <Button variant={'primary'} caption={'Mehr Fragen beantworten'} onClick={() => onClick && onClick()}/>
        </StyledEmptyReportComponent>
    );
};
