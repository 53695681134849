import React, { HTMLAttributes, ReactNode } from 'react';

interface ArrowLeftProps extends HTMLAttributes<SVGElement>{
    className?: string;
    children?: ReactNode;
}

export const ArrowLeftIcon: React.FC<ArrowLeftProps> = ({ className, ...props }) => {
    return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none" {...props}>
        <path
            d="M19.8456 31.7519L10.8459 22.7524C10.7529 22.6595 10.6792 22.5492 10.6288 22.4278C10.5785 22.3064 10.5526 22.1763 10.5526 22.0449C10.5526 21.9135 10.5785 21.7834 10.6288 21.662C10.6792 21.5406 10.7529 21.4303 10.8459 21.3375L19.8456 12.338C20.0332 12.1503 20.2877 12.0449 20.5531 12.0449C20.8184 12.0449 21.0729 12.1503 21.2605 12.338C21.4482 12.5256 21.5536 12.7801 21.5536 13.0454C21.5536 13.3108 21.4482 13.5653 21.2605 13.7529L13.967 21.045H33.5526C33.8179 21.045 34.0722 21.1503 34.2597 21.3379C34.4473 21.5254 34.5526 21.7797 34.5526 22.0449C34.5526 22.3101 34.4473 22.5645 34.2597 22.752C34.0722 22.9395 33.8179 23.0449 33.5526 23.0449H13.967L21.2605 30.337C21.4482 30.5246 21.5536 30.7791 21.5536 31.0444C21.5536 31.3098 21.4482 31.5643 21.2605 31.7519C21.0729 31.9395 20.8184 32.0449 20.5531 32.0449C20.2877 32.0449 20.0332 31.9395 19.8456 31.7519Z"
            fill="#1A384E" fillOpacity="0.5" />
    </svg>
        ;
};
