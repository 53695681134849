import React, { ReactNode } from 'react';
import { StyledFileValueDoneAlert } from './FileValueDoneAlert.styles';
import { Button } from '../../../../../../../../components/button/Button';

interface FileValueDoneAlertProps {
    className?: string;
    children?: ReactNode;
    hideAlert: () => void;
}

export const FileValueDoneAlert: React.FC<FileValueDoneAlertProps> = ({ className, hideAlert, children }) => {
    return (
        <StyledFileValueDoneAlert
            className={className}
            hideAlert={hideAlert}
            title={'Ihre Dokumente wurden erfolgreich ausgewertet.'}
            notice={'Alle Dokumente sind nun hochgeladen und ausgewertet. Sie können nun mit der Beantwortung der Fragen fortfahren.'}
        >
            <Button variant={'primary'} caption={'Weiter zu den Fragen'} onClick={() => hideAlert()} />
        </StyledFileValueDoneAlert>
    );
};
