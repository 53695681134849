import React, { ReactNode } from 'react';
import { StyledContentFrame } from './ContentFrame.styles';

interface ContentFrameProps {
    className?: string;
    children?: ReactNode;
}

export const ContentFrame: React.FC<ContentFrameProps> = ({ className, children }) => {
    return <StyledContentFrame className={className}>{children}</StyledContentFrame>;
};
