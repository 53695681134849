import React, { ReactNode } from 'react';
import { StyledCompanyIcon } from './CompanyIcon.styles';

interface CompanyIconProps {
    className?: string;
    children?: ReactNode;
}

export const CompanyIcon: React.FC<CompanyIconProps> = ({ className, children }) => {
    return (
        <StyledCompanyIcon className={className} xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
            <path d="M18.9615 0.273438H2.03846C1.63044 0.273438 1.23912 0.435525 0.950605 0.724042C0.662087 1.01256 0.5 1.40387 0.5 1.8119V14.1196C0.5 14.5276 0.662087 14.9189 0.950605 15.2074C1.23912 15.496 1.63044 15.6581 2.03846 15.6581H18.9615C19.3696 15.6581 19.7609 15.496 20.0494 15.2074C20.3379 14.9189 20.5 14.5276 20.5 14.1196V1.8119C20.5 1.40387 20.3379 1.01256 20.0494 0.724042C19.7609 0.435525 19.3696 0.273438 18.9615 0.273438ZM8.19231 9.50421V6.42728H12.8077V9.50421H8.19231ZM12.8077 11.0427V14.1196H8.19231V11.0427H12.8077ZM2.03846 6.42728H6.65385V9.50421H2.03846V6.42728ZM8.19231 4.88882V1.8119H12.8077V4.88882H8.19231ZM14.3462 6.42728H18.9615V9.50421H14.3462V6.42728ZM18.9615 4.88882H14.3462V1.8119H18.9615V4.88882ZM6.65385 1.8119V4.88882H2.03846V1.8119H6.65385ZM2.03846 11.0427H6.65385V14.1196H2.03846V11.0427ZM18.9615 14.1196H14.3462V11.0427H18.9615V14.1196Z" fill="#00FF8C"/>
        </StyledCompanyIcon>
    );
};
