import React, { ReactNode } from 'react';
import { StyledContentFooter } from './ContentFooter.styles';
import { Button } from '../../../../components/button/Button';

interface ContentFooterProps {
    className?: string;
    children?: ReactNode;
}

export const ContentFooter: React.FC<ContentFooterProps> = ({ className, children }) => {
    return <StyledContentFooter className={className}>
        <Button type="submit" variant={'primary'} caption={'Unternehmen anlegen'}/>
        <div className={'notice'}>*Pflichtfeld</div>
    </StyledContentFooter>;
};
