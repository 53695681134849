import styled from 'styled-components';

export const StyledContentSubTitle = styled.div`
    color: rgba(26, 56, 78, 0.50);
    text-align: center;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.875rem */
`;
