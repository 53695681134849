import styled from 'styled-components';

export const StyledTaskWrapper = styled.div`
    display: flex;
    width: 22.5625rem;
    align-items: center;
    gap: var(--spacing-xl, 1rem);
    .circle {
        position: relative;
        width: 2.69063rem;
        height: 2.4795rem;
        flex-shrink: 0;
    }
    .circle-result {
        position: absolute;
        right: -0.03813rem;
        top: -0.00244rem;
        width: 2.69063rem;
        height: 2.4795rem;
    }
    .circle-result .icon {
        position: absolute;
        width: 1.56256rem;
        height: 1.12506rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 50%;
        left: 50%;
        margin-left: calc(-1.56256rem / 2);
        margin-top: calc(-1.2506rem / 2);
    }
    .circle-result .ellipse-icon {
        fill: var(--Brand-Accent-601, #DCFED6);
    }
    .ellipse-icon circle {
        fill: var(--Base-Neutral-200, #DFE3EA);
    }
    .ellipse-icon.checked circle {
        fill: var(--Brand-Accent-601, #DCFED6);
    }
    .ellipse-icon circle {
        fill: #DFE3EA;
    }
    .ellipse-icon.done circle {
        fill: #D5FFED;
    }
    .circle-result .icon svg path {
        fill: white;
    }
    .circle-result.done .icon svg path {
        fill: #00FF8C;
    }

    .text-wrapper {
        display: flex;
        width: 18.875rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--spacing-xs, 0.25rem);
        flex-shrink: 0;
    }
    .text-wrapper .title {
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
    .text-wrapper .subtitle {
        width: 18.875rem;
        height: 1.1875rem;
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }

    .text-wrapper .subtitle.done {
        color: var(--Brand-Accent-800, #FF045F);
    }
`;
