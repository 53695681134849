import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { StyledCustomNodeFlow } from './CustomNodeFlow.styles';
import {
    addEdge,
    Connection,
    Controls,
    Edge,
    MiniMap,
    Node,
    NodeTypes,
    Position,
    ReactFlow,
    useEdgesState,
    useNodesState,
    useOnSelectionChange,
} from 'reactflow';
import { Col, Row } from '@baumeister/web-ui-bootstrap';
import DocumentVariableNode from './DocumentVariableNode';
import { FixedValueNode } from './FixedValueNode';
import SelectNode from './SelectNode';
import { IEditableOptionData } from '@baumeister/web-cen-protocol';
import StaticNode from './StaticNode';
import PromptNode from './PromptNode';
import ConditionalNode from './ConditionalNode';

interface CustomNodeFlowProps {
    className?: string;
    children?: ReactNode;
}

const initBgColor = '#1A192B';
const connectionLineStyle = { stroke: '#fff' };
const snapGrid = [20, 20];
const nodeTypes: NodeTypes = {
    documentVariableNode: DocumentVariableNode,
    selectNode: SelectNode,
    staticNode: StaticNode,
    fixedValueNode: FixedValueNode,
    promptNode: PromptNode,
    conditionalNode: ConditionalNode
};

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const mathTermOptions:IEditableOptionData[] = [
    {
        label: '*',
        value: 'multiply'
    },
    {
        label: '/',
        value: 'divide'
    },
    {
        label: '+',
        value: 'plus'
    },
    {
        label: '-',
        value: 'minus'
    },
    {
        label: '%',
        value: 'modulo'
    },
];

const logicalOptions:IEditableOptionData[] = [
    {
        label: 'and',
        value: 'and'
    },
    {
        label: 'or',
        value: 'or'
    },
    {
        label: 'xor',
        value: 'xor'
    },
    {
        label: '>=',
        value: 'ge'
    },
    {
        label: '>',
        value: 'gt'
    },
    {
        label: '==',
        value: 'eq'
    },
    {
        label: '<=',
        value: 'le'
    },
    {
        label: '<',
        value: 'lt'
    }
];

const documentOptions: IEditableOptionData[] = [
    {
        label: 'Finanzdokumente->Jahresabschluss 2022->Personalkosten',
        value: 'Personalkosten 2022'
    },
    {
        label: 'Finanzdokumente->Jahresabschluss 2023->Personalkosten',
        value: 'Personalkosten 2023'
    },
    {
        label: 'Finanzdokumente->Jahresabschluss 2022->Umsatz',
        value: 'Umsatz 2022'
    },
    {
        label: 'Finanzdokumente->Jahresabschluss 2023->Umsatz',
        value: 'Umsatz 2023'
    },
    {
        label: 'Unternehmensprofil->HR-Auszug->Firmenname',
        value: 'Firmenname'
    },
    {
        label: 'Unternehmensprofil->HR-Auszug->Amtsgericht',
        value: 'Amtsgericht'
    }
];

const questionOptions: IEditableOptionData[] = [
    {
        label: 'Unternehmensprofil->Rechtsform',
        value: 'Rechtsform'
    },
    {
        label: 'Unternehmensprofil->Gründungsdatum',
        value: 'Gründungsdatum'
    },
    {
        label: 'Operative Daten->Geschäftsmodell',
        value: 'Geschäftsmodell'
    },
    {
        label: 'Operative Daten->Marktposition',
        value: 'Marktposition'
    }
];
export const CustomNodeFlow: React.FC<CustomNodeFlowProps> = ({ className, children }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [bgColor, setBgColor] = useState(initBgColor);
    useOnSelectionChange({
        onChange: () => {
            setNodes(nodes.map(element => {
                    element.draggable = !element.selected;
                    return element;
                })
            );
        }
    });
    useEffect(() => {
        setNodes([
            {
                id: '1',
                type: 'documentVariableNode',
                data: { value: 'Personalkosten 2022', prefix: 'Dokumentenvariable', options: documentOptions },
                position: { x: 120, y: -40 },
                sourcePosition: Position.Right,
            },
            {
                id: '2',
                type: 'documentVariableNode',
                data: { value: 'Umsatz 2022', prefix: 'Dokumentenvariable', options: documentOptions },
                position: { x: 120, y: 80 },
                sourcePosition: Position.Right,
            },
            {
                id: '3',
                type: 'selectNode',
                data: { operation: 'divide', options: mathTermOptions },
                position: { x: 360, y: 20 },
                sourcePosition: Position.Right,
            },
            {
                id: '4',
                type: 'selectNode',
                data: { operation: 'multiply', options: mathTermOptions },
                position: { x: 540, y: 80 },
                sourcePosition: Position.Right,
            },
            {
                id: '5',
                type: 'staticNode',
                data: { value: '100' },
                position: { x: 460, y: 120 },
                sourcePosition: Position.Right,
            },
            {
                id: 'output',
                type: 'output',
                data: { label: 'Berechnungsergebnis' },
                position: { x: 700, y: 80 },
                targetPosition: Position.Left,
                draggable: false,
            },
        ]);

        setEdges([
            {
                id: 'e1-1',
                source: '1',
                target: '3',
                targetHandle: 'a',
                animated: true,
                style: { stroke: '#fff' },
            },
            {
                id: 'el-2',
                source: '2',
                target: '3',
                targetHandle: 'b',
                animated: true,
                style: { stroke: '#fff' },
            },
            {
                id: 'el-3',
                source: '3',
                target: '4',
                targetHandle: 'a',
                animated: true,
                style: { stroke: '#fff' },
            },
            {
                id: 'el-4',
                source: '5',
                target: '4',
                targetHandle: 'b',
                animated: true,
                style: { stroke: '#fff' },
            },
            {
                id: 'el-5',
                source: '4',
                target: 'output',
                animated: true,
                style: { stroke: '#fff' },
            },
        ]);
    }, []);
    const addNode = (type: string, data: unknown) => {
        const newNode: Node = {
            id: (nodes.length + 1).toString(),
            type,
            data,
            position: { x: Math.random() * 250, y: Math.random() * 250 },
        };
        setNodes((nds) => nds.concat(newNode));
    };
    const addSelectNode = (options: IEditableOptionData[], value: string) => {
        addNode('selectNode', {
            operation: value,
            options
        });
    };
    const addStaticNode = () => {
        addNode('staticNode', {value: ''});
    }
    const addStaticSetNode = () => {
        addNode('staticNode', {value: '', set: true});
    }
    const addDocumentNode = () => {
        addNode('documentVariableNode', {value: '', options: documentOptions, prefix: 'Dokumentenvariable'})
    }
    const addQuestionNode = () => {
        addNode('documentVariableNode', {value: '', options: questionOptions, prefix: 'Feld' })
    }
    const onConnect = useCallback(
        (params: Edge | Connection) =>
            setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds)),
        []
    );
    return (
        <StyledCustomNodeFlow className={className}>
            <Row>
                <Col lg={1}>
                    Objekte
                </Col>
                <Col lg={11}>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addDocumentNode()}>Dokumentenvariable
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addNode('promptNode', { value: 'Was ist der Sinn des Lebens?' })}>Prompt
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addQuestionNode()}>Frage
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addStaticNode()}>Wert Fragen-Auswahl
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addStaticNode()}>Fester Wert
                    </button>
                </Col>
            </Row>
            <Row>
                <Col lg={1}>
                    Berechnungen
                </Col>
                <Col lg={11}>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(mathTermOptions, 'plus')}>+
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(mathTermOptions, 'minus')}>-
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(mathTermOptions, 'multiply')}>*
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(mathTermOptions, 'divide')}>/
                    </button>
                </Col>
            </Row>
            <Row>
                <Col lg={1}>
                    Logik
                </Col>
                <Col lg={11}>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'and')}>UND
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'or')}>ODER
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'xor')}>XOR
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'gt')}>&gt;
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'ge')}>&gt;=
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'eq')}>==
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'le')}>&lt;=
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addSelectNode(logicalOptions, 'lt')}>&lt;
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addNode('conditionalNode', {})}>WENN DANN
                    </button>
                    <button style={{ gap: '15px', padding: '10px', margin: '10px' }}
                            onClick={() => addStaticSetNode()}>Wert zuordnen
                    </button>
                </Col>
            </Row>
            <Row>
                <Col lg={12} style={{ height: 800 }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        style={{ background: bgColor }}
                        nodeTypes={nodeTypes}
                        connectionLineStyle={connectionLineStyle}
                        snapToGrid={true}
                        defaultViewport={defaultViewport}
                        fitView
                        attributionPosition="bottom-left"
                    >
                        <MiniMap
                            nodeStrokeColor={(n) => {
                                return '#0041d0';
                            }}
                            nodeColor={(n) => {
                                return '#fff';
                            }}
                        />
                        <Controls />
                    </ReactFlow>
                </Col>
            </Row>
        </StyledCustomNodeFlow>
    );
};
