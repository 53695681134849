import { IWebUiBootstrapTheme } from '@baumeister/web-ui-bootstrap';
import { baseTheme } from '@baumeister/web-ui-bootstrap';
import './colexo-theme.fonts.css';
import 'reactflow/dist/style.css';

export const colexoTheme:IWebUiBootstrapTheme = {
    ...baseTheme,
    font: {
        fontFamily: 'Inter, sans-serif',
        rootFontSize: '16px',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.8,
        textAlign: 'left'
    },
    text: {
        ...baseTheme.text,
        color: '#1A384E',
        textColor: '#1A384E',
        headingColor: '#1A384E',
        tableTextColor: '#1A384E'
    },
    main: {
        padding: {
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px'
        }
    },
    colors: {
        primary: '#17a2b8',
        secondary: '#E8F5E9',
        success: '#4CAF50',
        danger: '#D32F2F',
        warning: '#FFA000',
        info: '#1976D2',
        dark: '#1A384E',
        light: '#F5F5F5',
        white: '#FFFFFF',
        muted: '#757575',
    },
    button: {
        backgroundColor: '#17a2b8',
        borderColor: '#17a2b8',
        color: '#1A384E',
        borderRadius: '5px',
        padding: '12px 24px',
        hover: {
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            color: '#1A384E',
        }
    },
    modal: {
        backgroundColor: '#cbffe7',
        border: '1px solid black'
    }
}
