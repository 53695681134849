import React, { memo, useState } from 'react';
import { Handle, Node, NodeProps, NodeToolbar, Position, useUpdateNodeInternals } from 'reactflow';
import { Button, Modal, ModalBody, ModalHeader } from '@baumeister/web-ui-bootstrap';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

type PromptNodeProps = {
    value: string;
    wantsInput?: boolean;
}

type PromptNode = Node<PromptNodeProps>;

const PromptNode = ({ data, isConnectable, selected, id }: NodeProps<PromptNodeProps> ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>(data.value);
    const [wantsInput, setWantsInput] = useState<boolean>(data.wantsInput || false);
    const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);
    const updateNodeInternals = useUpdateNodeInternals();
    const value = data.value || '';
    const handleCheckboxChange = () => {
        setWantsInput(!wantsInput);
        updateNodeInternals(id);
    };
    return <div>
        <NodeToolbar isVisible={selected}>
            <Button onClick={() => setPromptDialogOpen(true)}>Bearbeiten</Button>
        </NodeToolbar>
        <div>
            <b>{value.substring(0, Math.min(value.length, 30))}...</b>
        </div>
        <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
        {wantsInput && <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
        />}
        {promptDialogOpen && (
            <Modal>
                <ModalHeader toggle={() => setPromptDialogOpen(false)}>
                    Eingabe des Prompts
                </ModalHeader>
                <ModalBody>

                    <TextareaAutosize
                        name={'prompt'}
                        value={data.value}
                        minRows={5}
                        onChange={(evt) => {
                            const value = (evt.target as HTMLTextAreaElement).value;
                            setSelectedValue(value);
                            data.value = value;
                        }}
                        style={{ width: '100%' }}
                        className={classNames('autogrow', 'transition-height')}

                    />
                    <label>
                        <input
                            type="checkbox"
                            checked={wantsInput}
                            onChange={handleCheckboxChange}
                        />
                        Wants Input
                    </label>
                </ModalBody>
            </Modal>
        )}
    </div>;
}

export default memo(PromptNode);
