import React, { ReactNode } from 'react';
import { StyledReportFileSources } from './ReportFileSources.styles';
import { IReportFileSourceValue } from '../../../../../../../../../serverTypes';
import { Tooltip } from '@baumeister/web-ui-bootstrap';

interface ReportFileSourcesProps {
    className?: string;
    children?: ReactNode;
    fileValues: IReportFileSourceValue[];
}

export const ReportFileSources: React.FC<ReportFileSourcesProps> = ({ className, fileValues, children }) => {
    if (!fileValues || fileValues.length === 0) {
        return undefined;
    }
    return (
        <StyledReportFileSources className={className}>
            Quellen: {fileValues.map((element, index) => {
            const tooltipId = `file-source-tooltip-${element.reportFileSourceValueId}`;
            return (
                <React.Fragment key={index}>
                    {index > 0 && <React.Fragment>, </React.Fragment>}
                    <a className={'value'} target={'_blank'} href={element.downloadUrl} data-tooltip-id={tooltipId}>[{index + 1}]</a>
                    <Tooltip placement={'top'} target={tooltipId} className={'notice-tooltip'}>{element.displayName}</Tooltip>
                </React.Fragment>
            );
            })}
            {children}
        </StyledReportFileSources>
    );
}
