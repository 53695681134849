export enum ColexoProtocolMessage {
    INFO_LOCK_QUESTION = 'INFO_LOCK_QUESTION',
    INFO_DECLINE_QUESTION = 'INFO_DECLINE_QUESTION',
    INFO_ADD_ROW = 'INFO_ADD_ROW',
    INFO_DELETE_FILE_VALUE = 'INFO_DELETE_FILE_VALUE',
    DOCUMENT_SEARCH_FILES = 'DOCUMENT_SEARCH_FILES',
    INFO_QUEUE_PROMPT_VALUE = 'INFO_QUEUE_PROMPT_VALUE',
    INFO_DELETE_TABLE_ROW = 'INFO_DELETE_TABLE_ROW',
    BASE_DATA_SAVE = 'BASE_DATA_SAVE',
    INFO_LLM_FILE_VALUE = 'INFO_LLM_FILE_VALUE'
};

export type ColexoSocketMessage<T extends ColexoProtocolMessage, P> = {
    type: T;
    payload: P;
}

export interface IInfoLockQuestionRequest {
    entityPath: string;
    lockState: boolean;
}

export type InfoLockQuestionRequestMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_LOCK_QUESTION,
    IInfoLockQuestionRequest
>;

export interface IDeclineLockQuestionRequest {
    entityPath: string;
}

export type InfoDeclineQuestionRequestMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_DECLINE_QUESTION,
    IDeclineLockQuestionRequest
>;

export interface IInfoAddRowRequest {
    entityPath: string;
    pageLink: string;
    channel: string;
}

export type InfoAddRowRequestMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_ADD_ROW,
    IInfoAddRowRequest
>;

export interface IInfoDeleteFileValue {
    entityPath: string;
    channel: string;
    pageLink: string;
}

export type InfoDeleteFileValueMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_DELETE_FILE_VALUE,
    IInfoDeleteFileValue
>;

export interface IDocumentSearchFiles {
    entityPath: string;
    channel: string;
    search: string;
}

export type DocumentSearchFiles = ColexoSocketMessage<
    ColexoProtocolMessage.DOCUMENT_SEARCH_FILES,
    IDocumentSearchFiles>;

export interface IQueuePromptValueRequest {
    entityPath: string;
    queue: boolean;
}

export type QueuePromptValueRequestMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_QUEUE_PROMPT_VALUE,
    IQueuePromptValueRequest
>;

export interface IInfoDeleteTableRow {
    tableValueEntityPath: string;
}

export type InfoDeleteTableRowMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_DELETE_TABLE_ROW,
    IInfoDeleteTableRow
>;

export interface IBaseDataSave {
    entityPath: string | undefined;
    name: string | undefined;
    street: string | undefined;
    houseNumber: string | undefined;
    postalCode: string | undefined;
    city: string | undefined;
    tradeRegisterNumber: string | undefined;
}

export type BaseDataSave = ColexoSocketMessage<
    ColexoProtocolMessage.BASE_DATA_SAVE,
    IBaseDataSave
>;

export interface IInfoLlmFileValue {
    entityPath: string;
}

export type InfoLlmFileValueMessage = ColexoSocketMessage<
    ColexoProtocolMessage.INFO_LLM_FILE_VALUE,
    IInfoLlmFileValue
>;
