import styled from 'styled-components';

export const StyledVector1 = styled.svg`
    width: 0.23781rem;
    height: 0.42788rem;
    position: absolute;
    left: 1.21831rem;
    top: 1.74806rem;
    fill: #112145;
`;
