import styled from 'styled-components';
import {DocRoomCard} from "../doc-room-wrapper/doc-room-card/DocRoomCard";

export const StyledAnalyse = styled(DocRoomCard)`
    .title {
        align-self: stretch;
        color: var(--Alternative-Secondary-Blue, #22384C);
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .description {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
    }
    .frame4384 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;
