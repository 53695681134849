import React, { ReactNode } from 'react';
import { StyledContentWrapper } from './ContentWrapper.styles';

interface ContentWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ className, children }) => {
    return (
        <StyledContentWrapper className={className}>
            <div className={'title'}>
                Meine Aufgaben
            </div>
            <div className={'description'}>
                1 von 4 erledigt
            </div>
        </StyledContentWrapper>
    );
};
