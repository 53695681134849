import styled from 'styled-components';

export const InputFormWrapper = styled.div`
    display: flex;
    min-width: 35.625rem;
    max-width: 100rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: stretch;

    .badge {
        display: flex;
        padding: var(--spacing-xxs, 0.125rem) var(--spacing-md, 0.5rem);
        align-items: center;
        text-align: center;
    }
    .badge.more_twentyfive {
        border-radius: var(--radius-full, 624.9375rem);
        border: 1px solid var(--Spring-Green-700, #009652);
        background: var(--Spring-Green-50, #EDFFF6);
        color: var(--Spring-Green-700, #009652);
        text-align: center;

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .badge.less_twentyfive {
        border-radius: var(--radius-full, 624.9375rem);
        border: 1px solid var(--Athens-Gray-200, #D8DCE5);
        background: var(--Athens-Gray-50, #F5F6F9);
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .badge.less_five {
        border-radius: var(--radius-full, 624.9375rem);
        border: 1px solid var(--Brand-Accent-800, #FF045F);
        background: var(--Brand-Accent-801, #FFEFF2);
        color: var(--Brand-Accent-800, #FF045F);
        text-align: center;

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }

    .extension img {
        display: block;
        width: 1.125rem;
        height: 0.98438rem;
    }
`;
