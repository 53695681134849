import React, { ReactNode } from 'react';
import { StyledFinanzielleBasisdatenIcon } from './FinanzielleBasisdatenIcon.styles';

interface FinanzielleBasisdatenIconProps {
    className?: string;
    children?: ReactNode;
}

export const FinanzielleBasisdatenIcon: React.FC<FinanzielleBasisdatenIconProps> = ({ className, children }) => {
    return (
        <StyledFinanzielleBasisdatenIcon className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_3409_23070)">
                <path d="M11.4606 11.806C10.6393 11.3514 10.0833 10.4767 10.0833 9.47135C10.0833 7.99802 11.2766 6.80469 12.7499 6.80469C13.2099 6.80469 13.6433 6.92069 14.0206 7.12602" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.08325 2.46875C7.08325 4.67808 5.29259 6.46875 3.08325 6.46875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M18.4165 2.46875C18.4165 4.67808 20.2072 6.46875 22.4165 6.46875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.08325 16.4688C7.08325 14.2594 5.29259 12.4688 3.08325 12.4688" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.4166 16.4688H5.74992C4.27659 16.4688 3.08325 15.2754 3.08325 13.8021V5.13542C3.08325 3.66208 4.27659 2.46875 5.74992 2.46875H19.7499C21.2233 2.46875 22.4166 3.66208 22.4166 5.13542V6.46875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.0833 13.8047H15.0833C14.347 13.8047 13.75 14.4016 13.75 15.138V16.4714C13.75 17.2077 14.347 17.8047 15.0833 17.8047H21.0833C21.8197 17.8047 22.4167 17.2077 22.4167 16.4714V15.138C22.4167 14.4016 21.8197 13.8047 21.0833 13.8047Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M22.4166 9.80469H16.4166C15.6802 9.80469 15.0833 10.4016 15.0833 11.138V12.4714C15.0833 13.2077 15.6802 13.8047 16.4166 13.8047H22.4166C23.153 13.8047 23.7499 13.2077 23.7499 12.4714V11.138C23.7499 10.4016 23.153 9.80469 22.4166 9.80469Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M22.4166 17.8047H16.4166C15.6802 17.8047 15.0833 18.4016 15.0833 19.138V20.4714C15.0833 21.2077 15.6802 21.8047 16.4166 21.8047H22.4166C23.153 21.8047 23.7499 21.2077 23.7499 20.4714V19.138C23.7499 18.4016 23.153 17.8047 22.4166 17.8047Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3409_23070">
                    <rect width="24" height="24" fill="white" transform="translate(0.75 0.132812)"/>
                </clipPath>
            </defs>
        </StyledFinanzielleBasisdatenIcon>
    );
};
