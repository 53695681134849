import styled from 'styled-components';

export const StyledBaseDataField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    align-self: stretch;
    .title {
        display: flex;
        width: 10.08206rem;
        height: 0.875rem;
        flex-direction: column;
        justify-content: center;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }

    input {
        display: flex;
        padding: var(--spacing-xl, 1rem) var(--spacing-lg, 0.75rem);
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;
        color: var(--Base-Neutral-200, #22384C);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .formErrorContainer {
        font-weight: bold;
        color: red;
    }
`;
