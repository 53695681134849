import React, { HTMLAttributes, ReactNode } from 'react';
import { StyledClxReportIcon } from './ClxReportIcon.styles';

interface ClxReportIconProps extends HTMLAttributes<HTMLImageElement>{
    className?: string;
    children?: ReactNode;
}

export const ClxReportIcon: React.FC<ClxReportIconProps> = ({ className, children }) => {
    return (
        <StyledClxReportIcon className={className} src={'/clx-report-icon.png'}/>
    );
};
