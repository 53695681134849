import styled from 'styled-components';

export const StyledBigCircleText = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    margin-top: calc(-5.88525rem / 2);
    left: 50%;
    margin-left: calc(-8.625rem / 2);
    width: 8.625rem;
    height: 5.88525rem;
    flex-direction: column;
    justify-content: center;
    color: var(--Base-Neutral-600, #1A384E);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    .docs {
        color: var(--Base-Neutral-600, #1A384E);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &.small {
        margin-top: calc(-3.20213rem / 2);
        margin-left: calc(-3.85706rem / 2);
        width: 3.85706rem;
        height: 2.80213rem;
        font-size: 1.25rem;
    }
    .small .docs {
        font-size: 0.75rem;
    }
`;
