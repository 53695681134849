import React, { ReactNode } from 'react';
import { StyledColexoIcon } from './ColexoIcon.styles';

interface ColexoIconProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoIcon: React.FC<ColexoIconProps> = ({ className, children }) => {
    return (
        <StyledColexoIcon className={className} xmlns="http://www.w3.org/2000/svg" width="93" height="63" viewBox="0 0 93 63" fill="none">
            <path
                d="M0.889813 12.9956C1.4518 12.4777 2.10745 12.2188 2.80993 12.2188H25.2894V17.3977H5.61987V32.9345H25.2894V38.1135H2.80993C2.10745 38.1135 1.4518 37.8545 0.889813 37.3366C0.327826 36.8187 0 36.2145 0 35.524V14.8082C0 14.1177 0.327826 13.5135 0.889813 12.9956Z"
                fill="#112145" />
            <path
                d="M30.9092 7.08594H36.4822V32.9375H56.1986V38.1164H33.7191C33.0166 38.1164 32.361 37.8575 31.799 37.3396C31.237 36.8217 30.9092 36.2175 30.9092 35.527V7.08594Z"
                fill="#112145" />
            <path
                d="M54.9811 28.2762C53.0142 22.752 51.0472 17.271 49.0334 11.7468C51.7965 9.20044 54.5597 6.65412 57.3228 4.10781C59.3834 9.11412 61.4908 14.0773 63.5514 19.0836C69.7801 12.7394 75.962 6.35202 82.1907 0.0078125C85.6094 1.38886 88.9813 2.76992 92.4001 4.10781C84.7196 12.3941 76.9923 20.6804 69.3118 28.9667C64.5349 28.7078 59.758 28.492 54.9811 28.2762Z"
                fill="#00FF8C" />
            <path
                d="M69.3117 31.3856C73.2456 41.4414 77.1327 51.4972 81.0666 61.5961C76.758 61.8119 72.4494 61.9845 68.1409 62.2003L59.3364 39.6719C55.543 42.8656 51.7964 46.0593 48.003 49.2529C46.5044 47.2677 45.0526 45.2824 43.554 43.2972C47.3474 38.5066 51.0939 33.7593 54.8874 28.9688C59.7111 29.7887 64.488 30.6087 69.3117 31.3856Z"
                fill="#CBFFE7" />
        </StyledColexoIcon>
    );
};
