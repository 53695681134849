import React, { ReactNode } from 'react';
import { StyledCompanyCreatePage } from './CompanyCreatePage.styles';
import { ContentWrapper } from '../../../container/content-wrapper/ContentWrapper';
import { ContentHeader } from './header/ContentHeader';
import { ContentBody } from './body/ContentBody';
import { ContentFooter } from './footer/ContentFooter';

interface CompanyCreatePageProps {
    className?: string;
    children?: ReactNode;
}

export const CompanyCreatePage: React.FC<CompanyCreatePageProps> = ({ className, children }) => {
    return <StyledCompanyCreatePage className={className}>
        <ContentWrapper>
            <ContentHeader/>
            <ContentBody/>
        </ContentWrapper>
    </StyledCompanyCreatePage>;
};
