import React, { ReactNode } from 'react';
import { StyledSelectTemplate } from './SelectTemplate.styles';
import { IQuestionData } from '../../../../types';
import { IEditableOptionData, useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ISelectValue, ITextValue } from '../../../../../../../../serverTypes';
import classNames from 'classnames';
import { Tooltip } from '@baumeister/web-ui-bootstrap';

interface SelectTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const SelectTemplate: React.FC<SelectTemplateProps> = ({ className, questionData, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const selectedValues = (questionData.values[0].value as ISelectValue).selectedValues;
    let defaultOption = undefined;
    const disabled = questionData.instance.locked;
    if (selectedValues) {
        defaultOption = questionData.selectOptions.filter(element => element.value === selectedValues);
    }
    const description = (questionData.values[0].value as ISelectValue).description;
    const tooltipId = description ? `select-tooltip-${questionData.values[0].value.baseValueId}` : undefined;
    const onAccept = (value: string) => {
        cenSocketProtocol.requestUpdateValue(questionData.values[0].link, "selectedValues", value);
    }
    return <React.Fragment>
        <StyledSelectTemplate className={classNames(className, disabled && 'disabled')}
                                 defaultValue={defaultOption}
                                 options={questionData.selectOptions}
                                 onChange={(e: any) => onAccept((e as IEditableOptionData).value)}
    ></StyledSelectTemplate>
        {description && tooltipId && <Tooltip className={'notice-tooltip'} placement={'top'} target={tooltipId}>
            {description}
        </Tooltip>}
    </React.Fragment>;
};
