import React, { ReactNode } from 'react';
import { StyledButtonGroup } from './ButtonGroup.styles';

interface ButtonGroupProps {
    className?: string;
    children?: ReactNode;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ className, children }) => {
    return (
        <StyledButtonGroup className={className}>
            {children}
        </StyledButtonGroup>
    );
};
