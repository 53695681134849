import React, { ReactNode } from 'react';
import { StyledContentRight } from './ContentRight.styles';
import { TaskWrapper } from './task-wrapper/TaskWrapper';
import { CheckCircleIcon } from '../../../../../../components/icons/CheckCircleIcon';
import { DelegateIcon } from '../../../../../../components/icons/DelegateIcon';
import { CancelIcon } from '../../../../../../components/icons/CancelIcon';
import { AcceptIcon } from '../../../../../../components/icons/AcceptIcon';

interface ContentRightProps {
    className?: string;
    children?: ReactNode;
    total: number;
    done: number;
}

export const ContentRight: React.FC<ContentRightProps> = ({ className, total, done, children }) => {
    return (
        <StyledContentRight className={className}>
            <div className={'title'}>
                {done} / {total} erledigt
            </div>
            <div className={'status-wrapper'}>
                <TaskWrapper count={done} text={'beantwortet'} className={'answered'}>
                    <AcceptIcon/>
                </TaskWrapper>
                <TaskWrapper count={0} text={'delegiert'} className={'delegated'}>
                    <DelegateIcon/>
                </TaskWrapper>
                <TaskWrapper count={1} text={'nicht beantwortet'} className={'not-answered'}>
                    <CancelIcon/>
                </TaskWrapper>
            </div>
            {children}
        </StyledContentRight>
    );
};
