import React, { ReactNode } from 'react';
import { StyledToggle } from './Toggle.styles';
import classNames from 'classnames';
import { CollapseIcon } from '../../../../../../../components/icons/CollapseIcon';

interface ToggleProps {
    className?: string;
    children?: ReactNode;
    onClick: () => void;
    collapsed: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({ className, onClick, collapsed, children }) => {
    return (
        <StyledToggle className={classNames(className, collapsed ? 'collapsed': '')} onClick={onClick}>
            <CollapseIcon/>
        </StyledToggle>
    );
};
