import styled from 'styled-components';

export const StyledReportSection = styled.div`
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: stretch;
    border-radius: var(--radius-xl, 0.75rem);
    background: var(--Base-White, #FFF);

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
    .mainTitle {
        align-self: stretch;
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

        /* Heading 4 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 2.6rem */
    }
`;
