import React, { ReactNode } from 'react';
import { StyledWorkflowSection } from './WorkflowSection.styles';
import { CommunicationComponent } from './communication-component/CommunicationComponent';
import { CommunicationWrapper } from './communication-component/communication-wrapper/CommunicationWrapper';
import { Communication } from './communication-component/communication-wrapper/communication/Communication';
import { Tasks } from './communication-component/communication-wrapper/tasks/Tasks';
import { ColexoRoom } from './communication-component/communication-wrapper/colexo-room/ColexoRoom';
import { Overlay } from '../overlay/Overlay';

interface WorkflowSectionProps {
    className?: string;
    children?: ReactNode;
}

export const WorkflowSection: React.FC<WorkflowSectionProps> = ({ className, children }) => {
    return (
        <StyledWorkflowSection className={className}>
            <CommunicationComponent title={'Workflow'}>
                <CommunicationWrapper>
                    <Communication/>
                    <Tasks/>
                    <ColexoRoom/>
                </CommunicationWrapper>
            </CommunicationComponent>
            <Overlay/>
        </StyledWorkflowSection>
    );
};
