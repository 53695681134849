import React, { ReactNode } from 'react';
import { StyledDetailWrapper } from './DetailWrapper.styles';
import { QuestionInput } from '../question/QuestionInput';
import { IQuestionData } from '../../../types';
import { EditButton } from '../editbutton/EditButton';
import classNames from 'classnames';

interface QuestionWrapperProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const DetailWrapper: React.FC<QuestionWrapperProps> = ({ className, questionData, children }) => {
    const locked = questionData.instance.locked;
    if (!questionData.instance.visible) {
        return undefined;
    }
    return <StyledDetailWrapper className={classNames(className, `questionInstance-${questionData.instance.questionInstanceId || 0}`)}>
        <div className={'frame3'}>
            <div className={'questionTitle'}>{questionData.design.title}{questionData.design.mandatory && <span className={'mandatory'}>*</span>}</div>
        </div>
        <EditButton questionData={questionData}/>
        <QuestionInput questionData={questionData}/>
        {children}</StyledDetailWrapper>;
};
