import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    display: flex;
    width: 94.5rem;
    padding: 6.25rem 3.75rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;
