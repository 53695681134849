import styled from 'styled-components';

export const StyledUploadFileList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    & > .upload-illustration-comp {
        display: flex;
        padding: 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        align-self: stretch;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--spacing-xl, 1rem);
            align-self: stretch;
            .heading-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: var(--spacing-md, 0.5rem);
                align-self: stretch;
                .heading {
                    align-self: stretch;
                    color: var(--Base-Neutral-600, #1A384E);

                    /* Paragraph M - Bold */
                    font-family: Lato;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 1.5rem */
                }
            }
        }
    }

    & > .uploaded-files-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
    }
`;
