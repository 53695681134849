import styled from 'styled-components';
import {DocRoomCard} from "../doc-room-card/DocRoomCard";

export const StyledColexoRoom = styled(DocRoomCard)`
    .illustration-wrapper {
        display: flex;
        width: 26.8125rem;
        height: 10.0625rem;
        padding: 0rem 1.25rem 2.5rem 0rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    .illustration {
        width: 11.018rem;
        height: 7.625rem;
        flex-shrink: 0;
    }
`;
