import React, { ReactNode } from 'react';
import { StyledDashEditIcon } from './DashEditIcon.styles';

interface DashEditIconProps {
    className?: string;
    children?: ReactNode;
}

export const DashEditIcon: React.FC<DashEditIconProps> = ({ className, children }) => {
    return (
        <StyledDashEditIcon className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <g clipPath="url(#clip0_3015_14253)">
                <path d="M0.520887 15.0551L0.249178 18.6453C0.238426 18.7913 0.291064 18.935 0.395219 19.0378C0.489743 19.1323 0.617834 19.185 0.749481 19.185C0.762663 19.185 0.774632 19.185 0.787815 19.1837L4.38045 18.912C4.50013 18.9036 4.6126 18.851 4.69759 18.766L18.0114 5.44883C18.2076 5.25258 18.2076 4.93545 18.0114 4.7392L14.6928 1.42062C14.4966 1.22438 14.1794 1.22438 13.9832 1.42062L0.665794 14.738C0.580806 14.8229 0.529302 14.9354 0.520887 15.0551ZM1.50699 15.3159L14.3373 2.48558L16.9462 5.09449L4.11709 17.9248L1.29277 18.1378L1.50699 15.3159Z" fill="#1A384E" fillOpacity="0.5"/>
                <path d="M0 20.8062C0 21.0839 0.22495 21.3076 0.501413 21.3076H19.4986C19.7763 21.3076 20 21.0826 20 20.8062C20 20.5285 19.775 20.3047 19.4986 20.3047H0.501413C0.223816 20.3047 0 20.5297 0 20.8062Z" fill="#1A384E" fillOpacity="0.5"/>
            </g>
            <defs>
                <clipPath id="clip0_3015_14253">
                    <rect width="20" height="21" fill="white" transform="translate(0 0.382812)"/>
                </clipPath>
            </defs>
        </StyledDashEditIcon>
    );
};
