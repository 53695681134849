import styled from 'styled-components';

export const StyledQuestionItemWrapper = styled.div`
    display: flex;
    padding: 1.25rem 2.25rem;
    align-items: center;
    gap: 3.75rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--radius-sm, 0.375rem);
    background: var(--Base-Neutral-50, #F6F9FF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    cursor: pointer;

    &:not(.active) {
        opacity: 0.5;
    }
    &.active {
        border-radius: var(--radius-sm, 0.375rem);
        background: var(--Base-Neutral-50, #F6F9FF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    }
    .icon-circle {
        display: flex;
        width: 2.8125rem;
        height: 2.8125rem;
        padding: 0.64794rem 0.625rem 0.66456rem 0.6875rem;
        justify-content: center;
        align-items: center;
        border-radius: 31.25rem;
        background: var(--Base-Neutral-600, #1A384E);
    }
    .icon-circle .icon {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
    }
`;
