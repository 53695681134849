import styled from 'styled-components';

export const StyledBaseDataPage = styled.div`
    display: flex;
    padding-bottom: 2.5rem;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    align-self: stretch;
    background: var(--Base-Neutral-50, #F6F9FF);
    width: 100%;
    .section {
        display: flex;
        min-width: 35.875rem;
        padding: 2.5rem 3.75rem;
        justify-content: center;
        align-items: flex-start;
        gap: 2.75rem;
        flex: 1 0 0;
        align-self: stretch;
    }
    .avatar-upload_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
    }
    .avatar_wrapper {
        display: flex;
        padding: 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 12.5rem;
        border: 1px dashed var(--Base-Neutral-600, #1A384E);
    }
    .placeholder {
        width: 9.875rem;
        height: 9.875rem;
    }
`;
