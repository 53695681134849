import React, { ReactNode } from 'react';
import { StyledColexoSwitchOffIcon } from './ColexoSwitchOffIcon.styles';

interface ColexoSwitchOffIconProps {
    className?: string;
    children?: ReactNode;
}

export const ColexoSwitchOffIcon: React.FC<ColexoSwitchOffIconProps> = ({ className, children }) => {
    return (
        <StyledColexoSwitchOffIcon className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M20.9786 5.51818L16.3157 0.856288C16.1606 0.701226 15.9766 0.578223 15.774 0.494302C15.5715 0.410381 15.3543 0.367188 15.1351 0.367188C14.9158 0.367188 14.6987 0.410381 14.4961 0.494302C14.2935 0.578223 14.1095 0.701226 13.9545 0.856288L1.08836 13.7224C0.932664 13.8769 0.809224 14.0607 0.725216 14.2633C0.641207 14.4659 0.598307 14.6832 0.599007 14.9025V19.5654C0.599007 20.0082 0.774894 20.4328 1.08797 20.7459C1.40105 21.059 1.82568 21.2348 2.26844 21.2348H6.93137C7.15069 21.2355 7.36795 21.1926 7.57054 21.1086C7.77313 21.0246 7.95699 20.9012 8.11145 20.7455L16.8415 12.0164L17.2046 13.4668L13.365 17.3054C13.2083 17.4619 13.1203 17.6742 13.1202 17.8956C13.1201 18.117 13.2079 18.3294 13.3644 18.486C13.5209 18.6426 13.7332 18.7307 13.9546 18.7308C14.176 18.7309 14.3884 18.643 14.545 18.4865L18.7186 14.313C18.8207 14.2111 18.8946 14.0845 18.9332 13.9455C18.9718 13.8065 18.9737 13.6599 18.9388 13.52L18.2188 10.6392L20.9786 7.87938C21.1337 7.72436 21.2567 7.5403 21.3406 7.33773C21.4245 7.13516 21.4677 6.91805 21.4677 6.69878C21.4677 6.47951 21.4245 6.2624 21.3406 6.05983C21.2567 5.85726 21.1337 5.6732 20.9786 5.51818ZM2.26844 16.5719L5.26194 19.5654H2.26844V16.5719ZM7.27674 19.22L2.6138 14.5571L11.4503 5.7206L16.1133 10.3835L7.27674 19.22ZM17.2933 9.20345L12.6314 4.54052L15.1356 2.03637L19.7975 6.6993L17.2933 9.20345Z" fill="#1A384E" fillOpacity="0.5"/>
        </StyledColexoSwitchOffIcon>
    );
};
