import React, { ReactNode, useState } from 'react';
import { StyledNumericTemplate } from './NumericTemplate.styles';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import {
    IDesignNumericQuestion,
    IDesignTableQuestion,
    INumericValue,
    ITextValue,
} from '../../../../../../../../serverTypes';
import { NumericFormat } from 'react-number-format';
import { StyledTextTemplate } from '../text/TextTemplate.styles';
import { IQuestionData } from '../../../../types';
import classNames from 'classnames';
import { Tooltip } from '@baumeister/web-ui-bootstrap';

interface NumericTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
}

export const NumericTemplate: React.FC<NumericTemplateProps> = ({ className, questionData, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const [ value, setValue ] = useState<string>((questionData.values[0].value as INumericValue).input || '');
    const onAccept = (value: string) => {
        cenSocketProtocol.requestUpdateValue(questionData.values[0].link, "input", value);
    }
    const disabled = questionData.instance.locked;
    const fractionalDigits = (questionData.design as IDesignNumericQuestion).fractionalDigits;
    const thousandSeparator = (questionData.design as IDesignNumericQuestion).thousandSeparator;
    const description = (questionData.values[0].value as INumericValue).description;
    const tooltipId = description ? `numeric-tooltip-${questionData.values[0].value.baseValueId}` : undefined;
    return <React.Fragment><StyledNumericTemplate
        className={classNames(className, 'numeric')}
        value={value}
        data-tooltip-id={tooltipId}
        decimalScale={fractionalDigits ? 2 : 0}
        fixedDecimalScale={true}
        decimalSeparator={','}
        thousandSeparator={thousandSeparator ? '.' : undefined}
        disabled={disabled}
        onValueChange={(values) => {
            setValue(values.value);
            onAccept(values.value);
        }}
    />
        {description && tooltipId && <Tooltip className={'notice-tooltip'} placement={'top'} target={tooltipId}>
            {description}
        </Tooltip>}
    </React.Fragment>
};
