import styled from 'styled-components';

export const StyledReportPreviewContent = styled.div`
    display: flex;
    height: 203.6875rem;
    flex-direction: column;
    align-items: center;
    gap: 3.75rem;
    flex-shrink: 0;
    align-self: stretch;
    .top-content {
        display: flex;
        padding: 0rem 3.75rem 3.75rem 3.75rem;
        flex-direction: column;
        align-items: center;
        gap: 5rem;
        align-self: stretch;
    }
`;
