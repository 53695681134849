import styled from 'styled-components';

export const StyledToggle = styled.div`
    display: flex;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.88088rem 0rem 0.86913rem 0rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -1.40625rem;
    top: 2.45706rem;
    border-radius: 0.375rem;
    border: 1px solid var(--Base-Neutral-600, #1A384E);
    background: var(--Base-Neutral-600, #1A384E);
    cursor: pointer;
    svg {
        width: 0.5rem;
        height: 1rem;
        flex-shrink: 0;
    }
    &.collapsed {
        rotate: 180deg;
    }
`;
