import styled from 'styled-components';

export const StyledTeamMemberPhoto = styled.div`
    img {
        width: 4.875rem;
        height: 4.875rem;
        border-radius: 50%;
        object-fit: cover;
    }
`;
