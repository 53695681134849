import React, { ReactNode } from 'react';
import { StyledTableCell } from './TableCell.styles';

interface TableCellProps {
    className?: string;
    children?: ReactNode;
}

export const TableCell: React.FC<TableCellProps> = ({ className, children }) => {
    return (
        <StyledTableCell className={className}>
            {children}
        </StyledTableCell>
    );
};
