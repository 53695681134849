import styled from 'styled-components';

export const StyledContentFrame = styled.div`
    display: flex;
    max-width: 100rem;
    justify-content: center;
    align-items: center;
    gap: 6.25rem;
    align-self: stretch;
`;
