import React, { ReactNode, useState } from 'react';
import { StyledSectionTypeInstanceContent } from './SectionTypeInstanceContent.styles';
import { LeftContent } from './left/LeftContent';
import { RightContent } from './right/RightContent';
import classNames from 'classnames';
import { Toggle } from './left/toggle/Toggle';
import { InputContent } from './right/inputcontent/InputContent';
import { Preview } from './preview/Preview';

export type LeftContentCallback = (collapsed: boolean) => ReactNode;

interface SectionTypeInstanceContentProps {
    className?: string;
    leftContent: LeftContentCallback;
    rightContent: LeftContentCallback;
    children?: ReactNode;
}


export const SectionTypeInstanceContent:
    React.FC<SectionTypeInstanceContentProps> = ({ className, leftContent, rightContent, children }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    return <StyledSectionTypeInstanceContent
        className={className}>
        <div className={classNames('side-nav', collapsed && 'collapsed')}>
            <LeftContent collapsed={collapsed} setCollapsed={setCollapsed}>
                {leftContent(collapsed)}
            </LeftContent>
            <Toggle onClick={() => setCollapsed(!collapsed)} collapsed={collapsed}/>
        </div>
        <RightContent collapsed={collapsed}>
            {rightContent(collapsed)}
        </RightContent>
        {children}
    </StyledSectionTypeInstanceContent>;
};
