import React, { ReactNode } from 'react';
import { StyledTaskWrapper } from './TaskWrapper.styles';
import { CircleWrapper } from '../../../../../../../onboarding/content-frame/right/step/circle-wrapper/CircleWrapper';
import classNames from 'classnames';
import { VectorIcon } from '../../../../../../../../../components/icons/VectorIcon';

interface TaskWrapperProps {
    className?: string;
    children?: ReactNode;
    done: boolean;
    title: string;
    subtitle: string;
}

export const TaskWrapper: React.FC<TaskWrapperProps> = ({ className, done, title, subtitle, children }) => {
    return (
        <StyledTaskWrapper className={className}>
            <div className={'circle'}>
                <CircleWrapper className={classNames('circle-result', done && 'done')} iconClassName={classNames('ellipse-icon', done && 'done')}>
                    <div className={'icon'}>
                        <VectorIcon/>
                    </div>
                </CircleWrapper>
            </div>
            <div className={'text-wrapper'}>
                <div className={'title'}>
                    {title}
                </div>
                <div className={classNames('subtitle', done && 'done')}>
                    {subtitle}
                </div>
            </div>
        </StyledTaskWrapper>
    );
};
