import React, { ReactNode } from 'react';
import { StyledDownloadIcon } from './DownloadIcon.styles';

interface DownloadIconProps {
    className?: string;
    children?: ReactNode;
}

export const DownloadIcon: React.FC<DownloadIconProps> = ({ className, children }) => {
    return (
        <StyledDownloadIcon className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <g clipPath="url(#clip0_3015_12827)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.93652 11.2336C5.80019 11.0788 5.7554 10.8637 5.81881 10.6674C5.88216 10.4711 6.04426 10.3227 6.24538 10.2769C6.44655 10.2312 6.6569 10.2947 6.79896 10.4442L9.4358 13.3246V2.68291C9.43983 2.47666 9.55214 2.28786 9.73143 2.18584C9.91073 2.08389 10.1304 2.08389 10.3097 2.18584C10.489 2.28786 10.6013 2.47666 10.6053 2.68291V13.3246L13.2421 10.4442C13.3837 10.2925 13.5952 10.2273 13.7976 10.2728C14.0001 10.3183 14.1633 10.4677 14.2264 10.6653C14.2896 10.863 14.2432 11.0793 14.1046 11.2338L10.4517 15.2238C10.3411 15.345 10.1846 15.414 10.0205 15.414C9.85642 15.414 9.69992 15.345 9.5893 15.2238L5.93652 11.2336ZM18.7912 12.5834C18.6361 12.5834 18.4874 12.6452 18.3778 12.7548C18.2682 12.8645 18.2066 13.0132 18.2065 13.1682V18.5015H1.10482V13.1682C1.10078 12.962 0.988479 12.7732 0.809187 12.6712C0.629887 12.5693 0.41017 12.5693 0.23087 12.6712C0.0516369 12.7732 -0.0607298 12.962 -0.0646964 13.1682V19.0847C-0.0649569 19.2399 -0.00343307 19.3888 0.106204 19.4986C0.215904 19.6084 0.364737 19.6701 0.519937 19.6701H18.7916C18.9466 19.6702 19.0954 19.6087 19.2051 19.4992C19.3147 19.3896 19.3763 19.2408 19.3763 19.0858V13.1683C19.3762 13.0133 19.3145 12.8646 19.2049 12.755C19.0953 12.6453 18.9466 12.5836 18.7916 12.5835L18.7912 12.5834Z" fill="#1A384E" fillOpacity="0.5"/>
            </g>
            <defs>
                <clipPath id="clip0_3015_12827">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.882812)"/>
                </clipPath>
            </defs>
        </StyledDownloadIcon>
    );
};
