import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Layout } from '@baumeister/web-ui-bootstrap';

export const StyledAdminLayoutWrapper = styled.div``;

export const StyledSidebarHeader = styled.div`
    width: 220px;
    margin-top: 10px;
    margin-bottom: 110px;
    .logo {
        margin-left: 10px;
    }
`;

export const StyledSidebarLink = styled(NavLink)`
    display: block;
    width: 200px;
    background-color: #cbffe7;
    padding: 5px 10px;
    border: 5px solid #cbffe7;
    border-radius: 5px;
    .icon {
        display: none;
    }
`;

export const StyledLayout = styled(Layout)`
    main {
        width: calc(100vw - 270px);
    }
`;
