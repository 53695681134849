import styled from 'styled-components';

export const StyledDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xl, 1rem);
    align-self: stretch;
    background: #FFF;
    position: relative;

    .frame3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
        align-self: stretch;
    }
    .questionTitle {
        color: #1A384E;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
    .questionTitle .mandatory {
        color: #FF045F;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
`;
