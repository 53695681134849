import styled from 'styled-components';

export const StyledQuestionInput = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    & .buttonwrap {
        align-self: baseline;
    }
`;
