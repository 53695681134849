import React, { ReactNode } from 'react';
import { StyledPaginationNumberBase } from './PaginationNumberBase.styles';
import classNames from 'classnames';

interface PaginationNumberBaseProps {
    className?: string;
    content: number;
    pageCount: number;
    pageNumber: number;
    onClick: (pageNumber: number) => void;
}

export const PaginationNumberBase: React.FC<PaginationNumberBaseProps> = ({ className, content, pageNumber, pageCount, onClick }) => {
    const disabled = content < 0;
    const active = content === pageNumber + 1;
    return (
        <StyledPaginationNumberBase className={classNames(className, active && 'active', disabled && 'disabled')}>
            <button className={classNames('content', active && 'active')} disabled={disabled} onClick={!disabled ? () => onClick(content - 1) : undefined}>
                {content > 0 ? content : '...'}
            </button>
        </StyledPaginationNumberBase>
    );
};
