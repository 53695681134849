import React, { ReactNode } from 'react';
import { StyledDashboardPage } from './DashboardPage.styles';
import { CompanyInfoSection } from './company-info-section/CompanyInfoSection';
import { CompanyInfoComponent } from './company-info-component/CompanyInfoComponent';
import { WorkflowSection } from './workflow-section/WorkflowSection';
import { TransactionSection } from './transaction-section/TransactionSection';

interface DashboardPageProps {
    className?: string;
}

export const DashboardPage: React.FC<DashboardPageProps> = ({ className }) => {
    return (
        <StyledDashboardPage className={className}>
            <CompanyInfoSection>
                <CompanyInfoComponent>
                </CompanyInfoComponent>
            </CompanyInfoSection>
            <WorkflowSection/>
            <TransactionSection/>
        </StyledDashboardPage>
    );
};
