import React, { ReactNode } from 'react';
import { StyledReportTextTemplate } from './ReportTextTemplate.styles';
import { IReportTextWidget } from '../../../../../types';
import { ReportFileSources } from '../fileSources/ReportFileSources';

interface ReportTextTemplateProps {
    className?: string;
    widget: IReportTextWidget;
    fullPage: boolean;
}

export const ReportTextTemplate: React.FC<ReportTextTemplateProps> = ({ className, fullPage, widget }) => {
    const title = widget.design?.title;
    return (
        <StyledReportTextTemplate className={className}>
            <div className={'detailWrapper'}>
                {title && <div className={'subTitle'}>{title}</div>}
                <div className={'inputField'}>
                    <div className={'content'}>{widget.value.content}</div>
                    <ReportFileSources fileValues={widget.fileSources || []}/>
                </div>
            </div>
        </StyledReportTextTemplate>
    );
};
