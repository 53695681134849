import React, { ReactNode } from 'react';
import { StyledAiIcon } from './AiIcon.styles';

interface AiIconProps {
    className?: string;
    children?: ReactNode;
}

export const AiIcon: React.FC<AiIconProps> = ({ className, children }) => {
    return (
        <StyledAiIcon className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
            <path d="M11.9859 8.13199C11.9653 8.04459 11.9234 7.96364 11.8639 7.89633C11.8044 7.82903 11.7293 7.77745 11.6451 7.74617L7.71811 6.27313L8.71741 1.27461C8.74004 1.15847 8.72428 1.03812 8.67251 0.931724C8.62074 0.825329 8.53577 0.738658 8.43042 0.68479C8.32507 0.630923 8.20506 0.612782 8.08849 0.633105C7.97193 0.653427 7.86514 0.711111 7.78423 0.797452L0.149772 8.97723C0.0877659 9.04257 0.0429098 9.12225 0.0192115 9.20915C-0.00448681 9.29605 -0.0062894 9.38747 0.0139649 9.47524C0.0342193 9.56301 0.0758995 9.64439 0.135282 9.71212C0.194665 9.77985 0.2699 9.83182 0.354267 9.86337L4.28261 11.3364L3.28604 16.3295C3.26341 16.4456 3.27917 16.566 3.33094 16.6724C3.38271 16.7788 3.46768 16.8654 3.57303 16.9193C3.67838 16.9732 3.79839 16.9913 3.91495 16.971C4.03152 16.9507 4.13831 16.893 4.21921 16.8066L11.8537 8.62686C11.9146 8.56151 11.9585 8.48221 11.9815 8.39592C12.0046 8.30963 12.0061 8.219 11.9859 8.13199ZM4.73181 14.6642L5.4455 11.0937C5.47104 10.9671 5.4508 10.8355 5.38838 10.7224C5.32597 10.6093 5.22543 10.5221 5.10467 10.4762L1.50284 9.1231L7.27095 2.94328L6.55795 6.51375C6.5324 6.64038 6.55264 6.77197 6.61506 6.88507C6.67748 6.99817 6.77802 7.08544 6.89877 7.13133L10.4979 8.48099L4.73181 14.6642Z" fill="#EDFFF6"/>
        </StyledAiIcon>
    );
};
