import React from 'react';
import {Amount} from './Amount';
import {
    acceptNumber,
    CenValueRenderer,
    registerCenValueRenderer,
    StyledJSXElement, ValueType,
} from '@baumeister/web-cen-protocol';

export const amountRenderer: CenValueRenderer = (value: ValueType) => {
    return {
        styles: {
            textAlign: 'right',
        },
        element: <Amount value={value as number} />,
    } as StyledJSXElement;
};

export const registerAmountRenderer = () => {
    registerCenValueRenderer('float', { acceptNumber }, amountRenderer, 'currency');
};
