import React, { ReactNode } from 'react';
import { AppContainer, Layout } from '@baumeister/web-ui-bootstrap';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectBreadcrumbs, selectTitle } from '@baumeister/web-cen-protocol';
import { selectActions } from '@baumeister/web-cen-protocol';
import { ColexoLogo } from './ColexoLogo';
import { ColexoNavbar } from './navbar/ColexoNavbar';
import { StyledLayout, StyledSidebarHeader, StyledSidebarLink } from './AdminLayoutWrapper.styles';
import { StyledEmptyColexoLogo } from './EmptyColexoLogo.styles';
import { EmptyColexoLogo } from './EmptyColexoLogo';

interface AdminLayoutWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const AdminLayoutWrapper: React.FC<AdminLayoutWrapperProps> = ({ className, children }) => {
    const title = useAppSelector((state) => selectTitle(state.layout));
    const breadcrumbs = useAppSelector((state) => selectBreadcrumbs(state.layout));
    const actions = useAppSelector((state) => selectActions(state.layout));
    const navigate = useNavigate();
    return (
        <AppContainer className={'admin'} fluid={true}>
            <StyledLayout
                className={className}
                title={title || ''}
                breadcrumbs={breadcrumbs}
                actions={actions}
                useSidebar={true}
                useBreadcrumbs={true}
                useTitle={true}
                breadcrumbClickHandler={(element) => (element.href ? () => navigate(element.href || '') : undefined)}
                logo={<EmptyColexoLogo/>}
                navbar={<ColexoNavbar useNavbar={true} />}
                sidebarLinkRenderer={(href, props, children) =>
                    href ? (
                        <StyledSidebarLink to={href} {...props}>
                            {children}
                        </StyledSidebarLink>
                    ) : undefined
                }
            >
                {children}
            </StyledLayout>
        </AppContainer>
    );
};
