import React, { ReactNode } from 'react';
import { StyledKnobOffIcon } from './KnobOffIcon.styles';

interface KnobIconProps {
    className?: string;
}

export const KnobOffIcon: React.FC<KnobIconProps> = ({ className }) => {
    return (
        <StyledKnobOffIcon className={className} xmlns="http://www.w3.org/2000/svg" width="43" height="44" viewBox="0 0 43 44" fill="none">
            <g filter="url(#filter0_d_3666_1445)">
                <rect x="1.83484" y="1.80469" width="40" height="40" rx="20" fill="#1A384E"/>
                <rect x="1.58484" y="1.55469" width="40.5" height="40.5" rx="20.25" stroke="black" strokeOpacity="0.02" strokeWidth="0.5"/>
                <path d="M31.7807 16.5188L27.1174 11.8563C26.9624 11.7013 26.7783 11.5782 26.5757 11.4943C26.3732 11.4104 26.156 11.3672 25.9368 11.3672C25.7175 11.3672 25.5003 11.4104 25.2978 11.4943C25.0952 11.5782 24.9111 11.7013 24.7561 11.8563L11.8892 24.7239C11.7335 24.8784 11.61 25.0623 11.526 25.2649C11.442 25.4675 11.3991 25.6848 11.3998 25.9041V30.5676C11.3998 31.0104 11.5757 31.435 11.8888 31.7482C12.2019 32.0613 12.6265 32.2372 13.0693 32.2372H17.7325C17.9519 32.2379 18.1692 32.195 18.3718 32.111C18.5744 32.0269 18.7582 31.9035 18.9127 31.7478L27.6433 23.0177L28.0065 24.4682L24.1665 28.3073C24.0099 28.4638 23.9218 28.6761 23.9217 28.8976C23.9216 29.119 24.0095 29.3314 24.166 29.488C24.3225 29.6447 24.5348 29.7327 24.7562 29.7328C24.9776 29.7329 25.19 29.6451 25.3467 29.4886L29.5205 25.3145C29.6226 25.2126 29.6965 25.086 29.7351 24.947C29.7737 24.808 29.7756 24.6614 29.7407 24.5215L29.0207 21.6403L31.7807 18.8802C31.9357 18.7252 32.0587 18.5411 32.1427 18.3385C32.2266 18.1359 32.2698 17.9188 32.2698 17.6995C32.2698 17.4802 32.2266 17.2631 32.1427 17.0605C32.0587 16.8579 31.9357 16.6738 31.7807 16.5188ZM13.0693 27.5737L16.063 30.5676H13.0693V27.5737ZM18.0779 30.2222L13.4147 25.5587L22.2518 16.7212L26.915 21.3847L18.0779 30.2222ZM28.0951 20.2044L23.433 15.541L25.9373 13.0366L30.5995 17.7L28.0951 20.2044Z" fill="#00FF8C"/>
            </g>
            <defs>
                <filter id="filter0_d_3666_1445" x="0.734839" y="0.954687" width="42.2" height="42.2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="0.1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3666_1445"/>
                    <feOffset dy="0.25"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3666_1445"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3666_1445" result="shape"/>
                </filter>
            </defs>
        </StyledKnobOffIcon>
    );
};
