import React, { ReactNode } from 'react';
import { StyledContentWrapper } from './ContentWrapper.styles';

interface ContentWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ className, children }) => {
    return <StyledContentWrapper className={className}>{children}</StyledContentWrapper>;
};
