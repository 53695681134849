import React, { ReactNode } from 'react';
import { StyledOnboardingFrame } from './OnboardingFrame.styles';

interface OnboardingFrameProps {
    className?: string;
    children?: ReactNode;
}

export const OnboardingFrame: React.FC<OnboardingFrameProps> = ({ className, children }) => {
    return <StyledOnboardingFrame className={className}>{children}</StyledOnboardingFrame>;
};
