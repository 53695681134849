import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledPromptTemplate = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    .ai-button button span.caption {
        display: none;
    }
    &.locked {
        gap: 0;
    }
    button {
        display: flex;
        width: 8.625rem;
        height: 1.75rem;
        padding: 0.375rem 0.75rem 0.375rem 0.375rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: var(--Base-White, #FFF);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.75rem */
    }
    button.idle {
        border-radius: 0.375rem;
        background: var(--Purple-Gradient, linear-gradient(90deg, #C73AF9 0%, #7A0099 100%));
    }
    button.queued {
        border-radius: 0.375rem;
        background: var(--Dark-BTN-Color, linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #1A384E);
    }
    button.processing {
        border-radius: 0.375rem;
        background: var(--Disabled-BTN-Color, linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(26, 56, 78, 0.50));
    }

    button.disabled {
        display: none;
    }
`
;

export const StyledPromptTextArea = styled(TextareaAutosize)`
    display: flex;
    padding: 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    flex: 1 0 0;
    border-radius: 0.375rem;
    border: 1px solid #D8DCE5;
    background: #FFF;
    color: #1A384E;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:disabled {
        opacity: 0.5;
    }
    &.busyField {
        background: rgba(33, 33, 33, 0.4);
        opacity: 0.7;

    }
`;

export const StyledPromptInput = styled.input`
    display: flex;
    padding: 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    flex: 1 0 0;
    border-radius: 0.375rem;
    border: 1px solid #D8DCE5;
    background: #FFF;
    color: #1A384E;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:disabled {
        opacity: 0.5;
    }
    &.busyField {
        background: rgba(33, 33, 33, 0.4);
        opacity: 0.7;
    }
`;
