import styled from 'styled-components';

export const StyledSearchBar = styled.div`
    display: flex;
    width: 37.5rem;
    min-width: 21.5625rem;
    max-width: 40rem;
    align-items: flex-start;
    gap: 49.5rem;
    .search-field {
        display: flex;
        padding: var(--spacing-lg, 0.75rem);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-lg, 0.75rem);
        flex: 1 0 0;
        border-radius: var(--radius-sm, 0.375rem);
        border: 1px solid var(--Athens-Gray-100, #E9EBF0);
        background: var(--Base-White, #FFF);
    }
    input {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }
`;
