import React, { useCallback, useEffect, useState } from 'react';
import { Configuration, FrontendApi, UpdateSettingsFlowBody, UiNode, SettingsFlow } from '@ory/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { filterNodesByGroups } from '@ory/integrations/ui';
import { IOryUIOptions, mapUINode } from './utils';
import { OryLayout } from './OryLayout';
import { OryPageEnum } from './types';
import { sdk } from './sdk';
import { useAuth } from './AuthContext';

export interface SettingsPageProps {
}

export const SettingsPage: React.FC<SettingsPageProps> = () => {
    const [flow, setFlow] = useState<SettingsFlow | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { login } = useAuth();

    const getFlow = useCallback(
        (flowId: string) =>
            sdk
                .getSettingsFlow({ id: flowId })
                .then(({ data: flow }) => setFlow(flow))
                .catch((err) => {
                    console.error(err);
                    if (err.response?.status === 410) {
                        // Flow expired
                        createFlow();
                    }
                }),
        []
    );

    const createFlow = () => {
        sdk
            .createBrowserSettingsFlow()
            .then(({ data: flow }) => {
                setSearchParams({ ["flow"]: flow.id });
                setFlow(flow);
            })
            .catch((err) => {
                console.error(err);
                navigate("/auth/login");
            });
    };

    const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const formData = new FormData(evt.currentTarget);

        const submitter = (evt.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
        console.log("Submitter name:", submitter.name);
        console.log("Submitter value:", submitter.value);

        const body: UpdateSettingsFlowBody = {
            method: 'password',
            csrf_token: formData.get("csrf_token")?.toString() || "",
            password: formData.get("password")?.toString() || "",
        };

        submitFlow(body);
    };
    const submitFlow = async (body: UpdateSettingsFlowBody) => {
        if (!flow) return navigate("/auth/login", { replace: true });

        try {

            const { data: updatedFlow } = await sdk
                .updateSettingsFlow({
                    flow: flow.id,
                    updateSettingsFlowBody: body,
                });
            console.log("Password successfully updated:", updatedFlow);
            alert("Password updated successfully!");
            // Then get the session
            const { data: session } = await sdk.toSession();

            // Finally update auth state which will trigger the router change
            login(session.id, {
                id: session.identity.id,
                username: session.identity.traits.email
            });
        } catch (err: any) {
            console.error(err);
            if (err.response?.status === 400) {
                setFlow(err.response.data);
            } else {
                console.error(err);
                navigate("/auth/login");
            }
        }
    };

    useEffect(() => {
        const flowId = searchParams.get("flow");
        if (flowId) {
            getFlow(flowId);
        } else {
            createFlow();
        }
    }, [getFlow]);

    const oryOptions: IOryUIOptions = {
        submitButtonPostfix: (label) => null,
    };

    const nodes: UiNode[] = (flow?.ui.nodes ?? []).filter((node) =>
        ["default", "password"].includes(node.group)
    );

    console.log(flow);
    return flow ? (
        <OryLayout
            errorMessages={[]}
            title={"Passwort ändern"}
            subtitle={"Erstellen Sie ein sicheres neues Passwort."}
            handleSubmit={handleFormSubmit}
            page={OryPageEnum.Settings}
        >
            {nodes.map((node, key) =>
                mapUINode(OryPageEnum.Settings, node, key, oryOptions)
            )}
        </OryLayout>
    ) : (
        <div>Loading...</div>
    );
};

export default SettingsPage;
