import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { entitySlice } from '@baumeister/web-cen-protocol';
import { setupWebCenProtocolLibrary } from '@baumeister/web-cen-protocol';
import { layoutSlice } from '@baumeister/web-cen-protocol';

const appStore = configureStore({
    reducer: {
        entities: entitySlice.reducer,
        layout: layoutSlice.reducer,
    },
});

export type RootState = ReturnType<typeof appStore.getState>;
export type appDispatch = typeof appStore.dispatch;

export const useAppDispatch: () => appDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupWebCenProtocolLibrary(
    () => appStore.getState().entities,
    (selector) => useAppSelector((state) => selector(state.entities)),
    (selector) => useAppSelector((state) => selector(state.layout)),
    appStore.dispatch
);

export default appStore;
