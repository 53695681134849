import styled from 'styled-components';
import { DocRoomCard } from '../doc-room-card/DocRoomCard';

export const StyledTasks = styled(DocRoomCard)`
    display: flex;
    padding: 2.5rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    flex: 1 0 0;
    .button {
        align-self: unset;
    }
`;
