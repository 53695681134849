import React, { ReactNode } from 'react';
import { StyledNavLinkElement } from './NavLinkElement.styles';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface TopBarElementProps {
    className?: string;
    title: string;
    children?: ReactNode;
    link?: string | undefined;
    absolute?: boolean;
    active?: boolean;
}

export const NavLinkElement: React.FC<TopBarElementProps> = ({ className, title, link, absolute, active, children }) => {
    const navigate = useNavigate();
    return <StyledNavLinkElement className={classNames(className, active && 'active')}
                           href={absolute ? link : (link ? '' : undefined)}
                           onClick={absolute ? undefined : (link ? (evt) => { evt.preventDefault(); navigate(link); } : undefined)}
    >
        <div className={'title'}>{title}</div>
    </StyledNavLinkElement>;
};
