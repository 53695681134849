import React, { useEffect, useState } from 'react';
import { StyledFileValueProcessingAlert, StyledTimer } from './FileValueProcessingAlert.styles';
import { Button } from '../../../../../../../../components/button/Button';
import { SmallEllipseIcon } from '../../../../../../../../components/icons/SmallEllipseIcon';

interface FileValueProcessingAlertProps {
    className?: string;
    onChange: () => void;
    hideAlert: () => void;
}

interface TimerProps {
    className?: string;
    onFinished: () => void;
}

const TIMER_VALUE = 60;
const Timer: React.FC<TimerProps> = ({className, onFinished }) => {
    const [timeLeft, setTimeLeft] = useState<number>(TIMER_VALUE - 1);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            onFinished();
        }
    }, [timeLeft]);
    return (
        <StyledTimer>
            <div className={'text'}>{timeLeft}</div>
            <SmallEllipseIcon className={'ellipse'}/>
        </StyledTimer>
    )
}

export const FileValueProcessingAlert: React.FC<FileValueProcessingAlertProps> = ({ className, hideAlert, onChange }) => {
    const [finished, setFinished] = useState<boolean>(false);
    return (
        <StyledFileValueProcessingAlert
                className={className}
                hideAlert={hideAlert}
                title={'Bitte warten. Wir werten Ihre Dateien gerade aus.'}
                notice={'Die Auswertung dauert in der Regel weniger als 1 Minute. Bitte schließen Sie dieses Fenster nicht vor der vollständigen Auswertung.'}
            >
                <Button className={'timerButton'}
                        variant={finished ? 'primary' : 'action'}
                        caption={'Weiter zu den Fragen'}
                        disabled={!finished}
                        onClick={() => hideAlert()}
                >
                    {!finished && <Timer onFinished={() => setFinished(true)}/>}
                </Button>
                <a className={'link'} href={'#'} onClick={() => hideAlert()}>Abbrechen und selbst weitermachen</a>
        </StyledFileValueProcessingAlert>
    );
};
