import React, { ReactNode } from 'react';
import { StyledVector3 } from './Vector3.styles';

interface Vector3Props {
    className?: string;
    children?: ReactNode;
}

export const Vector3: React.FC<Vector3Props> = ({ className, children }) => {
    return (
        <StyledVector3 className={className} xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
            <path d="M1.76601 8.09907C1.47005 6.63849 1.1741 5.18932 0.871094 3.72874C1.28684 3.0555 1.70259 2.38226 2.11834 1.70903C2.42839 3.03268 2.74548 4.34492 3.05553 5.66857C3.99272 3.99119 4.92287 2.30239 5.86007 0.625C6.37446 0.990146 6.88182 1.35529 7.39622 1.70903C6.24058 3.8999 5.0779 6.09077 3.92226 8.28165C3.20351 8.21318 2.48476 8.15613 1.76601 8.09907Z" fill="#00FF8C"/>
        </StyledVector3>
    );
};
