import { RecoveryFlow, UiNode, UpdateRecoveryFlowBody } from '@ory/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sdk, sdkError } from './sdk';
import { IOryUIOptions, mapUINode } from './utils';
import { filterNodesByGroups } from '@ory/integrations/ui';
import { OryLayout } from './OryLayout';
import { OryPageEnum } from './types';
import { AxiosError } from 'axios';

export const Recovery = () => {
    const [flow, setFlow] = useState<RecoveryFlow | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const fetchFlow = (retrying: boolean, flowId?: string| null): any => {
        const flowPromise = !flowId || retrying
            ? sdk.createBrowserRecoveryFlow()
            : sdk.getRecoveryFlow({ id: flowId });

        return flowPromise
            .then(flowResponse => {
                setSearchParams({ ["flow"]: flowResponse.data.id });
                setFlow(flowResponse.data);
            })
            .catch((error: AxiosError) => {
                if (!retrying && error.response?.status === 400) {
                    return sdk.createBrowserLogoutFlow()
                        .then(({ data }: any) => {
                            // Handle the logout URL
                            if (data.logout_url) {
                                window.location.href = data.logout_url;
                                // Return a never-resolving promise since we're redirecting
                                return new Promise(() => {});
                            }
                            // If no logout URL, continue with recovery
                            return fetchFlow(true, flowId);
                        })
                        .catch(logoutError => {
                            console.error('Logout failed:', logoutError);
                            return Promise.reject(logoutError);
                        });
                }
                return Promise.reject(error);
            });
    };

    const getFlow = useCallback(
        (flowId: string) => {
            return fetchFlow(false, flowId);
        },
    []);

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = sdkError(getFlow, setFlow, "/auth/recovery")

    // create a new recovery flow
    const createFlow = () => {
        fetchFlow(false, null);
    }

    const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const formData = new FormData(evt.currentTarget);

        const submitter = (evt.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
        console.log('Submitter name:', submitter.name);
        console.log('Submitter value:', submitter.value);

        // For the initial 'choose_method' state
        if (submitter.name === 'method') {
            const email = formData.get("email")?.toString();
            const code = formData.get("code")?.toString();
            const body: UpdateRecoveryFlowBody = {
                method: 'code', // or 'link' depending on your implementation
                csrf_token: formData.get("csrf_token")?.toString() || ""
            };
            if (email) {
                body.email = email;
            }
            if (code) {
                body.code = code;
            }
            submitFlow(body);
        }
        // For the 'sent_email' state - requesting another recovery email
        else if (submitter.name === 'email') {
            const body: UpdateRecoveryFlowBody = {
                method: 'code', // or 'link' depending on your implementation
                csrf_token: formData.get("csrf_token")?.toString() || "",
                email: submitter.value, // Use the email from the resend button
            };
            submitFlow(body);
        }
        // For the 'passed_challenge' state - submitting the recovery code
        else if (formData.get("code")) {
            const body: UpdateRecoveryFlowBody = {
                method: 'code',
                csrf_token: formData.get("csrf_token")?.toString() || "",
                code: formData.get("code")?.toString() || "",
            };
            submitFlow(body);
        }
    };

    const submitFlow = (body: UpdateRecoveryFlowBody) => {
        // something unexpected went wrong and the flow was not set
        if (!flow) return navigate("/auth/login", { replace: true })

        sdk
            .updateRecoveryFlow({
                flow: flow.id,
                updateRecoveryFlowBody: body
            })
            .then(({ data: flow }) => {
                // Check the flow state and handle accordingly
                if (flow.state === 'choose_method') {
                    // Initial state - email just submitted
                    setFlow(flow);
                }
                else if (flow.state === 'sent_email') {
                    // Email has been sent - show message to check email
                    setFlow(flow);
                }
            })
            .catch((error) => {
                // Handle specific error cases
                if (error.response?.status === 400) {
                    // Invalid form data - show validation errors
                    setFlow(error.response.data);
                }
                else if (error.response?.status === 410) {
                    // Flow expired - create a new one
                    createFlow();
                }
                else {
                    console.log(error.response);
                    if (error.response?.status === 422) {
                        sdk.toSession().then((data) => {
                            console.log(data);
                            navigate("/auth/settings");
                        }).catch((error) => {
                            console.log(error);
                        })
//                        window.location = error.response.data.redirect_browser_to;
                    } else {
                        // Handle other errors
                        sdkErrorHandler(error).then(() => {});
                    }
                }
            });
    };

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = searchParams.get("flow")
        console.log(`getting flow: ${flowId}`);
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one
            return
        }
        // we assume there was no flow, so we create a new one
        createFlow()
    }, [])

    const oryOptions: IOryUIOptions = {
        submitButtonPostfix: (label) => {
            if (label.id === 1070005) {
                return undefined;
            }
            return (<div className="recoveryHelper">Wenn Sie Probleme haben, sich einzuloggen, wenden Sie sich bitte per E-Mail an unser Support-Team: <a href={"mailto:support@colexo.de"}>support@colexo.de</a></div>)
        },
    };

    const nodes:UiNode[] = (flow?.ui.nodes ?? []);

    console.log('flow', flow);
    // we check if the flow is set, if not we show a loading indicator
    return flow ? (
        // We create a dynamic Recovery form based on the flow using Ory Elements
        <OryLayout
            errorMessages={[]}
            title={"Reset Your Password"}
            subtitle={"We'll send you an email with password reset instructions. Please check your spam folder if needed."}
            handleSubmit={(evt) => handleFormSubmit(evt)}
            page={OryPageEnum.Recovery}
        >
            {filterNodesByGroups({
                nodes,
                // we will also map default fields here such as csrf_token
                // this only maps the `password` method
                // you can also map `oidc` or `webauhthn` here as well
                groups: ['code'],
            }).map((node, key) => mapUINode(OryPageEnum.Recovery, node, key, oryOptions))}
        </OryLayout>
    ) : (
        <div>Loading...</div>
    )
}
