import React, { ReactNode } from 'react';
import { StyledProgressText } from './ProgressText.styles';
import classNames from 'classnames';

interface ProgressTextProps {
    className?: string;
    children?: ReactNode;
    value: number;
    small: boolean;
}

export const ProgressText: React.FC<ProgressTextProps> = ({ className, value, small, children }) => {
    return <StyledProgressText className={classNames(className, small ? 'small' : '')}>{value} %{children}</StyledProgressText>;
};
