import styled from 'styled-components';

export const StyledHeadingWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
    color: var(--Alternative-Secondary-Blue, #22384C);
    text-align: center;
    font-family: Lato;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
