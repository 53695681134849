import styled from 'styled-components';

export const StyledNavContainer = styled.nav`
    display: flex;
    align-items: center;
    gap: var(--spacing-3xl, 1.5rem);
    .iconDashboard {
        width: 1.625rem;
        height: 1.625rem;
        fill: var(--Base-Neutral-300, rgba(26, 56, 78, 0.80));
    }
`;
