import React, { ReactNode } from 'react';
import { StyledAvatarIcon } from './AvatarIcon.styles';

interface AvatarIconProps {
    className?: string;
    children?: ReactNode;
}

export const AvatarIcon: React.FC<AvatarIconProps> = ({ className, children }) => {
    return (
        <StyledAvatarIcon className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <circle cx="16" cy="16.8047" r="16" fill="#1A384E" fillOpacity="0.5"/>
        </StyledAvatarIcon>
    );
};
