import React, { ReactNode } from 'react';
import { StyledHeadingWrapper } from './HeadingWrapper.styles';

interface HeadingWrapperProps {
    className?: string;
    children?: ReactNode;
    title: string;
}

export const HeadingWrapper: React.FC<HeadingWrapperProps> = ({ className, title, children }) => {
    return (
        <StyledHeadingWrapper className={className}>
            {title}
        </StyledHeadingWrapper>
    );
};
