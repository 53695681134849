import React, { ReactNode } from 'react';
import { StyledVector1 } from './Vector1.styles';

interface Vector1Props {
    className?: string;
    children?: ReactNode;
}

export const Vector1: React.FC<Vector1Props> = ({ className, children }) => {
    return (
        <StyledVector1 className={className} xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
            <path d="M0.627049 1.05696C0.711608 0.920028 0.81026 0.851562 0.915959 0.851562H4.29831V2.22086H1.33875V6.32875H4.29831V7.69804H0.915959C0.81026 7.69804 0.711608 7.62958 0.627049 7.49265C0.54249 7.35572 0.493164 7.19597 0.493164 7.0134V1.53621C0.493164 1.35364 0.54249 1.19389 0.627049 1.05696Z" fill="#112145"/>
        </StyledVector1>
    );
};
