import styled from 'styled-components';

export const StyledVector2 = styled.svg`
    width: 0.23781rem;
    height: 0.51275rem;
    position: absolute;
    left: 1.509rem;
    top: 1.66306rem;
    fill: #112145;
`;
