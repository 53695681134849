import React, { ReactNode } from 'react';
import { StyledTableHeader } from './TableHeader.styles';
import { TableHeadColumn } from '../headcolumn/TableHeadColumn';

interface TableHeaderProps {
    className?: string;
    children?: ReactNode;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ className, children }) => {
    return <StyledTableHeader className={className}>
        {children}
    </StyledTableHeader>;
};
