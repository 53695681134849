import React, { useEffect } from 'react';
import { ContentFrame } from './content-frame/ContentFrame';
import { ContentLeft } from './content-frame/left/ContentLeft';
import { selectPageData, useWebSocketContext } from '@baumeister/web-cen-protocol';
import { IUser } from '../../../serverTypes';
import { useAppSelector } from '../../../store';
import { OnboardingFrame } from './frame/OnboardingFrame';
import { ContentRight } from './content-frame/right/ContentRight';
import { useNavigate, useResolvedPath } from 'react-router-dom';

interface Onboarding1PageProps {
    className?: string;
}

export const Onboarding1Page: React.FC<Onboarding1PageProps> = ({ className }) => {
    const userPage = useAppSelector((state) => selectPageData(state.entities, 'user'));
    const user = userPage?.data as IUser;
    const navigate = useNavigate();
    const resolvedPath = useResolvedPath('../2', {relative: 'path'});
    const { cenSocketProtocol } = useWebSocketContext();
    useEffect(() => {
        cenSocketProtocol.requestPageByPath('user', '/user');
        cenSocketProtocol.requestPageByPath('company', '/company');
    })
    return (
        <OnboardingFrame className={className}>
            <ContentFrame>
                <ContentLeft
                    step={2}
                    title={`Hallo ${user?.firstName}! Fast geschafft!`}
                    onButtonClick={() => navigate(resolvedPath)}
                    hideArrow={true}
                    onArrowClick={() => {}}
                >
                    <p>Sie haben alle Basisdaten erfasst. Im nächsten Schritt können Sie Ihr FactSheet generieren und es an Interessenten versenden.</p>
                    <p><b>Gut zu wissen:</b><br/>Alle Informationen und Dokuente die im Schritt 1 “Fact Sheet” erfasst werden, sammelt die Software in einem Datenraum. Sie kommen Ihrem Ziel Stück für Stück immer näher.</p>
                </ContentLeft>
                <ContentRight activeStep={2}/>
            </ContentFrame>
        </OnboardingFrame>
    );
};
