import styled from 'styled-components';

export const StyledReportTableTemplate= styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .contents {
//        display: flex;
//        flex-direction: column;
//        align-items: flex-start;
        align-self: stretch;
        border-radius: 0rem 0rem var(--radius-sm, 0.375rem) var(--radius-sm, 0.375rem);
        border: 1px solid var(--Athens-Gray-200, #D8DCE5);
    }
`;

export const StyledReportTableRowTemplate = styled.tr`
//    display: flex;
    padding: 0.6875rem var(--spacing-xl, 1rem);
//    justify-content: space-between;
//    align-items: flex-start;
//    align-self: stretch;
`;

export const StyledReportTableColumnTemplate = styled.td`
    color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

    /* Paragraph XS */
    font-family: Lato;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.125rem */
    &.fullPage {
        font-size: 1rem;
    }
`;

export const StyledReportTableHeadTemplate = styled.th`
    color: var(--Base-White, #FFF);

    /* Paragraph XS - Bold */
    font-family: Lato;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.125rem */
    &.fullPage {
        font-size: 1rem;
    }
`;

export const StyledTableHead = styled.thead`
    border-radius: var(--radius-sm, 0.375rem) var(--radius-sm, 0.375rem) 0rem 0rem;
    background: var(--Base-Neutral-600, #1A384E);
`;
