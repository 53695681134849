import React, { ReactNode, useEffect, useState } from 'react';
import { StyledPromptInput, StyledPromptTemplate, StyledPromptTextArea } from './PromptTemplate.styles';
import { IQuestionData } from '../../../../types';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import { IPromptValue } from '../../../../../../../../serverTypes';
import { Button } from '../../../../../../../../components/button/Button';
import { ColexoProtocolMessage, QueuePromptValueRequestMessage } from '../../../../../../../../protocol';
import classNames from 'classnames';
import { AiIcon } from '../../../../../../../../components/icons/AiIcon';

interface PromptTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
    inTable: boolean;
}

function createTextField(inTable: boolean, className: string | undefined, disabled: undefined | boolean, setValue: (value: (((prevState: string) => string) | string)) => void, onAccept: (value: string) => void, value: string, children: ReactNode) {
    if (inTable) {
        return <StyledPromptInput
            className={className}
            disabled={disabled}
            onChange={(evt) => {
                const value = (evt.target as HTMLInputElement).value;
                setValue(value);
                onAccept(value);
            }}
            value={value}
        >{children}</StyledPromptInput>;
    }
    return <StyledPromptTextArea
        className={className}
        disabled={disabled}
        minRows={1}
        onChange={(evt) => {
            const value = (evt.target as HTMLTextAreaElement).value;
            setValue(value);
            onAccept(value);
        }}
        value={value}
    >{children}</StyledPromptTextArea>;
}

export const PromptTemplate: React.FC<PromptTemplateProps> = ({ className, questionData, inTable, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const [ value, setValue ] = useState<string>((questionData.values[0].value as IPromptValue).content || '');
    const promptValue = questionData.values[0].value as IPromptValue;
    const onAccept = (value: string) => {
        cenSocketProtocol.requestUpdateValue(questionData.values[0].link, "content", value);
    }
    const onQueuePressed = () => {
        const declineMessage:QueuePromptValueRequestMessage = {
            type: ColexoProtocolMessage.INFO_QUEUE_PROMPT_VALUE,
            payload: {
                entityPath: promptValue._link,
                queue: !promptValue.queued
            }
        };
        cenSocketProtocol.request(declineMessage);
    }
    useEffect(() => {
        const inputValue = (questionData.values[0].value as IPromptValue).content || '';
        if (inputValue !== value) {
            setValue(inputValue);
        }
    }, [(questionData.values[0].value as IPromptValue).content]);
    const disabled = questionData.instance.locked || promptValue.queued;
    const textFieldClassNames = classNames(promptValue.queued && 'busyField', className);
    const textField = createTextField(inTable, textFieldClassNames, disabled, setValue, onAccept, value, children);
    const locked = questionData.instance.locked;
    let buttonText = promptValue.queued ? 'Warten auf KI...' : 'Mit KI generieren';
    let buttonDisabled = false;
    let buttonState = promptValue.queued ? 'queued' : 'idle';
    if (promptValue.processing) {
        buttonText = 'In Bearbeitung...';
        buttonDisabled = true;
        buttonState = 'processing';
    }
    return <StyledPromptTemplate className={classNames(className, 'prompt-input-group', locked && 'locked')}>
        {textField}
        <div className={'ai-button'}>
            <Button className={classNames(disabled && 'disabled', buttonState)} caption={''} onClick={() => onQueuePressed()} disabled={buttonDisabled}><AiIcon/>{buttonText}</Button>
        </div>
    </StyledPromptTemplate>
};
