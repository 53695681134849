// src/ory/AuthContext.tsx (create if it doesn't exist)
import React, { createContext, useContext, useState } from 'react';
import { IAuthContextType, IAuthState, IUser } from './types';

const AuthContext = createContext<IAuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: (context: IAuthContextType) => React.ReactNode;
    initialState: IAuthState;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, initialState }) => {
    const [authState, setAuthState] = useState<IAuthState>(initialState);

    const login = (token: string, user: IUser) => {
        setAuthState({
            isAuthenticated: true,
            token,
            user
        });
    };

    const logout = () => {
        setAuthState({
            isAuthenticated: false,
            token: null,
            user: null
        });
    };

    const updateToken = (newToken: string) => {
        setAuthState(prev => ({
            ...prev,
            token: newToken
        }));
    };

    const value = {
        ...authState,
        login,
        logout,
        updateToken
    };

    return <AuthContext.Provider value={value}>{children(value)}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
