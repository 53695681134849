import React, { ReactNode } from 'react';
import { StyledTableColumn } from './TableColumn.styles';

interface TableColumnProps {
    className?: string;
    children?: ReactNode;
}

export const TableColumn: React.FC<TableColumnProps> = ({ className, children }) => {
    return (
        <StyledTableColumn className={className}>
            {children}
        </StyledTableColumn>
    );
};
