import styled from 'styled-components';

export const StyledItemProcessText = styled.div`
    display: flex;
    width: 2.5625rem;
    height: 0.9375rem;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0.125rem;
    top: 0.9375rem;
    color: #1A384E;
    text-align: center;

    /* Paragraph XS - Bold */
    font-family: Lato;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.125rem */
`;
