import styled from 'styled-components';

export const StyledSectionInstance = styled.div`
    display: flex;
    position: relative;
    padding: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: stretch;
    border-radius: var(--radius-xl, 0.75rem);
    background: var(--Base-White, #FFF);

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);

    & .title {
        color: #1A384E;

        /* Heading 4 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 2.6rem */
    }

    .success {
        opacity: 1;
        transition: opacity 1s ease;
    }

    .success.fade {
        opacity: 0;
    }

    & .modal-element {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: flex-start;
        gap: 0.625rem;
        border-radius: var(--radius-xl, 0.75rem);
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);
    }
`;

