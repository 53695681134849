import React, { ReactNode } from 'react';
import { StyledCustomLink } from './CustomLink.styles';
import { Link } from 'react-router-dom';

interface CustomLinkProps {
    className?: string;
    children?: ReactNode;
    href: string;
    tabIndex?: number;
}

const isExternalLink = (href: string) => {
    return href.startsWith('mailto:') || href.startsWith('http:') || href.startsWith('https:');
};


export const CustomLink: React.FC<CustomLinkProps> = ({ className, children, href, tabIndex }) => {
    if (isExternalLink(href)) {
        return <a tabIndex={tabIndex} href={href} className={className}>{children}</a>;
    }
    return <Link tabIndex={tabIndex} to={href} className={className}>{children}</Link>;
};

