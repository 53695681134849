import styled from 'styled-components';

export const StyledEditButton = styled.div`
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    position: absolute;
    right: 8.625rem;
    top: -0.5rem;
    .hover {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: var(--spacing-lg, 0.75rem);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.1875rem;
        background: var(--Athens-Gray-50, #F5F6F9);
        /* global-drop */
        box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
        border: 0;
    }

    .icon {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
    }
`;
