import styled from 'styled-components';

export const StyledVector3 = styled.svg`
    width: 0.40781rem;
    height: 0.47856rem;
    position: absolute;
    left: 1.67944rem;
    top: 1.54638rem;
    fill: var(--spring-green-500-main, #00FF8C);
`;
