import React, { ReactNode } from 'react';
import { StyledCommunicationWrapper } from './CommunicationWrapper.styles';

interface CommunicationWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const CommunicationWrapper: React.FC<CommunicationWrapperProps> = ({ className, children }) => {
    return (
        <StyledCommunicationWrapper className={className}>
            {children}
        </StyledCommunicationWrapper>
    );
};
