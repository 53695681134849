import styled from 'styled-components';

export const StyledContentForm = styled.div`
    width: 100%;
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;
    }
`;
