import styled from 'styled-components';

export const StyledUploadFileWrapper = styled.div`
    display: flex;
    width: 24.1875rem;
    min-width: 24.1875rem;
    justify-content: space-between;
    align-items: flex-start;
    .frame4571 {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
        .left-col {
            display: flex;
            align-items: flex-start;
            gap: var(--spacing-3xl, 1.5rem);
            .placeholder {
                display: flex;
                width: 4.25rem;
                height: 3rem;
                padding: 1.875rem 2.875rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: var(--radius-sm, 0.375rem);
                background: var(--Athens-Gray-50, #F5F6F9);
                color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

                /* Paragraph M */
                font-family: Lato;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 1.5rem */
            }
        }
        .text-wrapper {
            display: flex;
            width: 9.09156rem;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-md, 0.5rem);
            .display-name {
                color: var(--Base-Neutral-600, #1A384E);

                /* Paragraph XS - Bold */
                font-family: Lato;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 1.125rem */
            }
            .display-name a {
                color: var(--Base-Neutral-600, #1A384E);
            }
            .file-size {
                color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));

                /* Paragraph XS */
                font-family: Lato;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 1.125rem */
            }
        }
        .deleteButton {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-xl, 1rem);
            width: 0.75rem;
            height: 0.75rem;
            flex-shrink: 0;
            cursor: pointer;
        }
        .options {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.25rem;
            .dots {
                display: flex;
                align-items: flex-start;
                gap: 0.0625rem;
            }
            .option {
                a {
                    color: var(--Brand-Accent-800, #FF045F);

                    /* Paragraph XS - Bold */
                    font-family: Lato;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 1.125rem */
                }
            }
        }
    }
    a.disabled {
        cursor: initial;
        opacity: 0.5;
    }
`;
