import styled from 'styled-components';

export const StyledTopMenu = styled.div`
    & {
        display: flex;
        padding: 1.25rem var(--spacing-xl, 1rem) var(--spacing-xl, 1rem) var(--spacing-xl, 1rem);
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        position: absolute;
        top: 3rem;
        right: 0rem;
        border-radius: 0.625rem;
        background: var(--Athens-Gray-50, #F5F6F9);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.60), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 38px 90px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(40px);
    }
    .titleDescription {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
    }
    .title {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);

        /* Paragraph M - Bold */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
    .frame4580 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
    }
    .frame4581 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-md, 0.5rem);
    }
    .innerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--spacing-xl, 1rem);
    }
    .innerHead {
        display: flex;
        padding: var(--spacing-sm, 0.375rem) var(--spacing-lg, 0.75rem) var(--spacing-sm, 0.375rem) 0rem;
        align-items: center;
        gap: var(--spacing-xl, 1rem);
        align-self: stretch;
    }
    .frame4582 {
        display: flex;
        width: 9.40625rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .headName {
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;

        /* Paragraph XS */
        font-family: Lato;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .headMail {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;

        /* Paragraph XXs */
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 0.75rem */
    }
    .frame4585 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xs, 0.25rem);
        align-self: stretch;
    }
    .line3 {
        width: 13.15625rem;
        height: 0.0625rem;
        background: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
    }
    .innerFooter {
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
        border-radius: 0.3125rem;
    }
    .signOutFrame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
    }
    .signOutText {
        align-self: stretch;
        flex: 1 0 0;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.75rem */
    }
    .emptyFrame {
        width: 0.75rem;
        height: 0.75rem;
    }
    .signOutIcon {
        fill: var(--Base-Neutral-600, #1A384E);
        width: 0.56313rem;
        height: 0.56313rem;
    }
    .menuEntry {
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
        border-radius: 0.3125rem;
        cursor: pointer;
    }
    .menuEntry:hover {
        background: var(--Base-Neutral-150, #E3F2FF);

    }
    .innerMenuEntry {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
    }
    .innerMenuEntryText {
        align-self: stretch;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.75rem */
    }
`;
