import React, { ReactNode } from 'react';
import { Layout } from '@baumeister/web-ui-bootstrap';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectBreadcrumbs, selectTitle } from '@baumeister/web-cen-protocol';
import { selectActions } from '@baumeister/web-cen-protocol';
import { ColexoLogo } from './ColexoLogo';
import { ColexoNavbar } from './navbar/ColexoNavbar';
import { StyledAppContainer } from './AppContainer.styles';

interface LayoutWrapperProps {
    className?: string;
    children?: ReactNode;
    useNavbar: boolean;
}

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ useNavbar = true, className, children }) => {
    const title = useAppSelector((state) => selectTitle(state.layout));
    const breadcrumbs = useAppSelector((state) => selectBreadcrumbs(state.layout));
    const actions = useAppSelector((state) => selectActions(state.layout));
    const navigate = useNavigate();
    return (
        <StyledAppContainer
            fluid={false}
            className={'no-sidebar'}
        >
            <Layout
                title={title || ''}
                breadcrumbs={breadcrumbs}
                actions={actions}
                useSidebar={false}
                useBreadcrumbs={false}
                useTitle={false}
                breadcrumbClickHandler={(element) => (element.href ? () => navigate(element.href || '') : undefined)}
                logo={<ColexoLogo large={false}/>}
                navbar={<ColexoNavbar useNavbar={useNavbar} />}
            >
                {children}
            </Layout>
        </StyledAppContainer>
    );
};
