import React, { ReactNode } from 'react';
import { StyledBetrieblicheBasisdatenIcon } from './BetrieblicheBasisdatenIcon.styles';

interface BetrieblicheBasisdatenIconProps {
    className?: string;
    children?: ReactNode;
}

export const BetrieblicheBasisdatenIcon: React.FC<BetrieblicheBasisdatenIconProps> = ({ className, children }) => {
    return (
        <StyledBetrieblicheBasisdatenIcon className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M20.5833 7.82812H15.2499C13.9633 7.82812 12.9166 8.87479 12.9166 10.1615V21.1615H11.5833V13.4948C11.5833 12.2081 10.5366 11.1615 9.24992 11.1615H3.91659C2.62992 11.1615 1.58325 12.2081 1.58325 13.4948V20.8281C1.58325 22.1148 2.62992 23.1615 3.91659 23.1615H20.5833C21.8699 23.1615 22.9166 22.1148 22.9166 20.8281V10.1615C22.9166 8.87479 21.8699 7.82812 20.5833 7.82812ZM7.24992 19.8281H5.91659C5.36459 19.8281 4.91659 19.3801 4.91659 18.8281C4.91659 18.2761 5.36459 17.8281 5.91659 17.8281H7.24992C7.80192 17.8281 8.24992 18.2761 8.24992 18.8281C8.24992 19.3801 7.80192 19.8281 7.24992 19.8281ZM7.24992 16.4948H5.91659C5.36459 16.4948 4.91659 16.0468 4.91659 15.4948C4.91659 14.9428 5.36459 14.4948 5.91659 14.4948H7.24992C7.80192 14.4948 8.24992 14.9428 8.24992 15.4948C8.24992 16.0468 7.80192 16.4948 7.24992 16.4948ZM18.5833 19.8281H17.2499C16.6979 19.8281 16.2499 19.3801 16.2499 18.8281C16.2499 18.2761 16.6979 17.8281 17.2499 17.8281H18.5833C19.1353 17.8281 19.5833 18.2761 19.5833 18.8281C19.5833 19.3801 19.1353 19.8281 18.5833 19.8281ZM18.5833 16.4948H17.2499C16.6979 16.4948 16.2499 16.0468 16.2499 15.4948C16.2499 14.9428 16.6979 14.4948 17.2499 14.4948H18.5833C19.1353 14.4948 19.5833 14.9428 19.5833 15.4948C19.5833 16.0468 19.1353 16.4948 18.5833 16.4948ZM18.5833 13.1615H17.2499C16.6979 13.1615 16.2499 12.7135 16.2499 12.1615C16.2499 11.6095 16.6979 11.1615 17.2499 11.1615H18.5833C19.1353 11.1615 19.5833 11.6095 19.5833 12.1615C19.5833 12.7135 19.1353 13.1615 18.5833 13.1615Z" fill="white"/>
            <path d="M7.25 9.83034C6.698 9.83034 6.25 9.38234 6.25 8.83034V6.42768C6.25 5.45301 6.86467 4.57168 7.78067 4.23701L15.1127 1.54901C15.8287 1.28634 16.6273 1.39034 17.2513 1.82634C17.8753 2.26234 18.2487 2.97701 18.2487 3.73968V5.49701C18.2487 6.04901 17.8007 6.49701 17.2487 6.49701C16.6967 6.49701 16.2487 6.04901 16.2487 5.49701V3.73968C16.2487 3.58901 16.1593 3.50368 16.106 3.46634C16.0527 3.42901 15.942 3.37701 15.8007 3.42634L8.46733 6.11568C8.33533 6.16368 8.24867 6.28901 8.24867 6.42768V8.83034C8.24867 9.38234 7.802 9.83034 7.25 9.83034Z" fill="white"/>
        </StyledBetrieblicheBasisdatenIcon>
    );
};
