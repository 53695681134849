import styled from 'styled-components';

export const StyledUploadWrap = styled.div`
    display: flex;
    width: 26.0625rem;
    padding: var(--spacing-lg, 0.75rem);
    align-items: center;
    gap: var(--spacing-xl, 1rem);
    background: var(--Base-Neutral-200, #DFE3EA);
    .ctx-report-content-wrap {
        display: flex;
        width: 14.1875rem;
        height: 2.6875rem;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
    }
    .clx-raum-logo {
        display: flex;
        width: 15.00075rem;
        align-items: center;
        gap: -0.375rem;
        height: 1.21588rem;
        flex: 1 0 0;
        color: var(--Base-Neutral-600, #1A384E);
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 1.5rem */
    }
    .vendor-reports {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        font-family: Lato;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.125rem */
    }
    .upload.button {
        width: 2.75rem;
        height: 2.75rem;
        flex-shrink: 0;
        background: transparent;
        border: 0px;
    }
`;
