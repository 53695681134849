import React, { ReactNode } from 'react';
import { StyledContentBody } from './ContentBody.styles';
import { ContentForm } from '../form/ContentForm';

interface ContentBodyProps {
    className?: string;
}

export const ContentBody: React.FC<ContentBodyProps> = ({ className }) => {
    return <StyledContentBody className={className}>
        <ContentForm/>
    </StyledContentBody>;
};
