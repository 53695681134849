import React, { ReactNode } from 'react';
import { StyledArrowDown } from './ArrowDown.styles';

interface ArrowDownProps {
    className?: string;
    children?: ReactNode;
}

export const ArrowDown: React.FC<ArrowDownProps> = ({ className, children }) => {
    return (
        <StyledArrowDown className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M8.50004 3.80469V13.138M8.50004 13.138L13.1667 8.47135M8.50004 13.138L3.83337 8.47135" stroke="#1A384E" strokeLinecap="round" strokeLinejoin="round"/>
        </StyledArrowDown>
    );
};
