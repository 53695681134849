import React, { ReactNode } from 'react';
import { StyledTablePagination } from './TablePagination.styles';
import { ArrowLeftIcon } from '../../../../../../../../components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../../../../../../../../components/icons/ArrowRightIcon';
import { PaginationNumberBase } from './number/PaginationNumberBase';
import { ICursorDataState } from '@baumeister/web-cen-protocol';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';

interface TablePaginationProps {
    className?: string;
    cursor: ICursorDataState;
}

const calculateFourth = (pageNumber: number, pageCount: number) => {
    if (pageNumber < 2 || pageNumber + 2 >= pageCount) {
        return -1;
    }
    if (pageNumber === 2) {
        return pageNumber + 2;
    }
    return pageCount < 4 ? 4 : pageNumber + 1;
}

const calculateFifth = (pageNumber: number, pageCount: number) => {
    if (pageNumber < 2 || pageNumber + 3 >= pageCount) {
        return pageCount - 1;
    }
    if (pageNumber === 2) {
        return pageNumber + 3;
    }
    return pageCount >= 2 && pageCount <= 4 ? 5 : pageNumber + 2;
}

export const TablePagination: React.FC<TablePaginationProps> = ({ className, cursor}) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const requestPage = (page:number) => {
        cenSocketProtocol.requestCursorPage(cursor.channel, cursor.cursorId, page);
    }
    const pageCount = cursor.pageCount - 1;
    const pageNumber = cursor.pageNumber;
    /*
    Show the pagination:
    0: 1 2 3 . 8 9 10
    1: 1 2 3 . 8 9 10
    2: 1 2 3 4 5 . 10
    3: 1 2 3 4 5 . 10
    4: 1 . 3 4 5 . 10
    5: 1 . 4 5 6 . 10
    6: 1 . 5 6 7 . 10
    7: 1 . 6 7 8 . 10
    8: 1 2 3 . 8 9 10
    9: 1 2 3 . 8 9 10
     */
    const first:number = 1;
    const second:number = pageNumber < 4 || pageNumber + 2 >= pageCount ? 2 : -1;
    const third:number = pageNumber < 5 || pageNumber + 2 >= pageCount ? 3 : pageNumber - 1;
    const fourth:number = calculateFourth(pageNumber, pageCount);
    const fifth:number = calculateFifth(pageNumber, pageCount);
    const sixth:number = pageNumber < 2 || pageNumber + 2 >= pageCount ? pageCount : -1;
    const seventh:number = pageCount + 1;
    return (
        <StyledTablePagination className={className}>
            <div className={'previous-button-wrap'}>
                {cursor.pageNumber > 0 &&
                <button>
                    <ArrowLeftIcon/>
                    <div className={'text'} onClick={() => requestPage(cursor.pageNumber - 1)}>
                        Previous
                    </div>
                </button>}
            </div>
            <div className={'pagination-numbers'}>
                {pageCount > 0 && <PaginationNumberBase content={first} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 0 && <PaginationNumberBase content={second} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 1 && <PaginationNumberBase content={third} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 2 && <PaginationNumberBase content={fourth} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 3 && <PaginationNumberBase content={fifth} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 4 && <PaginationNumberBase content={sixth} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
                {pageCount > 5 && <PaginationNumberBase content={seventh} pageCount={pageCount} pageNumber={pageNumber} onClick={requestPage} />}
            </div>
            <div className={'next-button-wrap'}>
                {(cursor.pageNumber + 1 < cursor.pageCount) && <button>
                    <div className={'text'} onClick={() => requestPage(cursor.pageNumber + 1)}>
                        Next
                    </div>
                    <ArrowRightIcon />
                </button>}
            </div>
        </StyledTablePagination>
    );
};
