import React, { ReactNode } from 'react';
import { StyledQuestionItemWrapper } from './QuestionItemWrapper.styles';
import { ItemProcessCircle } from './ItemProcessCircle';
import { ItemProcessCircleWrapper } from './ItemProcessCircleWrapper';
import { TextWrapper } from './TextWrapper';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ItemProcessText } from './ItemProcessText';
import { RechtlicheBasisdatenIcon } from '../../../../../../../components/icons/RechtlicheBasisdatenIcon';
import { WirtschaftlicheBasisdatenIcon } from '../../../../../../../components/icons/WirtschaftlicheBasisdatenIcon';
import { FinanzielleBasisdatenIcon } from '../../../../../../../components/icons/FinanzielleBasisdatenIcon';
import { BetrieblicheBasisdatenIcon } from '../../../../../../../components/icons/BetrieblicheBasisdatenIcon';

interface QuestionItemWrapperProps {
    className?: string;
    children?: ReactNode;
    value: number;
    scale: number;
    title: string;
    subtitle: string;
    secondSubtitle?: string;
    active: boolean;
    link: string | undefined;
    collapsed: boolean;
    smallCircleText: ReactNode;
}

const findIcon = (title: string, className: string): ReactNode | undefined => {
    if (title === 'Rechtliche Basisdaten') {
        return <RechtlicheBasisdatenIcon className={className} />
    } else if (title === 'Wirtschaftliche Basisdaten') {
        return <WirtschaftlicheBasisdatenIcon className={className} />
    } else if (title === 'Finanzielle Basisdaten') {
        return <FinanzielleBasisdatenIcon className={className} />
    } else if (title === 'Betriebliche Basisdaten') {
        return <BetrieblicheBasisdatenIcon className={className} />
    } else {
        return undefined;
    }
}
export const QuestionItemWrapper: React.FC<QuestionItemWrapperProps> = ({ className, value, scale, title, subtitle, secondSubtitle, link, active, collapsed, smallCircleText, children }) => {
    const navigate = useNavigate();
    return <StyledQuestionItemWrapper className={classNames(className, active ? 'active' : 'inactive')} onClick={() => link && navigate(link)}>
        <div className={'icon-circle'}>
            {findIcon(title, 'icon')}
        </div>
        <TextWrapper title={title} subtitle={subtitle} collapsed={collapsed} secondSubtitle={secondSubtitle}/>
    </StyledQuestionItemWrapper>;
};
