import React, { ReactNode, useEffect, useState } from 'react';
import { StyledOryField } from './OryField.styles';
import { IOryUIOptions, mapIdentifierToClass, mapIdentifierToLabel } from '../utils';
import classNames from 'classnames';
import { UiNodeInputAttributes, UiText } from '@ory/client';
import { OryErrorMessage } from '../OryLayout';

interface OryFieldProps {
    className?: string;
    children?: ReactNode;
    attrs: UiNodeInputAttributes;
    options: IOryUIOptions;
    messages: Array<UiText>;
}

export const OryField: React.FC<OryFieldProps> = ({ attrs, options, className, children, messages }) => {
    const [fieldName, setFieldName] = useState<string>(attrs.name);
    const [fieldValue, setFieldValue] = useState<string>(attrs.value);
    useEffect(() => {
        if (fieldName !== attrs.name) {
            setFieldValue(attrs.value || '');
        }
        setFieldName(attrs.name);
        console.log(`changed to ${attrs.name} with value ${attrs.value}`);
    }, [attrs.name]);
    return (
            <StyledOryField className={classNames(className, mapIdentifierToClass(attrs.name), 'inputField')}>
                <label htmlFor={attrs.name} className={'label'}>
                    {mapIdentifierToLabel(attrs.name)}{attrs.required ? "*" : ""}
                </label>
                <input
                    name={fieldName}
                    type={attrs.type}
                    className={'field'}
                    autoComplete={
                        attrs.autocomplete || attrs.name === 'identifier'
                            ? 'username'
                            : ''
                    }
                    required={attrs.required}
                    disabled={attrs.disabled}
                    value={fieldValue}
                    onChange={(evt) => {
                        const value = (evt.target as HTMLInputElement).value;
                        setFieldValue(value);
                    }}
                />
                {attrs.name === 'password' &&
                    options.passwordHelper
                }
                {messages.map((element: UiText, index: number) =>
                    <div key={element.id} className={classNames("errorMessage", "form")}>
                        {element.text}
                    </div>
                )}
            </StyledOryField>
    );
};
