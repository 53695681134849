import styled from 'styled-components';

export const StyledReportIconWrapper = styled.div`
    width: 3.9375rem;
    height: 4.625rem;
    flex-shrink: 0;
    position: relative;
    .overlay {
        position: absolute;
        top: -12px;
        left: -8px;
    }
    .rectangle {
        width: 2.1875rem;
        height: 0.875rem;
        position: absolute;
        left: 0.75rem;
        top: 1.51269rem;
        border-radius: 0.125rem;
        background: #74E6CB;
    }
`;
