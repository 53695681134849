import styled from 'styled-components';

export const StyledProgressCircle = styled.div`
    position: relative;
    width: 11.5625rem;
    height: 11.5625rem;
    // transition: width 0.8s ease, height 0.8s ease;

    &.small {
        width: 7.1875rem;
        height: 7.1875rem;
    }

    .circleIcon {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
    }
`;
