import React, { ReactNode } from 'react';
import { StyledContentSubTitle } from './ContentSubTitle.styles';

interface ContentSubTitleProps {
    className?: string;
    children?: ReactNode;
    text: string;
}

export const ContentSubTitle: React.FC<ContentSubTitleProps> = ({ className, text, children }) => {
    return <StyledContentSubTitle className={className}>{text}{children}</StyledContentSubTitle>;
};
