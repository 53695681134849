import React, { ReactNode } from 'react';
import { StyledTableHeadColumn } from './TableHeadColumn.styles';

interface TableHeadColumnProps {
    className?: string;
    children?: ReactNode;
}

export const TableHeadColumn: React.FC<TableHeadColumnProps> = ({ className, children }) => {
    return <StyledTableHeadColumn className={className}>{children}</StyledTableHeadColumn>;
};
