import React, { ReactNode, useEffect, useState } from 'react';
import { StyledTextTemplate, StyledTextTemplateTextArea } from './TextTemplate.styles';
import { IQuestionData } from '../../../../types';
import { useWebSocketContext } from '@baumeister/web-cen-protocol';
import { ITextValue } from '../../../../../../../../serverTypes';
import { NumericFormat } from 'react-number-format';
import { Input, Tooltip } from '@baumeister/web-ui-bootstrap';

interface TextTemplateProps {
    className?: string;
    children?: ReactNode;
    questionData: IQuestionData;
    inTable: boolean;
}

export const TextTemplate: React.FC<TextTemplateProps> = ({ className, questionData, inTable, children }) => {
    const { cenSocketProtocol } = useWebSocketContext();
    const [ value, setValue ] = useState<string>((questionData.values[0].value as ITextValue).input || '');
    const onAccept = (value: string) => {
        cenSocketProtocol.requestUpdateValue(questionData.values[0].link, "input", value);
    }
    useEffect(() => {
        const inputValue = (questionData.values[0].value as ITextValue).input || '';
        if (inputValue !== value) {
            setValue(inputValue);
        }
    }, [(questionData.values[0].value as ITextValue).input]);
    const disabled = questionData.instance.locked;
    const description = (questionData.values[0].value as ITextValue).description;
    const tooltipId = description ? `text-tooltip-${questionData.values[0].value.baseValueId}` : undefined;
    if (inTable) {
        return <StyledTextTemplate
            className={className}
            disabled={disabled}
            onChange={(evt) => {
                const value = (evt.target as HTMLInputElement).value;
                setValue(value);
                onAccept(value);
            }}
            value={value}
        >{children}</StyledTextTemplate>;
    }
    return <React.Fragment>
        <StyledTextTemplateTextArea
                className={className}
                disabled={disabled}
                minRows={1}
                onChange={(evt) => {
                    const value = (evt.target as HTMLTextAreaElement).value;
                    setValue(value);
                    onAccept(value);
                }}
                value={value}
            >{children}</StyledTextTemplateTextArea>
        {description && tooltipId && <Tooltip className={'notice-tooltip'} placement={'top'} target={tooltipId}>
            {description}
        </Tooltip>}
    </React.Fragment>;
};
