import React, { ReactNode } from 'react';
import { StyledTopMenu } from './TopMenu.styles';
import { useAppSelector } from '../../store';
import { selectPageData } from '@baumeister/web-cen-protocol';
import { ICompany, IUser } from '../../serverTypes';
import { Avatar } from './Avatar';
import { SignOutIcon } from '../icons/SignOutIcon';
import { useNavigate } from 'react-router-dom';

interface TopMenuProps {
    className?: string;
    children?: ReactNode;
    logOutUrl: string | undefined;
}

export const TopMenu: React.FC<TopMenuProps> = ({ className, logOutUrl, children }) => {
    const userData = useAppSelector((state) =>
        selectPageData(state.entities, 'user')
    );
    const companyData = useAppSelector((state) =>
        selectPageData(state.entities, 'company')
    );
    const navigate = useNavigate();
    const user = userData?.data as IUser;
    const company = companyData?.data as ICompany;
    const baseDataUrl = '/company/basedata';

    return (
        <StyledTopMenu className={className}>
            <div className={'titleDescription'}>
                <div className={'title'}>{company?.name}</div>
            </div>
            <div className={'frame4580'}>
                <div className={'frame4581'}>
                    <div className={'innerContent'}>
                        <div className={'innerHead'}>
                            <Avatar />
                            <div className={'frame4582'}>
                                <div className={'headName'}>{user.firstName} {user.lastName}</div>
                                <div className={'headMail'}>{user.email}</div>
                            </div>
                        </div>
                        <div className={'frame4585'}>
                            <div className={'menuEntry'} onClick={() => navigate(baseDataUrl)}>
                                <div className={'innerMenuEntry'}>
                                    <div className={'innerMenuEntryText'}>
                                        Unternehmensdaten
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'line3'} />
                    </div>
                    <div className={'innerFooter'}>
                        <a className={'signOutFrame'} href={logOutUrl}>
                            <div className={'signOutText'}>Ausloggen</div>
                            <div className={'emptyFrame'}/>
                            <SignOutIcon className={'signOutIcon'}/>
                        </a>
                    </div>
                </div>
            </div>
            {children}
        </StyledTopMenu>
    );
};
