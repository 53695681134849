import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

export const StyledNumericTemplate = styled(NumericFormat)`
    display: flex;
    padding: 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid #D8DCE5;
    background: #FFF;
    color: #1A384E;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:disabled {
        opacity: 0.5;
    }
`;
