import React, { ReactNode } from 'react';
import { StyledEmptyColexoLogo } from './EmptyColexoLogo.styles';

interface EmptyColexoLogoProps {
    className?: string;
    children?: ReactNode;
}

export const EmptyColexoLogo: React.FC<EmptyColexoLogoProps> = ({ className, children }) => {
    return (
        <StyledEmptyColexoLogo className={className}>
            {children}
        </StyledEmptyColexoLogo>
    );
};
