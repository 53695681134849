import styled from 'styled-components';

export const StyledTransactionSection = styled.div`
    display: flex;
    position: relative;
    padding: 6.25rem 3.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--Base-Neutral-50, #F6F9FF);
    .communication-wrapper {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-3xl, 1.5rem);
        align-self: stretch;
    }
`;
