import styled from 'styled-components';

export const StyledTableHeader = styled.div`
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.375rem 0.375rem 0rem 0rem;
    background: #DFE3EA;
`;
