import styled from 'styled-components';

export const StyledContentTitle = styled.div`
    color: #1A384E;
    text-align: center;
    font-family: Lato;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 3rem */
`;
