import styled from 'styled-components';

// email-input-wrapper
export const StyledBaseDataForm = styled.div`
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: stretch;
    border-radius: var(--radius-xl, 0.75rem);
    background: var(--Base-White, #FFF);

    /* global-drop */
    box-shadow: 0px 4px 20px 0px rgba(129, 129, 129, 0.16);
    .formTitle {
        color: var(--Base-Neutral-600, #1A384E);

        /* Heading 4 */
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 2.6rem */
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 1.5rem);
        align-self: stretch;
    }
    .saveButton {
        align-self: initial;
    }
`;
