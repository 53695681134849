import React, { ReactNode } from 'react';
import { StyledContentWrapper } from './ContentWrapper.styles';
import { ColexoIcon } from '../../../../../../../../components/icons/ColexoIcon';
import { Button } from '../../../../../../../../components/button/Button';

interface ContentWrapperProps {
    className?: string;
    children?: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ className, children }) => {
    return (
        <StyledContentWrapper className={className}>
            <div className={'clx-raum-logo'}>
                <ColexoIcon className={'icon'} />
                <div className={'text'}>-Raum</div>
            </div>
            <div className={'text-wrapper'}>
                <div className={'line'}>An 5 Interessenten verschickt</div>
                <div className={'line'}>3 Rückmeldungen</div>
                <div className={'line'}>2 ungelesene Nachrichten</div>
            </div>
            <Button caption={'Zum CLX-Marktplatz'} variant={'primary'}/>
        </StyledContentWrapper>
    );
};
