import React, { ReactNode } from 'react';
import {
    StyledQuestionItemList,
} from '../../../../../info/sectiontype/content/left/questionitemlist/QuestionItemList.styles';
import { useDocumentSectionTypeInstanceData } from '../../../DocumentSectionTypeInstanceDataProvider';
import {
    QuestionItemWrapper,
} from '../../../../../info/sectiontype/content/left/questionitemwrapper/QuestionItemWrapper';
import { SmallCircleText } from '../smallcircletext/SmallCircleText';

interface QuestionItemListProps {
    className?: string;
    children?: ReactNode;
    collapsed: boolean;
}

export const QuestionItemList: React.FC<QuestionItemListProps> = ({ className, collapsed, children }) => {
    const documentSectionTypeInstanceData = useDocumentSectionTypeInstanceData();
    return (
        <StyledQuestionItemList className={className}>
            {documentSectionTypeInstanceData.sections.map((element) =>
                <QuestionItemWrapper key={element.instance?.sectionTypeInstanceId}
                                     value={element.document?.documentsUploaded || 0}
                                     scale={element.document?.documentsUploaded || 0}
                                     title={element.design?.title || ''}
                                     subtitle={element.design?.subTitle || ''}
                                     active={element?.orderNumber === documentSectionTypeInstanceData?.currentType?.orderNumber}
                                     link={element.documentDataLink}
                                     collapsed={collapsed}
                                     smallCircleText={<SmallCircleText count={element.document?.documentsUploaded || 0}/>}
                />
            )}
        </StyledQuestionItemList>
    );
};
