import styled from 'styled-components';
import { AlertCard } from './alert/AlertCard';

export const StyledFileValueProcessingAlert = styled(AlertCard)`
    & .link {
        color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
        text-align: center;

        /* Paragraph M */
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
        text-decoration-line: underline;
        align-self: stretch;
    }
    & .timerButton {
        & > .caption {
            color: var(--Base-Neutral-300, rgba(26, 56, 78, 0.50));
            text-align: center;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 1rem */
        }
        &.btn-disabled {
            opacity: 1;
        }
    }
`;


export const StyledTimer = styled.div`
    width: 2rem;
    height: 2rem;
    position: relative;
    & .text {
        position: absolute;
        left: calc((2rem - 1.21156rem) / 2);
        top: calc((2rem - 1.21156rem) / 2);
        display: flex;
        width: 1.21156rem;
        height: 1.21156rem;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: var(--Base-Neutral-600, #1A384E);
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 0.75rem */
    }
    & .ellipse {
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        fill: var(--Base-Neutral-600, #1A384E);
    }
    `;
